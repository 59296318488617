import { makeAutoObservable, runInAction } from 'mobx'
import React from 'react'
import { INews, INewsComment, INewsDetails, INewsLikeRequest, INewsPostRequest, INewsRequest } from '../../_common/model/news'
import { newsRepository, NewsRepository } from '../../_common/repository/news'
export class NewsStore {
    newsAPI: NewsRepository

    news_list: INews[] = []
    news_loading = false
    news_error = ""
    page_no = 0
    more_data_available = false

    news_details?: INewsDetails
    news_details_loading = false
    news_details_error = ""

    news_like_error = ""
    news_like_loading = false

    news_post_error = ""
    news_post_loading = false

    news_comments?: INewsComment[]
    news_comments_loading = false
    news_comments_error = ""

    constructor(args: {
        newsAPI: NewsRepository
    }) {
        makeAutoObservable(this);
        this.newsAPI = args.newsAPI;
    }

    loadNews = async (pageNo: number) => {
        const resp = await this.newsAPI.getNews(pageNo);
        runInAction(() => {
            this.news_loading = true;
            this.news_error = "";
        })
        if (resp.hasError) {
            runInAction(() => {
                this.news_loading = false;
                this.news_error = resp.error!;
                this.more_data_available = false;
            })
            return
        }
        runInAction(() => {
            this.news_loading = false;
            if (this.news_list.length <=0 ) {
                this.news_list = [...this.news_list, ...resp.result!];
            }
            else {
                this.news_list = resp.result!;
            }
            this.more_data_available = true;
        })
    }
    loadNewsDetails = async (id: string, userId: string) => {
        const resp = await this.newsAPI.getNewsDetails(id, userId);
        runInAction(() => {
            this.news_details_loading = true;
            this.news_details_error = "";
        })
        if (resp.hasError) {
            runInAction(() => {
                this.news_details_loading = false;
                this.news_details_error = resp.error!;
                this.more_data_available = false;
            })
            return
        }
        runInAction(() => {
            this.news_details_loading = false;
            this.news_details = resp.result!;
        })
    }

    addNewslike = async (payload: INewsLikeRequest) => {
        const resp = await this.newsAPI.setNewsLike(payload);
        runInAction(() => {
            this.news_like_loading = true;
            this.news_like_error = "";
        })
        if (resp.hasError) {
            runInAction(() => {
                this.news_like_loading = false;
                this.news_like_error = resp.error!;
            })
            return
        }
        runInAction(() => {
            this.news_like_loading = false;
        })
        this.loadNewsDetails(payload.news_id, payload.user_id)
    }

    addNewsPost = async (payload: INewsPostRequest) => {
        const resp = await this.newsAPI.setNewsPost(payload);
        runInAction(() => {
            this.news_post_loading = true;
            this.news_post_error = "";
        })
        if (resp.hasError) {
            runInAction(() => {
                this.news_post_loading = false;
                this.news_post_error = resp.error!;
            })
            return
        }
        runInAction(() => {
            this.news_post_loading = false;
        })
        this.loadNewsComments(payload)
    }

    loadMoreNews = () => {
        this.page_no++
        this.loadNews(this.page_no)
    }

    loadNewsComments = async (params: INewsRequest) => {
        const resp = await this.newsAPI.getNewsComments(params);
        runInAction(() => {
            this.news_comments_loading = true;
            this.news_comments_error = "";
        })
        if (resp.hasError) {
            runInAction(() => {
                this.news_comments_loading = false;
                this.news_comments_error = resp.error!;
            })
            return
        }
        runInAction(() => {
            this.news_comments_loading = false;
            this.news_comments = resp.result!;
        })
    }
}
export const NewsStoreContext = React.createContext(new NewsStore({ newsAPI: newsRepository }));
export const useNewsStore = () => React.useContext(NewsStoreContext);