// import {oldApi as api} from "../../core/api";
import axios from "axios";
import api from "../../core/api";
import { IGlobalSearchRes } from "../model/globalsearchfilter";
import { R } from "../pkg/types";

export interface IGlobalSearchRepository {
  globalSearch(payload:{
    searchtext:string,
    PTYPE:string,
    PNO:string
  }): Promise<R<IGlobalSearchRes[]>>;
}

class GlobalSearchRepository implements IGlobalSearchRepository {
  async globalSearch(payload:{
    searchtext:string,
    PTYPE:string,
     PNO:string
  }): Promise<R<IGlobalSearchRes[]>> {
    try {
      const resp = await api.get(`/globalsearch?${new URLSearchParams(payload)}`);
      const list = resp.data.people || resp.data.news || resp.data.games || resp.data.teams || resp.data.tv || resp.data.tournament || resp.data.royalbattles as IGlobalSearchRes[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }
}

export const globalSearchRepo: IGlobalSearchRepository = new GlobalSearchRepository();
