import React from 'react';
import { Image } from '../../_common/widget/Image';
import img1 from '../../assets/images/itc-bingo/social-icons.svg'
import img2 from '../../assets/images/itc-bingo/social-icons2.svg'
import img3 from '../../assets/images/itc-bingo/social-icons3.svg'
import img4 from '../../assets/images/itc-bingo/social-icons4.svg'
import img5 from '../../assets/images/itc-bingo/social-icons5.svg'
import img6 from '../../assets/images/itc-bingo/social-icons6.svg'
import img7 from '../../assets/images/itc-bingo/social-icons7.svg'
import img8 from '../../assets/images/itc-bingo/social-icons8.svg'
import img9 from '../../assets/images/itc-bingo/social-icons9.svg'
const Social = () => {
    return (<>
        <div className='row bg-black'>
            <div className="col-10 mx-auto social-container mt-4 mt-md-5">
                <div className='row'>
                    <div className="col-12 col-md-6">
                        <div className="row">
                            <div className="col-10 col-md-8 col-lg-6 mx-auto">
                                <p className='social-heading-yellow float-none'>Bingo! Nachos</p>
                                <div className='d-flex justify-content-between align-items-center my-1 my-md-3'>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/bingo_snacks/">
                                        <Image src={img2} alt="..." />
                                     </a>
                                    {/*<a target="_blank" rel="noopener" href="https://twitter.com/IGLeSports"> */}
                                        {/* <Image src={img4} alt="..." /> */}
                                    {/* </a>
                                    <a target="_blank" rel="noopener" href="https://www.facebook.com/IGLnetwork/"> */}
                                        {/* <Image src={img3} alt="..." />
                                     </a> */}
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/BingoSnacks">
                                        <Image src={img1} alt="..." />
                                    </a>
                                    <img style={{visibility:"hidden"}} src={img1} alt="..." />
                                    <img style={{visibility:"hidden"}} src={img1} alt="..." />
                                    <img style={{visibility:"hidden"}} src={img1} alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3 mt-md-0 col-md-6">
                        <div className="row">
                            <div className="col-10 col-md-8 col-lg-6 mx-auto">
                                <p className='social-heading-blue float-none'>IGL</p>
                                <div className='d-flex justify-content-between align-items-center my-1 my-md-3'>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/iglnetwork/"> 
                                    <Image src={img5} alt="..." /></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/IGLeSports">
                                    <Image src={img6} alt="..." /> </a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/IGLnetwork/">
                                    <Image src={img7} alt="..." /> </a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCWRkKcQwMsfKfVhl8UEg70g">
                                    <Image src={img8} alt="..." /></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://discord.com/invite/iglnetwork">
                                    <Image src={img9} alt="..." /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Social