import React from "react";
import { Link, useParams } from 'react-router-dom'
import { UpcomingTournaments } from '../../tournament/view/UpcomingTournaments'
import { IGameDetails } from '../../_common/model/game'
import { SocialConnect } from '../../_common/widget/SocialConnect'
import { GameLikedList } from "../view/GameLikedList";
import { GameTeamList } from "../view/GameTeamList";
import { observer } from "mobx-react";
type Props = {
    data: IGameDetails
}
export const GameDetailsSection = observer((props: Props) => {
    const {id}=useParams();
    const {data} = props;
    const [showLikeModal, setLikeModal]=React.useState(false);
    const [showTeamModal, setTeamModal]=React.useState(false);

    const showLikedMembers=()=>setLikeModal(true)
    const closeLikedMembers=()=>setLikeModal(false)
    const showGameTeams=()=>setTeamModal(true)
    const closeGameTeams=()=>setTeamModal(false)

    return (
        <>
        {showLikeModal && <GameLikedList show={showLikeModal} onClose={closeLikedMembers}/>}
        {showTeamModal && <GameTeamList show={showTeamModal} onClose={closeGameTeams}/>}
            <div className="common-page-raw section">
                <div className="container">
                    <div className="record-list-raw section relative">
                        <div className="gamedetail section line-arrow"></div>
                        <div className="record-list-col section">
                            <ul>
                                <li>
                                    <h3>
                                        <Link to="#" onClick={showLikedMembers}>{data?.Glikecount || 0}<span>Likes</span></Link>
                                    </h3>
                                </li>
                                <li>
                                    <h3>
                                        <Link to="#">{data?.TournamentCount || 0}<span>Tournaments</span></Link>
                                    </h3>
                                </li>
                                <li>
                                    <h3><Link to="#" className="likeclassName" onClick={showGameTeams}>{data?.TeamCount || 0}<span>Active
                                        Teams</span></Link></h3>
                                </li>
                                <li>
                                    <h3><Link to="#">{data?.ChallengeCount || 0}<span>Challenges</span></Link></h3>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="about-section-raw section">
                        <div className="title-sec">
                            <h2>About</h2>
                        </div>
                        <p>{props.data?.GameDescription}</p>
                        <div className="section text-center mt50">
                            <span className="btn-ic ic-challenge d-none"><i></i>Challenge</span>
                            <Link to="/games" className="btn-ic ic-more-game"><i></i>View more games</Link>
                        </div>
                    </div>
                    <div className="game-lists-raw all-games-detail-raw section">
                        <div className="section gm-li-raw">
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="title-divider">Upcoming tournaments</h3>
                                    <UpcomingTournaments game_id={id}/>
                                </div>
                                <div className="col-12 text-center">
                                    <Link to="/tournament" className="mb-5 btn-sm btn-main loadMore txt-shad-none"><i></i>View more</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SocialConnect />
            </div>
        </>
    )
});