import { observer } from 'mobx-react';
import React from 'react'
import { useAuthStore } from '../../auth/store/auth';
import { IGL_IMAGE_URL } from '../../core/config';
import { useProfileStore } from '../store/profileInfo';
import { useParams } from 'react-router-dom';
import { UserProfileInfo as  UserProfileInfoData} from '../../_common/model/user';
import { Image } from '../../_common/widget/Image';
export const UserProfileInfo = observer(() => {
    const { user } = useAuthStore();
    const { loadProfileInfo, profile_info } = useProfileStore();
    React.useEffect(() => {
        loadProfileInfo(user?.id!)
    }, [])
    const imageOnErrorHandler = (
        event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        event.currentTarget.src = `${IGL_IMAGE_URL}/polygon-bg.png`;
    };

    return (
        <UserInfo data={profile_info}>
            <UserSocialDetails data={profile_info} />
        </UserInfo>
    )
})
export const OtherUserProfileInfo = observer(() => {
    const { loadOtherProfileInfo, other_profile_info } = useProfileStore();
    const { userid } = useParams();
    React.useEffect(() => {
        loadOtherProfileInfo(userid!)
    }, [userid]);

    return (
        <UserInfo data={other_profile_info}>
        </UserInfo>
    )
})
type Props = {
    data?: UserProfileInfoData;
    children?: React.ReactNode;
}
const UserInfo = observer((props: Props) => {
    const { data, children } = props;

    return (
        <div className="follow-cointer-col section text-center">
            <div className="user-avtar-col" id="ProfileImage">
                <Image src={data?.UserProfileImage!} alt='profile' />
                {/* <img src={data?.UserProfileImage} alt='profile' className='img-fluid' onError={imageOnErrorHandler} /> */}
            </div>
            <div className="profile-intro d-flex mb-3">
                <h1>{data?.username}</h1>
            </div>
            {children}
        </div>
    )
});
const UserSocialDetails = observer((props: Props) => {
    const { data } = props;
    return (
        <div className="follow-points">
            <ul>
                <li>
                    <span>{data?.follower_count}</span>
                    <h6>followers</h6>
                </li>
                <li>
                    <span>{data?.following_count}</span>

                    <h6>following</h6>
                </li>
                <li>
                    <span>{data?.IGLCOIN}</span>
                    <h6>current balance</h6>
                </li>
                <li>
                    <span>{data?.UserCredit}</span>
                    <h6>total earned</h6>
                </li>
                <li>
                    <span>{data?.total_Tournament}</span>
                    <h6>tournaments played</h6>
                </li>
            </ul>
        </div>
    )
})