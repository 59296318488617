import api from "../../core/api";
import { ILatestVideos } from "../model/latestvideos";
import { R } from "../pkg/types";


export class TrendingVideo {

    async getTrendingvideo(): Promise<R<ILatestVideos[]>> {
        try {
            const resp = await api.get(`/get_tvlist`);
            const list = resp.data.TVlist as ILatestVideos[];
            if (!list) throw resp.data.msg
            return new R({result: list});
        }
        catch (e: any) {
            return new R({error: e.response.data.msg});
        }
    } 
    // async getAllTrendingVideo(){
    //     try{
    //         const resp = await api.get(`/get_tvlist`);
    //         const list = resp.data.TVlist as ILatestVideos[];
    //         if (!list) throw resp.data.msg
    //         return new R({result: list});
    //     }
    //     catch(e:any){
    //         return new R({error: e.response.data.msg});
    //     }
    // }
}

export const trendingVideo = new TrendingVideo();