import { IGL_IMAGE_URL } from "../../core/config";

export const About = () => {
    return (
        <>
            <div className="main-wrapper section">
                <div className="container-fluid">
                    <div className="banner-con section">

                        <div className="main-wrapper about-main-raw section" style={{ paddingTop: "100px"}}>
                            <div className="container">
                                <h2 className="head-f text-uppercase text-center">WHO WE ARE</h2>
                                <p className="space-e"></p>
                                <div className="row">
                                    <div className="col-md-6">
                                        {/* <figure className="xaboutimg"> */}
                                            <img
                                                src={`${IGL_IMAGE_URL}/thumb-6.png`}
                                                className="about-us-img"
                                                alt="..."
                                            />
                                        {/* </figure> */}
                                    </div>

                                    <div className="col-md-6">
                                        <div className="text-f para-a">
                                            <p style={{ color: "white" }}>
                                                IGL was created by a team of gamers who strongly feel that Indian players need a stage to jump start
                                                their gaming careers. Participating on IGL will not only give amateur and professional gamers the ability
                                                to earn some serious cash, top tier gamers will also receive an opportunity to compete with other
                                                professionals on a national level! If you want to level up your life and make a career playing your
                                                favourite games, IGL is here for you. Keep an eye out for tournaments updated daily.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="myImg" id="play_icons">
                            <img alt="" className="panel-image-preview" src={`${IGL_IMAGE_URL}/vdo-banner-01.jpg`} />
                            <div className="caption">
                                <span className="fontz-text glow">
                                    <a href="https://www.youtube.com/watch?v=6oC-LQ3GbVM" data-lity=""><p className="img-center"><img src={`${IGL_IMAGE_URL}/video-images.png`} className="img-responsive play_icon" /></p></a>
                                    <p className="space-c"></p>
                                    <h3 className="sub-head-j text-uppercase">A Gaming League with a difference</h3>
                                    <p className="space-c"></p>
                                    <h3 className="sub-head-k text-uppercase">Watch the video</h3>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}