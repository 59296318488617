import api from "../../core/api";
import { BattleRoyaleTeam } from "../model/team";
import { BattleRoyale, IBattleRoyalePayload } from "../model/tournament";
import { R } from "../pkg/types";
export interface ICheckInBattleRoyale{"status":string,"msg":string}
export class BattleRoyaleRepository {
  async getBattleRoyaleDetails(id?: string, userId?: string): Promise<R<BattleRoyale>> {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      let resp;
      if(queryParams.has('from_campaign')){
      //  resp = await api.get(`/battleroyaledetails?battleroyale_id=${id}&user_id=${userId}&timestamp=${new Date().getTime()}`);
       resp = await api.get(`/get_brDetails_SP?battleroyale_id=${id}&user_id=${userId}&timestamp=${new Date().getTime()}`);
      }
      else{
         resp = await api.get(`/battleroyaledetails_new?battleroyale_id=${id}&user_id=${userId}&timestamp=${new Date().getTime()}`);
      }
      
      if(resp.data.status==="0"){
        return new R({ error: resp.data.msg });
      }
      const list = resp.data.BattleRoyaleDetails as BattleRoyale;
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }
  async getBattleRoyaleJoinedeams(id?: string, userId?: string, PNO?:number): Promise<R<BattleRoyaleTeam[]>> {
    try {
      const resp = await api.get(`/battleroyalejoinedteams?battleroyale_id=${id}&user_id=${userId}&PNO=${PNO?.toString()}`);
      const list = resp.data.BattleRoyaleJoinedTeamlist as BattleRoyaleTeam[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }
  async getBattleRoyaleCheckedInTeams(id?: string, userId?: string, PNO?:number): Promise<R<BattleRoyaleTeam[]>> {
    try {
      const resp = await api.get(`/battleroyalecheckinteams?battleroyale_id=${id}&user_id=${userId}&PNO=${PNO}`);
      const list = resp.data.BattleRoyaleCheckedinTeamlist as BattleRoyaleTeam[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }
  async joinBattleRoyale(payload: IBattleRoyalePayload): Promise<R<string>> {
    try {
      const resp = await api.post('/join_battleroyale',
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
      const result = resp.data.msg as string;
      if (!result) throw resp.data.msg
      return new R({ result });
    } catch (e) {
      return new R({ error: e });
    }
  }
  async checkinBattleRoyale(payload: IBattleRoyalePayload): Promise<R<ICheckInBattleRoyale>> {
    try {
      const resp = await api.post('/checkin_battleroyale',
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
      const result = resp.data as ICheckInBattleRoyale;
      if (!result) throw resp.data.msg
      return new R({ result });
    } catch (e) {
      return new R({ error: e });
    }
  }
};

export const battleRoyaleRepository = new BattleRoyaleRepository();