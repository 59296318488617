import React from 'react';
import IglLogo from '../../assets/images/logo-3d-compressed.png';
import BingoLogo from '../../assets/images/itc-bingo/bingo logo.png';
import BingoLogo2 from '../../assets/images/itc-bingo/bingo logo 2.png';

const BinoChip = () => {
  return (
    <div className='row'>
        <div className="col-10 mx-auto bingo-chip my-4 mt-md-0 mb-md-5">
                <img src={IglLogo} alt="..." className='p-2'/>
                <img src={BingoLogo} alt="..." className='mx-4 mx-md-5'/>
                <img src={BingoLogo2} alt="..."/>
        </div>
    </div>
  )
}
export const BinoChipModal = () => {
  return (
    <div className='row mt-md-4'>
        <div className="col-12 mx-auto bingo-chip my-2 mt-md-0 mb-md-2">
                <img src={IglLogo} alt="..." className='p-2'/>
                <img src={BingoLogo} alt="..." className='mx-4 mx-md-3'/>
                <img src={BingoLogo2} alt="..."/>
        </div>
    </div>
  )
}

export default BinoChip