import React from 'react'
import { RWebShare } from "react-web-share";
export const ShareButton = () => {
  return (
    <RWebShare
      data={{
        text: "IGLNetwork",
        url: window.location.href,
        title: "IGLNetwork",
      }}
    >
      <button className="btn-ac share text-center"><span>Share</span></button>
    </RWebShare>
  )
}