import React from 'react'
type Props = {required: boolean};
export const TeamMembersHeading = ({required}: Props) => {
    return (
        <div className="row">
            <div className="col-6">
                <div className="form-grop group-a">
                    <label htmlFor="" className={`${required ? "required-field" : ""}`}>Team member</label>
                </div>
            </div>
            <div className="col-6">
                <div className="form-grop group-a">
                    <label htmlFor="" className={`${required ? "required-field" : ""}`}>In game id</label>
                </div>
            </div>
        </div>
    )
}