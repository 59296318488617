
const Page404 = () => {
    return (
        <>
            <div className="ongoing-events-raw section" style={{backgroundSize:"cover", backgroundPosition:"80%"}}>
                <div className="container">
                    <div className="game-lists-raw ongoing-competition-raw section">
                        <div className="title-raw">
                            <h1 style={{minHeight:"400px", display:"flex", justifyContent:"center",alignItems:"center"}}>
                                404 <br/> NOT FOUND
                            </h1>
                        </div>

                        {/* <div className="competition-tab-raw section">
                            <ul className="nav nav-tabs" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                                <li className="nav-item">
                                    <h3>Page Not Found</h3>
                                </li>
                            </ul>

                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Page404;