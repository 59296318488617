import { Field } from 'formik';
import React from 'react'
import { FieldProps } from '../../_common/model/field';
import { FieldError } from '../../_common/widget/FieldError';

const Username = (props: FieldProps) => {
    const { name="username", label, className } = props;
    return (
        <div className={className || "col-md-6"}>
            <div className="floating-label-group section">
                <Field
                    {...props}
                    className="form-control"
                    autoComplete="new-username"
                />
                <label className="floating-label">{label}</label>
                <FieldError name={name}/>
            </div>
        </div>
    )
}

export default Username