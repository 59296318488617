import React from 'react'
import { Modal, Form } from 'react-bootstrap'
import { useAuthStore } from '../store/auth';
import { observer } from 'mobx-react';
import { RegisterForm } from './RegisterForm';
import { useLocation } from 'react-router-dom';
import { BinoChipModal } from '../../itc-bingo/components/BinoChip';
import BingoPackets from '../../itc-bingo/components/BingoPackets';

const RegisterUser = observer(() => {
  const { show_register, hideRegister } = useAuthStore();
  const {pathname}=useLocation();
  const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const campaign = new URLSearchParams(window.location.search).get('from_campaign');
  if (!show_register) {
    return <></>
  }
  return (
    <Modal show={show_register} className={`register-modal modal-raw ${(queryParams && campaign ==="itc")||(pathname==="/bingo-nachos") ? "itc-tournament-details-col":""}`}>
      <button type="button" className="btn-close-modal" onClick={()=>hideRegister(pathname)}>&times;</button>
      <Modal.Body>
      {((queryParams && campaign ==="itc")||(pathname==="/bingo-nachos")) && <BinoChipModal/>}
        <RegisterForm />
        {((queryParams && campaign ==="itc")||(pathname==="/bingo-nachos")) && <BingoPackets/>}
      </Modal.Body>
    </Modal>
  )
})

export default RegisterUser