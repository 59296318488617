import React, { useEffect, useRef } from 'react'
// import { IGL_IMAGE_URL } from '../../core/config';
import ImageLogo from '../../assets/images/logo-3d-compressed.png';
import { ImageProps } from '../../_common/model/props.interface';
export const Image = (props: ImageProps)=>{
    const {src, alt, onErrorImg, onErrorStyle} = props;
    const styleObjectToString = (style: React.CSSProperties) => {
        return Object.entries(style)
          .map(([key, value]) => `${key}: ${value}`)
          .join('; ');
      };
      const onErrorStyleString = onErrorStyle ? styleObjectToString(onErrorStyle) : '';      
      const imgRef = useRef<HTMLImageElement>(null);
      useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                // Load the image when it comes into view
                const img = entry.target as HTMLImageElement;
                img.src = src;
                observer.unobserve(img);
              }
            });
          },
          { threshold: 0.5 } // Adjust the threshold as needed
        );
    
        // Observe the image element
        const imgElement = imgRef.current;
        if (imgElement) {
          observer.observe(imgElement);
    
          // Cleanup the observer when the component unmounts
          return () => observer.disconnect();
        }
      }, [src]);
    return (
        <img
      ref={imgRef}
      src={ImageLogo} // Initial src, will be replaced when in viewport
      alt={alt}
      className='img-fluid'
      onError={(event) => {
        event.currentTarget.src = onErrorImg || ImageLogo;
        event.currentTarget.style.cssText = onErrorStyleString;
      }}
    />
    )
}
