import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { TournamentBracket, TournamentWinners } from "../../_common/model/tournament";
import { tournamentsRepository, ITournamentsRepository } from "../../_common/repository/tournaments";
import Logo from '../../assets/images/logo-3d-compressed.png';
export class TournamentBracketStore {
  tournament_brackets?: TournamentBracket | null =null;;
  tournament_bracket_error = "";
  tournament_bracket_loading = true;

  tournament_winners_loading:boolean=false;
  tournament_winners_error:string="";
  tournament_winners:TournamentWinners[]=[];

  private api: ITournamentsRepository;

  constructor(args: { api: ITournamentsRepository }) {
    makeAutoObservable(this);
    this.api = args.api;
  }

  loadTournamentBrackets = async (id: string, userId: string) => {
    runInAction(() => {
      this.tournament_bracket_loading = true;
      this.tournament_bracket_error = "";
      this.tournament_brackets=null;
    });
    const resp = await this.api.getTournamentBrackets(id, userId);

    if (resp.hasError) {
      runInAction(() => {
       this.tournament_bracket_error = resp.error!;
       this.tournament_bracket_loading = false;
       this.tournament_brackets=null;
      });
      return;
    }
    runInAction(() => {
      // this.tournament_brackets = resp.result!;
      if(resp.result!.TournamentBrackets){
        this.tournament_bracket_error = "";
        this.tournament_brackets = resp.result!.TournamentBrackets as TournamentBracket;
      }
      else{
        this.tournament_bracket_error = "The tournament has not yet begun, brackets will be populated once the tournament begins";
        this.tournament_brackets = resp.result!.TournamentEmptyBrackets  as TournamentBracket
      }
      this.tournament_bracket_loading = false;
    });
  };
 
  getTournamentWinners = async (id: string, userId: string) => {
    runInAction(() => {
      this.tournament_winners_loading = true;
      this.tournament_winners_error = "";
      this.tournament_winners=[];
    });
    const resp = await this.api.getTournamentWinners(id, userId);

    if (resp.hasError) {
      runInAction(() => {
      //  this.tournament_winners_error = "The tournament has not yet begun, brackets will be populated once the tournament begins";
        this.tournament_winners_loading = false;
        this.tournament_winners=[
          {
          TeamID: "",
          TeamImage: Logo,
          TeamName:"",
          TeamOwnerID:"", 
          TournamentWinnerPosition: "3"
        },
        {
          TeamID: "",
          TeamImage: Logo,
          TeamName:"",
          TeamOwnerID:"", 
          TournamentWinnerPosition: "2"
        },
        {
          TeamID: "",
          TeamImage: Logo,
          TeamName:"",
          TeamOwnerID:"", 
          TournamentWinnerPosition: "1"
        }
      ];
      });
      return;
    }
    runInAction(() => {
      this.tournament_winners = resp.result!;
      this.tournament_winners_loading = false;
      this.tournament_winners_error = "";
    });
  };
}

export const TournamentBracketStoreContext = createContext(new TournamentBracketStore({ api: tournamentsRepository }));
export const useTournamentBracketStore = () => useContext(TournamentBracketStoreContext);
