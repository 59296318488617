import { makeAutoObservable, runInAction } from 'mobx';
import React from 'react'
import { walletRepository, WalletRepository } from "../../_common/repository/wallet";
import { IWalletStatusResp,ICheckUserBankDetails, IVerifyIfscCodeResp } from '../../_common/model/wallet';


export class PaymentStore {
    request_payment_loading = false;
    request_payment_error: string = "";
    request_payment_resp: string = "";

    bank_details_loading = false;
    bank_details_error: string = "";
    
    bank_details_resp = "";
    bank_details_modal: boolean = false;


    private paymentAPI: WalletRepository;


    walletStatusResp?:IWalletStatusResp={};
    wallet_error={}
    wallet_loading :boolean = false;


    checkUserBankDetails_lodaing : boolean = false;
    checkUserBankDetails_error={};
    checkUserBankDetailsResp?:ICheckUserBankDetails | null= null


    validating_ifsc_loading:boolean=false;
    ifsc_resp:IVerifyIfscCodeResp={};
    ifsc_error:string="";
    
    constructor(args: {
        paymentAPI: WalletRepository
    }) {
        makeAutoObservable(this);
        this.paymentAPI = args.paymentAPI
    }
    addRequestPayment = async (payload: any) => {
        runInAction(() => {
            this.request_payment_loading = true;
            this.request_payment_error = '';
            this.request_payment_resp='';
        })
        const resp = await this.paymentAPI.requestPayment(payload);

        if (resp.hasError) {
            runInAction(() => {
                this.request_payment_loading = false;
                this.request_payment_error = resp.error!;
                this.request_payment_resp='';
            })
            return resp;
        }
        runInAction(()=>{
            this.request_payment_loading = false;
            this.request_payment_error = "";
            this.request_payment_resp=resp.result.msg;
        })
        return resp
    }

    
    addBankDetails = async (payload: any) => {
        runInAction(() => {
            this.bank_details_loading = true;
        })
        const resp = await this.paymentAPI.bankDetails(payload);

        if (resp.hasError) {
            runInAction(() => {
                this.bank_details_loading = false
                this.bank_details_error = resp.error!;
                this.bank_details_resp = "";
                 this.bank_details_modal=false
            })
            return;
        }
                runInAction(()=>{
                this.bank_details_loading = false
                this.bank_details_resp=resp.result.msg;
                this.bank_details_modal=true;
                this.checkUserBankDetails({user_id:payload.user_id});
            })
            return resp;
    }

    closeBankDetailsModal = () =>{
         runInAction(()=>{
            this.bank_details_resp = "";
            this.bank_details_modal= false;
       
            // console.log(this.bank_details_modal)
        })
    }
    walletStatus=async (payload:object)=>{
        runInAction(() => {
            this.wallet_loading = true;
        })
        const resp = await this.paymentAPI.walletStatus(payload);

        if (resp.hasError) {
            runInAction(() => {
                this.wallet_loading = false
                this.wallet_error = resp.error!;
            })
            return;
        }
        runInAction(()=>{
            this.wallet_loading = false;
            this.walletStatusResp=resp.result!;
        })      
        return resp.result.current_balance!;
    }
   
     checkUserBankDetails = async (payload:object) => {
        
        runInAction(()=>{
            this.checkUserBankDetails_lodaing=true;
            this.checkUserBankDetails_error = '';
        })
        const resp= await this.paymentAPI.checkUserBankDetails(payload)
        if (resp.hasError) {
            runInAction(() => {
                this.checkUserBankDetails_lodaing=false;
                this.checkUserBankDetails_error = resp.error!;
            })
            return;
        }
        runInAction(()=>{
            this.checkUserBankDetails_lodaing=false;
            this.checkUserBankDetailsResp=resp.result!;
        })  
    }
    verify_ifsc_code= async (payload:string)=>{
        runInAction(()=>{
            this.validating_ifsc_loading=true;
        })
        const resp = await this.paymentAPI.verifyIfscCode(payload);
        if(resp.hasError){
            runInAction(()=>{
            this.ifsc_error=resp.error!;
            this.validating_ifsc_loading=false;
        })
            return;
        }
        runInAction(()=>{
            this.validating_ifsc_loading=false;
            this.ifsc_resp= resp.result!;
            this.ifsc_error="";
        })
    }
    clearIfscResp = ()=>{
        runInAction(()=>{
            this.ifsc_resp={}
            this.ifsc_error="";
            this.validating_ifsc_loading=false;
        })
    }
}
export const PaymentStoreContext = React.createContext(new PaymentStore({ paymentAPI: walletRepository }));
export const usePaymentStore = () => React.useContext(PaymentStoreContext);