import React from 'react'
import applewhite from "../../assets/images/apple_icon.png";
import applehover from "../../assets/images/hover_appstore.png";
import playwhite from "../../assets/images/android_app_icon.png";
import playhover from "../../assets/images/hover_playstore.png";
const FindUsOn = () => {


	const playstore = () => {
		document.getElementById("playstore_img")?.setAttribute("src", playhover);
		document.getElementById("play_store_text")?.setAttribute("style", "color:#1DF66A");
	}
	const playstoreOut = () => {
		document.getElementById("playstore_img")?.setAttribute("src", playwhite);
		document.getElementById("play_store_text")?.setAttribute("style", "color:#fff");
	}
	const appstore = () => {
		document.getElementById("appstore_img")?.setAttribute("src", applehover);
		document.getElementById("apple_store_text")?.setAttribute("style", "color:#00BFFF");
	}
	const appstoreOut = () => {
		document.getElementById("appstore_img")?.setAttribute("src", applewhite);
		document.getElementById("apple_store_text")?.setAttribute("style", "color:#fff");
	}


	return (
		<div>
			<div className="section">
				<div className="container">
					<div className="game-lists-raw find_us_on section">
						<div className="title-raw">
							<h1>Find Us On<span>Find Us On</span></h1>
						</div>
						<div className="competition-tab-raw section find_us_on_box" >
							<div className="section padding-xs find_us_bg">
								<div className="row mb-5">
									<div className="col-sm-12 col-md-8">
										<div className="div_contain">
											<p className="text-left text-white " style={{ padding: "15px 15px 80px 15px", lineHeight: "30px" }}>Turn your passion into profits.<br />Be the gaming content creator you want to be.</p>
											<div className="action_btn">
												<ul>
													<li>
														<a className="btn btn-7 btn-7a zmdi-shopping-cart" onMouseOver={() => appstore()} onMouseOut={() => appstoreOut()} rel="noreferrer noopener" target="_blank" href="https://apps.apple.com/us/app/igl-indian-gaming-league/id1490988972?ls=1" style={{ borderRadius: "15px", padding: "15px", textAlign: "left", background: " linear-gradient(270deg, rgba(0, 191, 255, 0.2) 0%, rgba(0, 191, 255, 0.5) 100%)" }}>
															<div id="btn_container">
																<img src={applewhite} id="appstore_img" width="30" height="30" alt="..." />
															</div>
															<span className="app_store">
																Download Our App
																<h4 id="apple_store_text">From App Store</h4>
															</span>
														</a>
													</li>
													<li>
														<a className="btn btn-7 btn-7a zmdi-shopping-cart" onMouseOver={() => playstore()} onMouseOut={() => playstoreOut()} target="_blank" rel="noreferrer noopener" href="https://play.google.com/store/apps/details?id=com.iglgames" download="IGL.apk" style={{ borderRadius: "15px", padding: " 15px", textAlign: "left", background: " linear-gradient(270deg, rgba(29, 246, 106, 0.2) 0%, rgba(29, 246, 106, 0.5) 100%)" }}>
															<div id="btn_container">
																<img src={playwhite} id="playstore_img" width="30" height="30" alt="..." />
															</div>
															<span className="play_store">
																Download Our App
																<h4 id="play_store_text">From Play Store</h4>
															</span>
														</a>
													</li>
												</ul>
											</div>
										</div>

									</div>
									<div className="col-sm-12 col-md-4">

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FindUsOn