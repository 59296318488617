import React from 'react'
import { IGL_IMAGE_URL } from '../../core/config'

export const SocialConnect = () => {
  return (
    <>
    <ul className="social-connects-raw">
        <li>
            <span>
                <a href="https://wa.me/919167332984" target="_blank" rel="noopener noreferrer"><img src={`${IGL_IMAGE_URL}/ic-whatsapp.png`}alt="Whatsapp" className="img-fluid"/></a>
            </span>
        </li>
        <li>
            <span>
                <a href="https://discord.com/invite/iglnetwork" target="_blank" rel="noopener noreferrer"><img src={`${IGL_IMAGE_URL}/ic-discord.png`} alt="Discord" className="img-fluid"/></a>
            </span>
        </li>
    </ul>
    </>
  )
}
