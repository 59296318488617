import React from 'react'
import { IGL_IMAGE_URL, IGL_URL } from '../../core/config'
import { Button } from './Button'
import { WalletType } from './WalletType'

export const ViewLedger = () => {
    return (
        <>
            <WalletType imgUrl={`${IGL_IMAGE_URL}/wallet.png`} title='View Ledger' >
                <Button label='View Ledger' path="/profile-ledgers" />
            </WalletType>
        </>
    )
}
