import React from 'react'
import { IMidwayImage} from '../../_common/model/midway';
import { Image } from './Image';
export const GalleryCard = ({data, onClick=()=>{}, active}:{data:IMidwayImage, onClick?:any, active:boolean}) => {
  return (
    <div className={`gallery-card col-6 col-md-4 ${active?"":"not-active"}`} onClick={onClick}>
        <div className='img-container'>
        <Image src={data.imageurl} alt="...."/>
        </div>
        <p className='discord-name'> <i className='fab fa-discord'/>{data.discordname}</p>
        <p className='user-name'>{data.username}</p>
    </div>
  )
}
export const GalleryCardLightBox = ({data, onClick=()=>{}, active}:{data:IMidwayImage, onClick?:any, active:boolean}) => {
  return (
    <div className={`gallery-card ${active?"":"not-active"}`} onClick={onClick}>
        <div className='img-container'>
        <Image src={data.imageurl} alt="...."/>
        </div>
        <p className='discord-name'> <i className='fab fa-discord'/>{data.discordname}</p>
        <p className='user-name'>{data.username}</p>
    </div>
  )
}
export const GalleryCardLoading = () => {
  return (
    <div className=' col-6 col-md-4'>
    <div className='gallery-card-loading'></div>
    </div>
  )
}
