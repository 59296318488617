import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Modal } from 'react-bootstrap'
// import { IGL_IMAGE_URL } from '../../core/config'
import { AccountNumber } from './AccountNumber'
import { IFSCCode } from './IFSCCode'
import { PanCardNumber } from './PanCardNumber'
import * as Yup from 'yup';
import { observer } from 'mobx-react'
import { useAuthStore } from '../../auth/store/auth'
import { usePaymentStore } from '../store/payment'
import { ICheckUserBankDetails } from '../../_common/model/wallet'
import { useNavigate } from 'react-router-dom'
import { ErrorModal } from '../../_common/widget/ErrorModal'
import CircularProgress from '@mui/material/CircularProgress';
import { BankDetailCheckBox } from './BankDetailCheckBox'

type Props = {
    show: boolean;
    onClose: React.MouseEventHandler<HTMLButtonElement> | any;
    checkUserBankDetailsResp: ICheckUserBankDetails | null;
    reedmeAmt: string;
    user_id: string;
}

const VALIDATION_SCHEMA = Yup.object({
    account_no: Yup.string().max(20).matches(/^[0-9]+$/,"Only Numbers are Allowed.").required("Enter the bank account number!"),
    ifsc_code: Yup.string().min(11).max(11).trim().required("Enter IFSC code!"),
    confirm_account_no:  Yup.string().required("Enter the bank account number again!")
    .oneOf([Yup.ref('account_no'), null], "Bank account numbers don't match!"),
    pancard_no:Yup.string().min(10,"Pan Card is Unique 10 Digit Aplhanumeric No.").max(10,"Pan Card is Unique 10 Digit Aplhanumeric No.").optional()
});

export const BankDetailsModal: React.FC<Props> = observer((props) => {
    const { show, onClose, checkUserBankDetailsResp, reedmeAmt } = props;
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const { user } = useAuthStore();
    const { addBankDetails, addRequestPayment, request_payment_error, wallet_loading, bank_details_loading, request_payment_loading,validating_ifsc_loading ,ifsc_error,request_payment_resp} = usePaymentStore();
    const navigate=useNavigate();
    const closeErrorModal = ()=>{
        setShowErrorModal(false)
    }
    const handleSubmit = async (values: any) => {
        const {accountno, ifsccode, pancardNumber, bankDetails_show }=checkUserBankDetailsResp!;
        const {account_no, ifsc_code, pancard_no, bankDetails_Form } = values;
        if(accountno === account_no && ifsccode === ifsc_code && pancardNumber === pancard_no  && (bankDetails_show==="1"?true:false)=== bankDetails_Form){
            await addRequestPayment({
                 user_id: user?.id!,
                 amount:reedmeAmt
             }).then(data => {
                 if (data.result !== undefined && data.result.status === "1") {
                     navigate("/profile/walletsuccesspayout");
                     return
                 }
                 setShowErrorModal(true);
             })
         }
        else {
             await addBankDetails({
                 user_id: user?.id!,
                 accountno: values.account_no,
                 ifsccode: values.ifsc_code,
                 pancardNumber: values.pancard_no,
                 bankDetails_show: values.bankDetails_Form===true?"1":"0"
               }).then((data)=>{
                if(data?.result){
                    addRequestPayment({
                        user_id: user?.id!,
                        amount:reedmeAmt
                    }); 
                }
               })
        }
         onClose();
    }
    // console.log(request_payment_resp)
    return (
        <>
        <Modal show={show} className="modal-raw create-team-modal modal-border-raw modal fade in">
            <button type="button" className="btn-close-modal" onClick={onClose}>&times;</button>
            <Modal.Body>
                <div className="title-raw downarrow mb30">
                    <h3>Bank Details<span>Bank Details</span></h3>
                </div>
                <div className="formboxmain">
                    
                    <p className="text-a text-center  mb-md-4 mb-3"><small>Please enter or update the bank details to get payment in your bank account.</small></p>
                    <Formik
                        initialValues={{
                            account_no: checkUserBankDetailsResp?.bankDetails_show==="1"?checkUserBankDetailsResp?.accountno : '',
                            confirm_account_no: checkUserBankDetailsResp?.bankDetails_show==="1"?checkUserBankDetailsResp?.accountno : '',
                            ifsc_code:checkUserBankDetailsResp?.bankDetails_show==="1"?  checkUserBankDetailsResp?.ifsccode :'',
                            pancard_no:checkUserBankDetailsResp?.bankDetails_show==="1" ? checkUserBankDetailsResp?.pancardNumber : '',
                            // in future if pancard required then need to initiate '' as above
                            bankDetails_Form: checkUserBankDetailsResp?.bankDetails_show==="0" ? false :true,
                        }}
                        validationSchema={
                            VALIDATION_SCHEMA
                        }
                        onSubmit={(values, { resetForm }) => {
                            handleSubmit(values);
                            // resetForm();
                        }}
                    >
                        <Form>
                            <div className="contact-form">
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <AccountNumber name='account_no' label="Account Number" mask/>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <AccountNumber label="Re-enter Account Number" name='confirm_account_no'/>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <IFSCCode name='ifsc_code'/>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <PanCardNumber name='pancard_no' />
                                    </div>
                                    <div className="col-12 mb-4">
                                    <BankDetailCheckBox name="bankDetails_Form" />
                                    </div>
                                </div>
                                <p className="space-e"></p>
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-6 col-md-2">
                                    <button className={`w-100 btn btn-a text-uppercase btn-border px-4 py-2 text-white ${wallet_loading || bank_details_loading || request_payment_loading || validating_ifsc_loading ? "disabled": ""}`} type = { ifsc_error==="" ?'submit':'button'}  style={{position: 'relative'}}>
                                        {wallet_loading || bank_details_loading || request_payment_loading || validating_ifsc_loading ? "Loading...": "Submit"}
                                        {wallet_loading || bank_details_loading || request_payment_loading || validating_ifsc_loading ?  <CircularProgress size={24} sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '60%',
                                            marginTop: '-12px',
                                        }}/>: <></>}
                                    </button>
                                    </div>
                                    <div className="col-6 col-md-2">
                                    <button className="w-100 btn btn-a text-uppercase btn-border px-4 py-2 text-white" type='button' onClick={onClose}>
                                        Close
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>
        <ErrorModal show={showErrorModal} onClose={closeErrorModal} >
            {request_payment_error}
            {request_payment_resp}
            {/* <br/> */}
            {/* Please try again! */}
        </ErrorModal>
        </>
    )
})