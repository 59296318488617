import React from 'react'
import { TournamentProps } from '../../_common/model/tournament'
import { GameAction } from '../widget/GameAction'
import { GameFullDetails } from '../widget/GameFullDetails'
import { GameInfo } from '../widget/GameInfo'
import { GameSchedule } from '../widget/GameSchedule'
import { observer } from 'mobx-react'
import BinoChip, { BinoChipModal } from '../../itc-bingo/components/BinoChip'
import BingoPackets from '../../itc-bingo/components/BingoPackets'
export const DetailsSection = observer((props: TournamentProps) => {
    const { bgImg, heading } = props;
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const from_campaign = new URLSearchParams(window.location.search).get('from_campaign');
    return (
        <div className={queryParams ? "main-wrapper tournament-details-col section itc-tournament-details-col" : "main-wrapper tournament-details-col section"} style={queryParams ? { background: `url(${require('../../assets/images/itc-main-banner.jpg')})`, backgroundSize: 'cover' } : {}}>
            <div className="inner-banner section" style={queryParams ? {} : {
                backgroundImage: `linear-gradient(44deg, rgba(0, 0, 0, 0.6), rgba(5, 6, 8, 0.6)),url(${bgImg}) `,
                height: '575', width: '1349'
            }}>
                {/* <div className="container-fluid gamedetail"> */}
                <div className="container-fluid">
                    <div className={queryParams ? "inner-banner-con title-raw title-raw-itc" : "inner-banner-con title-raw "}>
                        {(queryParams && from_campaign ==="itc") && <div className='float-none container mt-0 mb-3 mb-md-5'>
                            <div className="row">
                                <div className="col-12 col-md-8 mx-auto">
                                    <BinoChipModal />
                                </div>
                            </div>
                        </div>}
                        <h1 className='uppercase px-md-4 px-lg-5'>{heading}</h1>
                        <GameInfo {...props} />
                        <GameAction {...props} />
                    </div>
                </div>
            </div>
            <div className="common-page-raw tournaments-raw tournament-details-raw game-lists-raw section" >
                <div className="container">
                    <div className="table-wrapper pt50 leaderboard-raw  section">
                        <div className="container">
                            <GameSchedule {...props} />
                            <GameFullDetails {...props} />
                        </div>
                    </div>
                </div>
            </div>
            {/* {queryParams && from_campaign === "itc" &&<div className="container-fluid"><BinoChip/></div>} */}
            {queryParams && from_campaign === "itc" && <BingoPackets />}
        </div>
    )
})