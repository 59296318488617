import React from 'react'
import { Carousel } from 'react-bootstrap'
import HeaderBG from "../../assets/images/itc-bingo/header.png";
import HeaderBG2 from "../../assets/images/itc-bingo/pubg.png";
import HeaderBG3 from "../../assets/images/itc-bingo/new state.png";
import HeaderBG4 from "../../assets/images/itc-bingo/call of duty.png";
// import BingoLogo from "../../assets/images/itc-bingo/bingo logo.png";
import BingoLogo from "../../assets/images/itc-bingo/Powered bingo logo.png";
import { useAuthStore } from '../../auth/store/auth';
import { Link } from 'react-router-dom';
const Header = () => {
    const {user}=useAuthStore();
    return (
        <div className={user?.loggedin==="1"?'row mt-5 pt-1':'row'}>
        <div className='col-12 px-0'>
            <Carousel id="itc-bingo"  interval={3000} fade indicators={true} nextIcon={false} prevIcon={false}>
            <Carousel.Item className="w-100 d-block">
                   <img
                        className="d-block w-100 "
                        src={HeaderBG2}
                        alt="First slide"
                    /> 
                    <div className='d-flex justify-content-start align-items-center' > 
                   <div className='content col-12 col-md-8 col-xl-10'>
                  <div>
                  {/* <p className='poweredBy text-white ps-1 font-bold float-none text-center text-md-start'> */}
                    {/* <span className='color-itc font-Urbanist fw-bold'>POWERED</span> BY  */}
                   {/* <span className='color-itc font-Urbanist fw-bold'>BINGO! NACHOS</span> */}
                    {/* POWERED BY  */}
                   {/* </p> */}
                   <img src={BingoLogo} className='bingoLogo' alt="..."/>
                  </div>
                    <p className='heading float-none text-center text-md-start' style={{color:"#52c417"}}>BGMI</p>
                    {/* <p className='font-Urbanist text-white carousel-body float-none text-center text-md-start'>Free Fire is a multiplayer battle royale mobile game, developed and published by <br className='d-none d-md-block'/>Garena for Android and iOS. Battle in Style and be the last survivor!</p> */}
                    <p className='font-Urbanist text-white carousel-body float-none text-center text-md-start'>The Bingo! Nachos GAMEON CUP is BGMI's Tournament showcasing the best players<br className='d-none d-md-block'/> and teams from the BGMI community in intense battleground warfare fighting for <br className='d-none d-md-block'/>cash prizes of 4,00,000 and fierce competition, it's a battleground where <br className='d-none d-md-block'/>champions are forged</p>
                    <Link to="/tournament/brdetails/42416?from_campaign=itc">
                        <button className='bg-itc rounded fw-bold font-Urbanist float-none'>{user?.loggedin ? "More Details" : "REGISTER NOW"}</button>
                    </Link>
                   </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="w-100 d-block">
                   <img
                        className="d-block w-100 "
                        src={HeaderBG}
                        alt="First slide"
                    /> 
                    <div className='d-flex justify-content-start align-items-center' > 
                   <div className='content col-12 col-md-8 col-xl-10'>
                  {/* <p className='poweredBy text-white ps-1 font-bold float-none text-center text-md-start'><span className='color-itc font-Urbanist fw-bold'>POWERED</span> By <span className='color-itc font-Urbanist fw-bold'>BINGO! NACHOS</span></p>  */}
                   <img src={BingoLogo} className='bingoLogo' alt="..."/>
                    <p className='heading float-none text-center text-md-start' style={{color:"#a377d6"}}>FREE FIRE INDIA</p>
                    <p className='font-Urbanist text-white carousel-body float-none text-center text-md-start'>The Bingo! Nachos GAMEON CUP is an electrifying Free Fire Max esports tournament <br className='d-none d-md-block'/> where underdogs will clash with professional gamers for supremacy in <br className='d-none d-md-block'/> high-octane battles, aiming for victory and a massive prize pool of 4,00,000.</p>
                    
                    <Link to="/tournament/brdetails/42417?from_campaign=itc"><button className='bg-itc rounded fw-bold font-Urbanist float-none'>{user?.loggedin ? "More Details" : "REGISTER NOW"}</button></Link>
                   </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="w-100 d-block">
                   <img
                        className="d-block w-100 "
                        src={HeaderBG4}
                        alt="First slide"
                    /> 
                    <div className='d-flex justify-content-start align-items-center' > 
                   <div className='content col-12 col-md-8 col-xl-10'>
                   {/* <p className='poweredBy text-white ps-1 font-bold float-none text-center text-md-start'><span className='color-itc font-Urbanist fw-bold'>POWERED</span> BY  <span className='color-itc font-Urbanist fw-bold'>BINGO! NACHOS</span></p> */}
                   <img src={BingoLogo} className='bingoLogo' alt="..."/>
                    <p className='heading float-none text-center text-md-start' style={{color:"#a377d6"}}>Call Of Duty Mobile</p>
                    <p className='font-Urbanist text-white carousel-body float-none text-center text-md-start'>The Bingo! Nachos GAMEON CUP is a thrilling Call of Duty Mobile esports tournament <br className='d-none d-md-block'/> where top players from India, compete for glory in intense Multiplayer <br className='d-none d-md-block'/> and Battle Royale battles for PrizePool of 60K</p>
                    <Link to="/tournament/brdetails/42419?from_campaign=itc"> <button className='bg-itc rounded fw-bold font-Urbanist float-none'>{user?.loggedin ? "More Details" : "REGISTER NOW"}</button></Link>
                   </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="w-100 d-block">
                   <img
                        className="d-block w-100 "
                        src={HeaderBG3}
                        alt="First slide"
                    /> 
                    <div className='d-flex justify-content-start align-items-center' > 
                   <div className='content col-12 col-md-8 col-xl-10'>
                   <img src={BingoLogo} className='bingoLogo' alt="..."/>
                    <p className='heading float-none text-center text-md-start' style={{color:"#52c417"}}>New State Mobile</p>
                    <p className='font-Urbanist text-white carousel-body float-none text-center text-md-start'>The Bingo! Nachos GAMEON CUP is an exciting New State Mobile esports tournament <br className='d-none d-md-block'/> where top players battle in the Contest of Champions for a prize pool of 40K</p>
                    <Link to="/tournament/brdetails/42418?from_campaign=itc"> <button className='bg-itc rounded fw-bold font-Urbanist float-none'>{user?.loggedin ? "More Details" : "REGISTER NOW"}</button></Link>
                   </div>
                    </div>
                </Carousel.Item>
            </Carousel>
            </div>
      </div>
    )
}

export default Header