import { makeAutoObservable, runInAction } from 'mobx';
import React from 'react';
import { IAddGamePayload, IGame, IGameDetails, IGameDetailsParams, IGameLiked, IGameParams, IGameTeam } from '../../_common/model/game';
import { GameRepository, gameRpository } from '../../_common/repository/game';
export class GameStore {
    game_list: IGame[] = []
    game_loading = false;
    game_error = "";
    current_page = 0;
    game_platform = "";
    limit = 20;
    showMore = false;

    game_details?: IGameDetails
    game_details_loading = false;
    game_details_error = "";

    add_game_result?: string
    add_game_loading = false;
    add_game_error = "";

    game_like_result?: IGameLiked[] = []
    game_like_loading = false;
    game_like_error = "";
 
    game_team_result: IGameTeam[] = []
    game_team_loading = false;
    game_team_error = "";
 
    gameAPI: GameRepository
    constructor(args: {
        gameAPI: GameRepository
    }) {
        this.gameAPI = args.gameAPI;
        makeAutoObservable(this);
    }
    loadGames = async (params: IGameParams) => {
        runInAction(() => {
            this.game_loading = true;
            this.game_error = ""
        })
        const resp = await this.gameAPI.getGames(params);
        if (resp.hasError) {
            runInAction(() => {
                this.game_loading = false;
                this.game_error = resp.error!;
            })
            return
        }
        runInAction(() => {
            this.game_loading = false;
            // if (this.game_list.length > 0) {
            //     this.game_list = [...this.game_list, ...resp.result!];
            //     if (!params.platform && resp.result?.length! >= this.limit) {
            //         this.showMore = true;
            //     }
            //     return;
            // }
            this.game_list = resp.result!;
        })
    }

    loadGameDetails = async (params: IGameDetailsParams) => {
        runInAction(() => {
            this.game_details_loading = true;
            this.game_details_error = ""
        })
        const resp = await this.gameAPI.getGameDetails(params);
        if (resp.hasError) {
            runInAction(() => {
                this.game_details_loading = false;
                this.game_details_error = resp.error!;
            })
            return
        }
        runInAction(() => {
            this.game_details_loading = false;
            this.game_details = resp.result!;
        })
    }

    addGameToProfile = async (payload: IAddGamePayload) => {
        runInAction(() => {
            this.add_game_loading = true;
            this.add_game_error = "";
        })
        const resp = await this.gameAPI.addGame(payload);
        if (resp.hasError) {
            runInAction(() => {
                this.add_game_loading = false;
                this.add_game_error = resp.error!;
            })
            return
        }
        runInAction(() => {
            this.add_game_loading = false;
            this.add_game_result = resp.result!;
        })
    }
    loadGameLikeMembers= async (gameId: string) => {
        runInAction(() => {
            this.game_like_loading = true;
            this.game_like_error = ""
        })
        const resp = await this.gameAPI.getGameLikeMembers(gameId);
        if (resp.hasError) {
            runInAction(() => {
                this.game_like_loading = false;
                this.game_like_error = resp.error!;
            })
            return
        }
        runInAction(() => {
            this.game_like_loading = false;
            this.game_like_result = resp.result!;
        })
    }
    loadGameTeams= async (gameId: string) => {
        runInAction(() => {
            this.game_team_loading = true;
            this.game_team_error = ""
        })
        const resp = await this.gameAPI.getGameTeams(gameId);
        if (resp.hasError) {
            runInAction(() => {
                this.game_team_loading = false;
                this.game_team_error = resp.error!;
            })
            return
        }
        runInAction(() => {
            this.game_team_loading = false;
            this.game_team_result = resp.result!;
        })
    }
    loadMoreGames = () => {
        this.current_page++
    }

    changePlatform = (platform: string) => {
        this.game_platform = platform;
    }
}
export const GameStoreContext = React.createContext(new GameStore({ gameAPI: gameRpository }));
export const useGameStore = () => React.useContext(GameStoreContext);