import React from 'react'

type Props = {
    sNo?: number;
    description?: string;
    coins?: string;
    type?: string;
    date?: string;
}

export const LedgerDataRow: React.FC<Props> = (props) => {
    const { sNo, description, coins, type, date } = props;
    return (
        <>
            <tr>
                <td>{sNo}</td>
                <td>{description}</td>
                <td>{coins || '0'}</td>
                <td>{type}</td>
                <td>{date}</td>
            </tr>
        </>
    )
}