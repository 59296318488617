import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import React, {useState, useEffect } from 'react'
import { Amount } from '../widget/Amount';
import { BankDetailsModal } from '../widget/BankDetailsModal';
import { observer } from 'mobx-react';
import { usePaymentStore } from '../store/payment';
import { useAuthStore } from '../../auth/store/auth';
import { Modal } from 'react-bootstrap';
import { useProfileStore } from '../../profile/store/profileInfo';

type Props = {}

const VALIDATION_SCHEMA = Yup.object({
    amount: Yup.number()
        .typeError('Please enter valid amount')
        .positive('Must be a positive').required("Enter amount!")
});

export const PaymentRequestForm = observer((props: Props) => {
    const { user } = useAuthStore();
  const { profile_info } = useProfileStore()

    const { bank_details_modal, bank_details_resp, closeBankDetailsModal,checkUserBankDetails ,checkUserBankDetailsResp} = usePaymentStore();
    const [showModal, setShowModal] = React.useState(false);
    const handleClose = () => setShowModal(false);
    const handleOpen = () => setShowModal(true);
    const [reedmeAmt,setReedmeAmt]=useState("");
    const handleSubmit = (amount: string) => {
        // setShowModal(true)
        handleOpen()
        setReedmeAmt(amount);
        
    }
    useEffect(()=>{
        checkUserBankDetails({user_id: user?.id!})
    },[])
    return (
        <>
            <Formik
                initialValues={{ amount: "" }}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values.amount);
                    resetForm();
                    // handleOpen();
                }}
                validationSchema={VALIDATION_SCHEMA}
                validate={(values) => {
                    const valid_withdrawal = profile_info?.UserCredit ? Number(profile_info?.UserCredit) - 100 : 0
                    let errors:{amount?:string}={};
                    if (Number(values.amount) > valid_withdrawal ) {
                      errors.amount = "Insufficient funds- you are required to have a minimum of 100 IGL coins in your wallet";
                    }
                    return errors;
                }}
            >
                {props => {
                    return (
                        <Form>
                            <Amount name='amount' />
                            <div className="button text-center">
                                <input type="submit" name="payment" value="REQUEST" className="btn-main bg-trans mb-3" />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            <BankDetailsModal show={showModal} reedmeAmt={reedmeAmt} user_id={user?.id!} onClose={handleClose} checkUserBankDetailsResp={checkUserBankDetailsResp!}/>

            <Modal show={bank_details_modal} className="modal-raw create-team-modal modal-border-raw modal">
                <button type="button" className="btn-close-modal" onClick={closeBankDetailsModal}>&times;</button>
                <Modal.Body>
                    <div className="title-raw downarrow mb30">
                        <h3>Congratulations<span>Congratulations</span></h3>
                    </div>
                    <p>{bank_details_resp}</p>
                </Modal.Body>
            </Modal>
        </>
    )
})