import React from 'react'
import { FieldProps } from '../../_common/model/field'
import { Field, useFormikContext } from 'formik'
import { FieldError } from '../../_common/widget/FieldError' // wasn't working
import * as Yup from 'yup';
import { ITournamentboardParams } from '../model/leaderboard';

export const MonthDropdown = (props: FieldProps) => {
    const { name = "month", disabled, required } = props;

    const validate = async (value: any) => {
        var schema = Yup.string();

        let sch = required
            ? schema
                .required('Please select Month')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }
    const [toogle, setToogle] = React.useState(false);
    const { setFieldValue,values } = useFormikContext<ITournamentboardParams>();
    const data=[
        {
            title:"January",
            value:"01"
        },
        {
            title:"February",
            value:"02"
        },
        {
            title:"March",
            value:"03"
        },
        {
            title:"April",
            value:"04"
        },
        {
            title:"May",
            value:"05"
        },
        {
            title:"June",
            value:"06"
        },
        {
            title:"July",
            value:"07"
        },
        {
            title:"August",
            value:"08"
        },
        {
            title:"September",
            value:"09"
        },
        {
            title:"October",
            value:"10"
        },
        {
            title:"November",
            value:"11"
        },
        {
            title:"December",
            value:"12"
        }
    ];
    return (
        // <div className="select-dropdown">
        //     <Field component="select" name={name} disabled={disabled} validate={validate}>
        //         <option value="">Select Month</option>
        //             <option value='01'>January</option>
        //             <option value='02'>February</option>
        //             <option value='03'>March</option>
        //             <option value='04'>April</option>
        //             <option value='05'>May</option>
        //             <option value='06'>June</option>
        //             <option value='07'>July</option>
        //             <option value='08'>August</option>
        //             <option value='09'>September</option>
        //             <option value='10'>October</option>
        //             <option value='11'>November</option>
        //             <option value='12'>December</option>
        //     </Field>
        //     <FieldError name={name} />
        // </div>
        <div className="select-dropdown position-relative" onBlur={()=>{setToogle(false)}} tabIndex={6}>
        <label onClick={() => setToogle(!toogle)}>{(values.month===""||values.month === undefined) ? "Select Month" :  data!.find(item=>item.value === values.month)?.title }</label>
        {toogle &&
            <div className='position-absolute w-100' style={{zIndex:1}}>
                <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue("month","");
                       
                        setToogle(false);
                    }}>Select Month</p>
                {data?.map((option,index) => (
                    <p className='cursor-pointer font-D-DinCondensed' key={index} onClick={() => {
                        setFieldValue("month",option.value);
                        setToogle(false);
                    }}>{option.title}</p>
                ))} 
            </div>
        }
        <FieldError name={name} />
        </div>
    )
}