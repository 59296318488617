import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import {
  IResetPassoword,
  IUserLoginPayload,
  IUserRegisterPayload,
  IUserResetPayload,
  User,
} from "../../_common/model/user";
import { UserRepository, userRepository } from "../../_common/repository/user";
import { useNavigate } from "react-router-dom";
export class AuthStore {
  loggedIn = false;
  logging = false;
  loginError = "";

  registered = false;
  registering = false;
  registerError = "";

  reset_password_loading = false;
  reset_password_error = "";
  reset_password = "";
  showForgetResponse = false;
  user?: User;

  show_login = false;
  show_register = false;
  show_reset = false;

  //reset password
  resetPasswordResp: IResetPassoword = {};
  resetPasswordLoading: boolean = false;
  resetPasswordError: string = "";

  //request account delete
  requestAccDeleteLoading:boolean=false;
  requestAccDeleteErr:string="";
  requestAccDeleteData: IResetPassoword | null = null;

  showLogin = () => {
    this.loginError = "";
    this.show_login = true;
    this.show_register = false;
  };
  showRegister = () => {
    this.registerError = "";
    this.show_register = true;
    this.show_login = false;
  };
  showReset = () => {
    this.reset_password_error = "";
    this.show_reset = true;
    this.show_login = false;
  };
  hideLogin = (path: string) => {
    this.show_login = false;
    if (path === "/votes" || path.split("/")[1] === "news" ||  (path === "/bingo-nachos"))  {
    } else if (path !== "/" && !this.loggedIn) {
      window.history.go(-1);
    }
  };
  hideRegister = (path: string) => {
    this.show_register = false;
    if (path === "/bingo-nachos" ) 
    return{
    }
    else if (path !== "/" && !this.loggedIn) {
      window.history.go(-1);
    }
  };
  hideReset = () => (this.show_reset = false);

  constructor(args: { userAPI: UserRepository }) {
    makeAutoObservable(this);
    this.userAPI = args.userAPI;
    this.checkLogin();
    this.reset_password_error = "";
  }

  userAPI: UserRepository;

  checkLogin = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      runInAction(() => {
        const user = JSON.parse(window.atob(token));
        this.user = user;
        if (token) this.loggedIn = true;
        else this.loggedIn = false;
      });
    }
  };

  login = async (payload: IUserLoginPayload) => {
    runInAction(() => {
      this.logging = true;
      this.loginError = "";
    });

    const resp = await this.userAPI.loginUser(payload);
    if (resp.hasError) {
      runInAction(() => {
        this.loginError = resp.error!;
        this.logging = false;
      });
      return;
    }
    if (resp?.result) {
      const user_encrypted_data = window.btoa(JSON.stringify(resp.result));
      localStorage.setItem("token", user_encrypted_data);
    }
    runInAction(() => {
      this.loggedIn = true;
      this.logging = false;
    });
    if(window.location.pathname.split('/')[1]==='reset_password'){
      window.location.assign('/')
      return;
    }
    window.location.reload();
  };
 
  forgetPassword = async (payload: IUserResetPayload) => {
    runInAction(() => {
      this.reset_password_loading = true;
      this.reset_password_error = "";
    });
    const resp = await this.userAPI.forgotpasswordUser(payload);
    if (resp.hasError) {
      runInAction(() => {
        this.reset_password_loading = false;
        this.reset_password_error = resp.error!;
        this.reset_password = "";
      });
      return;
    }
    runInAction(() => {
      this.showForgetResponse = true;
      this.reset_password_loading = false;
      this.reset_password = resp.result!;
    });
    // console.log(this.reset_password_loading,"end");
  };
  toogleResponse = () => {
    runInAction(() => {
      this.showForgetResponse = false;
    });
  };
  register = async (payload: IUserRegisterPayload) => {
    runInAction(() => {
      this.registering = true;
      this.registerError = "";
    });

    const resp = await this.userAPI.createUser(payload);
    if (resp.hasError) {
      runInAction(() => {
        this.registerError = resp.error!;
        this.registering = false;
      });
      return resp.error;
    }
    if (resp?.result) {
      const user_encrypted_data = window.btoa(JSON.stringify(resp.result));
      localStorage.setItem("token", user_encrypted_data);
    }
    runInAction(() => {
      this.registered = true;
      this.registering = false;
      this.loggedIn = true;
    });
    if(window.location.pathname==="/bingo-nachos" || 
     (window.location.pathname+window.location.search==="/tournament/brdetails/42416?from_campaign=itc") ||
     (window.location.pathname+window.location.search==="/tournament/brdetails/42417?from_campaign=itc") ||
     (window.location.pathname+window.location.search==="/tournament/brdetails/42418?from_campaign=itc") ||
     (window.location.pathname+window.location.search==="/tournament/brdetails/42418?from_campaign=itc"))
     {
      window.location.reload();
    }
    else{
      window.location.replace("/thankyou");
    }
  };

  logout = async () => {
    const resp = await this.userAPI.logout({userid: this.user?.id!});
    if(resp.hasError){
     console.log("Signout failed");
     return;
    }
    runInAction(() => {
      localStorage.clear();
      this.loggedIn = false;
      window.location.reload();
    });
  };
  updateUserCredit = async (UserCredit: string) => {
    // console.log(UserCredit)
    runInAction(() => {
      this.user = { ...this.user!, UserCredit: UserCredit! };
    });
  };

  resetPassword = async (payload: { user_id: string; newpassword: string }) => {
    runInAction(() => {
      this.resetPasswordLoading = true;
    });
    const resp = await this.userAPI.resetPasswordRepo(payload);
    if (resp.hasError) {
      runInAction(() => {
        this.resetPasswordError = resp.error!;
        this.resetPasswordResp = {};
        this.resetPasswordLoading = false;
      });
    return;
    }
    runInAction(() => {
      this.resetPasswordLoading = false;
      this.resetPasswordResp = resp.result!;
      this.resetPasswordError = "";
    });
  };
  

  requestAccountDelete = async (payload:{ firstname: string,
  lastname: string,
  username: string,
  email: string,
  mobile: string,
  city: string,
  otpVerificationType:string
}) => {
    runInAction(() => {
      this.requestAccDeleteLoading = true;
      this.requestAccDeleteErr = "";
    });
    const resp = await this.userAPI.requestAccountDelete(payload);
    if (resp.hasError) {
      runInAction(() => {
        this.requestAccDeleteErr = resp.error!;
        this.requestAccDeleteData = null;
        this.requestAccDeleteLoading = false;
      });
    return;
    }
    runInAction(() => {
      this.requestAccDeleteLoading = false;
      this.requestAccDeleteData = resp.result!;
      this.requestAccDeleteErr = "";
    });
    return;
  };
  requestAccOtpLoading:boolean=false;
  requestAccOtpError:string="";
  requestAccOtpData:IResetPassoword|null = null;
  requestAccountVerifyOtp = async (payload:{ 
  otp: string,
  username: string,
}) => {
    runInAction(() => {
      this.requestAccOtpLoading = true;
      this.requestAccOtpError = "";
    });
    const resp = await this.userAPI.requestAccountOtpVerify(payload);
    if (resp.hasError) {
      runInAction(() => {
        this.requestAccOtpError = resp.error!;
        this.requestAccOtpData = null;
        this.requestAccOtpLoading = false;
      });
    return;
    }
    runInAction(() => {
      this.requestAccOtpLoading = false;
      this.requestAccOtpData = resp.result!;
      this.requestAccOtpError = "";
    });
    return;
  };


}

export const authstore = new AuthStore({ userAPI: userRepository });
export const AuthStoreContext = createContext(authstore);
export const useAuthStore = () => useContext(AuthStoreContext);
