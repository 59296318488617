import axios, { AxiosError } from "axios";
import { IUserLoginPayload, IUserRegisterPayload, IUserResetPayload, User, UserProfileInfo, IResetPassoword } from "../model/user";
import { R } from "../pkg/types";
import { CORE_API_URL } from "../../core/config";

export class UserRepository {

    async loginUser(payload: IUserLoginPayload): Promise<R<User>> {
        try {
            const resp = await axios.post(
                `${CORE_API_URL}/login`,
                payload,
                {
                    headers: {
                        //"x-api-key": "satyam@123",
                        'Content-Type': 'multipart/form-data',
                    }
                });

            const user = resp.data.Userdetails as User
            if (user === null || user === undefined) {
                return new R({error: resp.data.msg})
            }
            return new R({ result: user });
        } catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! Something went wrong!" })
            }
            return new R({ error: e.response.data.msg })
        }
    }
    async forgotpasswordUser(payload: IUserResetPayload): Promise<R<string>> {
        try {
            const resp = await axios.post(
                `${CORE_API_URL}/forget_password`,
                payload,
                {
                    headers: {
                        //"x-api-key": "satyam@123",
                        'Content-Type': 'multipart/form-data',
                    }
                });

            const result = resp.data.msg as string;
            return new R({ result });
        } catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! Something went wrong!" })
            }
            return new R({ error: e.response.data.msg })
        }
    }

    async createUser(payload: IUserRegisterPayload): Promise<R<User[]>> {
        try {
            const resp = await axios.post(
                `${CORE_API_URL}/register`,
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });
                const list = resp.data.Userdetails as User[];
                if (!list) {
                    if(resp.data.msg){
                        return new R({ error: resp.data.msg })
                    }
                    return new R({ error: "Oops! Something went wrong! Try again later" })
                }
            return new R({ result: list });
        } catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! Something went wrong!" })
            }
            return new R({ error: e.response.data.msg })
        }
    }
    async getUsers(): Promise<R<User[]>> {
        try {
            const resp = await axios.get(`https://iglnetwork.com/api/home_api/getUsers`, {
                headers: {
                    //"x-api-key": "satyam@123",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                },
            });

            const list = resp.data.data as User[];
            return new R({ result: list });
        } catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! Something went wrong!" })
            }
            return new R({ error: e.response.data.msg })
        }
    }

    async getProfileInfo(userId: string | number): Promise<R<UserProfileInfo>> {
        try {
            const resp = await axios.get(`${CORE_API_URL}/get_personlinfo_new?timestamp=${new Date().getTime()}`,
                { params: { user_id: userId } }
            );
            const list = resp.data.UserPersonalInfo as UserProfileInfo;
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }
    async resetPasswordRepo(payload: {user_id:string,newpassword:string}):Promise<R<IResetPassoword>>{
        try{
            const resp = await axios.post(
                `${CORE_API_URL}/reset_password `,
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
            const list = resp.data as IResetPassoword
            if(list.status==="1"){
                return new R({ result: list });
            }
            else
            throw list.msg
        }
        catch(e){
            return new R({ error: "Unable to update" });
        }
    }
    async requestAccountDelete(payload:{ firstname: string,
        lastname: string,
        username: string,
        email: string,
        mobile: string,
        city: string,
        otpVerificationType:string
      }):Promise<R<IResetPassoword>>{
        try{
            const resp = await axios.post(
                `${CORE_API_URL}/deactivateUserAccount`,
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
            const list = resp.data as IResetPassoword
            if(list.status==="1"){
                return new R({ result: list });
            }
            else
            throw list.msg
        }
        catch(e){
            console.log(e)
            return new R({ error: ((e as AxiosError)?.response?.data as IResetPassoword)?.msg ??"Cannot de-active user" });
        }
    }
    async requestAccountOtpVerify(payload:{ 
        otp: string,
        username: string,
      }):Promise<R<IResetPassoword>>{
        try{
            const resp = await axios.post(
                `${CORE_API_URL}/verify_otp_user_deactivate`,
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
            const list = resp.data as IResetPassoword
            if(list.status==="1"){
                return new R({ result: list });
            }
            else
            throw list.msg
        }
        catch(e){
            console.log(e)
            return new R({ error: ((e as AxiosError)?.response?.data as IResetPassoword)?.msg ??"Cannot de-active user" });
        }
    }
    async logout(payload: {userid:string}):Promise<R<boolean>>{
        try{
            const resp = await axios.post(
                `${CORE_API_URL}/signout `,
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
            const list = resp.data as IResetPassoword
            if(list.status==="1"){
                return new R({ result: true });
            }
            else
            throw list.msg
        }
        catch(e){
            return new R({ error: "Logout Failed" });
        }
        
    }
}

export const userRepository = new UserRepository();