import { observer } from 'mobx-react';
import React from 'react'
import { DataResponse } from '../../_common/widget/DataResponse';
import { TournamentCard } from '../widget/TournamentCard';
import { useMegaTournamentStore } from '../store/megatournaments';
import { TournamentSection } from '../widget/TournamentSection';
import { useItcBingoTournamentStore } from '../store/itcbingo';
import MegaTournamentCard from '../../itc-bingo/components/MegaTournamentCard';

export const MegaTournaments = observer(() => {
    const { loadTournament, tournaments, loadingTournament, errTournament } = useMegaTournamentStore();
    const {
        loadTournament: itcLoadTournament,
        tournaments: itcTournaments,
        loadingTournament: itcLoadingTournament,
        errTournament: itcErrTournament
      } = useItcBingoTournamentStore();
    React.useEffect(() => {
        loadTournament();
        itcLoadTournament();
    }, []);

    return (
        <div className="competition-tab-raw section">
            <ul className="nav nav-tabs" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">
                        Mega Tournaments
                    </a>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <div className="section">
                        <DataResponse isLoading={loadingTournament || itcLoadingTournament} data={tournaments || itcTournaments}>
                            <TournamentSection>
                                {
                                    tournaments?.map(item => {
                                        return (
                                            <div className="col-sm-3 col-6 gm-li align-items-stretch d-flex " key={item.TournamentID}>
                                                <TournamentCard
                                                    imgUrl={item.TournamentLogo}
                                                    title={item.TournamentTitle}
                                                    date={item.TournamentDate}
                                                    time={item.TournamentStartTime}
                                                    coins={item.IGLCoins}
                                                    platform={item.PlatformName}
                                                    players={item.TournamentTeams}
                                                    gameName={item.GameName}
                                                    detailId={item.TournamentID}
                                                    gameType={item.Tournamenttype}
                                                    btnLabel="More Info"
                                                    teamsize={item.TournamentTeamsType}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    itcTournaments?.map((item, index) => {
                                        return (
                                          <div  className="col-sm-3 col-6 gm-li align-items-stretch d-flex" key={index}>
                                            <MegaTournamentCard
                                              imgUrl={item.TournamentLogo}
                                              title={item.TournamentTitle}
                                              date={item.TournamentDate}
                                              time={item.TournamentStartTime}
                                              coins={item.IGLCoins}
                                              platform={item.PlatformName}
                                              players={item.TournamentTeams}
                                              gameName={item.GameName}
                                              detailId={item.TournamentID}
                                              gameType={item.Tournamenttype}
                                              teamsize={item.TournamentTeamsType}
                                              gameId={item.GameID}
                                              btnLabel="Join Now"
                                              from_campaign="itc"
                                            /></div>
                                        )
                                      })
                                }
                            </TournamentSection>
                        </DataResponse>
                    </div>
                </div>
            </div>
        </div>
    )
})