import React from 'react'
import { Modal } from 'react-bootstrap'
import { IModalProps } from '../model/props.interface'

type Props = IModalProps & { children?: React.ReactNode }

export const ErrorModal = (props: Props) => {
    const { show, onClose, children } = props;
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const campaign = new URLSearchParams(window.location.search).get('from_campaign');
    return (
        <>
            <Modal show={show} animation={true} id="after_vote" centered className={`login-modal modal-raw modal fade in show ${(queryParams && campaign ==="itc") ? "itc-tournament-details-col":""}`}>
                <button type="button" className="btn-close-modal"
                    onClick={onClose}
                >×</button>
                <Modal.Body className="px-5">
                    <p className='text-danger text-center'>{children}</p>
                </Modal.Body>
            </Modal>
        </>
    )
}
