import React, {useEffect} from 'react';
import { useGlobalSearchStore } from '../../../_common/store/globalSearchFilter';
import { observer } from 'mobx-react';
import Card from '../card';
interface IProps{
    PTYPE:string;
    link:string;
}
const MainTab = observer((props:IProps) => {
    const {globalSearch,globalSearchResult,globalSearchLoading,globalSearchText,more_data_available,page_no, loadMoreData, resetPageCount } = useGlobalSearchStore();
    const {PTYPE="",link}=props;
    useEffect(()=>{
        resetPageCount();
         globalSearch({
             PTYPE:PTYPE,
             PNO:String(page_no)
         })
    },[globalSearchText,PTYPE,globalSearch]);
    
  return (
    <div className="tab-pane active">
    <div className="section gm-li-raw">
        <div className="row">
            { (globalSearchLoading===true && page_no===0) ? <div className='title-raw d-flex justify-content-center align-items-center mx-auto' style={{ height: '450px' }}>
            <h1 className=''>Loading...</h1>
        </div>:( globalSearchResult && globalSearchResult.length > 0)  ? globalSearchResult?.map((item,index)=>
            <Card
            {...item} 
            key={index}
            link={link}
            />
            ):globalSearchText===""?<div className='title-raw d-flex justify-content-center align-items-center mx-auto' style={{ height: '450px' }}>
            <h1 className=''>Please search...</h1>
            </div> :
            <div className='title-raw d-flex justify-content-center align-items-center mx-auto' style={{ height: '450px' }}>
                <h1 className=''>Data not found.</h1>
            </div>}
        </div>
        {more_data_available===true ? 
        <div className="row">
                <div className="col-sm-12 col-12 text-center">
                <button className="mt50 btn-sm btn-main txt-shad-none" onClick={()=>{
                    loadMoreData({PTYPE:PTYPE})
                }}>View More</button>
                </div>
                </div>  : null}
    </div>
</div>
  )
})

export default MainTab