import { observer } from 'mobx-react'
import React from 'react'
import { useAuthStore } from '../../auth/store/auth';
import { DataResponse } from '../../_common/widget/DataResponse';
import { useLeaderboardStore } from '../store/leaderboard';
import { LeaderboardDataRow } from '../widget/LeaderboardDataRow';
import Pagination from 'react-bootstrap/Pagination'
import { Form, Formik } from 'formik';
import { PlatformDropdown } from '../../_common/widget/PlatformDrodown';
import { GameDropdown } from '../../_common/widget/GameDropdown';
import { TeamSizeDropdown } from '../../_common/widget/TeamSizeDropdown';
import { MonthDropdown } from '../../_common/widget/MonthDropdown';
import { YearDropdown } from '../../_common/widget/YearDropdown';
import { ILeaderboardParams } from '../../_common/model/leaderboard';

const initialState = {
    platform_id: "",
    game_id: "",
    team: "",
    month: String(new Date().getMonth() + 1).padStart(2, '0') || "",
    year: String(new Date().getFullYear()) || "",
};
type Props = {};
export const LeaderboardListing: React.FC<Props> = observer((props) => {
    const { user } = useAuthStore()
    const { loadLeaderboard, leaderboard_list, leaderboard_loading, active_page, changePage, total_pages, leaderboard_error } = useLeaderboardStore();
    const [filters, setFilters] = React.useState<ILeaderboardParams>(initialState);
    React.useEffect(() => {
        loadLeaderboard({
            user_id: user?.id,
            PNO: active_page,
            ...filters
        })
        window.scrollTo(0, 0);
    }, [active_page])
    const handleClearFIlter = (reset:any) =>{
        reset();
        handlePageChange(1);
        setFilters(initialState);
        loadLeaderboard({
            user_id: user?.id,
            PNO: active_page,
            ...filters
        })
        window.scrollTo(0, 0);
    }
    const handlePageChange = (pageNo: number) => {
        changePage(pageNo)
    }
    const handlePreviousPage = () => {
        changePage(active_page - 1)
    }
    const handleNextPage = () => {
        changePage(active_page + 1)
    }
    const handleSubmit = (values: any) => {
        const filters = {
            team: values.team,
            month: values.month,
            game_id: values.game_id,
            year: values.year,
            platform_id: values.platform_id
        }

        loadLeaderboard({
            user_id: user?.id,
            PNO: active_page,
            ...filters
        })
        setFilters(filters)
    }
    const createPaginationItem = (i: number) => {
        return <Pagination.Item
            key={i}
            active={i === active_page}
            onClick={() => handlePageChange(i)}
        >
            {i}
        </Pagination.Item>
    };

    const paginationItems = [];
    // Add the first item (page 1)
    paginationItems.push(createPaginationItem(1));
    // Add an ellipsis
    paginationItems.push(<Pagination.Ellipsis />);
    const midpoint = Math.ceil(total_pages / 2);
    // Create page numbers in the middle
    for (let i = midpoint; i <= midpoint + 4; i++) {
        paginationItems.push(createPaginationItem(i));
    }
    // Add an ellipsis
    paginationItems.push(<Pagination.Ellipsis />);
    // Add the last item (page N)
    paginationItems.push(createPaginationItem(total_pages));

    return (
        <>
            <div className="common-page-raw table-wrapper leaderboard-raw section">
                <div className="container">
                    <div className="leader-list-raw section line-arrow">
                        <Formik
                            initialValues={initialState}
                            onSubmit={(values, { resetForm }) => {
                                handleSubmit(values);
                                // resetForm();
                            }}
                        // validationSchema={{}}
                        >
                            {({resetForm}) => {
                                return (
                                    <Form>
                                        <div className="row justify-content-center">
                                            <div className="col-12">
                                                <div className="row justify-content-center no-gutters">
                                                    <div className="col-md-4 mb-md-3 mb-3">
                                                        <PlatformDropdown name='platform_id' />
                                                    </div>
                                                    <div className="col-md-4 col-6 mb-md-3 mb-3">
                                                        <GameDropdown name='game_id' />
                                                    </div>
                                                    <div className="col-md-4 col-6 mb-md-3 mb-3">
                                                        <TeamSizeDropdown name='team' />
                                                    </div>
                                                </div>
                                           
                                                <div className="row justify-content-center no-gutters">
                                                    <div className="col-md-4 col-6 mb-md-3 mb-3">
                                                        <MonthDropdown name='month' />
                                                    </div>
                                                    <div className="col-md-4 col-6 mb-md-3 mb-3">
                                                        <YearDropdown name='year' />
                                                    </div>
                                                    <div className="col-lg-4 col-12 mb-md-3 mb-3">
                                                    <div className="row">
                                                    <div className="text-md-start text-center col-6 mb-3">
                                                        <button className='mt-0 w-100' style={{
                                                            background: "transparent",
                                                            minWidth: "150px",
                                                            minHeight: "40px",
                                                            fontWeight: "bold",
                                                            fontSize: "16px",
                                                            color: "white",
                                                            letterSpacing: "0.5px",
                                                            border: "2px solid #009cff",
                                                            borderRadius: "50px",
                                                            textTransform: "uppercase",
                                                            backgroundColor: "#009cff"
                                                        }} type='submit'>Apply</button>
                                                    </div>
                                                    <div className="text-md-start text-center col-6 mb-3">
                                                        <button className='mt-0 w-100'
                                                            style={{
                                                                background: "transparent",
                                                                minWidth: "150px",
                                                                minHeight: "40px",
                                                                fontWeight: "bold",
                                                                fontSize: "16px",
                                                                color: "white",
                                                                letterSpacing: "1px",
                                                                border: "2px solid #009cff",
                                                                borderRadius: "50px",
                                                                textTransform: "uppercase",
                                                                textAlign: "center"
                                                            }}
                                                            onClick={() => handleClearFIlter(resetForm)} type='reset'>clear filter</button>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    {/* <div className="col-md-2 col-6 mb-md-5 mb-3">
                                                        <button className='d-md-none d-block btn-main mt-0 mb-3' type='submit'>Apply</button>
                                                        <button className='d-md-block d-none' style={{ background: 0 }} type='submit'>
                                                            <img src={`${IGL_IMAGE_URL}/search-ic.png`} />
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                        {leaderboard_error ? <h4 className='text-center'>{leaderboard_error}</h4>
                            : <DataResponse isLoading={leaderboard_loading} data={leaderboard_list}>
                                <div className="table-responsive forums-table-03">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Rank.</th>
                                                <th>Team</th>
                                                <th>Game</th>
                                                <th>Points</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {leaderboard_list?.map((item, idx) => (
                                                <LeaderboardDataRow
                                                    key={idx}
                                                    rank={item.Rank}
                                                    team={item.TeamName}
                                                    game={item.GameTitle}
                                                    points={item.LeaderboardPoints}
                                                />
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {total_pages > 1 &&
                                    <div className='d-flex justify-content-end'>
                                    <Pagination className='my-4 w-min'>
                                        <Pagination.Item onClick={handlePreviousPage} disabled={active_page === 1}>&laquo;</Pagination.Item>
                                        {total_pages && Array.from(Array(total_pages).keys()).map((_, index) => {
                                            const pno = index + 1;
                                            return (
                                                <Pagination.Item
                                                    onClick={() => handlePageChange(pno)}
                                                    key={pno}
                                                    active={pno === active_page}
                                                >
                                                    {pno}
                                                </Pagination.Item>
                                            );
                                        })}
                                        {/* {paginationItems} */}
                                        <Pagination.Item onClick={handleNextPage} disabled={active_page === total_pages}>&raquo;</Pagination.Item>
                                    </Pagination>
                                    </div>
                                }
                            </DataResponse>
                        }
                    </div>
                </div>
            </div>
        </>
    )
});