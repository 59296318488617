// import axios from "axios";
import api from "../../core/api";
import { GAME_TYPE } from "../../core/config";
import { TeamMember, Team, EligibleTeam, ITeamPayload, IEligibleTeamPayload, ICreateTeamResponse, ICheck_Teamname} from "../model/team";
import { R } from "../pkg/types";

export class TeamRepository {

    async createTeam(payload: ITeamPayload): Promise<R<ICreateTeamResponse>> {
        try {
            const resp = await api.post('/create_team',
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });

            const result = resp.data as ICreateTeamResponse;
            if (!result) {
                return new R({result: resp.data.msg})
            }

            return new R({ result});
        } catch (e) {
            return new R({ error: e });
        }
    }

    async getMyTeam(userId: string): Promise<R<Team[]>> {
        try {
            const resp = await api.get(`/userprofileteams?user_id=${userId}`,
            );
            const list = resp.data.TeamList as Team[];
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }

    async getOtherTeam(userId: string): Promise<R<Team[]>> {
        try {
            const resp = await api.get(`/member_of_the_teams?user_id=${userId}`,
            );
            const list = resp.data.TeamList as Team[];
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }

    async getPlatform() {
        try {
            const resp = await api.get(`/get_platforms`,
            );
            const list = resp.data.Platformlist;
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }

    async getGame(param: string) {
        try {
             const resp = await api.get(`/get_games_by_platform?platform_id=${param}`,
            );
            const list = resp.data.games;
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }
    async getIdValidation(param: string) {
        try {
             const resp = await api.get(`/getGamesCode?game_id=${param}`,
            );
            const list = resp.data.GameIdData;
            if(resp.data.status==="0" || !list){
                return new R({error:resp.data.msg})
            }
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }

    async getTeamSize(param: string) {
        try {
            const resp = await api.get(`/gameTeamSizeForApp?game_id=${param}`,
            );
            const list = resp.data.teamSize;
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }
    async getTeamMember(
        user?: string,
        game?: string,
        tournament_type?:string,
        tournamentId?:string,
    ): Promise<R<TeamMember[]>> {
        try {
            const resp = await api.get(`/gameusers?usernametext=${user}&game_id=${game}&tournament_type=${tournament_type}&tournamentId=${tournamentId}`,
            );
            const list = resp.data.UserGameInfo;
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }
    async getEligibleTeam(payload: IEligibleTeamPayload, type?: number): Promise<R<EligibleTeam[]>> {
        let _endpoint = '';
        if(type === GAME_TYPE.tournament){
            _endpoint = "/userteams"
        }
        else{
            _endpoint = '/getTeamsByUserGameIDByTeamSizeByPlatform_new'

        }
        try {
            const resp = await api.post(_endpoint,
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });
            // const list = resp.data.EligibleTeam?.length > 0 ?  resp.data.EligibleTeam : resp.data.IneligibleTeam as EligibleTeam[];
            const list = resp.data.EligibleTeam as EligibleTeam[];
            if (!list) throw resp.data.msg
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }
    async check_teamname(payload:{
        user_id:string,
        team_name:string,
    }):Promise<R<ICheck_Teamname>>{
        try {
            const resp = await api.post(`/check_teamname`,
            {
                ...payload,
                timestamp: new Date().getTime()
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
           
            if(!resp.data) throw resp.data.msg;
            return new R({ result: resp.data });
        } catch (error) {
            return new R({ error: "Network Error Couldnt Response" });
        }
    }
}

export const teamRepository = new TeamRepository();