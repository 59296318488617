import React from 'react'

const Heading = (props: { label: string }) => {
    return (
        <div className="title-shadow section mt80">
            <h2>{props.label}</h2>
        </div>
    )
}

export default Heading