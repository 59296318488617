import React, { useState, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Field, useField, useFormikContext } from 'formik';
import { useTeamStore } from '../store/team';
import { FieldProps } from '../../_common/model/field';
import Input from '@mui/material/Input';
import { observer } from 'mobx-react';
import { TeamState } from '../../_common/model/team';
import * as Yup from 'yup';
import { FieldError } from '../../_common/widget/FieldError';
import { useAuthStore } from '../../auth/store/auth';

export const TeamMemberAutocomplete = observer((props: FieldProps) => {
  const { team_member_list, teamMember, team_member_loading } = useTeamStore();
  const { name = 'member', value, required, tournamentId = '', tournament_type = '' } = props;
  const [field, meta, helpers] = useField({ name });
  const { user } = useAuthStore();
  const { values } = useFormikContext<TeamState>();

  const handleTeamMemberChange = useCallback(
    async (value: string) => {
      if (!value) {
        return;
      }
      teamMember(value.trim(), values.team_game, tournament_type, tournamentId);
    },
    [teamMember, values.team_game, tournament_type, tournamentId]
  );

  const debouncedHandleTeamMemberChange = useCallback(debounce(handleTeamMemberChange, 300), []);

  const validate = async (value: any) => {
    var schema = Yup.string();
    schema = required ? schema.required('This field is Required!') : schema;

    let err = await schema.validate(value).catch((err) => err);

    if (err?.errors?.length) {
      helpers.setError(err.errors.join(' '));
      return err.errors.join(' ');
    }
  };

  return (
    <>
      <div className="form-grop">
        <Field
          inputProps={{ type: 'hidden' }}
          {...field}
          sx={{ display: 'none' }}
          component={Input}
          validate={validate}
        />
        <Autocomplete
          className="form-control"
          isOptionEqualToValue={(option: any, value: any) => option?.username === value.username}
          getOptionLabel={(option: any) => `${option?.username}`}
          options={team_member_list !== undefined ? team_member_list?.filter((item) => item.id !== user?.id) : []}
          onInputChange={(_event, value) => debouncedHandleTeamMemberChange(value)}
          onChange={(_event, value) => {
            if (value === null) {
              // helpers.setValue("")
            } else {
              helpers.setValue(value.username);
            }
          }}
          loading={team_member_loading}
          renderInput={(params: any) => {
            return (
              <TextField
                {...params}
                name={name}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0',
                    padding: '0',
                  },
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    border: '0 transparent',
                  },
                }}
              />
            );
          }}
        />
        <FieldError name={name} />
      </div>
    </>
  );
});

function debounce(func: (value: string) => void, delay: number) {
  let timer: NodeJS.Timeout;
  return function (value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(value);
    }, delay);
  };
}
