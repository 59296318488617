import React from 'react'
import Header from './widgets/header'
import TabSection from './widgets/tabSection'
import "./page.css"
const GlobalFitler = () => {
  return (
    <div>
        <Header/>
        <TabSection/>
    </div>
  )
}

export default GlobalFitler