import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useGlobalSearchStore } from '../../_common/store/globalSearchFilter';
const Header = observer(() => {
    const VALIDATION_SCHEMA = Yup.object({
        searchtext: Yup.string().required("Please enter Search Value")
    });
    const { globalSearch, setSearchText, globalSearchError, page_no, resetPageCount, globalSearchText } = useGlobalSearchStore();
    const navigate = useNavigate();
    // const [toogle, setToogle] = useState(false);
    const handleSubmit = async (values : {searchtext:string}) => {
        setSearchText(values.searchtext);
        // setToogle(!toogle);
        if(window.location.pathname!=='/globals/people'){
            navigate('/globals/people')
        }
        else{
            resetPageCount();
            globalSearch({searchtext:values.searchtext,PTYPE:"Users", PNO:String(page_no)})
        }
    }
    
    return (
        <div className="main-wrapper challenges-banner-raw search-banner-raw section">
            <div className="inner-banner section" style={{ backgroundImage: "url(https://api.iglnetwork.com/assets/images/inner-banner.jpg)" }}>
                <div className="container-fluid">
                    <div className="inner-banner-con clear-both">
                        <div className="title-raw clear-both">
                            <h1>Search<span>Search</span></h1>
                        </div>
                        <div className="row clear-both pt-3 pt-md-5">
                            <div className="col-8 col-md-6 col-lg-4 mx-auto">
                            <div className="search-game-raw section text-center">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-12">
                            <Formik onSubmit={(values, { resetForm }) => {
                                handleSubmit(values);
                                resetForm();  // No need to reset if correct data then login
                            }}
                                enableReinitialize={true}
                                validationSchema={VALIDATION_SCHEMA}
                                initialValues={{
                                    searchtext: globalSearchText ?? ""
                                }}>
                                <Form className="clearfix position-relative">
                                    <Field type="text" name="searchtext" id="search" className="form-control" placeholder="Search"/>
                                    <button type="submit" style={{ "outline": "-webkit-focus-ring-color auto 0px", "letterSpacing": "1px", "borderRadius": "100px", "width": "36px", "position": "absolute", "right": "4px", "top": "6px", "height": "34px", "zIndex": "1", "background": "transparent", "border": "0px", "padding": "0px", "cursor": "pointer", "display": "flex", "justifyContent": "center", "alignItems": "center" }}></button>
                                    <ErrorMessage
                                        name={'searchtext'}
                                        component="p"
                                        className="text-danger small text-start"
                                    />
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Header