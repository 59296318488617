import React, { useEffect, useState, useRef } from "react";
import BingoPackets from "../itc-bingo/components/BingoPackets";
import BinoChip from "../itc-bingo/components/BinoChip";
import {
  GalleryCard,
  GalleryCardLightBox,
  GalleryCardLoading,
} from "./views/GalleryCard";
import Slider from "react-slick";
import { useMidwayStore } from "./store/midway-store";
import { observer } from "mobx-react";
import { SideGraphicsLeft, SideGraphicsRight } from "./views/SideGraphics";
import Pagination from "react-bootstrap/Pagination";
import Header from "./views/CarouselHeader";
import { useAuthStore } from "../auth/store/auth";
import { Image } from "../_common/widget/Image";
import AiImg from "../assets/images/itc-bingo/Bingo AI affair Logo.png";
import AiImgWithBg from "../assets/images/itc-bingo/Bingo AI affair Logo with bg.png";
const MidWay = observer(() => {
  require("./page.css");
  const ref = useRef<null | HTMLDivElement>(null);
  const {
    loadMidwayData,
    midwayData,
    midway_loading,
    active_page,
    changePage,
    total_pages,
  } = useMidwayStore();
  useEffect(() => {
    loadMidwayData({ page_no: active_page });
  }, [active_page]);
  // lighbox starts
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const openLightbox = (index: number) => {
    setSelectedImage(index);
    setLightboxOpen(true);
    window.scrollTo(
      0,
      user?.loggedin
        ? ref.current?.offsetTop! - 133
        : ref.current?.offsetTop! - 90
    );
  };

  const closeLightbox = () => {
    setSelectedImage(0);
    setLightboxOpen(false);
  };
  const handleLeftNav = () => {
    setSelectedImage((prev) => {
      return prev - 1;
    });
  };
  const handleRightNav = () => {
    setSelectedImage((prev) => {
      return prev + 1;
    });
  };
  //
  const { user } = useAuthStore();
  //
  const handlePageChange = (pageNo: number) => {
    
    window.scrollTo(
      0,
      user?.loggedin
        ? ref.current?.offsetTop! - 133
        : ref.current?.offsetTop! - 90
    );
    setTimeout(() => {
      changePage(pageNo);
    }, 700);
  };
  const handlePreviousPage = () => {
    window.scrollTo(
      0,
      user?.loggedin
        ? ref.current?.offsetTop! - 133
        : ref.current?.offsetTop! - 90
    );
    
    setTimeout(() => {
      changePage(active_page - 1);
    }, 700);
  };
  const handleNextPage = () => {
    window.scrollTo(
      0,
      user?.loggedin
        ? ref.current?.offsetTop! - 133
        : ref.current?.offsetTop! - 90
    );
    
    setTimeout(() => {
      changePage(active_page + 1);
    }, 700);
  };
  const createPaginationItem = (i: number) => {
    return (
      <Pagination.Item
        key={i}
        active={i === active_page}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </Pagination.Item>
    );
  };

  const paginationItems = [];
  // Add the first item (page 1)
  paginationItems.push(createPaginationItem(1));
  // Add an ellipsis
  paginationItems.push(<Pagination.Ellipsis />);
  const midpoint = Math.ceil(total_pages / 2);
  // Create page numbers in the middle
  for (let i = midpoint; i <= midpoint + 4; i++) {
    paginationItems.push(createPaginationItem(i));
  }
  // Add an ellipsis
  paginationItems.push(<Pagination.Ellipsis />);
  // Add the last item (page N)
  paginationItems.push(createPaginationItem(total_pages));

  return (
    <div>
      <div className="container-fluid">
        <Header />
      </div>
      <div className="container-fluid midway-container">
        <div className="row">
          <div className="col heading" ref={ref}>
            {/* <p className='color-itc font-Urbanist text-center heading'>AI AVATAR AFFAIR</p> */}
            <Image
              src={AiImg}
              // src={AiImgWithBg}
              alt="Second slide"
            />
          </div>
        </div>
        <div>
          <div className="row h-100">
            <SideGraphicsLeft />
            <div className="col-11 col-md-10 mx-auto">
              {/* lighbox */}
              {!lightboxOpen ? (
                <>
                  {midway_loading ? (
                    <div className="gallery-contianer row gx-4 gy-4 gx-md-5 gy-md-5">
                      <GalleryCardLoading />
                      <GalleryCardLoading />
                      <GalleryCardLoading />
                      <GalleryCardLoading />
                    </div>
                  ) : (
                    <div className="gallery-contianer row gx-4 gy-4 gx-md-5 gy-md-5">
                      {midwayData?.map((item, index) => (
                        <GalleryCard
                          data={item}
                          key={index}
                          active={true}
                          onClick={() => openLightbox(index)}
                        />
                      ))}
                    </div>
                  )}
                  {/* {total_pages > 1 && */}
                  <div className="pagination justify-content-start justify-content-xl-center">
                    <button
                      className="nav-item"
                      onClick={handlePreviousPage}
                      disabled={active_page === 1}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                    {total_pages &&
                      Array.from(Array(total_pages).keys()).map((_, index) => {
                        const pno = index + 1;
                        return (
                          <button
                            className={`nav-item ${
                              pno === active_page ? "active" : ""
                            }`}
                            onClick={() => handlePageChange(pno)}
                            key={pno}
                          >
                            {pno}
                          </button>
                        );
                      })}
                    <button
                      className="nav-item"
                      onClick={handleNextPage}
                      disabled={active_page === total_pages}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  </div>
                  {/* // } */}
                </>
              ) : (
                <div className="lightBox">
                  <button className="close" onClick={closeLightbox}>
                    <i className="fa-solid fa-circle-xmark"></i>
                  </button>
                  {/*  */}
                  {/* <SideGraphicsLeft/> */}
                  {/*  */}
                  {/* <div className='lightBox' onClick={closeLightbox} > */}
                  <div className="left-box">
                    {selectedImage > 0 && (
                      <GalleryCardLightBox
                        data={midwayData![selectedImage - 1]}
                        active={false}
                      />
                    )}
                    {selectedImage > 0 && (
                      <button className="nav" onClick={handleLeftNav}>
                        <i className="fa-solid fa-play fa-flip-horizontal"></i>
                      </button>
                    )}
                  </div>
                  <div className="mx-3">
                    <GalleryCardLightBox
                      data={midwayData![selectedImage]}
                      active={true}
                    />
                  </div>
                  <div className="right-box">
                    {selectedImage + 1 !== midwayData?.length! && (
                      <button className="nav" onClick={handleRightNav}>
                        <i className="fa-solid fa-play"></i>
                      </button>
                    )}
                    {selectedImage + 1 !== midwayData?.length! && (
                      <GalleryCardLightBox
                        data={midwayData![selectedImage + 1]}
                        active={false}
                      />
                    )}
                  </div>
                  {/*  */}
                  {/* <SideGraphicsRight/> */}
                  {/*  */}
                </div>
              )}
              {/* lighbox */}
            </div>
            <SideGraphicsRight />
          </div>
          <div className="row mt-0 mt-md-5">
            <div className="col-12">
              <BinoChip />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MidWay;
