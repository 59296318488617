import { observer } from 'mobx-react';
import React from 'react';
import { useAuthStore } from '../../auth/store/auth';
import { INewsCommentProps } from '../../_common/model/props.interface';
import { DataResponse } from "../../_common/widget/DataResponse"
import { useNewsStore } from "../store/news"

export const NewsCommentListing = observer(({ id }: { id?: string }) => {
    const { user } = useAuthStore();
    const { loadNewsComments, news_comments, news_comments_loading } = useNewsStore();
    React.useEffect(() => {
        loadNewsComments({
            user_id: user?.id!
            , news_id: id!
        })
    }, [])
    return (
        <DataResponse data={news_comments} isLoading={news_comments_loading}>
            {news_comments?.map((item, index) => (
                <div className="row item-comment" key={index}>
                    <Comment
                        imgUrl={item.UserProfileImage} name={`${item.firstname} ${item.lastname}`} username={item.username} comment={item.NewsCommentText} timeAgo={item.TimeAgo}
                    />
                </div>
            ))}
        </DataResponse>
    )
})

const Comment = (props: INewsCommentProps) => {
    const { imgUrl, name, username, comment, timeAgo } = props;
    return (
        <>
            <div className="col-md-2 comment-left">
                <div className="box-author mb-20">
                    <img src={imgUrl} alt={username} />
                    <div className="author-info">
                        <h6 className="color-gray-700">{name}</h6>
                        <span className="color-gray-700 text-sm mr-30">{username}</span>
                    </div>
                </div>
            </div>
            <div className="col-md-10 comment-right">
                <p className="text-start form-control mb-1">{comment}</p>
                <div className='text-start'>{timeAgo}</div>
            </div>
        </>
    )
}