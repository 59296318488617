import { observer } from 'mobx-react';
import React from 'react'
import "./votingsection.css"
import { Teamvotinglist } from '../../_common/model/teamvoting'
import { IGL_IMAGE_URL } from '../../core/config'
import {useTeamVotingStore} from "../store/teamvotingstore";
// auth
import { useAuthStore } from "../../auth/store/auth";
interface Props {
    teamlist: Teamvotinglist[],
    setModalState:Function,
    modalState:boolean,
}
const VotingSection = observer((props: Props) => {
    const {modalDataChange}=useTeamVotingStore();
    const { teamlist,setModalState, modalState } = props;
    const { loggedIn, showLogin} = useAuthStore();
    const handleSubmit=(item:Teamvotinglist)=>{
        //checking login in 
        if(loggedIn === true){
            setModalState();
            modalDataChange(item);
        }
        else{
            showLogin()
        }
    }
    return (
     
                   
                        <div className="row mx-0">
                        {teamlist.map((item, index) =>
                            <React.Fragment key={index}><div className="col-sm-5 col-md-5 gm-li" >
                                <div className="gm-col section">
                                    <div className="img-box img_position gh5" style={{ background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 49.16%, #000000 100%), url('${item?.team_img}')`, backgroundSize: "cover", backgroundPosition: "center" }}>

                                        <div className="top-right fw-bold" id="total_vote_13">{item.team_vote_count + " Votes"}</div>
                                        <div className="centered team_title">
                                            <h4 style={{ color: "#fff" }} id="team_vote_name13">{item.team_name}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="plateform-players-col">
                                    <button className="btn btn-send" id="vote_button_13" data-toggle="modal"
                                        disabled={item?.team_vote_status==="0"?false:true}
                                        onClick={()=>{handleSubmit(item)}}>{item?.team_vote_status==="0"?"Vote":"Voted"}</button>
                                </div>
                            </div>
                                {index === 0 ? <div className="col-sm-2 col-md-2 gm-li">
                                    <div className="section">
                                        <div className="img-box gh5 text-center">
                                                <img className="img-fluid" 
                                                src={`${IGL_IMAGE_URL}/team_vr.png`}
                                                alt="team_vr" style={{ height: "150px", width: "auto" }} />
                                        </div>
                                    </div>
                                </div> : <div className='my-5'>
                                <hr className='my-5 bg-light'/></div>}
                            </React.Fragment>
                        )}
                    </div>
    )
})

export default VotingSection