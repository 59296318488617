import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { IBattleRoyalePayload } from "../../_common/model/tournament";
import { battleRoyaleRepository, BattleRoyaleRepository, ICheckInBattleRoyale } from "../../_common/repository/battleroyale";

export class CheckinBatttleRoyaleStore {
  
  checkin_battleroyale:null | ICheckInBattleRoyale = null;
  checkin_battleroyale_error = "";
  checkin_battleroyale_loading = false;
  
  private checkinAPI: BattleRoyaleRepository;

  constructor(args: { checkinAPI: BattleRoyaleRepository}) {
    makeAutoObservable(this);
    this.checkinAPI = args.checkinAPI;
  }

  checkinBattleRoyale = async (payload: IBattleRoyalePayload) => {
    runInAction(() => {
      this.checkin_battleroyale_loading = true;
      this.checkin_battleroyale_error = "";
      this.checkin_battleroyale=null
    });
    const resp = await this.checkinAPI.checkinBattleRoyale(payload);

    if (resp.hasError) {
      runInAction(() => {
        this.checkin_battleroyale_error = resp.error!;
        this.checkin_battleroyale_loading = false;
        this.checkin_battleroyale=null

      });
      return resp.error;
    }
    await runInAction(() => {
      this.checkin_battleroyale = resp.result!;
      this.checkin_battleroyale_loading = false;
    });
    return resp.result;
  };
}

export const CheckinBatttleRoyaleStoreContext = createContext(new CheckinBatttleRoyaleStore({ checkinAPI: battleRoyaleRepository}));
export const useCheckinBatttleRoyaleStore = () => useContext(CheckinBatttleRoyaleStoreContext);
