import React from 'react'
import { FieldProps } from '../../_common/model/field'
import { Field, useFormikContext } from 'formik'
import { FieldError } from '../../_common/widget/FieldError' // wasn't working
import * as Yup from 'yup';
import { ITournamentboardParams } from '../model/leaderboard';

export const AvailabilityDropdown = (props: FieldProps) => {
    const { name = "month", disabled, required } = props;
    const { setFieldValue,values } = useFormikContext<ITournamentboardParams>();
    const validate = async (value: any) => {
        var schema = Yup.string();

        let sch = required
            ? schema
                .required('Please select Month')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }
    const data=[
        {
            title:"AVAILABLE",
            value:"available"
        },
        {
            title:"FULL",
            value:"full"
        }
    ];
    const [toogle, setToogle] = React.useState(false);
    return (
        // <div className="select-dropdown">
        //     <Field component="select" name={name} disabled={disabled} validate={validate}>
        //         <option value="">Select Availability</option>
        //             <option value='available'>AVAILABLE</option>
        //             <option value='full'>FULL</option>
        //     </Field>
        //     <FieldError name={name} />
        // </div>
        <div className="select-dropdown position-relative" onBlur={()=>{setToogle(false)}} tabIndex={6}>
        <label onClick={() => setToogle(!toogle)}>{(values.availability===""||values.availability === undefined) ? "Select Availability" :  data!.find(item=>item.value === values.availability)?.title }</label>
        {toogle &&
            <div className='position-absolute w-100' style={{zIndex:1}}>
                <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue("availability","");
                       
                        setToogle(false);
                    }}>Select Availability</p>
                {data?.map((option,index) => (
                    <p className='cursor-pointer font-D-DinCondensed' key={index} onClick={() => {
                        setFieldValue("availability",option.value);
                        setToogle(false);
                    }}>{option.title}</p>
                ))} 
            </div>
        }
        <FieldError name={name} />
        </div>
    )
}