import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTrendingStore } from "../store/trendingstore";
import VideoCard from '../../home/view/VideoCard'
const Trendingvideosection = observer(() => {
  const { newlatestvideo, listLatestVideo, filterTrending, filterTrendingData } = useTrendingStore()!
  useEffect(() => {
    newlatestvideo()
  }, [])
  return (
    <div className="tab-and-search-raw mt250 section">
      <div className="game-lists-raw igtv-raw trending-videos-raw section pb10">
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item active">
            <a
              className="nav-link active"
              data-toggle="tab"
              href="#tabs-1"
              role="tab"
              aria-expanded="true"
            >
              Trending
            </a>
          </li>
        </ul>
        {/* <!-- Tab panes --> */}
        <div className="search-game-raw">
          <input
            type="text"
            className="form-control cursor-default"
            placeholder="Search"
            onChange={(e) => filterTrending(e.target.value)}
          />
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="tabs-1" role="tabpanel">
            <div className="section gm-li-raw mCustomScrollbar _mCS_1">
              <div
                id="mCSB_1"
                className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                tabIndex={0}
              >
                <div
                  id="mCSB_1_container"
                  className="mCSB_container"
                >
                  <div className="row">
                    {filterTrendingData !== undefined ? <>
                      {filterTrendingData.length>0? filterTrendingData?.map((data, index) => (
                      <VideoCard
                        TVID={data.TVID}
                        TVTitle={data.TVTitle}
                        TournamentTitle={data.TournamentTitle}
                        TVVideoID={data.TVVideoID}
                        GameTitle={data.GameTitle}
                        TVDate={data.TVDate}
                        GameID={data.GameID}
                        TournamentID={data.TournamentID}
                        TVlike={data.TVlike}
                        islike={data.islike}
                        key={index}
                      />
                    )):<div className="banner-con section text-center d-flex justify-content-center align-items-center" style={{minHeight:"350px"}}><div className="title-raw"><h1>No Data Found</h1></div></div>}</>: listLatestVideo?.map((data, index) => (
                      <VideoCard
                        TVID={data.TVID}
                        TVTitle={data.TVTitle}
                        TournamentTitle={data.TournamentTitle}
                        TVVideoID={data.TVVideoID}
                        GameTitle={data.GameTitle}
                        TVDate={data.TVDate}
                        GameID={data.GameID}
                        TournamentID={data.TournamentID}
                        TVlike={data.TVlike}
                        islike={data.islike}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
                <div
                  id="mCSB_1_scrollbar_vertical"
                  className="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                  style={{ display: "block" }}
                >
                  <div className="mCSB_draggerContainer">
                    <div
                      id="mCSB_1_dragger_vertical"
                      className="mCSB_dragger"
                      style={{
                        position: "absolute",
                        minHeight: "30px",
                        display: "block",
                        height: "374px",
                        maxHeight: "363.797px",
                      }}
                    >
                      <div
                        className="mCSB_dragger_bar"
                        style={{ lineHeight: "30px" }}
                      ></div>
                    </div>
                    <div className="mCSB_draggerRail"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Trendingvideosection;
