import { observer } from 'mobx-react';
import React from 'react'
// import { useMegaTournamentStore } from '../../tournament/store/megatournaments';
import { useItcBingoTournamentStore } from '../../tournament/store/itcbingo';
// import { DataResponse } from '../../_common/widget/DataResponse';
import MegaTournamentCard from './MegaTournamentCard'
// import { Carousel } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MegaTournamentCardMobile from './MegaTournamentCardMobile';
const MegaTournamentSlider = observer(() => {
  // const { loadTournament, tournaments, loadingTournament, errTournament } = useMegaTournamentStore();
  const { loadTournament, tournaments, loadingTournament, errTournament } = useItcBingoTournamentStore();
  React.useEffect(() => {
    loadTournament()
  }, []);
  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          infinite: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          infinite: false
        }
      },
    ]
  };
  return (
    <>
    <div className='row bg-black d-none d-md-block mt-5'>
      <div className="col-megatournament-10 mx-auto ">
            <Slider {...settings}>
              {tournaments?.map((item, index) => {
                return (
                  <div key={index} className="px-2">
                    <MegaTournamentCard
                      imgUrl={item.TournamentLogo}
                      title={item.TournamentTitle}
                      date={item.TournamentDate}
                      time={item.TournamentStartTime}
                      coins={item.IGLCoins}
                      platform={item.PlatformName}
                      players={item.TournamentTeams}
                      gameName={item.GameName}
                      detailId={item.TournamentID}
                      gameType={item.Tournamenttype}
                      teamsize={item.TournamentTeamsType}
                      gameId={item.GameID}
                      btnLabel="Join Now"
                      from_campaign="itc"
                    /></div>
                )
              })
              }
            </Slider>
      </div>
    </div>
    <div className="container">
    <div className="row gy-3 gx-2 d-md-none  mt-2">
          {tournaments?.map((item, index) => {
            return (
              // <div className="col-10" key={index}>
              <div className="col-6" key={index}>
                <MegaTournamentCardMobile
              //  <MegaTournamentCard
                  imgUrl={item.TournamentLogo}
                  title={item.TournamentTitle}
                  date={item.TournamentDate}
                  time={item.TournamentStartTime}
                  coins={item.IGLCoins}
                  platform={item.PlatformName}
                  players={item.TournamentTeams}
                  gameName={item.GameName}
                  detailId={item.TournamentID}
                  gameType={item.Tournamenttype}
                  teamsize={item.TournamentTeamsType}
                  gameId={item.GameID}
                  btnLabel="View Details "
                  key={index}
                  from_campaign="itc"
                />
              </div>
            )
          })
          }
        </div>
    </div>
    </>
  )
})

export default MegaTournamentSlider