import api from "../../core/api";
import { ITournamentParams, MyTournamentResponse } from "../model/tournament";
import { R } from "../pkg/types";

export interface ITournamentsRepository {
  getMyTournaments(params?: ITournamentParams): Promise<R<MyTournamentResponse[]>>;
}

class MyTournamentsRepository implements ITournamentsRepository {
  async getMyTournaments(params?: ITournamentParams): Promise<R<MyTournamentResponse[]>> {
    try {
      const resp = await api.get(`/usertournaments`, {params});
      const list = resp.data.Tournamentlist as MyTournamentResponse[];
      if(list){
        return new R({ result: list });
      }
      else{
        return new R({error:resp.data.msg})
      }
    } catch (e) {
      return new R({ error: e });
    }
  }
}

export const tournamentsRepository: ITournamentsRepository = new MyTournamentsRepository();
