import { observer } from 'mobx-react';
import React, {useEffect} from 'react'
import { Carousel } from 'react-bootstrap'
import banner1 from "../../assets/images/logo-3d-compressed.png";
import { useAuthStore } from '../../auth/store/auth';
import { useMidwayStore } from '../../midway-gallery/store/midway-store';
const Header = observer(() => {
    const {user}=useAuthStore();
    const { loadBanner, loadingBanner, banners } = useMidwayStore()!;
    useEffect(()=>{
      if(window.innerWidth<768){
        loadBanner({device_type:"2"});
        return;
      }
      loadBanner({device_type:"1"});  
    },[])
    return (
        <div className={user?.loggedin==="1"?'row mt-5 pt-1':'row'}>
        <div className='col-12 px-0 bg-dark'>
            <Carousel id="itc-bingo"  interval={3000} fade indicators={true} nextIcon={false} prevIcon={false}>
            {banners && Array.isArray(banners) ? (
            banners?.map((banner, idx) => {
              return (
                <Carousel.Item key={idx} >
                  <img className="d-block w-100  h-100" src={`${banner.imageurl}`} alt={banner.page_title} />
                </Carousel.Item>
              );
            })
          ) : (
            <Carousel.Item
              // interval={1000}
              style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <img
                className="d-block w-100"
                src={banner1}
                alt="First slide"
                style={{ objectFit: "none", height: "150px" }}
              />
            </Carousel.Item>
          )}
           
            </Carousel>
            </div>
      </div>
    )
})

export default Header