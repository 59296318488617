export class R<H> {
  result?: H;
  error?: any;
  errorLevel?: number;
  count?: string;

  constructor(args: { result?: H; error?: any; errorLevel?: number, count?: string }) {
    this.result = args.result;
    this.count = args.count;
    if (args.error) {
      this.error = args.error;
      this.errorLevel = args.errorLevel ?? 100;
      if (this.errorLevel > 10) {
        console.log(this.error);
      }
    }
  }

  public get hasError() {
    return this.error != null;
  }
}
