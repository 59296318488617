import React from 'react'

type Props = {
    label: string,
    type?: 'submit' | 'reset' | 'button' | undefined,
    onClick?: React.MouseEventHandler<HTMLElement>
}

const Button = (props: Props) => {

    return (
        <button className="btn-main btn-two-line" type={props.type} onClick={props.onClick}>{props.label}</button>
    )
}

export default Button