import React from 'react'

export const SideGraphicsLeft = () => {
  return (
    <div className="col-md-1 bg-graphics">
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
        <path d="M43.7015 1.0572C43.8689 -0.3524 45.9143 -0.3524 46.0817 1.0572L47.4848 12.8825C49.2966 28.1521 61.3355 40.191 76.605 42.0028L88.4303 43.4059C89.84 43.5733 89.84 45.6187 88.4303 45.7861L76.605 47.1892C61.3355 49.001 49.2966 61.04 47.4848 76.3094L46.0817 88.1347C45.9143 89.5445 43.8689 89.5445 43.7015 88.1347L42.2983 76.3094C40.4865 61.04 28.4476 49.001 13.178 47.1892L1.35276 45.7861C-0.0568372 45.6187 -0.0568372 43.5733 1.35276 43.4059L13.178 42.0028C28.4476 40.191 40.4865 28.1521 42.2983 12.8825L43.7015 1.0572Z" fill="white" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="85" viewBox="0 0 86 85" fill="none">
        <path d="M42.8916 0.191406L46.0475 21.6026L55.3744 2.07288L52.079 23.4631L66.7479 7.55012L57.2942 27.0187L76.0017 16.1364L61.2296 31.9535L82.3137 27.0689L63.5356 37.8291L85.1226 39.376L64.0071 44.1232L84.1793 51.9644L62.6027 50.277L79.5672 63.7156L59.4468 55.7433L71.6965 73.5853L54.8198 60.0362L61.2663 80.6965L49.133 62.7748L49.2036 84.4173L42.8916 63.7156L36.5797 84.4173L36.6503 62.7748L24.5169 80.6965L30.9635 60.0362L14.0867 73.5853L26.3366 55.7433L6.21593 63.7156L23.1806 50.277L1.60394 51.9644L21.7761 44.1232L0.660583 39.376L22.2478 37.8291L3.46961 27.0689L24.5538 31.9535L9.7815 16.1364L28.4891 27.0187L19.0353 7.55012L33.7043 23.4631L30.4089 2.07288L39.7358 21.6026L42.8916 0.191406Z" fill="white" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="94" height="93" viewBox="0 0 94 93" fill="none">
        <path d="M59.4184 65.875L42.8098 92.6784L45.0616 65.0283L12.9702 78.2893L35.7721 57.1287L0.583923 58.5392L28.453 48.1L0.583923 26.9393L31.268 32.0179V0.417969L42.8098 32.0179L59.4184 4.08582L53.507 35.1214L77.7165 10.8572L59.4184 40.4821L87.8506 29.1965L59.4184 51.4856H93.1992L59.4184 65.875Z" fill="white" />
    </svg>
</div>
  )
}
export const SideGraphicsRight = () => {
  return (
    <div className="col-md-1 bg-graphics">
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M63.4619 15.8271L47.6348 0L31.8076 15.8271L15.9805 0L0.15332 15.8271L15.9805 31.6543L0.15332 47.4814L15.9805 63.3086L31.8076 47.4814L47.6348 63.3086L63.4619 47.4814L47.6348 31.6543L63.4619 15.8271ZM47.6348 31.6543L31.8076 47.4814L15.9805 31.6543L31.8076 15.8271L47.6348 31.6543Z" fill="white" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="89" viewBox="0 0 51 89" fill="none">
        <path d="M37.7236 1.91962C38.6143 1.91962 39.3364 1.19832 39.3364 0.308593L50.8044 0.308594C50.8044 7.52533 44.948 13.3757 37.7236 13.3757L13.8916 13.3757C13.0009 13.3757 12.2788 14.0969 12.2788 14.9866C12.2788 15.8764 13.0009 16.5977 13.8916 16.5977L37.7236 16.5977C44.948 16.5977 50.8044 22.448 50.8044 29.6648C50.8044 36.8815 44.948 42.7317 37.7236 42.7317L13.8916 42.7317C13.0009 42.7317 12.2788 43.4532 12.2788 44.3428C12.2788 45.2324 13.0009 45.9538 13.8916 45.9538L37.7236 45.9538C44.948 45.9538 50.8044 51.8041 50.8044 59.0207C50.8044 66.2377 44.948 72.088 37.7236 72.088L13.8916 72.088C13.0009 72.088 12.2788 72.8091 12.2788 73.699C12.2788 74.5886 13.0009 75.3101 13.8916 75.3101L37.7236 75.3101C44.948 75.3101 50.8044 81.1603 50.8044 88.377L39.3364 88.377C39.3364 87.4873 38.6143 86.7659 37.7236 86.7659L13.8916 86.7659C6.6673 86.7659 0.810753 80.9156 0.810753 73.699C0.810754 66.482 6.6673 60.6318 13.8916 60.6318L37.7236 60.6318C38.6143 60.6318 39.3364 59.9107 39.3364 59.0207C39.3364 58.1311 38.6143 57.4097 37.7236 57.4097L13.8916 57.4097C6.6673 57.4097 0.810754 51.5594 0.810755 44.3428C0.810755 37.1261 6.6673 31.2759 13.8916 31.2759L37.7236 31.2759C38.6143 31.2759 39.3364 30.5544 39.3364 29.6648C39.3364 28.7749 38.6143 28.0538 37.7236 28.0538L13.8916 28.0538C6.6673 28.0538 0.810756 22.2034 0.810756 14.9866C0.810756 7.7699 6.6673 1.91961 13.8916 1.91961L37.7236 1.91962Z" fill="white" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="102" height="47" viewBox="0 0 102 47" fill="none">
        <path d="M78.0436 46.8709C66.5258 46.8709 58.361 38.8397 50.8076 30.0561C43.1913 38.8937 35.0515 46.8709 23.5719 46.8709C10.6836 46.8709 0.199219 36.4422 0.199219 23.6239C0.199219 10.8056 10.6836 0.376953 23.5719 0.376953C35.0935 0.376953 43.5073 8.57264 50.8076 17.0181C58.1039 8.58128 66.5258 0.376953 78.0436 0.376953C90.9327 0.376953 101.416 10.8056 101.416 23.6239C101.416 36.4422 90.9273 46.8709 78.0436 46.8709ZM56.3367 23.5391C63.1729 31.5575 69.7817 38.4172 78.0436 38.4172C86.2808 38.4172 92.9813 31.7858 92.9813 23.628C92.9813 15.4747 86.2808 8.83926 78.0436 8.83926C69.6595 8.83514 63.0548 15.6566 56.3367 23.5391ZM23.5719 8.83514C15.3353 8.83514 8.63395 15.4702 8.63395 23.6239C8.63395 31.7817 15.3353 38.4127 23.5719 38.4127C31.876 38.4127 38.5057 31.4929 45.2785 23.5309C38.5522 15.6566 31.9517 8.83514 23.5719 8.83514Z" fill="white" />
    </svg>
</div>
  )
}
