import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { TournamentTeam } from "../../_common/model/team";
import { ITournamentsRepository, tournamentsRepository } from "../../_common/repository/tournaments";

export class TournamentJoinedTeamStore {
  
  joined_teams?: TournamentTeam[];
  joined_teams_error = "";
  joined_teams_loading = false;
  showMore:boolean=false;
  current_page:number=1;

  private joinedTeamsAPI: ITournamentsRepository;

  constructor(args: { joinedTeamsAPI: ITournamentsRepository}) {
    makeAutoObservable(this);
    this.joinedTeamsAPI = args.joinedTeamsAPI;
  }

  loadJoinedTeams = async (id: string, userId: string) => {
    runInAction(() => {
      this.joined_teams_loading = true;
    });
    const resp = await this.joinedTeamsAPI.getTournamentJoinedeams(id, userId, this.current_page);

    if (resp.hasError) {
      runInAction(() => {
        this.joined_teams_error = resp.error!;
        this.joined_teams_loading = false;
      });
      return;
    }
    runInAction(() => {
      if(resp.result!.length===10){
        this.showMore = true;
      }
      else{
        this.showMore = false;

      }
      if (this.joined_teams?.length! > 0) {
        this.joined_teams = [...this.joined_teams!, ...resp.result!];
        this.joined_teams_loading = false;
        return;
      }
      this.joined_teams = resp.result!;
      this.joined_teams_loading = false;
    });
   

  };

  resetPageCount = () => {
    this.current_page = 1;
    this.joined_teams = [];
  };
  loadMoreTeams = (id:string,user_id:string) => {
    this.current_page=this.current_page+1;
    this.loadJoinedTeams(id, user_id);
  }
}

export const TournamentJoinedTeamStoreContext = createContext(new TournamentJoinedTeamStore({ joinedTeamsAPI: tournamentsRepository}));
export const useTournamentJoinedTeamStore = () => useContext(TournamentJoinedTeamStoreContext);
