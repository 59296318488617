
import React from 'react'
import Email from '../widget/Email';
import { observer } from 'mobx-react';
import { useAuthStore } from '../store/auth';
import Button from '../widget/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ErrorMsg } from '../../_common/widget/ErrorMsg';
import { useNavigate } from 'react-router-dom';

const VALIDATION_SCHEMA = Yup.object({
    email: Yup.string().required("Please enter Email!").email("Please enter valid Email adress!"),
});

export const ResetForm = observer(() => {

    const { reset_password_error, reset_password_loading, forgetPassword,reset_password, hideReset,showForgetResponse,toogleResponse } = useAuthStore();
    const handleSubmit = async(values: any) => 
        await forgetPassword({ email: values.email });
    return (
        <>
            <div className="formboxmain">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title-raw ">
                            <h3>Forgot Password</h3>
                        </div>
                    </div>
                </div>
                {showForgetResponse===false?
                <div>
                <p className="field-name mb-3">Please enter your registered email address below to reset your password</p>
                <p className="space-d"></p>
                <ErrorMsg msg={reset_password_error} />
                <Formik
                    initialValues={{ email: "" }}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values);
                        resetForm();
                    }}
                    validationSchema={VALIDATION_SCHEMA}
                >
                    {(props) => {
                        return (
                            <Form id="ForgotPasswordForm" name="ForgotPasswordForm">
                                <div className="contact-form">
                                    <div className="row">
                                        <Email
                                            className="col-md-12"
                                            name='email'
                                            isFloating
                                            fullWidth
                                        />
                                    </div>
                                    <p className="space-e"></p>
                                    {/* {reset_password&& <div className="text-success">{reset_password}</div>} */}
                                    <p className="button text-center">
                                        <Button
                                            
                                            type="submit"
                                            label={reset_password_loading ? 'Submitting..' : 'Submit'}
                                        />
                                    </p>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            :<div className='mb-3 title-raw'>
                <p className='text-white fs-6 mb-3'>{reset_password}</p>
                <p onClick={()=>toogleResponse()} className='fs-6 text-white cursor-pointer mt-0 mx-auto d-block text-decoration-underline'>Resend Reset Link</p>    
            </div>}
            </div>
        </>
    )
});