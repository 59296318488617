import { observer } from 'mobx-react';
import React, { Suspense } from 'react'
import { ITournamentParams, Tournament } from '../../_common/model/tournament';
import { DataResponse } from '../../_common/widget/DataResponse';
import { useUpcomingTournamentStore } from '../store/upcomingtournaments';
import { TournamentCard } from '../widget/TournamentCard';
import { TournamentSection } from '../widget/TournamentSection';
import { useTournamentsStore } from '../store/tournaments';
import { useAuthStore } from '../../auth/store/auth';
import MegaTournamentCard from '../../itc-bingo/components/MegaTournamentCard';
// import AdSlot from '../../_common/view/AdsComponent/AD_Index';

type Props = ITournamentParams;

export const UpcomingTournaments = observer((props: Props) => {
    const AdSlot = React.lazy(() => import('../../_common/view/AdsComponent/AD_Index'));
    const { loadUpcomingTournaments, upcoming_tournaments, upcoming_tournaments_loading, loadMoreTournament, more_data_available, page_no, resetPageCount } = useUpcomingTournamentStore();
    const { tournament_filters } = useTournamentsStore();
    const { user } = useAuthStore();
    React.useEffect(() => {
        resetPageCount();
    }, []);
    React.useEffect(() => {
        resetPageCount();
        loadUpcomingTournaments({
            user_id: user?.id!,
            ...tournament_filters,
            // PNO:String(page_no)
        })
    }, [tournament_filters]);
    const [filter, setFilter] = React.useState<Tournament[]>([]);
    const [searchString, setSearchString] = React.useState('');
    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(upcoming_tournaments!.filter(item => {
            if (item.TournamentTitle.toLowerCase().includes(event.target.value.toLowerCase())) {
                return item;
            } else if (item.GameName.toLowerCase().includes(event.target.value.toLowerCase())) {
                return item;
            }
        }
        ))
        setSearchString(event.target.value);
    }
    const loader = upcoming_tournaments_loading === true && page_no === 0;
    //ads work starts
    const [isMobile, setIsMobile] = React.useState<boolean>(false);
    React.useEffect(() => {
        // Check the window width and set isMobile accordingly
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as per your requirement
        };

        handleResize(); // Call once on initial load

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    //   ads work ends
    return (
        <DataResponse isLoading={loader} data={upcoming_tournaments}>
            <div className="section gm-li-raw clear ">
                <div className="container-fluid clear ">
                    <div className="row">
                        <div className="col-12 col-md-4 ms-md-auto">
                            <input type="search" className="form-control cursor-pointer" defaultValue={searchString} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleFilter(event) }} placeholder="Search" />
                        </div>
                    </div>
                </div>
            </div>
            <TournamentSection>

                {filter.length > 0 ? filter.map((item, index) => {
                    if ((index + 1) % 16 === 0) {
                        return <React.Fragment key={index}><div className="col-sm-3 col-6 gm-li d-flex" >
                            {item.from_campaign === "itc" ? <MegaTournamentCard
                                imgUrl={item.TournamentLogo}
                                title={item.TournamentTitle}
                                date={item.TournamentDate}
                                coins={item?.IGLCoins}
                                platform={item.PlatformName}
                                players={item.TournamentTeams}
                                gameName={item.GameName}
                                btnLabel={'view details'}
                                detailId={item.TournamentID}
                                gameType={item.Tournamenttype}
                                time={item.TournamentStartTime}
                                teamsize={item.TournamentTeamsType}
                                from_campaign={item.from_campaign || ""}
                            /> : <TournamentCard
                                imgUrl={item.TournamentLogo}
                                title={item.TournamentTitle}
                                date={item.TournamentDate}
                                coins={item?.IGLCoins}
                                platform={item.PlatformName}
                                players={item.TournamentTeams}
                                gameName={item.GameName}
                                btnLabel={'view details'}
                                detailId={item.TournamentID}
                                gameType={item.Tournamenttype}
                                time={item.TournamentStartTime}
                                teamsize={item.TournamentTeamsType}
                                from_campaign={item.from_campaign || ""}
                            />}

                        </div>
                            <div className='col-12'>
                                {!isMobile && <Suspense fallback={<></>}>
                                    <AdSlot adUnitPath="/317733190/IGL_Web_Tournaments_728x90_Ad1" adSize={[728, 90]} />
                                </Suspense>}
                                {isMobile && <Suspense fallback={<></>}>
                                    <AdSlot adUnitPath="/317733190/IGL_Web_Tournaments_300x250_Ad1" adSize={[300, 250]} />
                                </Suspense>}
                            </div>
                        </React.Fragment>
                    }
                    return <div className="col-sm-3 col-6 gm-li d-flex" key={index}> {item.from_campaign === "itc" ? <MegaTournamentCard
                        imgUrl={item.TournamentLogo}
                        title={item.TournamentTitle}
                        date={item.TournamentDate}
                        coins={item?.IGLCoins}
                        platform={item.PlatformName}
                        players={item.TournamentTeams}
                        gameName={item.GameName}
                        btnLabel={'view details'}
                        detailId={item.TournamentID}
                        gameType={item.Tournamenttype}
                        time={item.TournamentStartTime}
                        teamsize={item.TournamentTeamsType}
                        from_campaign={item.from_campaign || ""}
                    /> : <TournamentCard
                        imgUrl={item.TournamentLogo}
                        title={item.TournamentTitle}
                        date={item.TournamentDate}
                        coins={item?.IGLCoins}
                        platform={item.PlatformName}
                        players={item.TournamentTeams}
                        gameName={item.GameName}
                        btnLabel={'view details'}
                        detailId={item.TournamentID}
                        gameType={item.Tournamenttype}
                        time={item.TournamentStartTime}
                        teamsize={item.TournamentTeamsType}
                        from_campaign={item.from_campaign || ""}
                    />}</div>
                }) :
                    (searchString !== "" && filter.length === 0) ?
                        <div className='title-raw d-flex justify-content-center align-items-center mx-auto' style={{ height: '450px' }}>
                            <h1 className=''>Tournament not found.</h1>
                        </div> :
                        upcoming_tournaments?.map((item, idx) => {
                            if ((idx + 1) % 16 === 0) {
                                return <React.Fragment key={idx}><div className="col-sm-3 col-6 gm-li d-flex" >

                                    {item.from_campaign === "itc" ? <MegaTournamentCard
                                        imgUrl={item.TournamentLogo}
                                        title={item.TournamentTitle}
                                        date={item.TournamentDate}
                                        coins={item?.IGLCoins}
                                        platform={item.PlatformName}
                                        players={item.TournamentTeams}
                                        gameName={item.GameName}
                                        btnLabel={'view details'}
                                        detailId={item.TournamentID}
                                        gameType={item.Tournamenttype}
                                        time={item.TournamentStartTime}
                                        teamsize={item.TournamentTeamsType}
                                        from_campaign={item.from_campaign || ""}
                                    /> : <TournamentCard
                                        imgUrl={item.TournamentLogo}
                                        title={item.TournamentTitle}
                                        date={item.TournamentDate}
                                        coins={item?.IGLCoins}
                                        platform={item.PlatformName}
                                        players={item.TournamentTeams}
                                        gameName={item.GameName}
                                        btnLabel={'view details'}
                                        detailId={item.TournamentID}
                                        gameType={item.Tournamenttype}
                                        time={item.TournamentStartTime}
                                        teamsize={item.TournamentTeamsType}
                                        from_campaign={item.from_campaign || ""}
                                    />}
                                </div>
                                    <div className='col-12'>
                                        {!isMobile && <Suspense fallback={<></>}>
                                            <AdSlot adUnitPath="/317733190/IGL_Web_Tournaments_728x90_Ad1" adSize={[728, 90]} />
                                        </Suspense>}
                                        {isMobile && <Suspense fallback={<></>}>
                                            <AdSlot adUnitPath="/317733190/IGL_Web_Tournaments_300x250_Ad1" adSize={[300, 250]} />
                                        </Suspense>}
                                    </div>
                                </React.Fragment>
                            }
                            return <div className="col-sm-3 col-6 gm-li d-flex" key={idx}> {item.from_campaign === "itc" ? <MegaTournamentCard
                                imgUrl={item.TournamentLogo}
                                title={item.TournamentTitle}
                                date={item.TournamentDate}
                                coins={item?.IGLCoins}
                                platform={item.PlatformName}
                                players={item.TournamentTeams}
                                gameName={item.GameName}
                                btnLabel={'view details'}
                                detailId={item.TournamentID}
                                gameType={item.Tournamenttype}
                                time={item.TournamentStartTime}
                                teamsize={item.TournamentTeamsType}
                                from_campaign={item.from_campaign || ""}
                            /> : <TournamentCard
                                imgUrl={item.TournamentLogo}
                                title={item.TournamentTitle}
                                date={item.TournamentDate}
                                coins={item?.IGLCoins}
                                platform={item.PlatformName}
                                players={item.TournamentTeams}
                                gameName={item.GameName}
                                btnLabel={'view details'}
                                detailId={item.TournamentID}
                                gameType={item.Tournamenttype}
                                time={item.TournamentStartTime}
                                teamsize={item.TournamentTeamsType}
                                from_campaign={item.from_campaign || ""}
                            />}</div>
                        })
                }
                {/* {more_data_available===true ? 
                <div className="col-sm-12 col-12 text-center">
                <button className="mt50 btn-sm btn-main txt-shad-none" onClick={()=>{
                     loadMoreTournament({user_id: user?.id!})
                }}>View More</button>
                </div>  : null} */}
            </TournamentSection>
        </DataResponse>
    )
})