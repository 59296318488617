import { Form, Formik } from 'formik';
import { observer } from 'mobx-react'
import React from 'react'
import { useAuthStore } from '../../auth/store/auth';
import { Comment } from '../../contact/widget/Comment'
import { ErrorMsg } from '../../_common/widget/ErrorMsg';
import { useNewsStore } from '../store/news';
import * as Yup from 'yup';

type Props = {
    id?: string
}
export const NewsPostForm = observer(({ id }: Props) => {
    const { user } = useAuthStore();
    const { addNewsPost, news_post_loading, news_post_error } = useNewsStore();
    const handlePost = (values: any) => {
        addNewsPost({ commenttext: values.comment, news_id: id!, user_id: user?.id! })
    }
    return (
        <>
            <Formik
                initialValues={{ comment: "" }}
                onSubmit={(values, { resetForm }) => {
                    handlePost(values);
                    resetForm();
                }}
                validationSchema={Yup.object({
                    comment: Yup.string().min(1).max(500).required("Please write comment to post!")
                })}
            >
                <Form>
                    <Comment label='Post a comment' name='comment' />
                    <ErrorMsg msg={news_post_error} />
                    <div className="col-12 text-end">
                        <button type="submit" className="btn-main">
                            {news_post_loading ? "Posting..." : "Post"}
                        </button>
                    </div>
                </Form>
            </Formik>
        </>
    )
});