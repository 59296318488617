import { observer } from 'mobx-react';
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuthStore } from '../../auth/store/auth';
import { DETAILS_TYPE, GAME_TYPE } from '../../core/config';
import { useTournamentDetailsStore } from '../store/tournamentdetails'
import { DetailsSection } from './DetailsSection';
import { SocialConnect } from '../../_common/widget/SocialConnect';

export const TournamentDetails = observer(() => {
    const { loadTournamentDetails,tournament_details_error,tournament_details_loading, tournament_details } = useTournamentDetailsStore();
    const { user } = useAuthStore();
    const { id } = useParams();
    
    React.useEffect(() => {
        loadTournamentDetails(id!, user?.id!)
    }, [id]);

    return (
        <>{tournament_details_loading?
            <div className='row'>
            <div className="col-12 d-flex justify-content-center align-items-center" style={{height:'80vh'}}>
                <div className="title-raw">
                    <h1>Loading...</h1>
                </div>
            </div>
        </div>:
        tournament_details_error!==""?
        <div className='row'>
        <div className="col-12 d-flex justify-content-center align-items-center" style={{height:'80vh'}}>
            <div className="title-raw">
                <h1>{tournament_details_error}</h1>
            </div>
        </div>
    </div>
        :
            <DetailsSection
                bgImg={tournament_details?.TournamentCoverImage}
                heading={tournament_details?.TournamentTitle!}
                detailType={DETAILS_TYPE.tournament}
                platform={tournament_details?.TournamentPlatform}
                platformId={tournament_details?.TournamentPlatformID}
                prize={String(tournament_details?.IGLTournamentCoins)}
                teams={`${tournament_details?.TotalJoininCount}/${tournament_details?.TournamentTeams}`}
                size={tournament_details?.TournamentTeamMembers}
                TotalCheckinCount={`${tournament_details?.TotalCheckinCount}/${tournament_details?.TournamentTeams}`}
                date={tournament_details?.TournamentDate}
                startTime={tournament_details?.TournamentStartTime}
                endTime={tournament_details?.TournamentExpirationTime}
                checkInTime={tournament_details?.TournamentCheckInTime}
                checkInCloseTime={tournament_details?.TournamentCheckOutTime}
                // 
                startdate={tournament_details?.TournamentStartDate}
                enddate={tournament_details?.TournamentExpirationDate}
                checkInDate={tournament_details?.TournamentCheckInstartDate}
                checkInCloseDate={tournament_details?.TournamentCheckInCloseDate}
                // 
                level={tournament_details?.TournamentLevel}
                levelId={tournament_details?.TournamentLevelID}
                game={tournament_details?.TournamentGame}
                expirationDate={tournament_details?.TournamentExpirationDate}
                expirationTime={tournament_details?.TournamentExpirationTime}
                timeZone={tournament_details?.TournamentTimeZone}
                entryFee={tournament_details?.TournamentEntryFees}
                
                gameType= {GAME_TYPE.tournament}
                gameId = {tournament_details?.TournamentGameID}
                
                rules={tournament_details?.TournamentRulesText}
                howToPlay={tournament_details?.TournamentHowToPlay}
                IGLTournamentCoins={tournament_details?.IGLTournamentCoins}
                isPrivate = {tournament_details?.tournaments_type}
                winnerPoints={tournament_details?.TournamentWinnerPoints}
                runnerup1stPoint={tournament_details?.TournamentRunnerup1Points}
                runnerup2ndPoints={tournament_details?.TournamentRunnerup2Points}
                prize1={tournament_details?.TournamentWinnerPrize}
                prize2={tournament_details?.TournamentRunnerupPrize}
                prize3={tournament_details?.Tournament2RunnerUpPrize}
                prize4={tournament_details?.Tournament4RunnerUpPrize}
                roundTime={tournament_details?.TournamentTimeinRounds}
            />}
            <SocialConnect />
        </>
    )
});