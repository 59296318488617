import React from 'react'
import { FieldProps } from '../../_common/model/field'
import { useFormikContext  } from 'formik'
import * as Yup from 'yup';
import moment from 'moment';
import { ITournamentboardParams } from '../model/leaderboard';
export const SortByDropDown = (props: FieldProps & {toogleDate:any;setToogleDate:any}) => {
    const { name = "sortby", required, toogleDate,setToogleDate } = props;

    const validate = async (value: any) => {
        var schema = Yup.string();
        let sch = required
            ? schema
                .required('Please Select Sort Criteria')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }
    const { setFieldValue,values } = useFormikContext<ITournamentboardParams>();
    const [toogle, setToogle] = React.useState(false);
    return (
    <>
        {toogleDate===false?<div className="select-dropdown position-relative" onBlur={()=>{setToogle(false)}} tabIndex={6}>
            <label onClick={() => setToogle(!toogle)}>{
            (values.date==="" && values.sort_type==="") ? "By Date" : values.date!=="" ? values.date : values.sort_type !=="" ? 
            values.sort_type==="ASC"?"Oldest to Soonest": values.sort_type==="DSC"?"Soonest to Oldest":
           "Soonest to Oldest" :"By Date"}</label>
            {toogle &&
                <div className='position-absolute w-100' style={{zIndex:3}}>
                    <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue("date", moment().format('YYYY-MM-DD'));
                        setFieldValue(name, "");
                        setToogle(false);
                    }}>Today</p>
                    <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue("date", moment(new Date().setDate(new Date().getDate() + 1)).format("YYYY-MM-DD"));
                        setFieldValue(name, "");
                        setToogle(false);
                    }}>Tomorrow</p>
                    <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue("sort_type", "ASC");
                        setFieldValue("date", "");
                        setToogle(false);
                    }}>Oldest to Soonest</p>
                    <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue("sort_type", "DESC");
                        setFieldValue("date", "");
                        setToogle(false);
                    }}>Soonest to Oldest</p>
                    <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue("sort_type", "");
                        setFieldValue("date", "");
                        setToogle(false);
                        setToogleDate(true);
                    }}>Custom Date</p>
                </div>
            }
        </div>: <p className='custom-date' onClick={()=>{
            setToogle(true);
            }} 
            onBlur={()=>{setToogle(false)}} tabIndex={6}
            >
            <input type="date" onChange={(e)=>{
                setFieldValue("date", e.target.value);
                setFieldValue(name, "");
            }} 
            /> 
            <button onClick={()=>setToogleDate(false)}/>
            {/* <span className='text-white  cursor-pointer' style={{ color:" #fff",background:" transparent", opacity: ".60",border: "none",textTransform: "uppercase",fontFamily: 'D-DIN'}}  onClick={()=>setToogleDate(false)}><i className="fa fa-light fa-angle-down me-2 md-me-4"></i></span> */}
        </p>}
       </>
    )
}