import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { Banner } from "../../_common/model/banner";
import { TournamentResponse } from "../../_common/model/tournament";
import { homeRepository, IHomeRepository } from "../../_common/repository/home";


export class HomeStore {
  banners?: Banner[];
  errBanner = "";
  loadingBanner = false;

  tournaments?: TournamentResponse[];
  errTournament = "";
  loadingTournament = false;

  private homeAPI: IHomeRepository;

  constructor(args: { homeAPI: IHomeRepository }) {
    makeAutoObservable(this);
    this.homeAPI = args.homeAPI;
  }

  loadBanner = async (args:{device_type:string}) => {
    runInAction(() => {
      this.loadingBanner = true;
      this.errBanner = "";
    });
    const resp = await this.homeAPI.getBannners(args);

    if (resp.hasError) {
      runInAction(() => {
        this.errBanner = resp.error!;
        this.loadingBanner = false;
      });
      return;
    }

    runInAction(() => {
      this.banners = resp.result!;
      this.loadingBanner = false;
    });
  };

  loadTournament = async () => {
    runInAction(() => {
      this.loadingTournament = true;
      this.errTournament = "";
    });
    const resp = await this.homeAPI.getTournaments();

    if (resp.hasError) {
      runInAction(() => {
        this.errBanner = resp.error!;
        this.loadingTournament = false;
      });
      return;
    }

    runInAction(() => {
      this.tournaments = resp.result!;
      this.loadingTournament = false;
    });
  };
}

export const HomeStoreContext = createContext(new HomeStore({ homeAPI: homeRepository }));
export const useHomeStore = () => useContext(HomeStoreContext);
