

import banner1 from "../../assets/images/Website_Banners_1.jpg";
import banner2 from "../../assets/images/Website_Banners_2.jpg";
import Carousel from "react-bootstrap/Carousel";

export const CarouselSection =() => {
 

  return (
    
      <Carousel fade>
              <Carousel.Item
                // interval={1000}
                // style={{ width: "100%", display: "flex", justifyContent: "center" }}
                // className="w-100 d-flex justify-content-center"
              >
                <img className="d-block w-100 h-screen" src={banner2} alt="..." />
                <div className="banner-con section text-center">
        <div className="title-raw">
          <h1>IGL TV<span>IGL TV</span></h1>
        </div>
      </div>
              </Carousel.Item>
       
          <Carousel.Item
            interval={1000}
            style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <img
              src={banner1}
              alt="First slide"
              className="d-block w-100 h-screen"
            />
            <div className="banner-con section text-center">
        <div className="title-raw">
          <h1>IGL TV<span>IGL TV</span></h1>
        </div>
      </div>
          </Carousel.Item>
      
      </Carousel>
  
  );
};