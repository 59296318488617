import { observer } from 'mobx-react'
import React from 'react'
interface Props {
  children?: React.ReactNode
}

export const TournamentSection: React.FC<Props> = observer((props) => {

  return (
    <div className="tab-content-section section ">
      <div className="section gm-li-raw">
        <div className="row">
          {props.children}
        </div>
      </div>
    </div>
  )
})
