import React from 'react'
// import { IGL_IMAGE_URL } from '../../core/config';
import { ImageProps } from '../model/props.interface';
import ImageLogo from '../../assets/images/logo-3d-compressed.png';
export const Image = (props: ImageProps)=>{
    const {src, alt, onErrorImg, onErrorStyle} = props;
    const styleObjectToString = (style: React.CSSProperties) => {
        return Object.entries(style)
          .map(([key, value]) => `${key}: ${value}`)
          .join('; ');
      };
      const onErrorStyleString = onErrorStyle ? styleObjectToString(onErrorStyle) : '';      
    return (
        <img src={src}  alt={alt} className='img-fluid' onError={ (
            event: React.SyntheticEvent<HTMLImageElement, Event>
        ) => {
            event.currentTarget.src = onErrorImg || ImageLogo;
            event.currentTarget.style.cssText = onErrorStyleString;
        }}/>
    )
}
