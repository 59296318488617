import { observer } from 'mobx-react'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { TournamentProps } from '../../_common/model/tournament'
import { CreateTeamForm } from './CreateTeamForm'
import { IModalProps } from '../../_common/model/props.interface'
import { BinoChipModal } from '../../itc-bingo/components/BinoChip'
type Props = IModalProps & TournamentProps;

const CreateTeam = observer((props: Props) => {
    const { show, onClose } = props;
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const campaign = new URLSearchParams(window.location.search).get('from_campaign');
    return (
        <>
        <Modal show={show} className={`modal-raw create-team-modal modal-border-raw modal fade in show ${(queryParams && campaign ==="itc")?"itc-tournament-details-col":""}`} id="create-team-modalbox">
            <button type="button" className="btn-close-modal" onClick={onClose}  aria-label="Close"></button>
            <Modal.Body>
            {((queryParams && campaign ==="itc")||(window.location.pathname==="/bingo-nachos")) && <BinoChipModal/>}
                <div className="title-raw downarrow mb30">
                    <h3>Create your Team<span>Create your Team</span></h3>
                </div>
                <div className="devider-col section"></div>
                <div className="modal-form section">
                    <div className="modal-form-scroll section mt-2">
                        <div className="modal-form-scroll-col section">
                            <CreateTeamForm {...props}/>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
})



export default CreateTeam