import React, { useEffect } from 'react'
import { useAuthStore } from '../../auth/store/auth'
import { usePaymentStore } from '../store/payment'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { GoBack } from '../../_common/widget/GoBack'
import { IGL_IMAGE_URL } from '../../core/config'
import { useProfileStore } from '../../profile/store/profileInfo'

const WalletSuccessPayout = observer(() => {
    const { walletStatus , walletStatusResp }=usePaymentStore()!
    const { user } =useAuthStore()!;
    const { loadProfileInfo } =useProfileStore()!;
    const navigate = useNavigate();
    useEffect(()=>{
        if(!user?.loggedin){
            return navigate("/");
        }
        walletStatus({user_id:user?.id}).then(data=>{
            loadProfileInfo(user?.id!)
        })
    },[])
  return (
    <div className="wallet-raw section mt-3 pt-5">
    <div className="wallet-section section pt10 pb10">
        <h4 className="head-f text-uppercase text-center">Current Balance: ₹{walletStatusResp?.current_balance || 0} <strong>({walletStatusResp?.current_balance || 0} IGL COINS)</strong> </h4>
        <ul className="wallet-ul mt-3">
            <li className="wallet-li">
                <div className="section box-conn">
                    <img src={`${IGL_IMAGE_URL}/payment.png`} alt="payment" className="img-fluid"/><h5>Payment</h5>

                </div>
            </li>
        </ul>
        <p className="radio-text text-uppercase text-center" style={{outline: "0",width: "100%",padding: "0",margin: "0",lineHeight: "120%",fontSize: "26px",color: "#009CFF",fontWeight: "bold",letterSpacing: "2px",marginBottom:" 20px"}}>Your redeemed amount will be Credited within 30 Working Days.</p>
        <p className="radio-text text-uppercase" style={{textAlign:"center",border:0,outline: "0",fontSize:"100%",color: "#009CFF",fontWeight: "bold",letterSpacing: "2px",marginBottom: "20px"}}>31.2% goverment mandated tax will be applicable on every withdrawal from 1st July 2023.</p>
        <GoBack/>
    </div>
</div>
  )
})

export default WalletSuccessPayout
