import React,{useEffect} from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HomePage } from "./home/home";
import { ContactPage } from "./contact/view/ContactPage";
import Page404 from "./_common/view/Page404";
import { About } from "./about/view/About";
import Profile from "./profile/Profile";
import { TournamentDetails } from "./tournament/view/TournamentDetails";
import { BattleRoyaleDetails } from "./battleroyale/view/BattleRoyaleDetails";
import { TournamentsPage } from "./tournament/view/TournamentsPage";
import { TOURNAMENT_TYPE } from "./core/config";
import { TournamentJoinedTeams } from "./tournament/view/TournamentJoinedTeams";
import { TournamentCheckedInTeams } from "./tournament/view/TournamentCheckedInTeams";
import { BattleRoyaleJoinedTeams } from "./battleroyale/view/BattleRoyaleJoinedTeams";
import { BattleRoyaleCheckedInTeams } from "./battleroyale/view/BattleRoyaleCheckedInTeams";
import { TournamentBracketsPage } from "./tournament/view/TournamentBracketsPage";
import { LedgerListing } from "./wallet/view/LedgerListing";
import { PrivateRoute } from "./PrivateRoute";
import { ThankyouPage } from "./_common/view/ThankyouPage";
import { ServiceTermsPage } from "./_common/view/ServiceTermsPage";
import { PrivacyPolicyPage } from "./_common/view/PrivacyPolicyPage";
import { LegalityPage } from "./_common/view/LegalityPage";
import { NewsDetailsPage } from "./news/views/NewsDetailsPage";
import { GamePage } from "./game/view/GamePage";
import { GameDetailsPage } from "./game/view/GameDetailsPage";
import { NewsPage } from "./news/views/NewsPage";
import { ComingSoonPage } from "./_common/view/ComingSoonPage";
import { UnderMaintainance } from "./_common/view/UnderMaintainance";
import IgLTV from "./igl-tv/page";
import TeamVoting from "./team-voting/page";
import TeamDetails from "./profile/TeamDetails";
import WalletSuccessPayout from "./wallet/view/WalletSuccessPayout";
import ItcBingo from "./itc-bingo/page";
import Leagues from "./leagues/views/page";
import { LeaderboardPage } from "./leaderboard/LeaderboardPage";
import ResetPassword from "./reset-password/page";
import GlobalFitler from "./global-filter/page";
import { BracketsMobileView } from "./tournament/widget/BracketsView";
import MidWay from "./midway-gallery/page";
import RequestAccountDelete from "./request-account-delete/page";
// import { LoginPage } from "./auth/view/LoginPage";

export const Routess = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
       <Route path="/games" element={<GamePage />} />
        <Route path="/games/details/:id" element={<PrivateRoute><GameDetailsPage /></PrivateRoute>} />
        <Route path="about" element={<About />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/profile/:tab" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/tournament/" element={<Navigate replace to={`/tournament/${TOURNAMENT_TYPE.upcoming}`} />} />
        <Route path="/tournament/:type" element={<TournamentsPage />}/>
        <Route path="/tournament/details/:id" element={<PrivateRoute><TournamentDetails /></PrivateRoute>} />
        <Route path="/tournament/mobileview_bracket/:id" element={<BracketsMobileView />} />
        <Route path="/tournament/brackets/:id" element={<PrivateRoute><TournamentBracketsPage /></PrivateRoute>} />
        <Route path="/tournament/joinedteams/:id" element={<PrivateRoute><TournamentJoinedTeams /></PrivateRoute>} />
        <Route path="/tournament/checkedinteams/:id" element={<PrivateRoute><TournamentCheckedInTeams /></PrivateRoute>} />
        <Route path="/tournament/brdetails/:id" element={<PrivateRoute><BattleRoyaleDetails /></PrivateRoute>} />
        <Route path="/tournament/brjoinedteams/:id" element={<PrivateRoute><BattleRoyaleJoinedTeams /></PrivateRoute>} />
        <Route path="/tournament/brcheckedinteams/:id" element={<PrivateRoute><BattleRoyaleCheckedInTeams /></PrivateRoute>} />
        <Route path="/profile-ledgers" element={<LedgerListing />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/thankyou" element={<ThankyouPage />} />
        <Route path="/news/details/:id" element={<NewsDetailsPage />} />
        <Route path="/logout" element={<Navigate to="/" />} />
        <Route path="/service-terms" element={<ServiceTermsPage/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
        <Route path="/legality" element={<LegalityPage/>} />
        <Route path="/leaderboard" element={<PrivateRoute><LeaderboardPage/></PrivateRoute>} />
        {/* <Route path="/leagues" element={<PrivateRoute><Leagues/></PrivateRoute>} /> */}
        <Route path="/comingsoon" element={<ComingSoonPage/>} />
        <Route path="/under-maintenance" element={<UnderMaintainance/>} />
        <Route path="/tv" element={<IgLTV/>} />
        <Route path="/votes" element={<TeamVoting/>} />
        <Route path="/profile/teamdetails/:id" element={<PrivateRoute><TeamDetails /></PrivateRoute>} />
        <Route path="/profile/walletsuccesspayout" element={<PrivateRoute><WalletSuccessPayout /></PrivateRoute>} />
        <Route path="/globals/" element={<PrivateRoute><GlobalFitler /></PrivateRoute>} />
        <Route path="/globals/:tab" element={<PrivateRoute><GlobalFitler /></PrivateRoute>} />
        <Route path="/user/:userid/" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/user/:userid/:tab" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/bingo-nachos" element={<ItcBingo/>} />
        <Route path="/bingo-nachos/gallery" element={<MidWay/>} />
        <Route path="/reset_password/:id" element={<ResetPassword/>} />
        <Route path="/request-account-delete" element={<RequestAccountDelete/>} />
        <Route path="/*" element={<Page404 />}></Route>
      </Routes>
    </>
  );
};