import React from 'react'
import { observer } from 'mobx-react'
import { FieldProps } from '../../_common/model/field'
import { Field, useField, useFormikContext } from 'formik'
import { FieldError } from '../../_common/widget/FieldError' // wasn't working
import * as Yup from 'yup';
import { useTeamStore } from '../../teams/store/team';
import { ITournamentboardParams } from '../model/leaderboard'

export const PlatformDropdown = observer((props: FieldProps) => {
    const { name = "platform", disabled, value, required } = props;

    const { platforms, platform,getplatformid } = useTeamStore()

    const [field, meta, helpers] = useField({ name });

    React.useEffect(() => {

        (async () => {
            await platform();
        })();
        helpers.setValue(value)
    }, [])

    const validate = async (value: any) => {
        var schema = Yup.string();

        let sch = required
            ? schema.required('Please select Platform!')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }
    const { setFieldValue,values } = useFormikContext<ITournamentboardParams>();
       const [toogle, setToogle] = React.useState(false);
    return (
        // <div className="select-dropdown">
        //     <Field onClick={(e:any)=>{getplatformid(e.target.value)}} component="select" name={name} disabled={disabled} validate={validate}>
        //         <option value="">Select Platform</option>
        //         {platforms?.map(option => (
        //             <option key={option.PlatformID} value={option.PlatformID}>{option.PlatformName}</option>
        //         ))}
        //     </Field>
        //     <FieldError name={name} />
        // </div>
      <div className="select-dropdown position-relative" onBlur={()=>{setToogle(false)}} tabIndex={6}>
        {/* @ts-ignore */}
        <label onClick={() => setToogle(!toogle)}>{(values[name]===""||values[name] === undefined) ? "Select Platform" :  platforms!.find(item=>item.PlatformID=== values[name])?.PlatformName }</label>
        {toogle &&
            <div className='position-absolute w-100' style={{zIndex:1}}>
                <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue(name,"");
                        getplatformid("")
                        setToogle(false);
                    }}>Select Platform</p>
                {platforms?.map(option => (
                   
                    <p className='cursor-pointer font-D-DinCondensed '  key={option.PlatformID} onClick={() => {
                        setFieldValue(name,option.PlatformID);
                        getplatformid(option.PlatformID)
                        setToogle(false);
                    }}>{option.PlatformName}</p>
                ))}
            </div>
        }
         <FieldError name={name} />
        </div>
    )
})