import api from "../../core/api";
import { IAddGamePayload, IGame, IGameDetails, IGameDetailsParams, IGameLiked, IGameParams, IGameTeam } from "../model/game";
import { R } from "../pkg/types";

export class GameRepository {
    async getGames(params: IGameParams): Promise<R<IGame[]>> {
        try {
            let _url = `/get_games?PNO=${params.PNO}`;
            if (params.platform) {
                _url += `&platform_id=${params.platform}`
            }
            const resp = await api.get(_url);
            const list = resp.data.Gamelist as IGame[];
            if (!list) throw resp.data.msg;
            return new R({ result: list })
        }
        catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! No data found" })
            }
            return new R({ error: e.response.data.msg })
        }
    }
    async getGameDetails(params: IGameDetailsParams): Promise<R<IGameDetails>> {
        try {
            const resp = await api.get(`/gamedetails?game_id=${params.game_id}&user_id=${params.user_id}`);
            const list = resp.data.GameDetails as IGameDetails;
            if (!list) throw resp.data.msg;
            return new R({ result: list })
        }
        catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! No data found" })
            }
            return new R({ error: e.response.data.msg })
        }
    }
    
    async addGame(payload: IAddGamePayload): Promise<R<string>> {
        try {
            const resp = await api.post('/addgametoprofile',
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });

            const result = resp.data.msg as string;
            if (!result) throw result
            return new R({ result: result });
        } 
        catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! Something went wrong" })
            }
            return new R({ error: e.response.data.msg })
        }
    }
    
    async getGameLikeMembers(gameId: string): Promise<R<IGameLiked[]>> {
        try {
            const resp = await api.get(`/get_gamelikememberlist?game_id=${gameId}`);
            const list = resp.data.Userlist as IGameLiked[];
            if (!list) throw resp.data.msg;
            return new R({ result: list })
        }
        catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! No data found" })
            }
            return new R({ error: e.response.data.msg })
        }
    }
    async getGameTeams(gameId: string): Promise<R<IGameTeam[]>> {
        try {
            const resp = await api.get(`/get_allgameteams?game_id=${gameId}`);
            const list = resp.data.TeamList as IGameTeam[];
            if (!list) throw resp.data.msg;
            return new R({ result: list })
        }
        catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! No data found" })
            }
            return new R({ error: e.response.data.msg })
        }
    }
}

export const gameRpository = new GameRepository();