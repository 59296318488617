import React, { useEffect, useRef, useState } from 'react'
import { useAuthStore } from '../../auth/store/auth';
import { Link, useParams } from 'react-router-dom';
import { useTournamentBracketStore } from '../store/tournamentbrackets';
import { useTournamentDetailsStore } from '../store/tournamentdetails';
import Logo from '../../assets/images/logo-3d-compressed.png';
import { TournamentBracketTeam } from '../../_common/model/tournament';
import { observer } from 'mobx-react';
export const BracketsMobileView = observer(() => {
    useEffect(()=>{
        document.getElementById("header")?.setAttribute("style","display:none");
        document.getElementsByTagName("footer")[0]?.setAttribute("style","display:none");
    },[])
    const { loadTournamentBrackets, tournament_brackets, tournament_bracket_error, tournament_bracket_loading } = useTournamentBracketStore();
    const { getTournamentWinners, tournament_winners, tournament_winners_error, tournament_winners_loading } = useTournamentBracketStore();
    const { user } = useAuthStore();
    const { id } = useParams();

    React.useEffect(() => {
       loadTournamentBrackets(String(id),new URLSearchParams(window.location.search).get("user_id") as string);
       getTournamentWinners(String(id),new URLSearchParams(window.location.search).get("user_id") as string);
    }, []);
    function ordinal(n: number) {
        var s = ["th", "st", "nd", "rd"];
        var v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }
    
  return (
    <div className="common-page-raw tournament-brackets-raw section ">
                <div className="container-fluid">
                    <div className="pt-5 mt-3">
                        {/* <div className="title-raw">
                            <h3>Tournament Brackets<span>Tournament Brackets</span></h3>
                        </div> */}

                        {tournament_bracket_error !== "" ?
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12">
                                    <div className="banner-con section text-center d-flex justify-content-center align-items-center fs-5 text-white font-MonsterratRegular lh-base">{tournament_bracket_error}
                                    </div>
                                </div>
                            </div>
                            : null}
                        {(tournament_bracket_loading === true || tournament_winners_loading) ?
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12">
                                    <div className="banner-con section text-center d-flex justify-content-center align-items-center" style={{ minHeight: "350px" }}><div className="title-raw"><h1>Loading...</h1>
                                    </div>

                                    </div>
                                </div>
                            </div>
                            : <>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="tournament">
                                            <div className="tournament__logo-container tournament__logo-container--right">
                                                <strong className="tournament__logo tournament__logo--gold"></strong>
                                            </div>
                                            <div className='tournament__grid'>
                                                {tournament_brackets?.L?.map((bracket, bracket_index) => {
                                                    const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round;
                                                    return round === "Playoff" ? null :
                                                        <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                            <div className="rounds">
                                                                <div className="timeline section">
                                                                    <div className="events">
                                                                        <ul>
                                                                            <li>
                                                                                <p style={{ fontSize: '15px' }}><Link to="#">{round}</Link></p><p>
                                                                                </p><h5><strong>Round {bracket.Round}</strong><br /> {bracket.Time}</h5>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {Array.isArray(bracket?.Groups) ?
                                                                bracket?.Groups?.map((group, group_index) => (
                                                                    <div className="tournament__match" key={group_index}>
                                                                        {group?.teams?.map((team, team_index) => (
                                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                                {/* <i className="ic-user"></i> */}
                                                                                <img src={team.TeamImage} alt="..." />
                                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{round}</i></Link>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                )) : typeof (bracket?.Groups) == 'object' ?
                                                                    Object.keys(bracket?.Groups).map((groupKey) => {
                                                                        // @ts-ignore
                                                                        let group = bracket?.Groups[groupKey];
                                                                        return (
                                                                            <div className="tournament__match" key={groupKey}>
                                                                                {group?.teams?.map((team: TournamentBracketTeam, team_index: number) => (
                                                                                    <Link
                                                                                        key={team_index}
                                                                                        className="tournament__match__team brradiusf cursor-default"
                                                                                        to="#"
                                                                                        style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}
                                                                                    >
                                                                                        <img src={team.TeamImage} alt="..." />
                                                                                        {team?.TeamName}
                                                                                        <span className='mx-3'>{team?.TeamName}</span>
                                                                                        <i className="ic-sts r2c">{round}</i>
                                                                                    </Link>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    })


                                                                    :
                                                                    <>
                                                                        {[...Array(bracket?.Groups).keys()].map((item, index) =>
                                                                            <div className="tournament__match" key={index}>

                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                            </div>
                                                                        )}
                                                                    </>
                                                            }
                                                        </div>
                                                })}
                                                {/* {tournament_brackets?.R && <div className='tournament__round' style={{width:"120px"}}></div>} */}
                                                {tournament_brackets?.Final ?
                                                    <div className='tournament__round tournament__round--first-round '>

                                                        <div className="rounds">
                                                            <div className="timeline section">
                                                                <div className="events">
                                                                    <ul>
                                                                        <li>
                                                                            <p style={{ fontSize: '15px' }}><Link to="#">{tournament_brackets?.Final?.Round}</Link></p><p>
                                                                            </p><h5><strong>Round {tournament_brackets?.Final?.Round}</strong><br /> {tournament_brackets?.Final?.Time}</h5>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {Array.isArray(tournament_brackets?.Final?.Groups) ?
                                                            tournament_brackets?.Final?.Groups?.map((group, group_index) => (
                                                                <div className={`tournament__match ${tournament_brackets?.R ? ' justify-content-center final-round-with-right' : "final-round-only-left"} `} key={group_index}>
                                                                    {tournament_brackets?.R ? <div>{group?.teams?.map((team, team_index) => (
                                                                        <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                            {/* <i className="ic-user"></i> */}
                                                                            <img src={team.TeamImage} alt="..." />
                                                                            <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{tournament_brackets?.Final?.Round}</i></Link>
                                                                    ))
                                                                    }</div> : <>{group?.teams?.map((team, team_index) => (
                                                                        <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                            {/* <i className="ic-user"></i> */}
                                                                            <img src={team.TeamImage} alt="..." />
                                                                            <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{tournament_brackets?.Final?.Round}</i></Link>
                                                                    ))
                                                                    }</>}
                                                                </div>
                                                            )) : typeof (tournament_brackets?.Final?.Groups) == 'number' ?
                                                                <>
                                                                    {[...Array(tournament_brackets?.Final?.Groups).keys()].map((item, index) =>
                                                                        <div className={`tournament__match ${tournament_brackets?.R ? ' justify-content-center final-round-with-right' : "final-round-only-left"} `} key={index}>
                                                                            {tournament_brackets?.R ? <div>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                            </div> : <>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link></>}


                                                                        </div>
                                                                    )}
                                                                </>
                                                                :
                                                                <>
                                                                    {[...Array(tournament_brackets?.Final).keys()].map((item, index) =>
                                                                        <div className="tournament__match final-round-only-left" key={index}>

                                                                            <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                <img src={Logo} alt="..." />
                                                                                <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                            <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                <img src={Logo} alt="..." />
                                                                                <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                        </div>
                                                                    )}
                                                                </>
                                                        }
                                                    </div>
                                                    : null}

                                                {tournament_brackets?.R?.map((bracket, bracket_index) => {
                                                    const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round as string;
                                                    return round === "Playoff" ? null :
                                                        <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                            <div className="rounds">
                                                                <div className="timeline section">
                                                                    <div className="events">
                                                                        <ul>
                                                                            <li>
                                                                                <p style={{ fontSize: '15px' }}><Link to="#">{round}</Link></p><p>
                                                                                </p><h5><strong>Round {bracket.Round}</strong><br /> {bracket.Time}</h5>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {Array.isArray(bracket?.Groups) ?
                                                                bracket?.Groups?.map((group, group_index) => (
                                                                    <div className="tournament__match right-bracket" key={group_index}>
                                                                        {group?.teams?.map((team, team_index) => (
                                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                                {/* <i className="ic-user"></i> */}
                                                                                <img src={team.TeamImage} alt="..." />
                                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{round}</i></Link>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                )) : typeof (bracket?.Groups) == 'object' ?

                                                                    Object.keys(bracket?.Groups).map((groupKey) => {
                                                                        // @ts-ignore
                                                                        const group = bracket?.Groups[groupKey];

                                                                        return (
                                                                            <div className="tournament__match right-bracket" key={groupKey}>
                                                                                {group?.teams?.map((team: TournamentBracketTeam, team_index: number) => (
                                                                                    <Link
                                                                                        key={team_index}
                                                                                        className="tournament__match__team brradiusf cursor-default"
                                                                                        to="#"
                                                                                        style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}
                                                                                    >
                                                                                        <img src={team.TeamImage} alt="..." />
                                                                                        <span className='mx-3'>{team.TeamName}</span>
                                                                                        <i className="ic-sts r2c">{round}</i>
                                                                                    </Link>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    })


                                                                    :
                                                                    <>
                                                                        {[...Array(bracket?.Groups).keys()].map((item, index) =>
                                                                            <div className="tournament__match right-bracket" key={index}>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                            </div>
                                                                        )}
                                                                    </>
                                                            }
                                                        </div>
                                                }).reverse()}

                                            </div>
                                            {/* L1 and R1 */}
                                           { tournament_brackets?.L1 &&  <div className='tournament__grid'>
                                                {tournament_brackets?.L1?.map((bracket, bracket_index) => {
                                                    const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round;
                                                    return round === "Playoff" ? null :
                                                        <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                            <div className="rounds">
                                                                <div className="timeline section">
                                                                    <div className="events">
                                                                        <ul>
                                                                            <li>
                                                                                <p style={{ fontSize: '15px' }}><Link to="#">{round}</Link></p><p>
                                                                                </p><h5><strong>Round {bracket.Round}</strong><br /> {bracket.Time}</h5>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {Array.isArray(bracket?.Groups) ?
                                                                bracket?.Groups?.map((group, group_index) => (
                                                                    <div className="tournament__match" key={group_index}>
                                                                        {group?.teams?.map((team, team_index) => (
                                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                                {/* <i className="ic-user"></i> */}
                                                                                <img src={team.TeamImage} alt="..." />
                                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{round}</i></Link>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                )) : typeof (bracket?.Groups) == 'object' ?
                                                                    Object.keys(bracket?.Groups).map((groupKey) => {
                                                                        // @ts-ignore
                                                                        let group = bracket?.Groups[groupKey];
                                                                        return (
                                                                            <div className="tournament__match" key={groupKey}>
                                                                                {group?.teams?.map((team: TournamentBracketTeam, team_index: number) => (
                                                                                    <Link
                                                                                        key={team_index}
                                                                                        className="tournament__match__team brradiusf cursor-default"
                                                                                        to="#"
                                                                                        style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}
                                                                                    >
                                                                                        <img src={team.TeamImage} alt="..." />
                                                                                        {team?.TeamName}
                                                                                        <span className='mx-3'>{team?.TeamName}</span>
                                                                                        <i className="ic-sts r2c">{round}</i>
                                                                                    </Link>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    })


                                                                    :
                                                                    <>
                                                                        {[...Array(bracket?.Groups).keys()].map((item, index) =>
                                                                            <div className="tournament__match" key={index}>

                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                            </div>
                                                                        )}
                                                                    </>
                                                            }
                                                        </div>
                                                })}

                                                {tournament_brackets?.R1?.map((bracket, bracket_index) => {
                                                    const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round as string;
                                                    return round === "Playoff" ? null :
                                                        <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                            <div className="rounds">
                                                                <div className="timeline section">
                                                                    <div className="events">
                                                                        <ul>
                                                                            <li>
                                                                                <p style={{ fontSize: '15px' }}><Link to="#">{round}</Link></p><p>
                                                                                </p><h5><strong>Round {bracket.Round}</strong><br /> {bracket.Time}</h5>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {Array.isArray(bracket?.Groups) ?
                                                                bracket?.Groups?.map((group, group_index) => (
                                                                    <div className="tournament__match right-bracket" key={group_index}>
                                                                        {group?.teams?.map((team, team_index) => (
                                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                                {/* <i className="ic-user"></i> */}
                                                                                <img src={team.TeamImage} alt="..." />
                                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{round}</i></Link>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                )) : typeof (bracket?.Groups) == 'object' ?

                                                                    Object.keys(bracket?.Groups).map((groupKey) => {
                                                                        // @ts-ignore
                                                                        const group = bracket?.Groups[groupKey];

                                                                        return (
                                                                            <div className="tournament__match right-bracket" key={groupKey}>
                                                                                {group?.teams?.map((team: TournamentBracketTeam, team_index: number) => (
                                                                                    <Link
                                                                                        key={team_index}
                                                                                        className="tournament__match__team brradiusf cursor-default"
                                                                                        to="#"
                                                                                        style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}
                                                                                    >
                                                                                        <img src={team.TeamImage} alt="..." />
                                                                                        <span className='mx-3'>{team.TeamName}</span>
                                                                                        <i className="ic-sts r2c">{round}</i>
                                                                                    </Link>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    })


                                                                    :
                                                                    <>
                                                                        {[...Array(bracket?.Groups).keys()].map((item, index) =>
                                                                            <div className="tournament__match right-bracket" key={index}>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                            </div>
                                                                        )}
                                                                    </>
                                                            }
                                                        </div>
                                                }).reverse()}

                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                {/* playoff display */}
                                {tournament_brackets?.Playoff && tournament_brackets?.Playoff ?
                                    <div className='tournament__grid'>
                                        <div className='tournament__round tournament__round--first-round'>

                                            <div className="rounds">
                                                <div className="timeline section">
                                                    <div className="events">
                                                        <ul>
                                                            <li>
                                                                <p style={{ fontSize: '15px' }}><Link to="#">{tournament_brackets?.Final?.Round}</Link></p><p>
                                                                </p><h5><strong>PlayOff Round {tournament_brackets?.Final?.Round}</strong><br /> {tournament_brackets?.Final?.Time}</h5>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            {Array.isArray(tournament_brackets?.Final?.Groups) ?
                                                tournament_brackets?.Final?.Groups?.map((group, group_index) => (
                                                    <div className="tournament__match " key={group_index}>
                                                        {group?.teams?.map((team, team_index) => (
                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                {/* <i className="ic-user"></i> */}
                                                                <img src={team.TeamImage} alt="..." />
                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{tournament_brackets?.Final?.Round}</i></Link>
                                                        ))
                                                        }
                                                    </div>
                                                )) : typeof (tournament_brackets?.Final?.Groups) == 'number' ?
                                                    <>
                                                        {[...Array(tournament_brackets?.Final?.Groups).keys()].map((item, index) =>
                                                            <div className="tournament__match" key={index}>

                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                    <img src={Logo} alt="..." />
                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                    <img src={Logo} alt="..." />
                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                            </div>
                                                        )}
                                                    </>
                                                    :
                                                    <>
                                                        {[...Array(tournament_brackets?.Final).keys()].map((item, index) =>
                                                            <div className="tournament__match" key={index}>

                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                    <img src={Logo} alt="..." />
                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                    <img src={Logo} alt="..." />
                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                            </div>
                                                        )}
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    : null}

                                {/* playoff ends here */}
                                {tournament_winners && <div className='tournament__grid'>
                                    {/* winners */}

                                    {tournament_winners?.map((bracket, bracket_index) => {
                                        // const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round;
                                        return (
                                            <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                <div className="rounds">
                                                    <div className="timeline section">
                                                        <div className="events">
                                                            <ul>
                                                                <li>
                                                                    <p style={{ fontSize: '15px' }}><Link to="#">{bracket.TournamentWinnerPosition === '1' ? 'Winner' : 'RUP'}</Link></p><p>
                                                                    </p>
                                                                    <h5>{bracket.TournamentWinnerPosition === '1' ? "" : ordinal(Number(bracket.TournamentWinnerPosition) - 1)}<strong></strong><br /> {bracket.TournamentWinnerPosition === '1' ? 'Winner' : 'Runner Up'}</h5>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tournament__match tournament-winner" >
                                                    <Link className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === bracket.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                        <img src={bracket.TeamImage} alt="..." />
                                                        <span className='mx-3'>{bracket.TeamName}</span></Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )?.reverse()}
                                </div>}
                            </>}
                    </div>
                </div>
            </div>
  )
})