import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { BattleRoyaleTeam } from "../../_common/model/team";
import {
  battleRoyaleRepository,
  BattleRoyaleRepository,
} from "../../_common/repository/battleroyale";

export class BattleRoyaleJoinedTeamStore {
  joined_teams?: BattleRoyaleTeam[];
  joined_teams_error = "";
  joined_teams_loading = false;
  showMore: boolean = false;
  current_page: number = 1;
  private joinedTeamsAPI: BattleRoyaleRepository;

  constructor(args: { joinedTeamsAPI: BattleRoyaleRepository }) {
    makeAutoObservable(this);
    this.joinedTeamsAPI = args.joinedTeamsAPI;
  }

  loadJoinedTeams = async (id: string, userId: string) => {
    runInAction(() => {
      this.joined_teams_loading = true;
    });
    const resp = await this.joinedTeamsAPI.getBattleRoyaleJoinedeams(
      id,
      userId,
      this.current_page
    );

    if (resp.hasError) {
      runInAction(() => {
        this.joined_teams_error = resp.error!;
        this.joined_teams_loading = false;
        this.showMore = false;
      });
      return;
    }
    runInAction(() => {
      if (resp.result!.length === 10) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
      if (this.joined_teams!.length > 0) {
        this.joined_teams! = [...this.joined_teams!, ...resp.result!];
        this.joined_teams_loading = false;
        return;
      }
      this.joined_teams = resp.result!;
      this.joined_teams_loading = false;
    });
  };
  loadMoreGames = (id: string, user_id: string) => {
    this.current_page = this.current_page + 1;
    this.loadJoinedTeams(id, user_id);
  };
  resetPageCount = () => {
    this.current_page = 1;
    this.joined_teams = [];
  };
}

export const BattleRoyaleJoinedTeamStoreContext = createContext(
  new BattleRoyaleJoinedTeamStore({ joinedTeamsAPI: battleRoyaleRepository })
);
export const useBattleRoyaleJoinedTeamStore = () =>
  useContext(BattleRoyaleJoinedTeamStoreContext);
