import { toAlphabets } from "./helper";

export const APP_BASES_URL = 'http://localhost:3000';
export const OLD_API_URL = 'https://api.iglnetwork.com/api';
// export const OLD_API_URL = 'https://stagtest.iglnetwork.com/api';
// export const OLD_API_URL = window.location.hostname==="iglnetwork.com" ? 'https://api.iglnetwork.com/api':'https://stagtest.iglnetwork.com/api';

export const CORE_API_URL = 'https://api.iglnetwork.com/api-V1';
// export const CORE_API_URL = 'https://stagtest.iglnetwork.com/api-V1';
// export const CORE_API_URL = window.location.hostname==="iglnetwork.com" ?'https://api.iglnetwork.com/api-V1':'https://stagtest.iglnetwork.com/api-V1';


export const PROFILE_MENU = [
    {path: '/profile/home', name: 'Profile'},
    {path: '/profile/teams', name: 'Teams'},
    {path: '/profile/tournaments', name: 'Tournaments'},
    // {path: '/profile/challenges', name: 'Challenges'},
    // {path: '/profile/trophies', name: 'Trophies'},
    {path: '/profile/wallet', name: 'Wallet'},
    // {path: '/profile/achievements', name: 'Achievements'},
];

export const OTHER_PROFILE_MENU=(userId?:string)=>[
    {path: `/user/${userId}/profile`, name: 'Profile'},
    {path: `/user/${userId}/teams`, name: 'Teams'},
    {path: `/user/${userId}/tournaments`, name: 'Tournaments'},
];

export const GAME_TYPE = {
    tournament: 1,
    battleroyale: 2
};


export const DETAILS_TYPE = {
    tournament: 'Tournament',
    battleroyale: 'Battle Royale'
};
export const TOURNAMENT_TYPE = {
    upcoming: 'upcoming',
    my_tournament: 'my-tournaments'
}
export const JOIN_TEXT = {
    CHECK_IN: "CHECK IN!",
    CHECK_IN_CLOSED: "CHECK IN CLOSED!",
    CHECK_IN_FULL: "CHECK IN FULL!",
    CHECKED_IN: "CHECKED IN!",
    EXPIRED: "EXPIRED!",
    JOIN_NOW: "JOIN NOW!",
    JOINED: "JOINED!",
    JOINED_FULL: "JOINED FULL!",
    SUBMIT_RESULT: "SUBMIT RESULT!",
}
export const TOURNAMENT_STATUS = Object.values(JOIN_TEXT).map(item=>toAlphabets(item));

export const PLAYER_INFO = [
    {label: "Name" , key: "firstname" },
    {label: "Gender" , key: "UserGender" },
    {label: "Email Id" , key: "email" },
    {label: "Contact No" , key: "mobile" },
]
export const OTHER_PLAYER_INFO = [
    {label: "Name" , key: "firstname" },
    {label: "Gender" , key: "UserGender" },
    // {label: "Email Id" , key: "email" },
    // {label: "Contact No" , key: "mobile" },
]

export const WITHDRAWAL_AMOUNT_LIMIT = 100;

export const IGL_URL = 'https://api.iglnetwork.com';
// export const IGL_URL = 'https://www.iglnetwork.com';
export const IGL_IMAGE_URL = `${IGL_URL}/assets/images`;

export const  removeOrdinalSuffix = (dateString:string)=>{
   return dateString?.replace(/(\d+)(st|nd|rd|th)/, '$1');
}