import React from 'react'
import { LeaderboardListing } from './view/LeaderboardListing'
import { LeaderboardBanner } from './widget/LeaderboardBanner'

export const LeaderboardPage = () => {
    return (
        <>
            <LeaderboardBanner />
            <LeaderboardListing />
        </>
    )
}
