import { Form, Formik } from 'formik'
import { observer } from 'mobx-react'
import React from 'react'
import Checkbox from "../../auth/widget/Checkbox"
import Email from "../../auth/widget/Email"
import Name from "../../auth/widget/Name"
import Number from "../../auth/widget/Number"
import { CONTACT_VALIDATION_SCHEMA } from '../../_common/validation/contact'
import { Button } from "../../_common/widget/Button"
import { ErrorMsg } from "../../_common/widget/ErrorMsg"
import { useContactStore } from "../store/contact"
import { Comment } from "../widget/Comment"

const initialState = {
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    message: "",
    issubcribe: false
}

export const ContactForm = observer(() => {
    const { contactUs, contact_loading, contact_error } = useContactStore();

    const handleSubmit = (values: any) => {
        const issubcribe = values.issubcribe ? "1" : "0"
        contactUs({ ...values, issubcribe })
    }
    return (
        <>
            <Formik
                initialValues={initialState}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values);
                    resetForm()
                }}
                validationSchema={CONTACT_VALIDATION_SCHEMA}
            >
                <Form>
                    <ErrorMsg msg={contact_error} />
                    <div className="row">
                        <Name
                            name="firstname"
                            label="First Name"
                        />
                        <Name
                            name="lastname"
                            label="Last Name"
                        />
                        <Email
                            name="email"
                            label="Email"
                        />
                        <Number
                            name="mobile"
                            label="Mobile Number"
                        />
                    </div>
                    <div className="row">
                        <Comment name="message" label="Tell us" />
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <Checkbox
                                name="issubcribe"
                                label="Subscribe to our Newsletter"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="text-center">
                                    <Button type="submit" label={contact_loading ? 'Sending...' : 'Send'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </>
    )
});