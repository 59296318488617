import React from "react";
import "./assets/css/style.css";
// import "./assets/css/_responsive.scss";
// import "./assets/css/style1.scss";
// import "./assets/css/slick.css";
import "./assets/js/main.js";

import { BrowserRouter } from "react-router-dom";
import { HomePageLogin } from "./_common/view/header";
import { Routess } from "./Routes";
import Footer from "./_common/view/footer";
import { ScrollToTop } from "./_common/widget/ScrollToTop";
import LoginUser from "./auth/view/LoginUser";
import RegisterUser from "./auth/view/RegisterUser";
import ForgotPassword from "./auth/view/ForgotPassword";
import { PopAlert } from "./_common/widget/PopAlert";
import GlobalHeader from "./_common/view/GlobalHeader";
import { observer } from "mobx-react";
import { useProfileStore } from "./profile/store/profileInfo";
import { useAuthStore } from "./auth/store/auth";
import { SuccessModal } from "./_common/widget/SuccessModal";
import { useSuccessStore } from "./_common/store/success";


const App = observer(() => {
  const { user } = useAuthStore();
  const { loadProfileInfo } = useProfileStore();
  const { success_msg, hideSuccess } = useSuccessStore();
  React.useEffect(() => {
    if (user?.id) {
      loadProfileInfo(user?.id!)
    }
  }, []);
  React.useEffect(() => {
    // Initialize googletag
    if (window?.googletag) {
      window.googletag = window?.googletag || { cmd: [] };
      window?.googletag?.cmd.push(function () {
      window?.googletag.pubads().enableSingleRequest();
      window?.googletag.pubads().collapseEmptyDivs();
      window?.googletag.enableServices();
    });
    }
  }, [window])
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <div className="App">
          {/* <HomePageLogin /> */}
          <GlobalHeader />
          {/* <HomePage /> */}
          <Routess />
          <Footer />
          <LoginUser />
          <RegisterUser />
          <ForgotPassword />
          <SuccessModal show={Boolean(success_msg)} onClose={hideSuccess}>
            {success_msg}
          </SuccessModal>
          <PopAlert />
        </div>
      </BrowserRouter>
    </>
  );
});

export default App;
