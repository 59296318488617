import api from "../../core/api";
import { TournamentTeam } from "../model/team";
import { IgetTournamentBrackets, ITournamentParams, ITournamentPayload, MegaTournamentResponse, Tournament, TournamentBracket, TournamentWinners } from "../model/tournament";
import { R } from "../pkg/types";

export interface ITournamentsRepository {
  getMegaTournaments(): Promise<R<MegaTournamentResponse[]>>;
  getUpcomingTournaments(params?: ITournamentParams): Promise<R<Tournament[]>>;
  getTournamentJoinedeams(id?: string, userId?: string,  PNO?:number): Promise<R<TournamentTeam[]>>;
  getTournamentCheckedInTeams(id?: string, userId?: string,PNO?:number): Promise<R<TournamentTeam[]>>;

  getTournamentDetails(id?: string, userId?: string): Promise<R<Tournament>>;
  joinTournament(payload?: ITournamentPayload): Promise<R<string>>;
  checkinTournament(payload?: ITournamentPayload): Promise<R<string>>;
  getTournamentBrackets(id?: string, userId?: string): Promise<R<IgetTournamentBrackets>>;
  getTournamentWinners(id?: string, userId?: string): Promise<R<TournamentWinners[]>>;
}

class TournamentsRepository implements ITournamentsRepository {

  async getMegaTournaments(): Promise<R<MegaTournamentResponse[]>> {
    try {
      const resp = await api.get(`/get_mega_tournamentlist`,{
        headers:{
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        }
      });
      if(resp.data.status === "0"){
        return new R({ error: resp.data.msg });
      }
      const list = resp.data.Tournamentlist as MegaTournamentResponse[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  };

  async getUpcomingTournaments(params?: ITournamentParams): Promise<R<Tournament[]>> {
    try {
      const resp = await api.get(`/get_upcoming_tournamentlist`, {params});
      const list = resp.data.Tournamentlist as Tournament[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  };

  async getTournamentDetails(id?: string, userId?: string): Promise<R<Tournament>> {
    try {
      const resp = await api.get(`/tournamentdetails?tournament_id=${id}&user_id=${userId}&timestamp=${new Date().getTime()}`);
      if(resp.data.status==="0"){
        return new R({ error: resp.data.msg });
      }
      const list = resp.data.TournamentDetails as Tournament;
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  };

  async getTournamentJoinedeams(id?: string, userId?: string, PNO?:number): Promise<R<TournamentTeam[]>> {
    try {
      const resp = await api.get(`/tournamentjointeams?tournament_id=${id}&user_id=${userId}&PNO=${PNO}`);
      const list = resp.data.Teamlist as TournamentTeam[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }
  async getTournamentCheckedInTeams(id?: string, userId?: string, PNO?:number): Promise<R<TournamentTeam[]>> {
    try {
      const resp = await api.get(`/tournamentcheckinteams?tournament_id=${id}&user_id=${userId}&PNO=${PNO}`);
      const list = resp.data.Teamlist as TournamentTeam[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }

  async joinTournament(payload: ITournamentPayload): Promise<R<string>> {
    try {
      const resp = await api.post('/join_tournament',
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
      const result = resp.data.msg as string;
      if (!result) throw resp.data.msg
      return new R({ result });
    } catch (e) {
      return new R({ error: e });
    }
  }
  async checkinTournament(payload: ITournamentPayload): Promise<R<string>> {
    try {
      const resp = await api.post('/checkin_tournament',
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
      const result = resp.data.msg as string;
      if (!result) throw resp.data.msg
      return new R({ result });
    } catch (e) {
      return new R({ error: e });
    }
  }
  async getTournamentBrackets(id?: string, userId?: string): Promise<R<IgetTournamentBrackets>> {
    try {
      //https://stagtest.iglnetwork.com/api-V1/tournament_brackets_function
      // const resp = await api.get(`/tournament_brackets?tournament_id=${id}&user_id=${userId}`);
      const resp = await api.get(`/tournament_brackets_function?tournament_id=${id}&user_id=${userId}`);
      let list = resp.data as IgetTournamentBrackets;
      // if(resp.data.TournamentBrackets){
      //   list = resp.data.TournamentBrackets as TournamentBracket[];
      // }
      // else{
      //   list = resp.data.TournamentEmptyBrackets  as TournamentBracket[]
      // }
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }
  async getTournamentWinners(id?: string, userId?: string): Promise<R<TournamentWinners[]>> {
    try {
      const resp = await api.get(`/get_tournamentwinners?tournament_id=${id}&user_id=${userId}`);
      const list = resp.data.Winnerlist as TournamentWinners[];
      if(!list){
        return new R({ error: resp.data.msg });        
      }
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }
};

export const tournamentsRepository: ITournamentsRepository = new TournamentsRepository();