import React from 'react'
import ProfileImg from "../../assets/images/logo-3d-compressed.png";
// import EditImg from "../../assets/images/ic-edit.png";
import GamingPlatforms from './GamingPlatform';
import { observer } from 'mobx-react';
import { useProfileStore } from '../store/profileInfo';
import { useParams } from 'react-router-dom';
import { UserProfileInfo } from '../../_common/model/user';
import { OTHER_PLAYER_INFO, PLAYER_INFO } from '../../core/config';
const ProfileSection = observer(() => {
    const { userid } = useParams();
    const {profile_info} = useProfileStore()
    const ProfileImage=()=>{
        return (<img src={profile_info?.UserProfileImage} alt='profile' className='rounded-circle' onError={ (
            event: React.SyntheticEvent<HTMLImageElement, Event>
        ) => {
            event.currentTarget.src = ProfileImg;
        }}/>)
    };
    return (
        <>
            <div className="tab-content-section section pb10">
                <div className="personal-info-section text-center section pt10">
                    <div className="personal-info-back">
                        <div className="row">
                            <div className="col-md-3 col-12 left-conn">
                                <div className="left-profile pdlr10 ptb10 section">
                                    <div className="player-info section">
                                        <div className="img-wrap">
                                            <ProfileImage/>
                                        </div>
                                        <div className="information-head">
                                            <h6>
                                                Player<br /> Information
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="player-info-details">
                                    {!userid ? <PlayerDetails /> : <OtherPlayerDetails/>}
                                    </div>
                                    {/* <button className="btn-edit edit-member-btn" onClick={() => console.log('update player info')}><img className="img-fuild" src={EditImg} alt="" /></button> */}
                                </div>
                            </div>
                            <div className="col-md-9 col-12 right-conn">
                                <GamingPlatforms/>
                                {/* <button className="btn-edit edit-member-btn"><img className="img-fuild" src={EditImg} alt="" /></button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
const PlayerDetails = observer(() => {
    const { profile_info } = useProfileStore();
    return (
        <ul>
            {PLAYER_INFO.map((item,index) => <PlayerInfo data={profile_info!} infoType={item.label} infoKey={item.key} key={index}/>)}
        </ul>
    )
})
const OtherPlayerDetails = observer(() => {
    const { other_profile_info } = useProfileStore();
    
    return (
        <ul>
            {OTHER_PLAYER_INFO.map((item,index) => <PlayerInfo data={other_profile_info!} infoType={item.label} infoKey={item.key} key={index}/>)}
        </ul>
    )
})
interface IPlayerInfo {
    data: UserProfileInfo;
    infoType: string;
    infoKey: string;
}
const PlayerInfo = observer((props: IPlayerInfo) => {
    const { data, infoType, infoKey } = props;
    if(data?.[infoKey] == undefined){
        return <></>
    }
    if(infoKey === "UserGender"){
        data[infoKey] = data[infoKey] === "1" ? "Female" : "Male"
    }
    return (
        <li>
            <span>{infoType}:</span>
            <h6 className="player_info">{infoKey === "mobile" ? "+91 " + data?.[infoKey]: data?.[infoKey] || "-" }</h6>
            {/* <h6 style={{ display: 'none' }} className="info_edit">edit {infoKey} here</h6> */}
        </li>
    )
})
export default ProfileSection