import React from 'react'
import { Link } from 'react-router-dom'
import AiImg from '../../assets/images/itc-bingo/Bingo AI affair Logo.png'
import AiImgWithBg from '../../assets/images/itc-bingo/Bingo AI affair Logo with bg.png'
const FanArtGalleryButton = () => {
    // return (
    //     <div className="row">
    //         <div className="col-10 mx-auto my-4 mt-md-0 mb-md-5 text-center">
    //             <Link to={'/bingo-nachos/gallery'} className="fanart-button">
    //                AI AVATAR AFFAIR
    //             </Link>
    //         </div>
    //     </div>
    // )
    return (
        <div className="row followus-carousel">
           <div className="col-8 col-md-6 col-xl-4 mx-auto d-flex justify-content-evenly">
           <Link to={'/bingo-nachos/gallery'} className="">
           <div className='img-container purple'>
                <img
                    className="d-block"
                    src={AiImg}
                    // src={AiImgWithBg}
                    alt="Second slide"
                />
            </div>
               </Link>
           </div>
        </div>
    )
}

export default FanArtGalleryButton