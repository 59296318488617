import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { authstore, AuthStore } from "../../auth/store/auth";
import { INotification, INotificationParams, INotificationResp } from "../../_common/model/notifications";
import { NotificationsRepository, notificationsRepository } from "../../_common/repository/notifications";

export class NotificationsStore {
    auth_store?: AuthStore

    notifications_loading = false;
    notifications_error = '';
    notifications_list: INotificationResp|null=null;
    limit = 10;
    current_page = 0;
    view_more = false;

    constructor(args: {
        notificationsAPI: NotificationsRepository,
        authstore: AuthStore,
    }) {
        makeAutoObservable(this)
        this.notificationsAPI = args.notificationsAPI;
        this.auth_store = args.authstore;
        this.notifications_list = null;
    }

    notificationsAPI: NotificationsRepository

    loadNotifications = async (params: INotificationParams) => {
        runInAction(() => {
            this.notifications_loading = true
            this.notifications_error = ""
            if(this.current_page===0){
                this.notifications_list=null;
            }
        })
        const resp = await this.notificationsAPI.getNotifications(params)
        if (resp.hasError) {
            runInAction(() => {
                this.notifications_loading = false
                this.notifications_error = resp.error!
            })
            return
        }
        runInAction(() => {
            this.notifications_loading = false;
            if(params.type && this.current_page > 0){
                // @ts-ignore
                this.notifications_list[params.type] = [...resp.result[params.type],...this.notifications_list[params.type] ];
                return;
            }
            this.notifications_list = resp.result!;
            // if (resp.result?.length! > 0) {
            //     // this.notifications_list = [...this.notifications_list, ...resp.result!];

            //     if (resp.result?.length! >= this.limit) {
            //         this.view_more = true;
            //     }
            //     return
            // }
            // this.view_more = false
            // this.notifications_list = resp.result!;
        })
    }
    loadMore = () => {
    this.current_page= this.current_page+1;
    }
    resetPage = ()=>{
      this.current_page = 0;
    }
}

export const NotificationsStoreContext = createContext(new NotificationsStore({ notificationsAPI: notificationsRepository, authstore: authstore }));
export const useNotificationsStore = () => useContext(NotificationsStoreContext);