import api from "../../core/api";
import { ILeaderboardlist, ILeaderboardParams } from "../model/leaderboard";
import { R } from "../pkg/types";

export class LeaderBoardRepository {
    async getLeaderboardList(params: ILeaderboardParams): Promise<R<ILeaderboardlist[]>> {
        try {
            const resp = await api.get(`/get_leaderboard`,
                { params }
            );
            const list = resp.data.Leaderboardlist as ILeaderboardlist[];
            if (!list) throw resp.data.msg;
            return new R({ result: list, count: resp.data.total_row })
        }
        catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! No data found" })
            }
            return new R({ error: e.response.data.msg })
        }
    }
}

export const leaderBoardRepository = new LeaderBoardRepository();