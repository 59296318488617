import React from 'react'
import { observer } from 'mobx-react'
import { FieldProps } from '../../_common/model/field'
import { useTeamStore } from '../../teams/store/team';

export const Platform = observer((props: FieldProps) => {
    const { name, value, onChange } = props;

    const { platforms, platform } = useTeamStore()

    React.useEffect(() => {
        platform()
    }, [])
    
    return (
        <div className="select-dropdown">
            <select className="" name={name} id={name} value={value} onChange={onChange}>
                <option value="">Select Platform</option>
                {platforms?.map(option => (
                    <option key={option.PlatformID} value={option.PlatformID}>{option.PlatformName}</option>
                ))}
            </select>
        </div>
    )
})