import React from 'react'
import ProfileSection from './widget/ProfileSection';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import Teams from '../teams/Teams';
import {UserProfileInfo, OtherUserProfileInfo } from './widget/UserProfileInfo';
import { OTHER_PROFILE_MENU, PROFILE_MENU } from '../core/config';
// import { useLocation } from 'react-router';
import { TournamentsPage } from '../tournament/tournaments';
import { Challenges } from '../challenge/Challenges';
import { Trophies } from '../trophy/view/Trophies';
import { Achievements } from '../achievement/view/Achievements';
import { Wallet } from '../wallet/view/Wallet';

const ProfilePage = observer(() => {
    const {userid} = useParams();
    const navigate = useNavigate();
    // if(userid === 'undefined'){
    //     navigate('/profile/home')
    // }
    return (
        <>
            <div className="main-wrapper profile-raw section">
                <div className="container">
                    <div className="profile-details-raw section pt10">
                    {userid ? <OtherUserProfileInfo /> : <UserProfileInfo />}
                        <div className="tabbing-raw section mt-sm-5 pb10">
                            <TabPanel />
                            <TabContent />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

const TabContent = () => {
    // const location = useLocation();
    const {tab} = useParams();
    let element = <></>;
    switch (tab) {
        case 'teams':
            element = <Teams />
            break;
            case 'tournaments':
            element = <><TournamentsPage /></>
            break;
        // case 'challenges':
        //     element = <Challenges/>
        //     break;
        // case 'trophies':
        //     element = <Trophies/>
        //     break;
        case 'wallet':
            element = <Wallet/>
            break;
        // case 'achievements':
        //     element = <Achievements />
        //     break;
        default:
            element = <ProfileSection />
    }
    return (
        <>
            <div className="tab-content">
                <div className="tab-pane active" role="tabpanel">
                        {element}
                </div>
            </div>
        </>
    )
}
const TabPanel = () => {
    const { userid } = useParams();
    const TAB_DATA = !userid ? PROFILE_MENU : OTHER_PROFILE_MENU(userid);
    return (
        <>
            <div className="horizontal-tab-raw section">
                <div className="divide-raw section">
                    <ul id="nav-tabs-wrapper" className="nav nav-tabs nav-tabs-horizontal d-flex justify-content-center" role="tablist">
                    {TAB_DATA.map(item => (
                            <li className="nav-item" key={item.name}>
                                <NavLink end className="nav-link" to={item.path}>{item.name}</NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}
export default ProfilePage