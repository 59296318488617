import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { observer } from 'mobx-react';
import { useAlertStore } from '../store/alert';

export const PopAlert = observer(() => {
    const {isAlertOpen, alertMsg, alertType, closeAlert, alertHideTime}= useAlertStore();
    if (!isAlertOpen) {
        return <></>
    }
    return (
        <Snackbar open={isAlertOpen} onClose={closeAlert} autoHideDuration={alertHideTime}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
            <MuiAlert onClose={closeAlert} severity={alertType} elevation={6} variant="filled" sx={{ width: '100%' }}>
                {alertMsg}
            </MuiAlert>
        </Snackbar>
    );
});