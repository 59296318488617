import { observer } from 'mobx-react';
import React from 'react'
import { useParams } from 'react-router';
import { useAuthStore } from '../../auth/store/auth';
import { toAlphabets } from '../../core/helper';
import { TournamentProps } from '../../_common/model/tournament';
import { JoinNowModal } from '../../_common/widget/JoinNowModal';
import { Verification } from '../../_common/widget/Verification';
import { useBattleRoyaleDetailsStore } from '../store/battleroyaledetails';
import { useCheckinBatttleRoyaleStore } from '../store/checkinbattleroyale';
import { useSuccessStore } from '../../_common/store/success';
import { useVerificationStore } from '../../_common/store/verification';
import { ICheckInBattleRoyale } from '../../_common/repository/battleroyale';
import { ErrorModal } from '../../_common/widget/ErrorModal'
import { useProfileStore } from '../../profile/store/profileInfo';
import { CoinDeduct } from '../../_common/widget/CoinDeduct';

export const JoinBattleRoyaleButton = observer((props: TournamentProps) => {
    const { isPrivate } = props;
    const { join_battleroyale_text, clickable, loadBattleRoyaleDetails,  battleroyale_details_loading} = useBattleRoyaleDetailsStore();
    const { user } = useAuthStore();
    const { loadProfileInfo,profile_info } = useProfileStore();
    const { checkinBattleRoyale, checkin_battleroyale_loading } = useCheckinBatttleRoyaleStore();
    const {setShowVerificationModal,setJoinNowModal,showVerificationModalValue,JoinNowModalShow}=useVerificationStore();
    const { id } = useParams();
    // const [show, setShow] = React.useState(false);
    // const [showVerificationModal, setShowVerificationModal] = React.useState(false);
    const {showSuccess} = useSuccessStore();
    const [showModalnew, setModalnew] = React.useState(false);
    const [errorMessage,setErrorMessage]=React.useState("");
    const showModal = async () => {
        if (toAlphabets(join_battleroyale_text) === "joinnow" && isPrivate === "1") {
            setShowVerificationModal(true);
        }
        else {
            if (toAlphabets(join_battleroyale_text) === "checkin") {
                if(props?.entryFee == "0"){
                    await checkinBattleRoyale({
                        battleroyale_id: id!,
                        user_id: user?.id!,
                    }).then((data)=>{
                        loadBattleRoyaleDetails(id!, user?.id!);
                        if(data.status==="1"){
                            showSuccess(data?.msg);
                        }
                        else{
                            setShowErrorModal(true)
                            setErrorMessage(data?.msg);
                    }
                        
                    }) as ICheckInBattleRoyale;
                }
                else{
                    setModalnew(true)
                }
                return;
            }
            setJoinNowModal(true);
        }
    }
    const handleCloseVerification = () => setShowVerificationModal(false);
    const closeModal = () => setJoinNowModal(false);

    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [accepted, setAccepted] = React.useState(false);
    const closeErrorModal = ()=>{
        setShowErrorModal(false)
    }
    const handleChange = async () => {
        await checkinBattleRoyale({
            battleroyale_id: id!,
            user_id: user?.id!,
        }).then((data)=>{
            loadBattleRoyaleDetails(id!, user?.id!);
            if(data.status==="1"){
                showSuccess(data?.msg);
            }
            else{
                setShowErrorModal(true)
                setErrorMessage(data?.msg);
        }
            setModalnew(false)
            loadProfileInfo(user?.id!)
        }) as ICheckInBattleRoyale;
            setAccepted(true)
    }

    const closeModalnew = () => {
        setModalnew(false)
    }

    React.useEffect(() => {
        if(accepted){
            setModalnew(false)
        }
    },[accepted])

    return (
        <>
        {(parseInt(props?.entryFee!) <= parseInt(profile_info?.UserCredit) ) ?
               
                <>
                <span className="btn-ac join-btn" role={(clickable === true && checkin_battleroyale_loading === false) ? "button" : ""} onClick={(clickable === true && checkin_battleroyale_loading === false) ? showModal : ()=>{(clickable) && setModalnew(true)}}><i></i>&nbsp; {(battleroyale_details_loading || checkin_battleroyale_loading) ? "loading.." : join_battleroyale_text}</span>
                {JoinNowModalShow && <JoinNowModal show={JoinNowModalShow} onClose={closeModal} actionType={join_battleroyale_text} {...props} />}
                <CoinDeduct onAcceptButtonClick={handleChange} {...props} show={showModalnew} onClose={closeModalnew} accepted={accepted} />
                <Verification {...props} show={showVerificationModalValue} onClose={handleCloseVerification}/>
            </>
                :
                <span onClick={()=>{
                    if(clickable === true && checkin_battleroyale_loading === false){
                         setShowErrorModal(true)
                         setErrorMessage("No Sufficient IGL Coins in your Account. ");
                    }}}>
                    <span className="btn-ac join-btn" role={(clickable === true && checkin_battleroyale_loading === false) ? "button" : ""}><i></i> &nbsp;{(battleroyale_details_loading || checkin_battleroyale_loading) ? "loading.." : join_battleroyale_text}</span>
                    
            </span>
        }
        <ErrorModal {...props} show={showErrorModal} onClose={closeErrorModal}>
        <div className="title-raw">
  <h3>Alert!</h3>
        </div>
           {errorMessage}
        </ErrorModal>
        </>
    )
});