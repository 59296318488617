import React from 'react'
import "./page.css"
import Header from './components/header'
import MiniGames from './components/mini-games'
import Follow from './components/follow'
import Description from './components/description'
import MegaTournamentSlider from './components/MegaTournamentSlider'
import  Social  from './components/Social'
import BingoPackets from './components/BingoPackets'
import BinoChip from './components/BinoChip'
import FanArtGalleryButton from './components/FanArtGalleryButton'
const ItcBingo = () => {
  return (
    <div className='container-fluid bg-black'>
      <Header />
      {/* <Description/> */}
      <MegaTournamentSlider/>
      <Social/>
      <BingoPackets/>
      <FanArtGalleryButton/>
      <BinoChip/>
      {/* <MiniGames /> */}
      {/* <Follow /> */}
    </div>
  )
}

export default ItcBingo