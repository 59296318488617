import { ErrorMessage } from 'formik'
import React from 'react'

export const FieldError = ({ name }: { name: string }) => {
    return (
        <ErrorMessage
            name={name}
            component="p"
            className="text-danger small text-start"
        />
    )
}
