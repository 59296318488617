import api from "../../core/api";
import { R } from "../pkg/types";

export class ContactRepository{
    async contactUs(payload: any): Promise<R<string>> {
        try {
            const resp = await api.post('/contactus',
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });
                
            const list = resp.data.msg as string;
            if (!list) throw resp.data.msg

            return new R({ result: list });
        } catch (e: any) {
            return new R({ error: e.response.data.msg });
        }
    }
}

export const contactRepository = new ContactRepository();