import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuthStore } from '../../auth/store/auth';
import { ShareButton } from '../../tournament/widget/ShareButton'
import { ErrorMsg } from '../../_common/widget/ErrorMsg';
import { useNewsStore } from '../store/news';
import { NewsCommentListing } from '../widget/NewsCommentListing';
import { NewsPostForm } from '../widget/NewsPostForm';

export const NewsDetailsPage = observer(() => {
    const { user, showLogin } = useAuthStore();
    const { loadNewsDetails, news_details, news_details_loading, news_details_error, addNewslike } = useNewsStore();
    const { id } = useParams();
    React.useEffect(() => {
        loadNewsDetails(id!, user?.id!);
    }, [])
    const handleClickLikes = () => {
        if(user?.id){
            addNewslike({
                news_id: id!
                , user_id: user?.id!
            });
        }
        else{
            showLogin();
        }
    }
    if (news_details_loading) {
        return <></>
    }
    else if (news_details_error) {
        return <ErrorMsg msg={news_details_error} />
    }
    return (
        <div className="main-wrapper news-detail-raw section">
            <div className="section">
                <div className="container">
                    <div className="inner-banner-con">
                        <div className="details-head section">
                            <h1>{news_details?.NewsTitle}</h1>
                            <img src={news_details?.NewsImage} className='news-detail-image' alt="..."/>
                            <div className="post-by section">
                                <ul className="list-inline my-4">
                                    <li>By : <span style={{ color: "#f09507" }}>Admin</span></li>
                                    <li>Date : {news_details?.NewsDate}</li>
                                </ul>
                            </div>
                            <div className="details-body section">
                                <div className="description">
                                    <p dangerouslySetInnerHTML={{ __html: news_details?.NewsDescription! }}></p>
                                </div>
                            </div>
                            <div className="post-action section">
                                <button className="btn-ac like" onClick={handleClickLikes}><span>{news_details?.Nlike} Like</span></button>
                                <ShareButton />
                            </div>
                            <div className="news-comment section">
                                <h4 className='news-comment-heading'>{user?.id ? "Post A Comment" : "All Comments"}</h4>
                                {user?.id ? 
                                <div className="row item-comment">
                                    <div className="col-md-2 comment-left">
                                        <div className="box-author mb-3 mb-md-0">
                                            <img src={`${news_details?.NewsImage}`} alt={news_details?.NewsTitle} height={217} width={217} />
                                            <div className="author-info">
                                                <h6 className="color-gray-700">{`${user?.firstname} ${user?.lastname}`}</h6>
                                                <span className="color-gray-700 text-sm mr-30">{user?.username}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-10 comment-right">
                                        <NewsPostForm id={id} />
                                    </div>
                                </div>
                               :null} 
                                <NewsCommentListing id={id} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});