import { Field, useField } from 'formik';
import React from 'react'
import { FieldError } from '../../_common/widget/FieldError';
import { FieldProps } from '../../_common/model/field';


export const AccountNumber = (props: FieldProps) => {
    const { name='account_no', label="Account No.", mask=false } = props;
    const [field, meta, helpers] = useField({ name });

    return (
        <div className="form-group">
            <Field type={mask ? "password" : "text"} className="form-control input-a" maxLength="20" placeholder={label} id={name} name={name}
                onChange={(e: any) => {
                    const value = e.target.value.replace(/\D/g, "");
                    helpers.setValue(value)
                }}
            />
            <FieldError name={name} />
        </div>
    )
}