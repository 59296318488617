import React from 'react'
interface Props {
    imgUrl: string,
    title: string,
    children?: React.ReactNode
}
export const WalletType: React.FC<Props> = (props) => {
    const { imgUrl, title, children } = props;
    return (
        <>
            <li className="wallet-li">
                <div className="section box-conn">
                    <img src={imgUrl} alt={`${title} pic`} className="img-fluid" />
                    <h5>{title}</h5>
                    {children}
                </div>
            </li>
        </>
    )
}