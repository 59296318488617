import React from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom';
import { INewsProps } from '../../_common/model/props.interface';


export const NewsCard = observer((props: INewsProps) => {
    const {imgUrl, title, description, date, id } = props;
    const news_detail_page = `/news/details/${id}`
    return (
        <>
            <div className="col-md-5">
                <div className="img-box">
                    <Link data-toggle="modal" to={news_detail_page}>
                        <img src={imgUrl} alt="..."/>
                    </Link>
                </div>
            </div>
            <div className="col-md-7">
                <div className="news-box">
                    <h4 className='my-3 mt-md-0'><Link to={news_detail_page}>{title}</Link></h4>
                    <p>{description}</p>
                    <div className="post-card-info">
                        <ul className="list-inline">
                            <li>By : <span style={{ color: "#f09507" }}>Admin</span></li>
                            <li className="dot"></li>
                            <li>Date : {date}</li>
                        </ul>
                    </div>
                   
                    <p>
                        <Link to={news_detail_page}>Read More</Link>
                    </p>
                   
                </div>
            </div>
        </>
    )
});