import React from 'react'
import { Field, useField } from 'formik';
import { FieldError } from '../../_common/widget/FieldError';
import { usePaymentStore } from '../store/payment';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

type Props = {
    name: string,
}

export const IFSCCode = observer((props: Props) => {
    const { name } = props;
    const { validating_ifsc_loading, ifsc_error, verify_ifsc_code, ifsc_resp, clearIfscResp } = usePaymentStore();
    const [meta] = useField({ name });
    React.useEffect(() => {
        if (meta.value.length === 11) {
            verify_ifsc_code(meta.value)
        }
        else if (meta.value !== 11) {
            clearIfscResp();
        }
    }, [meta.value])

    return (
        <div className="form-group">
            <Field type="text" className="form-control input-a" maxLength={11} placeholder="IFSC Code" id={name} name={name} />
            <FieldError name={name} />
            {ifsc_resp?.bank && <p className="text-white small text-start ps-4 mt-3"><b>Bank Name :</b> {ifsc_resp.bank}</p>}
            {ifsc_resp?.address && <p className="text-white small text-start ps-4"><b>Bank Address :</b> {ifsc_resp.address}</p>}
            {validating_ifsc_loading && <p className="text-white small text-start ps-4">Loading...</p>}
            {ifsc_error!=="" ? <p className="text-danger small text-start">{ifsc_error}</p>:null}
        </div>
    )
})