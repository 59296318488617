import React from 'react'
import { Link } from 'react-router-dom';
import { IGameProps } from '../../_common/model/props.interface'

export const GameCard = (props: IGameProps) => {
    const {imgUrl, title, id}=props;
    const detail_url = `/games/details/${id}`
    return (
        <div className="col-sm-3 col-6 gm-li game-listing d-flex">
            <div className="gm-col section d-flex flex-column">
                <div className="img-box flex-shrink-0 flex-grow-0">
                    <Link to={detail_url}>
                        <img src={imgUrl} height="198" width="263" alt={title} className="img-fluid"/>
                    </Link>
                </div>
                <div className="about-game-raw flex-grow-1 d-flex flex-shrink-0 flex-column justify-content-between">
                    <div className="about-game-conn">
                        <div className="left-conn">
                            <h5><Link to={detail_url}>{title}</Link></h5>
                        </div>
                    </div>
                    <ul className="plateform-players-col position-relative">
                        <li>
                            <Link className="btn-join" to={detail_url}>Know more</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
