import React from 'react'

import { Modal } from 'react-bootstrap'
import { IModalProps } from '../model/props.interface';

type Props = IModalProps & { children?: React.ReactNode }

export const SuccessModal = (props: Props) => {
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const campaign = new URLSearchParams(window.location.search).get('from_campaign');
    const { show, onClose, children } = props;
    if(!show){
        return <></>
    }
    return (
        <Modal show={show} animation={true} id="after_vote" className={`login-modal modal-raw modal fade in show  ${(queryParams && campaign ==="itc") ? "itc-tournament-details-col":""}`} onHide={onClose} centered >
            <button type="button" className="btn-close-modal"
                onClick={onClose}
            >×</button>
            <Modal.Body>

                <div className="row">
                    <div className="col-md-12">
                        <div className="title-raw mb30">
                            <h3>CONGRATS!!</h3>
                            <p className='text-white text-uppercase'>{children}</p>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}
