import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const CONTACT_VALIDATION_SCHEMA = Yup.object({
    firstname: Yup.string()
        .required("Required!")
        .matches(/^[A-Za-z ]*$/, 'Please use alphabets only!')
        .min(2, "This must be atleast 2 characters!")
        .max(20, "This must not exceed 20 characters!"),
    lastname: Yup.string()
        .required("Required!")
        .matches(/^[A-Za-z ]*$/, 'Please use alphabets only!')
        .min(2, "This must be atleast 2 characters!")
        .max(20, "This must not exceed 20 characters!"),
    email: Yup.string().required('Required').email('Email is invalid!'),
    mobile: Yup.string()
        .required("Required!")
        .matches(phoneRegExp, 'Invalid Mobile Number!')
        .min(10, "Please enter 10 digits Mobile Number!")
        .max(10, "Please enter 10 digits Mobile Number!"),
    message: Yup.string()
        .required("Required!"),
})