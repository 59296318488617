import React from 'react';
import { makeAutoObservable } from "mobx";

export class SuccessStore {
    success_msg: string = '';
    constructor() {
        makeAutoObservable(this)

    }
    showSuccess = (msg: string) => {
        this.success_msg = msg;
    }
    hideSuccess = () => {
        this.success_msg = '';
    }
}
export const SuccessStoreContext = React.createContext(new SuccessStore());
export const useSuccessStore = () => React.useContext(SuccessStoreContext);