import { Field } from 'formik';
import React from 'react'
import { FieldProps } from '../../_common/model/field';
import { FieldError } from '../../_common/widget/FieldError';

const Checkbox = (props: FieldProps) => {
    const { name = "checkbox", label } = props;
    return (
        <>
            <label>
                <Field
                    {...props}
                    type="checkbox"
                />
                <span className="chk-style">{label}</span>
            </label>
            <FieldError name={name} />
        </>
    )
}

export default Checkbox