import { ErrorMessage, Field } from 'formik';
import React from 'react'
import { FieldProps } from '../../_common/model/field';
import { FieldError } from '../../_common/widget/FieldError';

const Email = (props: FieldProps) => {
    const { label, isFloating = false, fullWidth } = props;
    return (
        <div className={fullWidth ? "col-md-12" : "col-md-6"}>
            {
                isFloating
                    ? <div className="floating-label-group section">
                        <EmailField {...props} />
                        <label className="floating-label">{label}</label>
                    </div>
                    : <div className="form-group">
                        <EmailField  {...props} placeholder={label} />
                    </div>
            }
        </div>
    )
}

type EmailFieldProps = FieldProps & { placeholder?: string }
const EmailField = (props: EmailFieldProps) => {
    const { name = "email" } = props;
    return (
        <>
            <Field
                {...props}
                className={`${props.className} form-control`}
            />
            <FieldError
                name={name}
            />
        </>
    )
}

export default Email