import React from 'react'

type Props = {
    data?: any,
    isLoading?: boolean,
    children?: React.ReactNode,
    msg?: string
}

export const DataResponse: React.FC<Props> = (props) => {
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const from_campaign = new URLSearchParams(window.location.search).get('from_campaign');
    const { data, isLoading, children, msg } = props;
    if (isLoading) {
        return <h4 className='my-5 text-center d-flex justify-content-center align-items-center' style={{height:'300px'}}>Data Loading...</h4>
    }
    if (!data?.length) {
        if(msg){
            return <p className='my-5 text-center' style={{color:(queryParams && from_campaign==="itc")?"#ffcd1e": "#50aeff"}}>{msg}</p>
        }
        else{
            return <h4 className='my-5 text-center'>No data available!</h4>
        }
    }
    return (
        <>{children}</>
    )
}
