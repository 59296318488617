import React from 'react'
type Props = {
    count?: string;
}
export const GameLike = (props:Props) => {
    return (
        <>
            <li className="like-col">
                <div className="hexagon-col">
                    <h4>{props?.count || 0}</h4>
                </div>
                <h5>Likes</h5>
            </li>
        </>
    )
}
