import api from "../../core/api";
import { LedgerResponse,ICheckUserBankDetails ,IVerifyIfscCodeResp} from "../model/wallet";
import { R } from "../pkg/types";

export class WalletRepository {
    async getLedger(userId: string, pageNo: number): Promise<R<LedgerResponse[]>> {
        try {
            const resp = await api.get(`/getledgers?user_id=${userId}&&PNO=${pageNo}`);
            const list = resp.data.ladgers as LedgerResponse[];
            if (!list) throw resp.data.msg
            return new R({ result: list, count: resp.data.total_row })
        } catch (e) {
            return new R({ error: e })
        }
    }
    requestPayment = async (payload: any) => {
        try {
            const resp = await api.post(`/withdrawal`
                , payload
                , {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });
            const list = resp.data;
            if (!list || list.status!=="1") throw resp.data.msg
            return new R({ result: list });
        }
        catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! Something went wrong!" })
            }
            return new R({ error: e.response?.data?.msg })
        }
    }
    bankDetails = async (payload: any) => {
        try {
            const resp = await api.post(`/addbankdetails`
                , payload
                , {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });
            const list = resp.data;
            if (!list) throw resp.data.msg
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e })
        }
    }
     walletStatus = async (payload: any) => {
        try {
            const resp = await api.post(`/withdrawalstatus`
                , payload
                , {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });
            const list = resp.data;
            if (!list) throw resp.data.msg
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e })
        }
    }
    async checkUserBankDetails(payload:object): Promise<R<ICheckUserBankDetails>> {
        try {
            const resp = await api.post(`/checkUserBankDetails`,payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }

            });
            const list = resp.data.BankDetails as ICheckUserBankDetails || null;
            if (!list) throw resp.data.msg
            return new R({ result: list })
        } catch (e) {
            return new R({ error: e })
        }
    }
    async verifyIfscCode(payload:string): Promise<R<IVerifyIfscCodeResp>> {
        try {
            const resp = await api.post(`/verify_IFSCCode`,{
                ifsc:payload
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if(typeof(resp.data.msg)==="object")
            {
                const list = resp.data.msg as IVerifyIfscCodeResp;
                return new R({ result: list })
            }
            else{
                throw resp.data.msg
            } 
        } catch (e) {
            return new R({ error: "Invalid IFSC Code" })
        }
    }
}
export const walletRepository = new WalletRepository();