import React from 'react'
import { observer } from 'mobx-react'
import { useTeamStore } from '../store/team'
import { FieldProps } from '../../_common/model/field'
import { Field, useField, useFormikContext } from 'formik'
import { TeamState } from '../../_common/model/team'
import * as Yup from 'yup';
import { FieldError } from '../../_common/widget/FieldError'

export const TeamSizeDropdown = observer((props: FieldProps) => {
    const { name='size', value, disabled, required } = props;

    const { team_sizes, teamSize, team_size_loading } = useTeamStore()
    const [field, meta, helpers] = useField({ name });
    const { values } = useFormikContext<TeamState>();

    React.useEffect(() => {
        if(!values.team_game){
            return
        }
        (async () => {
            await teamSize(values.team_game);
        })();
        helpers.setValue(value)
    }, [values.team_game])

    const validate = async (value: any) => {
        var schema = Yup.string();

        let sch = required
            ? schema.required('Please select Size!')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }
    return (
        <div className="form-grop">
            <label htmlFor={name} className={`${required ? "required-field" : ""}`}>Team Size</label>
            <p>Choose team size</p>
            <Field component='select' className="form-control" name={name} disabled={disabled} validate={validate} style={disabled ? {background: "#b1afaf"} : {}}>
                <option value="">Select Team Size</option>
                {team_size_loading 
                ? <option disabled>Loading..</option>
                : team_sizes?.map((item,index) => (
                    <option value={item?.teamSize} key={index}>{item?.name}</option>
                ))}
            </Field>
            <FieldError name={name} />
            {/* <p className="text-danger small text-start">{meta.error}</p> */}
        </div>
    )
})