import React,{useEffect} from 'react'
import { Payment } from './Payment'
// import { RedeemCoupon } from './RedeemCoupon'
import { ViewLedger } from './ViewLedger'
import { observer } from 'mobx-react'
import { useProfileStore } from '../../profile/store/profileInfo'
import { useAuthStore } from '../../auth/store/auth'

export const WalletSection = observer(() => {
   const {loadProfileInfo}=useProfileStore();
   const {user}=useAuthStore();
   useEffect(()=>{
        loadProfileInfo(user?.id!);
   },[])
    return (
        <>
            <div className="wallet-section section">
                <ul className="wallet-ul">
                    <ViewLedger />
                    <Payment />
                    {/* <RedeemCoupon /> */}
                </ul>
            </div>
        </>
    )
})