import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { observer } from 'mobx-react';
import { useAuthStore } from '../store/auth';
import { ResetForm } from './ResetForm';

const ForgotPassword = observer(() => {
    const { show_reset, hideReset } = useAuthStore();

    if (!show_reset) {
        return <></>
    }
    return (
        <Modal show={show_reset} className="login-modal modal-raw " aria-labelledby="contained-modal-title-vcenter"
        centered>
            <button type="button" className="btn-close-modal" onClick={hideReset}>&times;</button>
            <Modal.Body className="logsec">
                <ResetForm/>
            </Modal.Body>
        </Modal>
    )
});

export default ForgotPassword