import axios from "axios";
import api from "../../core/api";
import { TournamentTeam } from "../model/team";
import { IgetTournamentBrackets, ITournamentParams, ITournamentPayload, MegaTournamentResponse, Tournament, TournamentBracket, TournamentWinners } from "../model/tournament";
import { R } from "../pkg/types";

export interface IItcBingoRepository {
  getBingoTournaments(): Promise<R<MegaTournamentResponse[]>>;
}

class ItcBingoRepository implements IItcBingoRepository {

  async getBingoTournaments(): Promise<R<MegaTournamentResponse[]>> {
    try {
      // const resp = await api.get(`/get_mega_tournamentlist_new`,{
      const resp = await api.get(`/get_mega_tournamentlist_SP`,{

        headers:{
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        }
      });
      const list = resp.data.Tournamentlist as MegaTournamentResponse[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  };
};

export const itcBingoRepository: IItcBingoRepository = new ItcBingoRepository();
