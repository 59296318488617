import $ from "jquery";
$(window).on("load", () => {
  $("#status").fadeOut("slow");
});

$(window).on("load", () => {
  // eslint-disable-next-line no-unused-expressions
  $("#status").fadeOut();
  $("#preloader").fadeOut("slow");
  $("body").css({
    overflow: "visible",
  });
});

$(document).ready(() => {
  // $(".nav-toggle").click(function () {
  //   $(this).toggleClass("active");
  //   $(".nav-main").slideToggle("open");
  //   $("body").toggleClass("nav-open");
  //   $("html").toggleClass("nav-open");
  // });

  // $(".dropdown-menu-pro a").click(function() {
  //     var url = $(this).attr('href');
  //     console.log('url', url);
  //     if (url.match('#')) {
  //         var tid = url.split('#')[1];
  //         console.log('tid', tid);
  //         $('.nav a[href$=#' + tid + ']').tab('show');
  //         window.scrollTo(0, 0);
  //     }
  // });

  // Tab js start
  $(".nav-tabs-dropdown").each(function (i, elm) {
    $(elm).text($(elm).next("ul").find("li.active a").text());
  });
  $(".nav-tabs-dropdown").on("click", function (e) {
    e.preventDefault();
    $(e.target).toggleClass("open").next("ul").slideToggle();
  });
  $('#nav-tabs-wrapper a[data-toggle="tab"]').on("click", function (e) {
    e.preventDefault();
    $(e.target).closest("ul").hide().prev("a").removeClass("open").text($(this).text());
  });
  // Tab js end

  $(".add-to-cart").click(function () {
    $(this).hide().next(".add-qty-col").css("display", "inline-block");
  });

  //profile drop down
/*   $(".dropdown-toggle").click(function (event) {
    $(this).next(".dropdown-menu-pro").slideToggle();
    event.stopPropagation();
  });

  $(".dropdown-menu-pro").click(function (event) {
    event.stopPropagation();
  });
  $("html").click(function () {
    $(".dropdown-menu-pro").slideUp();
  }); */

  //notification drop down
  $(".dropdown-toggle-noti").click(function (event) {
    $(this).next(".dropdown-menu-noti").slideToggle();
    event.stopPropagation();
  });

  $(".dropdown-menu-noti").click(function (event) {
    event.stopPropagation();
  });
  $("html").click(function () {
    $(".dropdown-menu-noti").slideUp();
  });

  $(window).on("scroll", function (event) {
    var scrollValue = $(window).scrollTop();
    if (scrollValue > 0) {
      $("#header").addClass("affix");
    } else {
      $("#header").removeClass("affix");
    }
  });
  document.onkeydown = function (e) {
    if (e.keyCode == 123) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.which == 83) {
      e.preventDefault();
      return false;
    }
  };

  $(function () {
    $(".tab-pane.active .game-listing-col .game-listing").slice(0, 8).show();
    $(".loadMore").on("click", function (e) {
      e.preventDefault();
      $(".tab-pane.active .game-listing-col .game-listing:hidden").slice(0, 4).slideDown();
      if ($(".tab-pane.active .game-listing-col .game-listing:hidden").length == 0) {
        $("#load").fadeOut("slow");
      }
    });
  });
});

$(window).width() < 1120 &&
  $(".nav-main ul li a").click(function () {
    $(".nav-toggle").removeClass("active");
    $(".nav-main").removeClass("open");
    $("body").removeClass("nav-open");
    $("html").removeClass("nav-open");
    $(".nav-main").slideUp(".nav-main");
  });

// $(function () {
//   var hash = location.hash;
//   var href = "";
//   hash && $('.tabbing-raw .nav-item a[href="' + hash + '"]').tab("show");
//   $(".tabbing-raw .nav-tabs a").click(function (e) {
//     $(this).tab("show");
//     var scrollme = $("body").scrollTop();
//     location.hash = this.hash;
//     $("html,body").animate(
//       {
//         scrollTop: $(href).offset().top,
//       },
//       "slow"
//     );
//     e.preventDefault();
//   });
// });

// $(".brought-slide").slick({
//   dots: false,
//   infinite: true,
//   arrows: true,
//   slidesToShow: 4,
//   draggable: false,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 991,
//       settings: {
//         slidesToShow: 3,
//       },
//     },
//     {
//       breakpoint: 767,
//       settings: {
//         slidesToShow: 3,
//       },
//     },
//     {
//       breakpoint: 479,
//       settings: {
//         slidesToShow: 2,
//       },
//     },
//   ],
// });

// $(document).ready(() => {
//   let url = location.href.replace(/\/$/, "");
//   if (location.hash) {
//     const hash = url.split("#");
//     $('.dropdown-menu-pro a[href="#' + hash[1] + '"]').tab("show");
//     url = location.href.replace(/\/#/, "#");
//     history.replaceState(null, null, url);
//     // //setTimeout(() => {
//     //     $(window).scrollTop(0);
//     // }, 400);
//   }
//   $('a[data-toggle="tab"]').on("click", function () {
//     let newUrl;
//     const hash = $(this).attr("href");
//     if (hash == "#profile.php") {
//       newUrl = url.split("#")[0];
//     } else {
//       newUrl = url.split("#")[0] + hash;
//     }
//     newUrl += "/";
//     history.replaceState(null, null, newUrl);
//   });
// });
