import { observer } from 'mobx-react';
import React from 'react'
import { useParams } from 'react-router-dom';

import { useAuthStore } from '../../auth/store/auth';
import { useBattleRoyaleDetailsStore } from '../../battleroyale/store/battleroyaledetails';
import { useJoinBatttleRoyaleStore } from '../../battleroyale/store/joinbattleroyale';
import { GAME_TYPE, IGL_IMAGE_URL } from '../../core/config';
import { toAlphabets } from '../../core/helper';
import { useTeamStore } from '../../teams/store/team';
import { useJoinTournamenStore } from '../../tournament/store/jointournament';
import { useTournamentDetailsStore } from '../../tournament/store/tournamentdetails';
import { IModalProps } from '../model/props.interface';
import { IEligibleTeamPayload } from '../model/team';
import { TournamentProps } from '../model/tournament';
import { DataResponse } from './DataResponse';
import { useSuccessStore } from '../store/success';
import { CoinDeduct } from './CoinDeduct';

type ExtraProps = { 
    actionType: string, 
    accepted: boolean,
    onClick: React.MouseEventHandler<HTMLLIElement> | any
}
type Props = IModalProps & ExtraProps & TournamentProps;
export const EligibleTeams = observer((props: Props) => {

    const { gameId, levelId, size, actionType, gameType, accepted, onClick, from_campaign } = props;
    const { loadEligibleTeam, eligible_teams, eligible_team_loading } = useTeamStore();
    const { user } = useAuthStore();
    const { showSuccess } = useSuccessStore();
    const { id } = useParams();
    const { joinTournament } = useJoinTournamenStore();
    const { joinBattleRoyale } = useJoinBatttleRoyaleStore();
    const {loadBattleRoyaleDetails}= useBattleRoyaleDetailsStore();
    const {loadTournamentDetails}= useTournamentDetailsStore();

    const [showModal, setModal] = React.useState(false);

    const [acceptedd, setAcceptedd] = React.useState(false);
    React.useEffect(() => {
        const data: IEligibleTeamPayload = {
            user_id: user?.id!,
            game_id: gameId,
        }
        if (gameType === GAME_TYPE.tournament) {
            data.tournamentId= id;
            data.TournamentLevel = levelId;
            data.TournamentTeamMembers = size;
        }
        else{
            data.BattleRoyale_id = id;
            data.battelroyaleLevel = levelId;
            data.size = size;
            if(from_campaign){
                data.from_campaign = from_campaign;
            }
        }
        loadEligibleTeam(data, gameType);
        
    }, [id]);


    const closeModal = () => {
        setModal(false)
    }

    const handleChange = () => {
        if(acceptedd){
            setModal(false)
        }
        setAcceptedd(true)
    }

    React.useEffect(() => {
        if(acceptedd){
            setModal(false)
        }
    },[acceptedd])

    const handleClick = async(teamId: string) => {
        
        if(!accepted){
            onClick();
            return
        }
        
        const tournament_data = {
            tournament_id: id!,
            user_id: user?.id!,
            team_id: teamId
        }
        const battleroyale_data = {
            battleroyale_id: id!,
            user_id: user?.id!,
            team_id: teamId
        }
        let res;
         if(props?.entryFee === "0"){

        if (gameType === GAME_TYPE.tournament) {
            if (toAlphabets(actionType) === "joinnow") {
                res = await joinTournament(tournament_data) as string;
                showSuccess(res)

                let msgnew = res as any;
                // console.log(msgnew)
                if(msgnew){
                    props.onClose()
                }
            }
            await loadTournamentDetails(id!, user?.id!)
        }
        else {
            if (toAlphabets(actionType) === "joinnow") {
                res = await joinBattleRoyale(battleroyale_data) as string;
                showSuccess(res)
                props.onClose()
            }
            await loadBattleRoyaleDetails(id!, user?.id!)
        }
    }
    else{
            if (gameType === GAME_TYPE.tournament) {
                if (toAlphabets(actionType) === "joinnow") {
                    res = await joinTournament(tournament_data) as string;
                    showSuccess(res)
    
                    let msgnew = res as any;
                    // console.log(msgnew)
                    if(msgnew){
                        props.onClose()
                    }
                }
                await loadTournamentDetails(id!, user?.id!)
            }
            else {
                if (toAlphabets(actionType) === "joinnow") {
                    res = await joinBattleRoyale(battleroyale_data) as string;
                    showSuccess(res)
                    props.onClose()
                }
                await loadBattleRoyaleDetails(id!, user?.id!)
            }
        }
    }

    return (
        <>
        <DataResponse data={eligible_teams} isLoading={eligible_team_loading} msg="You have no teams available - Please create a team">
            <>
                <ul style={{ display: "inline-block" }}>
                    {eligible_teams?.map((item, idx) => (
                        <li key={item.TeamID} onClick={() => handleClick(item.TeamID)}>
                            <span className="bracket-compatitore-link " role="button">
                                <img className='object-cover' src={item?.TeamImage ? item?.TeamImage :`${IGL_IMAGE_URL}/bluebg.png`} />
                                <p>{item.TeamName}</p>
                            </span>
                        </li>
                    ))}
                </ul>
            </>
        </DataResponse>
        {/* <CoinDeduct onAcceptButtonClick={handleChange} {...props} show={showModal} onClose={closeModal} accepted={accepted} /> */}
        </>
    )
});