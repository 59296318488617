import React,{Suspense} from 'react'
import { observer } from 'mobx-react'
import { DataResponse } from '../../_common/widget/DataResponse'
import { useGameStore } from '../store/game'
import { GameCard } from '../widget/GameCard'
import { Platform } from '../widget/Platform'
// import AdSlot from '../../_common/view/AdsComponent/AD_Index'


export const GameListing = observer(() => {
    const AdSlot = React.lazy(() => import('../../_common/view/AdsComponent/AD_Index'));
    const { loadGames, game_list, game_loading, current_page, game_platform, changePlatform, loadMoreGames, showMore } = useGameStore();
    const [platform, setPlatform] = React.useState("");

    React.useEffect(() => {
        loadGames({
            PNO: current_page,
            platform: game_platform
        })
    }, [current_page, game_platform])
    
    // const handleViewMore = () => {
    //     loadMoreGames()
    // }
    const handleChangePlatform = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPlatform(e.target.value);
        changePlatform(e.target.value);
    }

    //ads work starts
    const [isMobile, setIsMobile] = React.useState<boolean>(false);
    React.useEffect(() => {
        // Check the window width and set isMobile accordingly
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as per your requirement
        };

        handleResize(); // Call once on initial load

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    //   ads work ends
    return (
        <>
            <div className="common-page-raw section">
                <div className="container">
                    <div className="game-lists-raw all-games-raw section pt-5 mt-5">
                        <div className="game-platform-raw section">
                            <div className="row justify-content-center">
                                <div className="col-md-4">
                                    <Platform name="platform" value={platform} onChange={handleChangePlatform} />
                                </div>
                            </div>
                            <DataResponse data={game_list} isLoading={game_loading}>
                                <div className="section gm-li-raw game-listing-col">
                                    <div className="row">
                                        {game_list.map((item, index) => {

                                            if ((index + 1) % 16 === 0) {
                                                return <React.Fragment key={index}>
                                                    <GameCard imgUrl={item.GameImagePath} title={item.GameTitle} id={item.GameID} />
                                                    <div className='col-12'>
                                                    {!isMobile && <Suspense fallback={<></>}>
                                    <AdSlot adUnitPath="/317733190/IGL_Web_Games_728x90_Ad1" adSize={[728, 90]} />
                                </Suspense>}
                                {isMobile && <Suspense fallback={<></>}>
                                    <AdSlot adUnitPath="/317733190/IGL_Web_Games_300x250_Ad1" adSize={[300, 250]} />
                                </Suspense>}
                                                    </div>
                                                </React.Fragment>
                                            }
                                            return <GameCard key={index} imgUrl={item.GameImagePath} title={item.GameTitle} id={item.GameID} />
                                        })}
                                        {/* {showMore &&
                                            <div className="col-sm-12 col-12 text-center">
                                                <button className="mt50 btn-sm btn-main loadMore txt-shad-none" onClick={handleViewMore}>View More</button>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </DataResponse>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
