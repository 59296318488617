import { Field } from 'formik';
import React from 'react'
import { Link } from 'react-router-dom';
import { FieldProps } from '../../_common/model/field';
import { FieldError } from '../../_common/widget/FieldError';

const TermsCondition = (props: FieldProps) => {
    const { name = "tnc" } = props;
    return (
        <>
            <label>
                <Field
                    {...props}
                    type="checkbox"
                />
                <span className="chk-style">
                    I accept <Link to="/service-terms">terms of use</Link> and
                    <Link to="/privacy-plicy"> privacy policy.</Link>
                </span>
            </label>
            <FieldError name={name} />
        </>
    )
}

export default TermsCondition