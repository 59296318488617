import React from 'react'

export const PrivacyPolicyPage = () => {
    return (
        <>
            <div className="main-wrapper contact-banner section">
                <div className="container-fluid">
                    <div className="banner-con section">
                        <div className="main-wrapper about-main-raw section" style={{paddingTop: "100px"}}>
                            <div className="container">
                                <h2 className="head-f text-uppercase text-center">Privacy Policy</h2>
                                <p className="space-e"></p>
                                <div className="container">
                                    <div className="static-text-a">
                                        <p>Last updated on April, 2019</p>
                                        <p>User privacy is of paramount importance to &nbsp; Indian Gaming League (“<strong>IGL</strong>”). IGL has developed this “<strong>Privacy Policy</strong>” which covers how IGL collects, uses, discloses and stores User information. Users are advised to familiarize themselves with IGL’s privacy practices.</p>
                                        <p>This document is an electronic record in terms of Applicable Laws. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                                        <p>By using the Portal or IGL’s Services or otherwise providing information to IGL, User agrees that their Personal Information (<em>as defined below</em>) and Gamer Information (<em>as defined below</em>) shall be handled in accordance with this Privacy Policy. Further, User also agrees to IGL’s terms for accessing and correcting information, including but not limited to Personal Information and Gamer Information as described in this Policy.</p>
                                        <p>As a result of visiting or accessing the Website, Users agree to be bound by the terms and conditions of this Privacy Policy. Users are advised to refrain from using or accessing the Website, if they do not agree with the terms and conditions of this Privacy Policy.</p>
                                        <p>User’s use of the Portal and Services and any dispute over privacy, are governed by this Privacy Policy and IGL’s Terms of Use, which shall be deemed to be incorporated into this Privacy Policy and shall be read in addition to this Privacy Policy. In the event of any inconsistency or ambiguity arising between the Terms of Use and the terms of this Privacy Policy, the provisions in this Privacy Policy to the extent of such inconsistency or ambiguity, shall prevail.</p>
                                        <p>&nbsp;</p>
                                        <p>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Definitions</strong></p>
                                        <p>For the purpose of this Privacy Policy, wherever the context so requires, the term:</p>
                                        <p>1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “<strong>Account</strong>” shall mean the account created by the User on the Portal by registering on the Portal.</p>
                                        <p>1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “<strong>Applicable Law</strong>” shall include all laws, statutes, ordinance, regulations, guidelines, policies and other pronouncements having the effect of law of the all or any applicable jurisdictions by state, municipality, court, tribunal, government, ministry, department, commission, arbitrator or board or such other body which has the force of law in all or any applicable jurisdiction.</p>
                                        <p>1.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “<strong>Content</strong>” shall include, but not limit to images, photos, audio, video, location data, nearby places and all other forms of information or data.</p>
                                        <p>1.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “<strong>Person</strong>” shall mean any natural person, trust, firm, company, governmental authority, joint venture, association, partnership, society or other entity (whether or not having separate legal personality).</p>
                                        <p>1.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “<strong>Portal</strong>” shall mean an internet portal located at URL – [<em>insert URL</em>] (“<strong>Website</strong>”) and a computer program designed to run on smartphones and tablets i.e. a mobile application available for android and iOS operating located at [<em>insert</em>] and [<em>insert</em>] (“<strong>App</strong>”).</p>
                                        <p>1.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “<strong>Services</strong>” shall mean the services provided by IGL via the Portal.</p>
                                        <p>1.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>“User” </strong>shall mean any Person who seeks to avail the Services of IGL for the purpose of gaming, hosting, participating, transacting or uploading information or views.</p>
                                        <p>&nbsp;</p>
                                        <p>2.&nbsp;&nbsp;&nbsp;&nbsp; <strong>Collection and use of Information</strong></p>
                                        <p>2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong><u>PERSONAL INFORMATION</u></strong>: IGL may collect and use, through the Portal, information that can be used to identify or contact a single Person (“<strong>Personal Information</strong>”), including but not limited to Personal Information as enumerated below:</p>
                                        <p>2.1.1.&nbsp;&nbsp;&nbsp;&nbsp; Information IGL Collects Directly From User. </p>
                                        <p>When Users communicate or indulge in gaming on the Portal, IGL collects information from the User, including but not limited to Personal Information. For example, IGL collects information from the User when the User signs up for the Services, creates an online Account with IGL, participates in a game, tournaments or survey, or otherwise contacts IGL. The type of information that IGL collects depends on the User’s interaction with IGL, but may include, where permitted by Applicable Laws, the following: User’s name, User’s email address (business or personal), physical mailing/billing address (business or personal), credit/debit card/billing information, telephone number (business or personal), and any other information that you choose to provide.</p>
                                        <p>2.1.2.&nbsp;&nbsp;&nbsp;&nbsp; Information IGL Collects Automatically.</p>
                                        <p>IGL automatically collects the following information about Users use of the Portal or online Services through cookies, web beacons, log files and other technologies: User’s unique device identifiers,User’s domain name, User’s browser type and operating system, web pages the User views, links the User clicks, User’s IP address, the length of time User visits the Portal or uses the Services, User’s activities on the Portal or user of the Services (including User’s movement through the Portal), and the referring URL or the webpage that led the User to IGL’s Portal.</p>
                                        <p>2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong><u>GAMER INFORMATION</u></strong><strong><em>: </em></strong>IGL may collectinformation about User’s activity on the Portal as a member which is not itself personally identifiable with the User and is therefore not Personal Information, but which may be linked (on a non-identifiable basis) to User’s Account information, and include (a) Username, (b) Skill Rating, (c) Top Players Ranking (if any) and IGL COIN balance, (d) scores in games and tournaments, (e) gaming preferences and personal motto, (f) summary of gaming activity, (g) winnings from games and tournament, and (h) footage of gameplay in promotional materials such as trailers which may be displayed on the Website.</p>
                                        <p>2.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may use and retain the information IGL collects from the User in an aggregated form or IGL may associate it with User’s username and other Personal Information that IGL collects about the User.</p>
                                        <p>2.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may use and retain such information to send User any communication including but not limited to notifications related to the User’s Account and announcements about new games and/or tournaments that IGL may offer from time to time.</p>
                                        <p>2.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may also use Personal Information for internal purposes such as auditing, data analysis, and research to improve the Services.</p>
                                        <p>2.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may collect information during deposit of funds into the UserAccount&nbsp;- for the purpose of creating and depositing funds to the your User Account which will allow the Individual to enter cash games and tournaments, in addition to the basic information like name; credit card information: type, number, expiration month, expiration year and card verification number; and billing information: street address, city, postal code, province and country.</p>
                                        <p>2.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may collect information during withdrawal of funds from the User Account&nbsp;for the purpose of confirming User’s identity, including but not limited to email address, first name, last name; street address, city, postal code, state and country, bank account, pan card and phone number.</p>
                                        <p>2.8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may collect information during claim of prizing from Person for the purpose of confirming their identity: name, photograph, age and address.</p>
                                        <p>2.9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may collect information during game challenges from the User on the PlayStation Network ID, XBOX Live Gamer-tag, NINTENDO Account, IOS &amp; Android Game ID’s, email address and in-game usernames, in order to make or accept game challenges, and IGL to track the game results. The Website also provides ways to voluntarily give IGLthe Personal Information including but not limited to: from social network sites ("SNS"), where the User creates an Account by logging into a SNS via the Website, certain Personal Information which the User has provided the SNS for the purpose of completing the opening of the Account; from the User, through completion of contest forms or other participation in promotional efforts, for the purpose of such contests and promotions, including for the purpose of awarding prizes; from you, through the completion of surveys, for the purpose of gathering User’s opinions and comments in regard to IGL products and services. In addition to collecting Personal Information directly from visitors to the Website, we may also collect Personal Information from third parties such as marketing partners to which permission has been granted to share that information with IGL.</p>
                                        <p>&nbsp;</p>
                                        <p>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Manner of Dealing with information</strong></p>
                                        <p>3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In an effort to make the Website effective, certain information may be collected each time User visits the Website. Such information may be stored in server logs. These encrypted statistics do not identify User personally, but provide IGL the information regarding User’s visit to the Website and the type of User who is accessing the Website and certain browsing activities by the User. This data may include, IP address of User’s server from where the Website is being accessed, the type of internet browser, the operating system of User’s system and the website, User last visited before visiting the Website. The duration of User’s stay on the Website is also stored in the session along with the date and time of User’s access. This data is used by IGL to understand the usage of the Website and the number of Users visiting the Website. Some of the information related to the Website and User’s visit to the Website may be shared with the sponsors, investors, advertisers, developer, strategic business partners of IGL in order to enhance and grow the business and to fine tune the Website. IGL assures that this information will never include any of User’s Personal Information. This anonymous information is collected through the use of a pixel tag, which is industry standard technology used by most major Websites.</p>
                                        <p>3.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL uses User’s Personal Information to resolve disputes; troubleshoot problems; help promote a safe service; measure consumer interest in the Services, inform the User about updates on Services, and customize User experience; detect and protect the Website against error, fraud and other criminal activity; enforce IGL’s terms and conditions; and as otherwise described to the User at the time of collection of User Details.</p>
                                        <p>3.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL uses User’s usage date (“Usage Data”) to help diagnose problems with and maintain computer servers, to manage the Website, and to enhance the Website and Services based on the usage pattern data received.</p>
                                        <p>3.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may generate reports and analysis based on the Usage Data for internal analysis, monitoring and marketing decisions.</p>
                                        <p>3.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may provide Usage Data to third parties, but when it does so, it does not provide personally-identifying information without User’s permission.</p>
                                        <p>&nbsp;</p>
                                        <p>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Disclosure of Information </strong></p>
                                        <p>4.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may share Personal Information and/or Gamer Information with its affiliates and authorised agents, representatives and advisors to help detect and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts to prevent abuse of IGL services; and to facilitate joint or co-branded services that the User requests where such services are provided by more than one corporate entity. Those entities and affiliates may not market to the User as a result of such sharing unless the User explicitly opts-in.</p>
                                        <p>4.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may share Personal Information with the co-branded partners with whom IGL may jointly offer a Service or feature (“<strong>Co-Branded Partner</strong>”) such as a tournament and/or league. Such Co-Branded Partner's name will be featured prominently. The User may be asked to provide information to register for a Service offered by a Co-Branded Partner. In doing so, the User may be providing their Personal Information to both IGL and the Co-Branded Partner, or, their consent, IGL may share their Personal Information with the Co-Branded Partner. It is pertinent to mention in this context that the Co-Branded Partner's privacy policy may apply to its use of User’s Personal Information.</p>
                                        <p>4.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To the consent that you consent, IGL may also disclose Personal Information with the marketing partners who provide products, information or services that the User has expressly requested or which the User may be interested in purchasing or obtaining, in order that the marketing partner may provide the User with information regarding such products, information or services. In doing so, IGL shall to ensure that the marketing partner only use the Personal Information for the stated purposes and that they provide a method of opting out from receiving future communications from such parties, but we shall not otherwise be responsible for their handling or dissemination practices with respect to Personal Information. If the User is desirous of changing their account preferences to not receive information from such marketing partners, they may please contact Customer Care or use the "unsubscribe" link included in email messages sent by such parties.</p>
                                        <p>4.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may share User Details with third parties in order to conduct a basic verification of information provided by the User.</p>
                                        <p>4.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may disclose Personal Information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to summons, court orders, or other legal or quasi-legal processes. IGL may disclose Personal Information and/or Gamer Information to law enforcement offices, third party rights’ owners, or others in the good faith belief that such disclosure is reasonably necessary to enforce IGL’s Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of the Users or the general public.</p>
                                        <p>4.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may also disclose information about the Users if it determines that disclosure is reasonably necessary to enforce IGL terms and conditions or protect its operations or Users. Additionally, in the event of a reorganization, merger, or sale IGL may transfer any or all Personal Information to the relevant third party.</p>
                                        <p>4.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL and its affiliates may share / sell some or all of the User’s Personal Information with another business entity should IGL (or IGL assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur that other business entity (or the new combined entity) would be required to follow this Privacy Policy with respect to the User’s Personal Information and/or Gamer Information.</p>
                                        <p>&nbsp;</p>
                                        <p>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Cookies and Other Technologies</strong></p>
                                        <p>5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When Users register themselves on the Portal, information in the form of a very small bit of code is sent by IGL’s API to User’s device. This code is unique to each User. It enables seamless travel across the Portal, allowing User to use the Portal without having to enter its password frequently or fill out registration forms with information User has already provided.</p>
                                        <p>5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL uses cookies on certain pages of the Portal to help analyse the Portal web page flow, deliver ads that relate to User’s interests, measure the effectiveness of ad campaigns and promote trust and safety. IGL offers certain features that are only available through the use of a “cookie”. Cookies can also help IGL to provide information that is targeted to the User’s interests. Most cookies are “session cookies”, meaning that they are automatically deleted from the User’s hard drive at the end of a session. Users are always free to decline IGL’s cookies by disabling cookies in their internet browser, although in that case the User may not be able to use certain features on the Portal and the User may be required to re-enter its password more frequently during a session. If the User chooses to avail IGL’s Services on the Portal, IGL collects information about User behaviour.</p>
                                        <p>5.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Additionally, the User may encounter "cookies" or other similar devices on certain pages of the Portal that are placed by third parties. IGL does not control the use of cookies by third parties.</p>
                                        <p>&nbsp;</p>
                                        <p>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Access to User Information</strong></p>
                                        <p>6.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL provides Users with the means to ensure that their User Details are correct and current. Users may review and update this information at any time by logging into their Account. Users can also change their passwords and other details related to their profile by logging into their account. At the time of updating User Details, IGL may ask Users to verify User identity before IGL can act on User’s request.</p>
                                        <p>6.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL may reject requests that are unreasonably repetitive, require disproportionate technical effort (for example, developing a new system or fundamentally changing an existing practice), risk the privacy of others, or would be extremely impractical (for instance, requests concerning information residing on backup systems).</p>
                                        <p>6.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL shall provide information access and correction for free. IGL aims to maintain Services in a manner that protects information from accidental or malicious destruction. Therefore, after User deletes its Account from the Website, IGL may not immediately delete residual copies from active servers and may not remove information from the backup systems.</p>
                                        <p>&nbsp;</p>
                                        <p>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Updating Information</strong></p>
                                        <p>7.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; As a registered User, User’s Personal Information can be viewed and edited through User’s Account which is protected by a Password.</p>
                                        <p>7.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL takes reasonable steps to keep User’s Personal Information accurate and complete. If User has created an Account on the Website, the User can update their Personal Information after they log into the Account. User can also contact IGL at the e-mail or postal address listed in the "<a href="https://www.logisticskart.com/cms.aspx?pageid=25">Contact Us</a>" section.</p>
                                        <p>&nbsp;</p>
                                        <p>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Protection of Personal Information </strong></p>
                                        <p>8.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL has taken adequate measures to protect the security of User’s Personal Information and to ensure that User’s choices for its intended use are honored. IGL takes robust precautions to protect User data from loss, misuse, unauthorized access or disclosure, alteration, or destruction.</p>
                                        <p>8.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL recommends that Users should not divulge their Password to anyone.</p>
                                        <p>8.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL personnel will never ask Users for their Password via any type of communication. If User shares a computer with others, the User should not save log-in information (e.g., User ID and Password) on the computer.</p>
                                        <p>8.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The User should remember to sign out of the Account and close browser window when the User has finished the session. </p>
                                        <p>8.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User’s Personal Information is never shared outside IGL without User permission, except under conditions explained above. Inside the company, data is stored in password-controlled servers with limited access to employees and other personnel or consultants on a need-to-know basis.</p>
                                        <p>8.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No data transmission over the Internet or any wireless network can be guaranteed to be perfectly secured. As a result, while IGL tries to protect User’s information, no website or company, including IGL, can absolutely ensure or guarantee the security of any information User transmit to the Website and the User does so at User’s own risk. </p>
                                        <p>8.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When IGL receives formal written complaints, IGL shall contact the complainant to follow up on such complaints. IGL shall work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of Personal Information that cannot be resolved with such persons directly.</p>
                                        <p>&nbsp;</p>
                                        <p>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Compliance with GDPR</strong></p>
                                        <p>9.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL recognizes that protection of data is increasingly important to individuals, organizations and stakeholders.&nbsp; Accordingly, IGL must earn the User’s trust for User to share its Personal Information with IGL. IGL currently processes and manages Personal Information in accordance with Applicable Laws, including but not limited to applicable privacy laws.</p>
                                        <p>9.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL has already embedded and will continue to embed data protection principles even more deeply into its business processes, with the objective that technical and organizational security measures limit, by default, the amount and use of Personal Information to what is specifically required.</p>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                        <p>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Minor’s use of the Portal and/or the Services</strong></p>
                                        <p>10.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Website and its contents are not intended to be targeted to minors under applicable law.However, IGL has no way of distinguishing the age of individuals who access the Website and so it carries out the same Privacy Policy for individuals of all ages.</p>
                                        <p>10.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If a minor has provided IGL with personal information without parental or guardian consent, the parent or guardian should contact IGLat <em>support@iglnetwork.com</em>.</p>
                                        <p>&nbsp;</p>
                                        <p>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Consent</strong></p>
                                        <p>11.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The User acknowledges and agrees to the collection, retention and use of the information User discloses on the Portal in accordance with this Privacy Policy, including but not limited to User’s consent for sharing User information as per this Privacy Policy.</p>
                                        <p>11.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User also acknowledges and agrees that with respect to certain activities, the collection, transfer, storage, and processing of User information may be undertaken by trusted third party vendors or agents of IGL such as credit card processors, web hosting providers, communication services, and web analytic providers, to help facilitate IGL in providing certain functions.</p>
                                        <p>11.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When a User uses the "<em>Refer Your Friends Feature</em>”, IGL relies on that individual to obtain the consent of their friend to allow us to use that information to invite them to create a user account.</p>
                                        <p>11.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unless stated otherwise, the Privacy Policy applies to all information that the IGL has about the User and User’s Account.&nbsp;</p>
                                        <p>&nbsp;</p>
                                        <p>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Opt-in and Opt-out</strong></p>
                                        <p>12.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; From time to time, IGL may communicate with Users who subscribe to its Services via email or text message. IGL provides to the Users the opportunity to exercise an opt-in choice if they do not want to receive non-essential communication from IGL, such as emails or updates from IGL regarding new services offered on the Portal or if Users do not want IGL to share their Personal Information with third parties. IGL will process User’s subscription request as soon as possible.</p>
                                        <p>12.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Upon subscription, IGL also provides User’s with the opportunity to exercise an opt-out choice if they no longer want to receive non-essential communication from IGL. The opt-out choice may be exercised by contacting IGL at <em>support@iglnetwork.com</em>.IGL will process User’s unsubscribe request as soon as possible and in some circumstances, Users may receive a few more emails until the unsubscribe request is processed.</p>
                                        <p>12.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If the Users want to remove their contact information from the Portal or withdraw all consents given by them to IGL, they can do so by writing to <em>support@iglnetwork.com</em>.</p>
                                        <p>&nbsp;</p>
                                        <p>13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Limitation of Liability</strong></p>
                                        <p>13.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IGL is not responsible for any breach of security or for any actions of any third parties that receive the User’s Information.</p>
                                        <p>13.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Notwithstanding anything contained in this Privacy Policy or elsewhere, IGL shall not be held responsible for any loss, damage or misuse of the User Personal Information, if such loss, damage or misuse is attributable to a Force Majeure Event (as defined below).</p>
                                        <p>13.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A “<strong>Force Majeure Event</strong>” shall mean any event that is beyond the reasonable control of IGL and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes, lockouts or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, civil disturbances, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, and any other similar events not within the control of IGL and which IGL is not able to overcome.</p>
                                        <p>&nbsp;</p>
                                        <p>14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Grievance Department</strong></p>
                                        <p>In accordance with Information Technology Act 2000 and rules made there under, the contact details of the Grievance Department are provided below:</p>
                                        <p>Email: <em>support@iglnetwork.com</em></p>
                                        <p>Timings: 10.00AM – 5.00PM (Monday – Friday)</p>
                                        <p>&nbsp;</p>
                                        <p>15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Severability</strong></p>
                                        <p>If any part of these Policy shall be held or declared to be invalid or unenforceable for any reason by any court of competent jurisdiction, such provision shall be ineffective but shall not affect any other part of these Policy, and in such event, such provision shall be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law or applicable court decisions.</p>
                                        <p>&nbsp;</p>
                                        <p>16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Changes to the Privacy Policy</strong></p>
                                        <p>This Privacy Policy is subject to change, and any changes to this Privacy Policy will be posted on the Portal so that the Users are always aware of what information IGL collects, how IGL uses it, and under what circumstances IGL discloses it. All information submitted through the Portal to IGL is subject to the terms and conditions of this Privacy Policy, as amended.</p>                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
