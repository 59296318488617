import React from 'react'
import { Link } from 'react-router-dom';
interface Props{
    label: string;
    path?: string;
    onClick?: React.ChangeEvent<HTMLAnchorElement> | any;
}
export const Button = (props: Props) => {
    const {label, path, onClick} = props;
    return (
        <div className="section">
            <Link to={path || "#"} className="mt0 btn-main bg-trans" onClick={onClick}>{label}</Link>
        </div>
    )
}
