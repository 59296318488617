
import api from "../../core/api";
import { TeamvotinglistResp, ISelectTeam} from "../model/teamvoting";
import { R } from "../pkg/types";

export class TeamVoting {
    async getTeams(args?:string): Promise<R<TeamvotinglistResp[]>> {
        try {
            
            const resp = await api.get(`/teamvotinglist?userid=${args==="0"? "''": args}&type=all`,{
                headers:{
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            });
            const list = resp.data.Votelists as TeamvotinglistResp[];
            if (!list) throw resp.data.msg
            return new R({result: list});
        }
        catch (e: any) {
            return new R({error: e.response.data.msg});
        }
    } 
     async SelectTeam(payload: ISelectTeam): Promise<R<string>> {
         try {
             const resp = await api.post(`/vote_insert`
                 , payload
                 , {
                    headers:{
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    }
                });
                // console.log(resp)
             const result = resp.data;
             if (!result) throw resp.data.msg
             return new R({ result: result});
         }
         catch (e: any) {
             return new R({ error: e.response.data.msg })
         }
    }
}

export const teamVotingRepository = new TeamVoting();