import React from 'react'
import { observer } from 'mobx-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { removeOrdinalSuffix } from '../../core/config';
import ScrimSticker from '../../assets/images/itc-bingo/scrims-sticker.png'
import { Image } from '../../_common/widget/Image';
import BingoIPIMAGEGreen from '../../assets/images/itc-bingo/New Gameon Logo Green.png'
import BingoIPIMAGEPurple from '../../assets/images/itc-bingo/New Gameon Logo Purple.png'
interface Props {
    gameType?: number;
    imgUrl?: string;
    detailId?: string | number;
    title?: string;
    date?: string;
    coins?: string | number;
    platform?: string;
    players?: string;
    btnLabel?: string;
    gameName?: string;
    time?: string;
    teamsize?:string;
    from_campaign?:string;
    gameId?:string;
};
const MegaTournamentCardMobile = observer((props:Props) => {
    const { imgUrl, detailId, title, date, coins, platform, players, btnLabel, gameName, gameType, time,teamsize, from_campaign, gameId } = props;
    const detail_endpoint = gameType == 1 ? 'details' : 'brdetails';
    let path =`/tournament/${detail_endpoint}/${detailId}`;
    if(from_campaign){
        path= path+'?from_campaign='+from_campaign  
    }
    return (
        <div className="itc-TournamentCard-mobile h-100">
            <div className='d-flex flex-column flex-grow-1 flex-column h-100'>
                <div className="flex-shrink-0">
                    <Link className="position-relative w-100 d-block" to={path}>
                        {/* <img src={imgUrl} alt={gameName} height="150" className='bg-img'/> */}
                        <Image src={imgUrl!} onErrorStyle={{objectFit:"contain"}} onErrorImg={(gameId ==='69'|| gameId ==='76')?BingoIPIMAGEGreen : (gameId ==='72'|| gameId ==='40')?BingoIPIMAGEPurple :""} alt={gameName??""} />
                        {Number(coins)===0 && <div className='scrims-sticker-new'><img src={ScrimSticker} alt="..." /></div>}
                    </Link>
                </div>
                <div className=" flex-grow-1 d-flex flex-column justify-content-between px-1 py-4">
                    <Link to={path} className='font-Urbanist text-white text-center'>{title}</Link>
                   
                    <ul className="row align-items-center">
                    <li className="col-12">
                            <span className='font-Urbanist body-text color-itc pe-2'>Next Match : <br/></span>
                            {detail_endpoint === 'brdetails'? `${moment(date, 'MMM Do, YYYY').format('MMM D, YYYY')}` : removeOrdinalSuffix(date!)} {detail_endpoint === 'brdetails'? `${moment(time, ["h:mm:ss A"]).format("HH:mm")}` : `${moment(time, ["HH.mm.ss"]).format("HH:mm")}`}
                        </li>
                        <li className="col-12">
                            <span className='font-Urbanist body-text color-itc pe-2'>Platform : </span>
                            {platform==="PLAYSTATION4"?"PS4":platform==="PLAYSTATION5"?"PS5":platform || "NA"}
                        </li>
                        <li className="col-12">
                            <span className='font-Urbanist body-text color-itc pe-2'>Team Size : </span>
                            {teamsize || "NA"}
                        </li>
                        <li className="col-12">
                            <span className='font-Urbanist body-text color-itc pe-2'>Players : </span>
                            {players || "NA"}
                        </li>
                        <li className="col-12">
                            <span className='font-Urbanist body-text color-itc pe-2'>Price Pool : </span>
                            {coins || "0"} IGL COINS
                        </li>

                    </ul>                  
                        <div className=" w-100">
                            <Link to={path} className="btn text-white w-100 text-center text-center">{btnLabel} <i className="fa-solid fa-angles-right"></i></Link>
                        </div>
                </div>
            </div>
        </div>
    )
})

export default MegaTournamentCardMobile