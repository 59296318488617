import { observer } from 'mobx-react'
import React from 'react'
import { useAuthStore } from '../../auth/store/auth';
import { useTeamStore } from '../store/team'
import TeamSection from '../widget/TeamSection';
import { useParams } from 'react-router-dom';
const MyTeams = observer(() => {
    const {user}=useAuthStore()
    const {myTeam, my_teams,my_team_loading} = useTeamStore()
    const {userid} = useParams();
    React.useEffect(()=>{
        let id = user?.id!;
        if(userid){
            id = userid;
        }
        myTeam(id)
    },[userid])
    
    return (
        <TeamSection data={my_teams!} isLoading={my_team_loading}/>
    )
});

export default MyTeams