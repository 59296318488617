import React from 'react'
import { observer } from 'mobx-react'
import { useTeamStore } from '../store/team'
import { FieldProps } from '../../_common/model/field'
import { Field, useField } from 'formik'
// import { FieldError } from '../../_common/widget/FieldError' // wasn't working
import * as Yup from 'yup';

export const PlatformDropdown = observer((props: FieldProps) => {
    const { name = "platform", disabled, value, required } = props;

    const { platforms, platform } = useTeamStore()

    const [field, meta, helpers] = useField({ name });

    React.useEffect(() => {

        (async () => {
            await platform();
        })();
        if(value == undefined){
            return
        }
        helpers.setValue(value)
    }, [])

    const validate = async (value: any) => {
        var schema = Yup.string();

        let sch = required
            ? schema
                .required('Please select Platform')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }

    return (
        <div className="form-grop">
            <label htmlFor={name} className={`${required ? "required-field" : ""}`}>Gaming platform</label>
            <p>Choose a gaming platform your team is on</p>
            <Field component="select" className="form-control" name={name} disabled={disabled} validate={validate} style={disabled ? {background: "#b1afaf"} : {}}>
                <option value="">Select Platform</option>
                {platforms?.map(option => (
                    <option key={option.PlatformID} value={option.PlatformID}>{option.PlatformName}</option>
                ))}
            </Field>
            {/* <FieldError name={name} /> */}
            <p className="text-danger small text-start">{meta.error}</p>
        </div>
    )
})