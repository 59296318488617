import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
// import { ITournamentsRepository, tournamentsRepository } from "../../_common/repository/tournaments";
import { trendingVideo } from "../../_common/repository/trendingvideo";
import { ILatestVideos } from "../../_common/model/latestvideos";
export class TrendingStore {
    listLatestVideo?: ILatestVideos[];
    constructor() {
        makeAutoObservable(this);
    }
    newlatestvideo = async () => {
        const resp = await trendingVideo.getTrendingvideo();
        if (resp.hasError) {
            runInAction(() => {
            });
            return;
        }
        runInAction(() => {
        this.listLatestVideo = resp.result!;
      });
    }
    filterTrendingData?: ILatestVideos[];
    filterTrending =  (args:string) => {
        
        const updateTvState =  this.listLatestVideo?.filter(data => data.TVTitle.toLowerCase().includes(args.toLowerCase()));
        // console.log(updateTvState)
        runInAction(()=>{
            this.filterTrendingData = updateTvState;
        })
    }
    // alltvlist= async ()=>{
    //  return trendingVideo.getAllTrendingVideo();
    // }
}
export const TrendingStoreStoreContext = createContext(new TrendingStore());
export const useTrendingStore = () => useContext(TrendingStoreStoreContext);