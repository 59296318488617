import React, { useEffect, useState, Suspense } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik';
import { TOURNAMENT_TYPE } from '../../core/config';
import { CarouselSection } from '../../_common/widget/CarouselSection'
import { TournamentTabSection } from '../widget/TournamentTabSection';
import { MyTournaments } from './MyTournaments';
import { UpcomingTournaments } from './UpcomingTournaments';
import { useAuthStore } from '../../auth/store/auth';
import { useProfileStore } from '../../profile/store/profileInfo';
import { observer } from 'mobx-react';
// import { useUpcomingTournamentStore } from '../store/upcomingtournaments';
import { PlatformDropdown } from '../../_common/widget/PlatformDrodown';
import { GameDropdown } from '../../_common/widget/GameDropdown';
import { TeamSizeDropdown } from '../../_common/widget/TeamSizeDropdown';
import { PrizePoolDropdown } from '../../_common/widget/PrizePoolDropdown';
import { SortByDropDown } from '../../_common/widget/SortByDropdown';
import { AvailabilityDropdown } from '../../_common/widget/AvailabilityDropdown';
// import { useMyTournamentsStore } from '../store/mytournaments';
import { useTournamentsStore } from '../store/tournaments';
import { ITournamentParams } from '../../_common/model/tournament';
import { TournamentTypeDropdown } from '../../_common/widget/TournamentTypeDropdown';
// import AdSlot from '../../_common/view/AdsComponent/AD_Index';


export const TournamentsPage = () => {
    const { user } = useAuthStore();
    const { loadProfileInfo } = useProfileStore();
    useEffect(() => {
        if (user?.id) {
            loadProfileInfo(user.id);
        }
    }, [])
    return (
        <>
            <CarouselSection />
            <TournamentSection />
        </>
    )
};

const ActiveTournaments = () => {
    const { type } = useParams();
    let tournament;
    switch (type) {
        case TOURNAMENT_TYPE.my_tournament:
            tournament = <MyTournaments />;
            break;
        case TOURNAMENT_TYPE.upcoming:
            tournament = <UpcomingTournaments />;
            break;
        default:
            tournament = <></>;
    }
    return (
        <div className="tab-content tournaments-tabpane-raw">
            <div className="tab-pane active" role="tabpanel">
                {tournament}
            </div>
        </div>
    )
};

const TournamentSection = () => {
    return (
        <div className="common-page-raw tournaments-raw game-lists-raw section clear-both pt50">
            <div className="container">
                <TournamentFilters />
                <TournamentAdsContainer/>
                <TournamentTabSection />
                <ActiveTournaments />
            </div>
        </div>
    )
};

const TournamentAdsContainer=()=>{
    const AdSlot = React.lazy(()=> import('../../_common/view/AdsComponent/AD_Index'));
    const [isMobile, setIsMobile] = useState<boolean>(false);
    useEffect(() => {
    // Check the window width and set isMobile accordingly
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as per your requirement
    };

    handleResize(); // Call once on initial load

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
    return (
    <div className="row">
            <div className="col-12">
            {!isMobile && <Suspense fallback={<></>}>
                                <AdSlot adUnitPath="/317733190/IGL_Web_Tournaments_728x90_Ad1" adSize={[728, 90]} />
                            </Suspense>}
                            {isMobile && <Suspense fallback={<></>}>
                                <AdSlot adUnitPath="/317733190/IGL_Web_Tournaments_300x250_Ad1" adSize={[300, 250]} />
                            </Suspense>}
            {/* {!isMobile && <AdSlot adUnitPath="/317733190/IGL_Web_Tournaments_728x90_Ad1" adSize={[728, 90]} />}
            {isMobile && <AdSlot adUnitPath="/317733190/IGL_Web_Tournaments_300x250_Ad1" adSize={[300, 250]} />} */}
            </div>
      </div>
    )
    
}

const TournamentFilters = observer(() => {

    const { type } = useParams();
    const { user } = useAuthStore();
    const { setTournamentFilters, tournament_filters } = useTournamentsStore();
    // const { loadUpcomingTournaments } = useUpcomingTournamentStore()
    // const { loadTournament } = useMyTournamentsStore();
    const initialState = {
        teamsize: "",
        availability: "",
        game_id: "",
        prizepool: "",
        platform_id: "",
        sort_type: "",
        Tournamenttype: "",
        date: ""
    };

    const handleReset = () => {
        setTournamentFilters({ user_id: user?.id! });
        setToogleDate(false)
    }

    const handleSubmit = (values: any) => {
        // console.log(values)
        let filters: ITournamentParams = {
            teamsize: values.teamsize,
            availability: values.availability,
            game_id: values.game_id,
            prizepool: values.prizepool,
            platform_id: values.platform_id,
            date : values.date,
            sort_type : values.sort_type,
            Tournamenttype: values.Tournamenttype,
        }
        setTournamentFilters({user_id: user?.id!, ...filters});
    }
    
    const [toogleDate, setToogleDate] = React.useState(false);
    return <Formik
    initialValues={initialState}
    onSubmit={(values, { resetForm }) => {
        handleSubmit(values);
        // resetForm();
    }}
// validationSchema={{}}
>
    {({resetForm}) => {
        return (
            <Form>
                <div className="row pt10">
                            <div className="col-md-3 col-6 mb-4">
                                <PlatformDropdown name='platform_id' />
                            </div>
                            <div className="col-md-3 col-6 mb-4">
                                <GameDropdown name='game_id' />
                            </div>
                            <div className="col-md-3 col-6 mb-4">
                                <TeamSizeDropdown name='teamsize' />
                            </div>

                            <div className="col-md-3 col-6 mb-4">
                                <PrizePoolDropdown name='prizepool' />
                            </div>
                            <div className="col-md-3 col-6 mb-4">
                                <AvailabilityDropdown name='availability' />
                            </div>
                            <div className="col-md-3 col-6 mb-4">
                                {/* date dropdown */}
                                <SortByDropDown toogleDate={toogleDate} setToogleDate={setToogleDate} name='sort_type' />
                            </div>
                            <div className="col-md-3 col-12 mb-4">
                                <TournamentTypeDropdown name='Tournamenttype' />
                            </div>
                            <div className="col-md-3 col-12 mb-4 text-md-start text-center d-flex justify-content-between">
                            <button className='mt-0 filter-btn filter-btn-coloured' type='submit'>Apply</button>

                            <button className='mt-0 filter-btn' onClick={()=>{handleReset();resetForm()}} type='reset'>clear filter</button>
                        </div>
                        </div>
                </Form>
            )
        }}
    </Formik>
})