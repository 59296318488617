import React from 'react'
import Socialmedia from '../../_common/widget/Socialmedia';
import Password from "../widget/Password";
import Username from "../widget/Username";
import Button from '../widget/Button';
import { observer } from 'mobx-react';
import { useAuthStore } from '../store/auth';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ErrorMsg } from '../../_common/widget/ErrorMsg';
import { Link } from 'react-router-dom';

const initialState = {
  username: "",
  password: ""
}
const VALIDATION_SCHEMA = Yup.object({
  username: Yup.string().required("Please enter Username!")
  // .min(6, 'Username must be at least 6 characters')
  // .max(20, 'Username must not exceed 20 characters')
  , password: Yup.string().required("Please enter Password!"),
});
export const LoginForm = observer(() => {
  const { login, logging, loggedIn, loginError, showRegister, showReset } = useAuthStore()
  const handleSubmit = async (values: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.has('from_campaign')){
      login({email: values.username, pwd: values.password, from_campaign: queryParams.get('from_campaign')!})
      return;
    }
    login({email: values.username, pwd: values.password})
  };
  return (
    <>
      <div className="formboxmain">
        <div className="row">
          <div className="col-md-12">
            <div className="title-raw mb30">
              <h3>LOGIN</h3>
            </div>
          </div>
        </div>
        <ErrorMsg msg={loginError} />
        <Formik
          initialValues={initialState}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            // resetForm();  // No need to reset if correct data then login
          }
          }
          validationSchema={VALIDATION_SCHEMA}
        >
          {(props) => {
            return (
              <Form id="LoginForm" name="LoginForm">
                <div className="formboxmain">
                  <div className="row ">
                    <Username
                      className="col-md-12 "
                      name="username"
                      label="Username/Email"
                    />
                    <Password
                      className="col-md-12"
                      name="password"
                      label="Password"
                    />
                  </div>

                  <div className="row">
                    <div><a className="forgot-pass-col" 
                    onClick={showReset}
                     >Forgot your password?</a></div>
                  </div>

                  <div className="button text-center">
                    <Button type='submit' label={(logging && !loggedIn) ? 'Signing In..' : 'Sign In'} />
                    <label className="already-member-col">Don’t have an account yet?<br />
                      <a onClick={showRegister}>Create your account</a></label>
                    <Socialmedia />
                    <ul className="terms-and-policy-col">
                      <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                      <li><Link to="/service-terms">Terms of Use</Link></li>
                    </ul>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
});