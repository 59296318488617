import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { BattleRoyaleTeam } from "../../_common/model/team";
import {
  battleRoyaleRepository,
  BattleRoyaleRepository,
} from "../../_common/repository/battleroyale";

export class BattleRoyaleCheckedInTeamStore {
  checkedin_teams?: BattleRoyaleTeam[];
  checkedin_teams_error = "";
  checkedin_teams_loading = false;
  // loader
  showMore: boolean = true;
  current_page: number = 1;

  private joinedTeamsAPI: BattleRoyaleRepository;

  constructor(args: { joinedTeamsAPI: BattleRoyaleRepository }) {
    makeAutoObservable(this);
    this.joinedTeamsAPI = args.joinedTeamsAPI;
  }

  loadCheckedInTeams = async (id: string, userId: string) => {
    runInAction(() => {
      this.checkedin_teams_loading = true;
    });
    const resp = await this.joinedTeamsAPI.getBattleRoyaleCheckedInTeams(
      id,
      userId,
      this.current_page
    );

    if (resp.hasError) {
      runInAction(() => {
        this.checkedin_teams_error = resp.error!;
        this.checkedin_teams_loading = false;
      });
      return;
    }

    runInAction(() => {
      if(resp.result!.length===10){
        this.showMore = true;
      }
      else{
        this.showMore = false;
      }
      if (this.checkedin_teams?.length! > 0) {
        this.checkedin_teams = [...this.checkedin_teams!, ...resp.result!];
        this.checkedin_teams_loading = false;
        return;
      }
      this.checkedin_teams = resp.result!;
      this.checkedin_teams_loading = false;
    });
  };
  loadMoreTeams = (id: string, user_id: string) => {
    this.current_page = this.current_page + 1;
    this.loadCheckedInTeams(id, user_id);
  };
  resetPageCount = () => {
    this.current_page = 1;
    this.checkedin_teams = [];
  };
}

export const BattleRoyaleCheckedInTeamStoreContext = createContext(
  new BattleRoyaleCheckedInTeamStore({ joinedTeamsAPI: battleRoyaleRepository })
);
export const useBattleRoyaleCheckedInTeamStore = () =>
  useContext(BattleRoyaleCheckedInTeamStoreContext);
