import React from 'react'

const Socialmedia = () => {
    return (
        <ul className="social-list-raw">
            <li>
                <a target="_blank" rel="noopener" href="https://www.facebook.com/IGLnetwork/"><i className="fab fa-facebook-f"></i></a>
            </li>
            <li>
                <a target="_blank" rel="noopener" href="https://twitter.com/IGLeSports"><i className="fab fa-twitter"></i></a>
            </li>
            <li>
                <a target="_blank" rel="noopener" href="https://www.instagram.com/iglnetwork/"><i className="fab fa-instagram"></i></a>
            </li>
            <li>
                <a target="_blank" rel="noopener" href="https://www.youtube.com/channel/UCWRkKcQwMsfKfVhl8UEg70g"><i className="fab fa-youtube"></i></a>
            </li>
            <li>
                <a target="_blank" rel="noopener" href="https://discord.com/invite/iglnetwork"><i className="fa-brands fa-discord"></i></a>

            </li>
        </ul>
    )
}

export default Socialmedia