import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { TournamentTeam } from "../../_common/model/team";
import { ITournamentsRepository, tournamentsRepository } from "../../_common/repository/tournaments";

export class TournamentCheckedInTeamStore {

    checkedin_teams?: TournamentTeam[];
    checkedin_teams_error = "";
    checkedin_teams_loading = false;
    showMore:boolean=false;
    current_page:number=1;
    private joinedTeamsAPI: ITournamentsRepository;

    constructor(args: { joinedTeamsAPI: ITournamentsRepository }) {
        makeAutoObservable(this);
        this.joinedTeamsAPI = args.joinedTeamsAPI;
    }
    loadCheckedInTeams = async (id: string, userId: string) => {
        runInAction(() => {
            this.checkedin_teams_loading = true;
        });
        const resp = await this.joinedTeamsAPI.getTournamentCheckedInTeams(id, userId, this.current_page);

        if (resp.hasError) {
            runInAction(() => {
                this.checkedin_teams_error = resp.error!;
                this.checkedin_teams_loading = false;
            });
            return;
        }
        runInAction(() => {
          if(resp.result!.length===10){
            this.showMore = true;
          }
          else{
            this.showMore = false;
          }
            if (this.checkedin_teams?.length! > 0) {
                this.checkedin_teams = [...this.checkedin_teams!, ...resp.result!];
                this.checkedin_teams_loading = false;
                return;
              }
              this.checkedin_teams = resp.result!;
              this.checkedin_teams_loading = false;
        });
    };
    
  resetPageCount = () => {
    this.current_page = 1;
    this.checkedin_teams = [];
  };
  loadMoreTeams = (id:string,user_id:string) => {
    this.current_page=this.current_page+1;
    this.loadCheckedInTeams(id, user_id);
  }
}

export const TournamentCheckedInTeamStoreContext = createContext(new TournamentCheckedInTeamStore({ joinedTeamsAPI: tournamentsRepository }));
export const useTournamentCheckedInTeamStore = () => useContext(TournamentCheckedInTeamStoreContext);
