import { observer } from 'mobx-react';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { useAuthStore } from '../../auth/store/auth';
import { IModalProps } from '../model/props.interface';
import { TournamentProps } from '../model/tournament';
import { useVerificationStore } from '../store/verification';
import { VerificationField } from './VerificationField';
import { useParams } from 'react-router-dom';
import { ErrorMsg } from './ErrorMsg';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

type Props = IModalProps & TournamentProps;

export const Verification = observer((props: Props) => {
  const { id } = useParams();
  const { show, onClose } = props;
  const { user } = useAuthStore();
  const { verify } = useVerificationStore();
  const [error, setError] = React.useState("")

  const handleSubmit = async (values: any) => {
    const resp = await verify({
      user_id: user?.id!,
      tournament_type: props.gameType === 1 ? "1" : "0"
      , code: values.code,
      tournament_id: id
    })
    if (resp.error) {
      setError(resp.error)
      return
    }
    onClose();
  }

  return (
    <>
      <Modal show={show} className="modal-raw modal-border-raw ">
        <button type="button" className="btn-close-modal" onClick={onClose}>&times;</button>
        <Modal.Body>
          <div className="row text-center">
            <div className="col-12">
              <h4 className='mb-5'>Verification Code</h4>
              <Formik
                initialValues={{
                  code: ""
                }}
                validationSchema={Yup.object({
                  code: Yup.string().required("Please enter code!")
                })}
                onSubmit={(values)=>{
                  handleSubmit(values)
                }}
              >
                {(props) => {
                  return (
                    <Form>
                      <VerificationField
                        name='code'
                        label="Enter Tournament's Private Code"
                        isFloating
                        fullWidth={true}
                      />
                      <ErrorMsg msg={error} />
                      <button type='submit' className="btn-main btn-sqr-gradient mt-0">
                        Submit
                      </button>
                    </Form>)
                }
                }
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
});