import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { ITournamentPayload } from "../../_common/model/tournament";
import { ITournamentsRepository, tournamentsRepository } from "../../_common/repository/tournaments";

export class JoinTournamenStore {
  
  join_tournament = "";
  join_tournament_error = "";
  join_tournament_errornew = "";
  join_tournament_loading = false;
  
  private joinAPI: ITournamentsRepository;

  constructor(args: { joinAPI: ITournamentsRepository}) {
    makeAutoObservable(this);
    this.joinAPI = args.joinAPI;
  }

  setjoin_tournament_errornew =(e:string) => {
    runInAction(() => {
      this.join_tournament_errornew = e
    })
  }

  joinTournament = async (payload: ITournamentPayload) => {
    runInAction(() => {
      this.join_tournament_loading = true;
      this.join_tournament_errornew = "";
      
    });
    const resp = await this.joinAPI.joinTournament(payload);

    if (resp.hasError) {
      runInAction(() => {
        // console.log(resp.error?.response?.data?.msg!)
        
        this.join_tournament_error = resp.error!;
        this.join_tournament_errornew = resp.error?.response?.data?.msg!;
        this.join_tournament_loading = false;
      });
      return ;
    }
    runInAction(() => {
      this.join_tournament_errornew = "";
      this.join_tournament = resp.result!;
      this.join_tournament_loading = false;
    });
    return resp.result;
  };
}

export const JoinTournamenStoreContext = createContext(new JoinTournamenStore({ joinAPI: tournamentsRepository}));
export const useJoinTournamenStore = () => useContext(JoinTournamenStoreContext);
