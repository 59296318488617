import React from 'react'
import { TournamentProps } from '../../_common/model/tournament';
import { observer } from 'mobx-react';
import moment from 'moment';
import { GAME_TYPE } from '../../core/config';

export const GameFullDetails = observer((props: TournamentProps) => {
    const {
        heading,
        level,
        platform,
        game,
        size,
        date,
        startTime,
        expirationDate,
        expirationTime,
        // timeZone,
        timeZone,
        entryFee,
        prize,
        prize1,
        prize2,
        prize3,
        prize4,
        levelId,
        detailType,
        gameType,
        winnerPoints,
        runnerup1stPoint,
        runnerup2ndPoints,
        runnerup3ndPoints,
        runnerup4ndPoints,
        checkInCloseTime,
        IGLTournamentCoins,
        roundTime
    } = props;
    return (

        <div className="leader-list-raw section line-arrow">
            <div className="leader-list-col section text-center pdlr10 mt0">
                <div className="table-responsive">
                    <table className="first-col">
                        <tbody>
                            <tr>
                                <th><span>{detailType} Overview</span></th>
                            </tr>
                        </tbody>
                    </table>
                    <table className="second-table">
                        <tbody>
                            <tr>
                                <td>{detailType} Name</td>
                                <td className="desh">&nbsp;</td>
                                <td>{heading}</td>
                            </tr>
                            <tr>
                                <td>{detailType} Level</td>
                                <td className="desh">&nbsp;</td>
                                <td>{level || "NA"}</td>
                            </tr>
                            <tr>
                                <td>{detailType} Platform</td>
                                <td className="desh">&nbsp;</td>
                                <td>{platform || "NA"}</td>
                            </tr>
                            <tr>
                                <td>{detailType} Game</td>
                                <td className="desh">&nbsp;</td>
                                <td>{game || "NA"}</td>
                            </tr>
                            <tr>
                                <td>{detailType} Team Size</td>
                                <td className="desh">&nbsp;</td>
                                <td>{size || "NA"}</td>
                            </tr>
                            <tr>
                                <td>Round Time</td>
                                <td className="desh">&nbsp;</td>
                                <td>{`${roundTime || 0} Mins`}</td>
                            </tr>
                            <tr>
                                <td>Submission of results</td>
                                <td className="desh">&nbsp;</td>
                                <td>Before round time expires</td>
                            </tr>
                            <tr>
                                <td>{detailType} Start Date & Time</td>
                                <td className="desh">&nbsp;</td>
                                {/* <td>{date} {detailType==="Battle Royale"?`${moment(startTime,"h:mm:ss A").format("HH:mm:ss")}`:startTime}</td> */}
                                <td>{detailType === "Battle Royale" ? moment( date!, 'MMM D, YYYY').format('D MMM, YYYY') : moment( date!, 'Do MMM, YYYY').format('D MMM, YYYY')} {`${moment(startTime, "h:mm:ss A").format("HH:mm")}`}</td>
                            </tr>
                            <tr>
                                <td>{detailType} Expiration Date & Time</td>
                                <td className="desh">&nbsp;</td>
                                {/* <td>{expirationDate} {detailType==="Battle Royale"?moment(expirationTime,"h:mm:ss A").format("HH:mm:ss"):expirationTime}</td> */}
                               
                                <td>{detailType === "Battle Royale" ? moment( expirationDate!, 'MMM D, YYYY').format('D MMM, YYYY') : moment( expirationDate!, 'Do MMM, YYYY').format('D MMM, YYYY')} { moment(expirationTime, "h:mm:ss A").format("HH:mm")}</td>
                            </tr>
                            <tr>
                                <td>{detailType} Time-Zone</td>
                                <td className="desh">&nbsp;</td>
                                <td>{timeZone || 'Asia/Kolkata'}</td>
                            </tr>
                            <tr>
                                <td>Entry Fee</td>
                                <td className="desh">&nbsp;</td>
                                <td>{entryFee || '0'} IGL Coins</td>
                            </tr>
                            {/* <tr>
                                <td>{gameType === GAME_TYPE.tournament ? "Winner Prize" : "Prize Pool"}</td>
                                <td className="desh">&nbsp;</td>
                                <td>{prize !== undefined ? prize == "0" ? "Scrims" : `${prize} IGL Coins` : "0"} </td>
                            </tr> */}
                            <tr>
                                <td>Prize Pool</td>
                                <td className="desh">&nbsp;</td>
                                <td>{prize !== undefined ? `${prize} IGL Coins` : "0"} </td>
                            </tr>
                            {Number(prize1) > 0 &&
                                <tr>
                                    <td>Winner Prize</td>
                                    <td className="desh">&nbsp;</td>
                                    <td>{prize1 !== undefined ?  `${prize1} IGL Coins` : "0"} </td>
                                </tr>}
                            {Number(prize2) > 0 && <tr>
                                <td>1st Runnerup Prize</td>
                                <td className="desh">&nbsp;</td>
                                <td>{prize2 !== undefined ? `${prize2} IGL Coins` : "0"} </td>
                            </tr>}
                            {Number(prize3) > 0 && <tr>
                                <td>2nd Runnerup Prize</td>
                                <td className="desh">&nbsp;</td>
                                <td>{prize3 !== undefined ? `${prize3} IGL Coins` : "0"} </td>
                            </tr>}
                            {Number(prize4) > 0 && <tr>
                                <td>3rd Runnerup Prize</td>
                                <td className="desh">&nbsp;</td>
                                <td>{prize4 !== undefined ? `${prize4} IGL Coins` : "0"} </td>
                            </tr>}
                            {Number(winnerPoints) > 0 && <tr>
                                <td>Winner Points</td>
                                <td className="desh">&nbsp;</td>
                                <td>{winnerPoints} </td>
                            </tr>}
                            {Number(runnerup1stPoint) > 0 && <tr>
                                <td>1st Runnerup Points</td>
                                <td className="desh">&nbsp;</td>
                                <td>{runnerup1stPoint} </td>
                            </tr>}
                            {Number(runnerup2ndPoints) > 0 && <tr>
                                <td>2nd Runnerup Points</td>
                                <td className="desh">&nbsp;</td>
                                <td>{runnerup2ndPoints} </td>
                            </tr>}
                            {Number(runnerup3ndPoints) > 0 && <tr>
                                <td>3rd Runnerup Points</td>
                                <td className="desh">&nbsp;</td>
                                <td>{runnerup3ndPoints} </td>
                            </tr>}
                            {Number(runnerup4ndPoints) > 0 && <tr>
                                <td>4th Runnerup Points</td>
                                <td className="desh">&nbsp;</td>
                                <td>{runnerup4ndPoints} </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
})