import React, { useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik, useFormikContext } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import { FieldError } from "../_common/widget/FieldError";
import { useAuthStore } from "../auth/store/auth";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import Name from "../auth/widget/Name";
import Username from "../auth/widget/Username";
import Email from "../auth/widget/Email";
import Number from "../auth/widget/Number";
import City from "../auth/widget/City";
import Checkbox from "../auth/widget/Checkbox";
import Button from "../auth/widget/Button";
import { ErrorMsg } from "../_common/widget/ErrorMsg";
import { stringLiteral } from "@babel/types";

const initialState = {
  firstname: "",
  lastname: "",
  username: "",
  mobile: "",
  city: "",
  termcheckage: false,
};
const otpinitialState = {
  otp: "", 
};
const RequestAccountDelete = observer(() => {
  const {
    requestAccDeleteData,
    requestAccDeleteErr,
    requestAccountDelete,
    requestAccDeleteLoading,
  } = useAuthStore();
  const {
    requestAccOtpData,
    requestAccOtpError,
    requestAccOtpLoading,
    requestAccountVerifyOtp,
  } = useAuthStore();

  const handleSubmit = async (values: any) => {
    setUserName(values.username)
    await requestAccountDelete({ ...values, otpVerificationType: "mobile" });
    if (requestAccDeleteErr === "") {
      setShowOtp(true);
      return;
    }
  };
  const navigate = useNavigate();
  const {user, logout}=useAuthStore();
  const verifyOtp = async (values: any) => {
    await requestAccountVerifyOtp({ ...values,username:userName });
    if (requestAccOtpError === "" ) {
      if(user?.username === userName){
        await logout();
        navigate("/");
        return;
      }
      navigate("/");
      return;
    }
  };
 
  const ACCOUNT_DELETE_SCHEMA = Yup.object({
    firstname: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please use alphabets only!")
      .min(2, "Please enter atleast 2 characters!")
      .max(20, "First Name must not exceed 20 characters!")
      .optional(),
    lastname: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please use alphabets only!")
      .min(2, "Please enter atleast 2 characters!")
      .max(20, "Last Name must not exceed 20 characters!")
      .optional(),
    username: Yup.string().required("Please enter Username!"),
    mobile: Yup.string()
      .required("Please enter your Phone Number!")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Please enter valid Mobile Number!"
      )
      .min(10, "Please enter 10 digits Mobile Number!")
      .max(10, "Please do not enter more than 10 digits Number!"),
    city: Yup.string()
      .optional()
      .min(2, "Please enter Proper City Name")
      .max(50, "City Name must not exceed 50 characters!"),
    termcheckage: Yup.bool().oneOf([true], "Please agree to proceed!"),
  });
  const CONFIRM_OTP_SCHEMA = Yup.object({
    otp: Yup.string()
       .required("Please enter Otp!")
      .required(),
    username: Yup.string().required("Please enter Username!"),
  });
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  return (
    <div className="min-vh-100 justify-content-center align-items-center d-flex flex-column">
      {!showOtp ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mx-auto col-md-8">
              <div className="title-raw mt-5 pt-2">
                <h1>Request Account Delete</h1>
              </div>
            </div>
          </div>
          <div className="row mt-5 float-none clear-both">
            <div className="col-12 mx-auto col-md-8">
              <Formik
                initialValues={initialState}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values);
                }}
                validationSchema={ACCOUNT_DELETE_SCHEMA}
              >
                <Form autoComplete="off">
                  <div className="row">
                    <input
                      autoComplete="false"
                      name="hidden"
                      type="text"
                      style={{ display: "none" }}
                    />
                    <Username
                      name="username"
                      label="User Name"
                      isFloating={true}
                    />
                    <Number
                      name="mobile"
                      label="Mobile Number"
                      isFloating={true}
                    />
                    <Name
                      name="firstname"
                      label="First Name"
                      isFloating={true}
                    />
                    <Name name="lastname" label="Last Name" isFloating={true} />
                    <City name="city" label="City" isFloating={true} />
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="terms-raw ">
                        <Checkbox
                          name="termcheckage"
                          label="I confirm to delete my account."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <ErrorMsg msg={requestAccDeleteErr} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Button
                        type="submit"
                        label={
                          requestAccDeleteLoading ? "Submitting" : "Submit"
                        }
                      />
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mx-auto col-md-8">
              <div className="title-raw mt-5 pt-2">
                <h1>Confirm Otp Verification</h1>
              </div>
            </div>
          </div>
          <div className="row mt-5 float-none clear-both">
            <div className="col-12 mx-auto col-md-8">
              <Formik
                initialValues={otpinitialState}
                onSubmit={(values, { resetForm }) => {
                  verifyOtp(values);
                }}
                validationSchema={CONFIRM_OTP_SCHEMA}
              >
                <Form autoComplete="off">
                  <div className="row">
                    <input
                      autoComplete="false"
                      name="hidden"
                      type="text"
                      style={{ display: "none" }}
                    />
                    <div className="col-12 d-flex justify-content-center align-items-center">
                      <Number name="otp" label="OTP" isFloating={true} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Button
                        type="submit"
                        label={requestAccOtpLoading ? "Submitting" : "Submit"}
                      />
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default RequestAccountDelete;
