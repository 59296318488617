import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useGlobalSearchStore } from '../store/globalSearchFilter';

const GlobalSearch = observer(() => {
    // const [toogle, setToogle] = useState(false);
    const navigate = useNavigate();
    const { globalSearch, setSearchText, globalSearchError, page_no, resetPageCount, globalSearchText } = useGlobalSearchStore();
    const handleSubmit = async (values : {searchtext:string}) => {
        setSearchText(values.searchtext);
        // setToogle(!toogle);
        if(window.location.pathname!=='/globals/people'){
            navigate('/globals/people')
        }
        else{
            resetPageCount();
            globalSearch({searchtext:values.searchtext,PTYPE:"Users", PNO:String(page_no)})
        }
    }
    // useEffect(()=>{
    // if(globalSearchError!==""){
    //     setToogle(true);
    //     return;
    // }
    // setToogle(false);
    
    // },[globalSearchError]);
    const handleShow = () => {
        // setShow(!show)
        const element = document.getElementById("global-search-btn");
        element?.classList.toggle('d-block')
        const element2 = document.getElementById("menu-dropdown");
        element2?.classList.remove('show')
        const element3 = document.getElementById("notification-dropdown");
        element3?.classList.remove('show')
        document?.getElementById("header")?.classList?.remove('affix');
    }
    const handleHide = () => {
        // setShow(!show)
        const element = document.getElementById("global-search-btn");
        element?.classList.remove('d-block')
        const element2 = document.getElementById("menu-dropdown");
        element2?.classList.remove('show')
        const element3 = document.getElementById("notification-dropdown");
        element3?.classList.remove('show')
    }
    // useEffect(()=>{
    //     if(toogle){
    //        document?.getElementsByTagName('html')[0].classList?.add('overflow-hidden');
    //        document?.getElementById("header")?.classList?.remove('affix');
    //     }
    //     else{
    //         document?.getElementsByTagName('html')[0].classList?.remove('overflow-hidden');
    //         document?.getElementById("header")?.classList?.add('affix');
    //     }
    // },[toogle])
    const VALIDATION_SCHEMA = Yup.object({
        searchtext: Yup.string().required("Please enter Search Value")
    });
    return (
        <li className="searchbar">
            {/* <button type='button' className="search-fa bg-transparent" onClick={() => setToogle(!toogle)}> */}
            <button type='button' className="search-fa bg-transparent" onClick={() => handleShow()} 
            // onBlur={handleHide} tabIndex={-1}
             >
                <img src="https://api.iglnetwork.com/assets/images/ic-search.png" alt="searchImage" />
            </button>
                <div className={`togglesearch`}  id="global-search-btn" >
                <div className="search-game-raw section text-center">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-12">
                            <Formik onSubmit={(values, { resetForm }) => {
                                handleSubmit(values);
                                //resetForm();  // No need to reset if correct data then login
                                handleHide();
                            }}
                                enableReinitialize={true}
                                validationSchema={VALIDATION_SCHEMA}
                                initialValues={{
                                    searchtext: globalSearchText
                                }}>
                                <Form className="clearfix position-relative h-100">
                                    <Field type="text" name="searchtext" id="search" className="form-control" placeholder="Search" />
                                    <button type="submit" style={{ "outline": "-webkit-focus-ring-color auto 0px", "letterSpacing": "1px", "borderRadius": "100px", "width": "36px", "position": "absolute", "right": "4px", "top": "6px", "height": "34px", "zIndex": "1", "background": "transparent", "border": "0px", "padding": "0px", "cursor": "pointer", "display": "flex", "justifyContent": "center", "alignItems": "center" }}></button>
                                    <ErrorMessage
                                        name={'searchtext'}
                                        component="p"
                                        className="text-danger small text-start"
                                    />
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={`togglesearch ${toogle ? 'd-block':''}`}  onMouseLeave={handleHide}> */}
            
        </li>
    )
})

export default GlobalSearch