import React from 'react'
import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { useGameStore } from '../store/game';
import { ErrorMsg } from '../../_common/widget/ErrorMsg';
import { useAuthStore } from '../../auth/store/auth';
import { IModalProps } from '../../_common/model/props.interface';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
type Props = IModalProps & { id?: string }
export const AddGameModal = observer((props: Props) => {
    const initialState = {
        usergame_id: "",
    }
    const VALIDATION_SCHEMA = Yup.object({
        usergame_id: Yup.string().required("Please enter GameId")
    });
    
    const { show, onClose } = props;
    // const [usergame_id, setUserGameId] = React.useState("");
    const { user } = useAuthStore();
    const { addGameToProfile, add_game_loading, add_game_result, add_game_error } = useGameStore();
    const handleSubmit = async ( data:any ) => {
        addGameToProfile({
            user_id: user?.id,
            usergame_id: data.usergame_id,
            game_id: props.id
        })
        onClose()
    };

    return (
        <>
            <Modal show={show} className="modal-raw modal-border-raw ">
                <button type="button" className="btn-close-modal" onClick={onClose}>&times;</button>
                <Modal.Body>
                    <div className="row text-center">
                        <div className="col-sm-12 col-12">
                            <div className="title-raw downarrow mb30">
                                <h3>Add game ID<span>Add game ID</span></h3>
                            </div>
                        </div>
                        <div className="col-sm-12 col-12">
                            <ErrorMsg msg={add_game_error} />
                            <Formik
                                initialValues={initialState}
                                onSubmit={(values, { resetForm }) => {
                                    handleSubmit(values);
                                    resetForm();
                                }
                                }
                                validationSchema={VALIDATION_SCHEMA}
                            >        
                           {({ errors }) => (
                                <Form className="section">
                                    <div className="search-game-raw section">
                                        <Field type="text" placeholder="Game ID"
                                            name="usergame_id" className="form-control mb-3"
                                        />
                                        {errors?.usergame_id && <ErrorMsg msg={errors?.usergame_id} />}
                                        <button className="btn-main btn-two-line mt-0" type='submit'>Add</button>
                                    </div>
                                </Form>
                            )}
                            </Formik>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
});