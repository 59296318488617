import React from 'react'

export const ErrorMsg = (props: {
    msg: string
}) => {
    if(!props.msg) return <></>
    return (
        <div className="row mb-4">
            <div className="col-md-12 text-danger mb5">
                <div>{props.msg}</div>
            </div>
        </div>
    )
}