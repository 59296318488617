import React from "react";
import { TournamentsStore, TournamentsStoreContext } from "./store/tournaments";
import { tournamentsRepository } from "../_common/repository/tournaments";
// import { observer } from "mobx-react";
import { MyTournaments } from "./view/MyTournaments";
import Heading from "../profile/widget/Heading";
import { observer } from "mobx-react";

export const TournamentsPage = () => {
  const store = new TournamentsStore({ api: tournamentsRepository });

  return (
    <TournamentsStoreContext.Provider value={store}>
      <Tournaments />
    </TournamentsStoreContext.Provider>
  );
};
// export default TournamentsPage;
const Tournaments = observer(() => {
  return (
    <>
    <Heading label="Tournaments"/>
      <div className="tournaments-raw game-lists-raw section pt50">
        <div className="container">
          <div className="tab-content tournaments-tabpane-raw">
            {/* <UpcomingTournamentsSection /> */}
            <MyTournaments />
          </div>
        </div>
      </div>
    </>
  );
});