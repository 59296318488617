import React from 'react'
import { IGL_IMAGE_URL } from '../../core/config';
import { TournamentProps } from '../../_common/model/tournament';
import { observer } from 'mobx-react';
import moment from 'moment';

interface DateProps {
  path: string;
  title: string;
  title1: string;
  value: string;
  value1: string;
  value2: string;
  value3: string;
};

interface TimeProps {
  path: string;
  title1: string;
  value1: string;
  title2: string;
  value2: string;
  value3: string;
  value4: string;
};

const ScheduleDateItem = observer((props: DateProps) => {
  const { path, title,title1, value, value1,value2,value3 } = props;
  return (
    <div className="col-sm-6 col-lg-5 col-12 timesate-li position-relative d-flex flex-column">
      <span><img className="img-responsive" src={path} alt='Date' style={{height: "38px"}}/></span>
      <div className="box-raw section flex-grow-1">
      <div className="row">
          <div className="col-sm-6 col-12">
          <span>{title}</span>
        <h4>{value}</h4>
        <h4>{value1} </h4>
          </div>
          <div className="col-sm-6 col-12">
          <span>{title1}</span>
        <h4>{value2}</h4>
        <h4>{value3} </h4>
          </div>
      </div>
       
      </div>
    </div>
  )
});

const ScheduleTimeItem = observer((props: TimeProps) => {
  const { path, title1, value1, title2, value2, value3, value4 } = props;
  return (
    <div className="col-sm-6 col-lg-5 col-12 timesate-li position-relative d-flex flex-column">
      <span><img className="img-responsive" src={path} alt="Time" style={{height: "38px"}}/></span>
      <div className="box-raw section flex-grow-1">

        <div className="row">
          <div className="col-sm-6 col-12">
            <span>{title1}</span>
            <h4 className='fs-small'>{value3} </h4>
            <h4>{value1} </h4>
          </div>
          <div className="col-sm-6 col-12">
            <span>{title2}</span>
            <h4>{value4} </h4>
            <h4>{value2} </h4>
          </div>
        </div>
      </div>
    </div>
  )
});

export const GameSchedule = observer((props: TournamentProps) => {
  const { date, startTime, endTime, checkInTime, checkInCloseTime, detailType, startdate, enddate, checkInDate, checkInCloseDate } = props;
  const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
  return (
    <div className="time-date-col pdlr15 pb10">
      <div className="row justify-content-center">
        <ScheduleDateItem path={queryParams?require('../../assets/images/ic-calendar-line-itc.png'):`${IGL_IMAGE_URL}/ic-calendar-line.png`} 
        title="Start Date & Time" 
        value={detailType === "Battle Royale" ?moment(date!).format('D MMM, YYYY'):moment(date!,'Do MMM, YYYY').format('D MMM, YYYY')} 
        value1={moment(startTime!, "h:mm:ss A").format("HH:mm")}
        
        title1="End Date & Time" 
        value2={detailType === "Battle Royale" ? moment( enddate!, 'DD/MM/YYYY').format('D MMM, YYYY') : moment( enddate!, 'Do MMM, YYYY').format('D MMM, YYYY')}
        value3={moment(endTime!, "h:mm:ss A").format("HH:mm")}

        />
        {/* <ScheduleTimeItem path={`${IGL_IMAGE_URL}/ic-clock-line.png`} title1="Start Time:" value1={moment(startTime!, "h:mm:ss A").format("HH:mm")} title2="End Date and Time:" value2={moment(endTime!, "h:mm:ss A").format("HH:mm")}
          // value3={ moment(startdate!).format('D[th] MMM, YYYY')}
          value3={""}
          // value4={detailType === "Battle Royale" ? enddate! :moment(removeOrdinalSuffix(enddate!), 'D MMM, YYYY').format('DD/MM/YYYY')} />
          value4={detailType === "Battle Royale" ? moment( enddate!, 'DD/MM/YYYY').format('D MMM, YYYY') : moment( enddate!, 'Do MMM, YYYY').format('D MMM, YYYY')} /> */}
        <ScheduleTimeItem path={queryParams?require('../../assets/images/ic-clock-line-itc.png'):`${IGL_IMAGE_URL}/ic-clock-line.png`} title1="check-in date & time :" value1={ moment(checkInTime!, "h:mm:ss A").format("HH:mm")} title2="check-in close date & time" value2={ moment(checkInCloseTime!, "h:mm:ss A").format("HH:mm") }  
          value3={detailType === "Battle Royale" ? moment(checkInDate!).format("D MMM, YYYY") : moment(checkInDate!, ).format("D MMM, YYYY")}
          value4={detailType === "Battle Royale" ? moment(checkInCloseDate!).format("D MMM, YYYY") : moment(checkInCloseDate!).format("D MMM, YYYY")} />
      </div>
    </div>
  )
})