import { makeAutoObservable, runInAction } from "mobx"
import { createContext, useContext } from "react"
import { ITeamDetail } from "../../_common/model/teamdetails"

import { teamDetailsRepo,TeamDetailsRepo } from "../../_common/repository/teamdetails"

export class TeamDetails {
 

  teamDetailLoading = false
  teamDetails_error = ''
  teamDetailsData?:ITeamDetail={}
  constructor(args: {
    teamDetailApi: TeamDetailsRepo,
  }) {
    makeAutoObservable(this)
    this.teamDetailApi = args.teamDetailApi
  }

  teamDetailApi: TeamDetailsRepo

  loadProfileInfo = async (teamId: number | string) => {
    runInAction(() => {
      this.teamDetailLoading = true
      this.teamDetails_error = ""
    })
    const resp = await this.teamDetailApi.getTeamDetails(teamId)
    if (resp.hasError) {
      runInAction(() => {
        this.teamDetailLoading = false
        this.teamDetails_error = resp.error!
      })
      return
    }    
    runInAction(() => {
      this.teamDetailLoading = false;
      this.teamDetailsData = resp.result!;
    })
  }
}

export const TeamDetailContext = createContext(new TeamDetails({ teamDetailApi: teamDetailsRepo }));
export const useTeamDetailStore = () => useContext(TeamDetailContext);