import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { IGL_IMAGE_URL } from '../../core/config';
import { useNotificationsStore } from "../../notifications/store/notifications";
import { dateTime } from '../../core/helper';
// import trophy from "../../assets/images/trophy.png"
// import trophygrey from "../../assets/images/trophy-grey.png"
// import bell from "../../assets/images/bell.png"
import { useAuthStore } from '../../auth/store/auth';
import { useProfileStore } from '../../profile/store/profileInfo';
// import moment from 'moment';
// import { DataResponse } from '../widget/DataResponse';

const NotificationDropdown = observer(() => {
    const { notifications_list, loadNotifications, resetPage, notifications_loading, current_page, loadMore } = useNotificationsStore()!;
    const { profile_info, resetNotiCount } = useProfileStore();
    const handleShow = () => {
        // setShow(!show)
        resetNotiCount();
        const element = document.getElementById("notification-dropdown");
        if (!element?.classList.contains('show')) {
            resetPage();
            loadNotifications({
                user_id: user?.id!,
                PNO: current_page,
                timestamp: new Date().getTime()
            });
            document?.getElementById("header")?.classList?.remove('affix');
            setNotiTab(0)
        }
        element?.classList.toggle('show')
        element!.scrollTop = 0;
        const element2 = document.getElementById("menu-dropdown");
        element2?.classList.remove('show')
    }
    const { user } = useAuthStore();
    // useEffect(() => {
    //     if (current_page > 0) {
    //         loadNotifications({
    //             user_id: user?.id!,
    //             PNO: current_page,
    //             timestamp: new Date().getTime()
    //         });
    //     }
    // }, [current_page])
    // const handleViewMore = () => loadMore();
    const [notiTab, setNotiTab] = useState(0);
    return (
        <div>
            <div onClick={handleShow} className="icon cursor-pointer position-relative">
                {(profile_info?.unread_notifications !== undefined && (profile_info?.unread_notifications !== "0")) && <div className='notification-count'>{profile_info?.unread_notifications}</div>}
                <img src={`${IGL_IMAGE_URL}/ic-notification.png`} alt="user" /></div>
            <div className="dropdown-menu dropdown-list notification-card notification-list" id="notification-dropdown">
                {notifications_loading ? <p className='h-100 justify-content-center d-flex align-items-center'>Loading...</p> :
                    notifications_list === null ? <p className='h-100 justify-content-center d-flex align-items-center'>No Notifications Found</p> :
                        <>
                            {notiTab === 0 ?
                                <>
                                    <Tab data={notifications_list?.Today!}
                                        title='Today'
                                        setTab={setNotiTab}
                                        showBack={false}
                                        viewMoreFn={() => {
                                            setNotiTab(1);
                                            resetPage();
                                            loadNotifications({
                                                user_id: user?.id!,
                                                PNO: current_page,
                                                type: "Today",
                                                timestamp: new Date().getTime()
                                            });
                                        }}
                                        viewMoreLength={5}
                                    />
                                    <Tab data={notifications_list?.Yesterday!}
                                        title='Yesterday'
                                        setTab={setNotiTab}
                                        showBack={false}
                                        viewMoreFn={() => {
                                            setNotiTab(2);
                                            resetPage();
                                            loadNotifications({
                                                user_id: user?.id!,
                                                PNO: current_page,
                                                type: "Yesterday",
                                                timestamp: new Date().getTime()
                                            });
                                        }}
                                        viewMoreLength={1}
                                    />
                                    <Tab data={notifications_list['One Week Ago']!}
                                        title='This Week'
                                        setTab={setNotiTab}
                                        showBack={false}
                                        viewMoreFn={() => {
                                            setNotiTab(3);
                                            resetPage();
                                            loadNotifications({
                                                user_id: user?.id!,
                                                PNO: current_page,
                                                type: "One Week Ago",
                                                timestamp: new Date().getTime()
                                            });
                                        }}
                                        viewMoreLength={1}
                                    />
                                    <Tab data={notifications_list?.Older!}
                                        title='Older'
                                        setTab={setNotiTab}
                                        showBack={false}
                                        viewMoreFn={() => {
                                            setNotiTab(4);
                                            resetPage();
                                            loadNotifications({
                                                user_id: user?.id!,
                                                PNO: current_page,
                                                type: "Older",
                                                timestamp: new Date().getTime()
                                            });
                                        }}
                                        viewMoreLength={1}
                                    />
                                </>
                                : notiTab === 1 ?
                                    <Tab data={notifications_list?.Today!}
                                        title='Today'
                                        setTab={setNotiTab}
                                        showBack={true}
                                        viewMoreFn={() => {
                                            loadMore();
                                            loadNotifications({
                                                user_id: user?.id!,
                                                PNO:  current_page+1,
                                                type: "Today",
                                                timestamp: new Date().getTime()
                                            });
                                        }}
                                        viewMoreLength={10}
                                    />
                                    : notiTab === 2 ?
                                        <Tab data={notifications_list?.Yesterday!}
                                            title='Yesterday'
                                            setTab={setNotiTab}
                                            showBack={true}
                                            viewMoreFn={() => {
                                                loadMore();
                                                loadNotifications({
                                                    user_id: user?.id!,
                                                    PNO:  current_page+1,
                                                    type: "Yesterday",
                                                    timestamp: new Date().getTime()
                                                });
                                            }}
                                            viewMoreLength={10}
                                        />
                                        : notiTab === 3 ?
                                            <Tab data={notifications_list?.['One Week Ago']!}
                                                title='This Week'
                                                setTab={setNotiTab}
                                                showBack={true}
                                                viewMoreFn={() => {
                                                    loadMore();
                                                    loadNotifications({
                                                        user_id: user?.id!,
                                                        PNO:  current_page+1,
                                                        type: 'One Week Ago',
                                                        timestamp: new Date().getTime()
                                                    });
                                                }}
                                                viewMoreLength={10}
                                            />
                                            : notiTab === 4 ?
                                                <Tab data={notifications_list?.Older!}
                                                    title='Older'
                                                    setTab={setNotiTab}
                                                    showBack={true}
                                                    viewMoreFn={() => {
                                                        loadMore();
                                                        loadNotifications({
                                                            user_id: user?.id!,
                                                            PNO: current_page+1,
                                                            type: "Older",
                                                            timestamp: new Date().getTime()
                                                        });
                                                    }}
                                                    viewMoreLength={10}
                                                />
                                                : null}
                        </>}
            </div>
        </div>
    )
});
export default NotificationDropdown;
const Tab = observer(({ data, title, setTab, showBack, viewMoreFn, viewMoreLength }: {
    data: Array<any>,
    title: string,
    setTab: any,
    showBack: boolean,
    viewMoreFn: any
    viewMoreLength: number
}) => {
    const { loadNotifications, resetPage, current_page } = useNotificationsStore();
    const { user } = useAuthStore();
    if (!data || data?.length === 0) {
        return <></>;
    }
    return (
        <div className="main-notificationbox">
            {data && data.length > 0 ? <p className='d-flex justify-content-between'><span className='font-MonsterratRegular'>{title}</span>
                {showBack && <span className='font-MonsterratRegular cursor-pointer' onClick={() => {
                    setTab(0);
                    resetPage();
                    loadNotifications({
                        user_id: user?.id!,
                        PNO: current_page,
                        timestamp: new Date().getTime()
                    });
                }}>&larr; Back</span>}</p> : null}
            {data?.map((item, index) => (
                <NotificationCard url={item.NotificationUrl} createdAT={dateTime(item.NotificationCreatedAt)} description={item.NotificationDescription} image={item.UserProfileImage} key={index} />
            ))}
            {data && data.length % viewMoreLength === 0
                ? <p className='text-white text-center mb-3 cursor-pointer' onClick={viewMoreFn}>View More</p>
                : <></>
            }

        </div>
    )
})
const NotificationCard = observer(({ url, image, description, createdAT }: {
    url: string,
    image: string,
    description: string,
    createdAT: string,
}) => {
    return (
        <Link to={url} onClick={() => {
            const element = document.getElementById("notification-dropdown");
            element?.classList?.remove("show");
        }}>
            <div className="inner-card" >
                <div className="d-flex align-items-center">
                    <div className="trophy-image me-2">
                        <div className="image-cover">
                            <img src={image} alt="..." />
                        </div>
                    </div>
                    <p className='text-start noti-desc'>{description}</p>
                </div>
                <p className='text-start text-white mt-3 float-none noti-date'>{createdAT}</p>
            </div>
        </Link>
    )
})