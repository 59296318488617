import { observer } from 'mobx-react'
import React from 'react'
import Socialmedia from '../widget/Socialmedia'
import { useProfileStore } from '../../profile/store/profileInfo';

const TopHeader = observer(() => {
  const { profile_info } = useProfileStore()

  return (
    <div className="topbar section">
			<div className="left-side">
				<h6><span>Welcome,</span> {profile_info?.username}</h6>
				{/* <h6 className='uppercase'><span>Balance </span><strong className="uppercase">{user?.UserCredit} IGl Coins</strong></h6> */}
				<h6 className='text-end '><span>Balance </span><strong className="uppercase">{profile_info?.UserCredit} IGL Coins</strong></h6>
			</div>
			<div className="right-side">
				<Socialmedia/>
				<h6 className="uppercase">Download Now :</h6>
				<ul className="social-list-raw">
					<li>
						<a href="https://play.google.com/store/apps/details?id=com.iglgames"><i className="fab fa-google-play"></i></a>
					</li>
					<li>
						<a target="_blank" rel="noopener" href="https://apps.apple.com/us/app/igl-indian-gaming-league/id1490988972?ls=1"><i className="fab fa-apple"></i></a>
					</li>
				</ul>
			</div>
		</div>
  )
});

export default TopHeader