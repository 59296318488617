import { ErrorMessage, Field } from 'formik';
import React from 'react'

type Props = {
    name: string,
}

export const PanCardNumber = (props: Props) => {
    const { name} = props;
    return (
        <div className="form-group">
            <Field type="text" className="form-control input-a" placeholder="PanCard Number (Optional)" id={name} name={name} />
            <ErrorMessage name={name} className="text-danger" component="span" />
        </div>
    )
}