import { observer } from 'mobx-react'
import React from 'react'
import { useAuthStore } from '../../auth/store/auth'
import { useTeamStore } from '../store/team'
import TeamSection from '../widget/TeamSection'
import { useParams } from 'react-router-dom'

const OtherTeams = observer(() => {
    const { user } = useAuthStore()
    const { otherTeam, other_teams, other_team_loading } = useTeamStore()
    const {userid} = useParams();

    React.useEffect(()=>{
        let id = user?.id!;
        if(userid){
            id = userid;
        }
        otherTeam(id)
    }, [userid]);

    return (
        <TeamSection data={other_teams!} isLoading={other_team_loading}/>
    )
})

export default OtherTeams