import React from 'react'
import { FieldProps } from '../../_common/model/field'
import { Field, useFormikContext } from 'formik'
import { FieldError } from '../../_common/widget/FieldError' // wasn't working
import * as Yup from 'yup';
import { ITournamentboardParams } from '../model/leaderboard';

export const TournamentTypeDropdown = (props: FieldProps) => {
    const { name = "month", disabled, required } = props;

    // const validate = async (value: any) => {
    //     var schema = Yup.string();

    //     let sch = required
    //         ? schema
    //             .required('Please select Month')
    //         : schema;
    //     let err = await sch.validate(value).catch(err => err)
    //     if (err?.errors?.length) {
    //         return err.errors.join(" ")
    //     }
    // }
    const [toogle, setToogle] = React.useState(false);
        const { setFieldValue,values } = useFormikContext<ITournamentboardParams>();
        const data=[
            {
                title:"Battle Royale",
                value:"2"
            },
            {
                title:"Brackets",
                value:"1"
            }
        ]
    return (
        // <div className="select-dropdown">
        //     <Field component="select" name={name} disabled={disabled} validate={validate}>
        //             <option value="">Select PrizePool</option>
        //             <option value='0'>Scrims</option>
        //             <option value='lessthan100'>Less than 100</option>
        //             <option value='100'>100</option>
        //             <option value='morethan100'>More than 100</option>
        //     </Field>
        //     <FieldError name={name} />
        // </div>
        <div className="select-dropdown position-relative" onBlur={()=>{setToogle(false)}} tabIndex={7}>
        <label onClick={() => setToogle(!toogle)}>{(values.Tournamenttype===""||values.Tournamenttype === undefined) ? "Select Tournament Type" :  data!.find(item=>item.value === values.Tournamenttype)?.title }</label>
        {toogle &&
            <div className='position-absolute w-100' style={{zIndex:1}}>
                <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue("Tournamenttype","");
                       
                        setToogle(false);
                    }}>Select Tournament Type</p>
                {data?.map((option,index) => (
                    <p className='cursor-pointer font-D-DinCondensed '  key={index} onClick={() => {
                        setFieldValue("Tournamenttype",option.value);
                        setToogle(false);
                    }}>{option.title}</p>
                ))} 
            </div>
        }
        <FieldError name={name} />
        </div>
    )
}