import React, { useEffect, useState, Suspense } from 'react'
import { GameCarousel } from '../widget/GameCarousel'
import { GameListing } from './GameListing'
import { useAuthStore } from '../../auth/store/auth'
import { useProfileStore } from '../../profile/store/profileInfo'
// import AdSlot from '../../_common/view/AdsComponent/AD_Index'

export const GamePage = () => {
  const AdSlot = React.lazy(() => import('../../_common/view/AdsComponent/AD_Index'));
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    // Check the window width and set isMobile accordingly
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as per your requirement
    };

    handleResize(); // Call once on initial load

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { user } = useAuthStore();
  const { loadProfileInfo } = useProfileStore();
  useEffect(() => {
    if (user?.id) {
      loadProfileInfo(user?.id);
    }
  }, [])
  return (
    <>
      <GameCarousel />
      {!isMobile && <Suspense fallback={<></>}>
        <AdSlot adUnitPath="/317733190/IGL_Web_Games_728x90_Ad1" adSize={[728, 90]} />
      </Suspense>}
      {isMobile && <Suspense fallback={<></>}>
        <AdSlot adUnitPath="/317733190/IGL_Web_Games_300x250_Ad1" adSize={[300, 250]} />
      </Suspense>}
      <GameListing />
    </>
  )
}
