import React from 'react'
import { useAuthStore } from '../../auth/store/auth'
import TopHeader from "./TopHeader";
import { observer } from 'mobx-react';
import { IGL_IMAGE_URL, PROFILE_MENU, TOURNAMENT_TYPE } from '../../core/config';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { NotificationList } from '../../notifications/view/NotificationList';
// import NotificationDropdown from './NotificationDropdown';
import GlobalSearch from './GlobalSearch';
import Logo from '../../assets/images/logo-3d-compressed.png';
import ITCLogo from '../../assets/images/itc-bingo/New Gameon Logo Purple.png';
import NotificationDropdown from './NotificationDropdown';
const GlobalHeader = observer(() => {
    const { showLogin, showRegister, loggedIn, user, logout } = useAuthStore();
    // const [show, setShow] = React.useState(false);
    const ProfileMenu = observer(() => {
        const handleShow = () => {
            // setShow(!show)
            const element = document.getElementById("menu-dropdown");
            element?.classList.toggle('show')
            const element2 = document.getElementById("notification-dropdown");
            element2?.classList.remove('show')
        }
        const handleHide = () => {
            // setShow(!show)
            const element = document.getElementById("menu-dropdown");
            element?.classList.remove('show')
            const element2 = document.getElementById("notification-dropdown");
            element2?.classList.remove('show')
        }
        const handleLogout = () => logout()
        return (
            <div className="admin"
            //  tabIndex={1} onBlur={handleHide}
            >
                <div className="icon dropdown-toggle" onClick={handleShow}>
                    <img src="https://api.iglnetwork.com/assets/images/ic-admin.png" alt="admin" />
                </div>
                {/* {
                    show && */}
                <ul className="dropdown-menu dropdown-list " id="menu-dropdown" onClick={handleHide}
                    onMouseLeave={handleHide}
                >
                    {PROFILE_MENU.map((item, index) => (
                        <li key={index} ><NavLink end to={item.path}>{item.name}</NavLink></li>
                    ))}
                    <li onClick={handleLogout}><NavLink end to="/logout">Logout</NavLink></li>
                </ul>
                {/* } */}
            </div>
        )
    });
 /*
    const [showNotifications, setshowNotifications] = React.useState(false)
    const handleNotifications = () => {
        setshowNotifications(!showNotifications)
     
    const handleCloseNotification = () => {
        setshowNotifications(false)
    }

 */
    const [width, getWith] = React.useState(window.innerWidth);
    const setWidth = () => {
        getWith(window.innerWidth)
    }

    React.useEffect(() => {
        window.addEventListener('resize', setWidth);
        if (width >= 1120) {
            document.querySelector(".nav-toggle")?.classList.remove("active");
            document.querySelector(".nav-main")?.setAttribute("style", "width:100%")
            document.querySelector("body")?.classList.remove("nav-open");
            document.querySelector("html")?.classList.remove("nav-open");

        }
        return (() => {
            window.removeEventListener('resize', setWidth);
        })
    }, [width])
    const handleClick = () => {

        document.getElementById("menu-dropdown")?.classList.remove('show');
       document.getElementById("notification-dropdown")?.classList.remove('show');
        if (width > 1120) {
            return false
        }

        document.querySelector<HTMLElement>(".nav-main")!.style.display = document.querySelector<HTMLElement>(".nav-main")!.style.display === "block" ? "none" : "block"
        document.querySelector(".nav-toggle")?.classList.toggle("active");
        document.querySelector(".nav-main")?.classList.toggle("open");
        document.querySelector("body")?.classList.toggle("nav-open");
        document.querySelector("html")?.classList.toggle("nav-open");
    }
    const [pageLogo,setPageLogo]=React.useState(Logo);
    const location = useLocation();
    React.useEffect(()=>{
        if(location.pathname==='/bingo-nachos' || location.pathname==='/bingo-nachos/gallery'){
            setPageLogo(ITCLogo)
        }
        else{
            setPageLogo(Logo)
        }
    },[location])
    return (
        <header id="header" className="">
            {loggedIn && <TopHeader />}
            <div className="logo desktop "
                style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", top: loggedIn && width > 768 ? "55px" : loggedIn && width <= 768 ? "61px" : width <= 768 ? "14px" : "12px", zIndex: "10" }}>
                <Link to="/">
                    <img
                    // src={`${IGL_IMAGE_URL}/logo.png`}
                    src={pageLogo}
                    alt="IGL logo"
                    className="img-fluid"
                    style={{ width: "100px,", zIndex: "1", position: "relative", marginLeft: "-4px" }} /></Link>
            </div>
            <div className="header section d-flex align-items-center">
                <div className="d-none d-lg-block flex-shrink-0" style={{ minWidth: "148px" }}>
                    <div className="login-raw">
                        <a
                            className="btn-main btn-sqr-gradient"
                            href="https://discord.com/invite/iglnetwork"
                            target="_blank" rel="noreferrer"
                        ><i className="fa-brands fa-discord me-1"></i>Join Our Discord</a>
                    </div>
                </div>
                <div className="toggle-menu">
                    <Link to="#" className="nav-toggle" onClick={handleClick}>
                        <span className="Top"></span><span className="Middle"></span><span className="Bottom"></span>
                    </Link>
                </div>
                <div
                    className="header-right"
                    style={{ display: " flex", justifyContent: "space-around" }}
                >
                    <div className="nav-main w-100">
                        <div className="scroll-wrap" >
                            <ul className="mt-0 text-start text-xxl-end ps-0 d-xl-flex align-items-center" onClick={handleClick}>
                                <li>
                                    <NavLink end to="/"><span>HOME</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/games"><span>GAMES</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/news"><span>IGL NEWS</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/tournament/${TOURNAMENT_TYPE.upcoming}`}><span>TOURNAMENTS</span></NavLink>
                                </li>
                                <li>
                                    <a href="https://iglnetwork.myshopify.com/" target="_blank" rel="noreferrer">
                                        <span>STORE</span>
                                    </a>
                                </li>
                            </ul>
                            <ul className="text-end text-xxl-start mt-0 ps-0 d-xl-flex align-items-center" onClick={handleClick}>
                                {/* {user?.id?
                                <li>
                                <NavLink to="/leagues"><span>LEAGUES</span></NavLink>
                                </li>
                                :null} */}
                                <li className='campaing-img'>
                                    {/* <NavLink to="/itc-bingo"><span>ITC BINGO</span></NavLink> */}
                                    <NavLink to="/bingo-nachos"><img src={require('../../assets/images/itc-bingo/bingo logo.png')} alt="" /></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/leaderboard"><span>LEADERBOARD</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/votes"><span>VOTES</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/tv"><span>IGL TV</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about"><span>ABOUT</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact"><span>CONTACT</span></NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{ minWidth: "148px" }}>
                    {user?.loggedin ?
                        <div style={{ zIndex: 9 }}>
                            <ul className="d-flex justify-content-end align-items-center" style={{ gap: "10px" }}>

                                <GlobalSearch />

                                {/* <li onClick={handleNotifications}>
                        <Link to="#" className="icon" rel="no-reffer"><img src={`${IGL_IMAGE_URL}/ic-notification.png`} alt="user" /></Link>
                      </li> */}
                                <li>
                                    {/* <Link to="#" className="icon" rel="no-reffer"><img src={`${IGL_IMAGE_URL}/ic-notification.png`} alt="user" /></Link> */}
                                    <NotificationDropdown />
                                </li>
                                <li>
                                    <ProfileMenu />
                                </li>
                            </ul>
                            {/* <NotificationList
                                show={showNotifications}
                                onClose={handleCloseNotification}
                            /> */}
                        </div>
                        : <div className="login-raw justify-content-end">
                            <Link to="#" className="btn-login" onClick={showLogin} >Login</Link>
                            <Link to="#" className="btn-main btn-sqr-gradient" onClick={showRegister}>Register</Link>
                        </div>
                    }
                </div>
            </div>
        </header>

    )
})

export default GlobalHeader