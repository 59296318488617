import { observer } from 'mobx-react'
import React from 'react'
import { DataResponse } from '../../_common/widget/DataResponse';
import { useNewsStore } from '../store/news';
import { NewsCard } from './NewsCard';
import { useAuthStore } from '../../auth/store/auth';

export const NewsListing = observer(() => {
    const { loadNews, news_list, news_loading, page_no } = useNewsStore();
  
    React.useEffect(() => {
        loadNews(page_no)
    }, [])
   // console.log(news_list)
   return (
        <DataResponse isLoading={news_loading} data={news_list}>
            {
                news_list.map((news, index) => (
                    <div className="row news-detail-raw mt30 mb30" key={index}>
                            <NewsCard
                                id={news.NewsID}
                                imgUrl={news.NewsImage}
                                title={news.NewsTitle}
                                date={news.NewsDate}
                                description={news.NewsDescription} 
                            />
                    </div>
                ))
            }
        </DataResponse>
    )
});