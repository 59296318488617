import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { observer } from 'mobx-react';
import { LoginForm } from './LoginForm';
import { useAuthStore } from '../store/auth';
import { useLocation } from 'react-router';
import BingoPackets from '../../itc-bingo/components/BingoPackets';
import BinoChip, { BinoChipModal } from '../../itc-bingo/components/BinoChip';

const LoginUser = observer(() => {
  const { show_login, hideLogin } = useAuthStore();
  const {pathname}=useLocation();
  const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const campaign = new URLSearchParams(window.location.search).get('from_campaign');
  if (!show_login) {
    return <></>
  }
  return (
    <Modal show={show_login} className={`login-modal modal-raw ${(queryParams && campaign ==="itc")||(pathname==="/bingo-nachos") ? "itc-tournament-details-col":""}`} aria-labelledby="contained-modal-title-vcenter"
    centered>
      <button type="button" className="btn-close-modal" onClick={()=>hideLogin(pathname)}>&times;</button>
      <Modal.Body className="logsec">
        {((queryParams && campaign ==="itc")||(pathname==="/bingo-nachos")) && <BinoChipModal/>}
        <LoginForm />
        {((queryParams && campaign ==="itc")||(pathname==="/bingo-nachos")) && <BingoPackets/>}
      </Modal.Body>
    </Modal>
  )
})

export default LoginUser