import React from 'react'
import { useNavigate } from 'react-router-dom';

export const GoBack = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
  return (
    <div className='font-Goldman text-center text-white h3 cursor-pointer' style={{color: "rgb(0, 156, 255)"}} onClick={goBack}><span>&#10229;</span>Go Back</div>
  )
}
