import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import {
  IGlobalSearchRepository,
  globalSearchRepo,
} from "../repository/globalSearchFilter";
import { IGlobalSearchRes } from "../model/globalsearchfilter";
export class GlobalSearchFilter {
  private globalSearchApi: IGlobalSearchRepository;
  constructor(args: { globalSearchApi: IGlobalSearchRepository }) {
    this.globalSearchApi = args.globalSearchApi;
    makeAutoObservable(this);
  }
  globalSearchLoading: boolean = false;
  globalSearchResult?: IGlobalSearchRes[] = [];
  globalSearchError: string = "";
  globalSearchText: string = "";

  //
  more_data_available: boolean = false;
  page_no = 1;
  //

  setSearchText = (payload: string) => {
    this.globalSearchText = payload;
    return;
  };
  globalSearch = async (payload: {
    searchtext?: string;
    PTYPE: string;
    PNO: string;
  }) => {
    if (this.globalSearchText === "") {
      this.globalSearchError = "Please Search Your Query";
      return;
    } else {
      this.globalSearchError = "";
    }
    runInAction(() => {
      this.globalSearchLoading = true;
      this.globalSearchError = "";
    });
    const result = await this.globalSearchApi.globalSearch({
      PTYPE: payload.PTYPE,
      searchtext: payload.searchtext
        ? payload.searchtext
        : this.globalSearchText,
        PNO:String(this.page_no)
    });
    if (!result?.result || result?.hasError) {
      runInAction(() => {
        this.globalSearchLoading = false;
        this.globalSearchError = result.error || "No Data Found";
      });
      return;
    }
    runInAction(() => {
      this.globalSearchError = "";
      if (result.result!.length >= 20) {
        this.more_data_available = true;
      } else {
        this.more_data_available = false;
      }

      if (this.globalSearchResult && this.globalSearchResult.length >= 1) {
        this.globalSearchResult = [...this.globalSearchResult!, ...result.result!];
      } else {
        this.globalSearchResult = result.result!;
      }

      this.globalSearchLoading = false;
    });
  };

  loadMoreData = (params?: any) => {
    this.page_no++;
    this.globalSearch({ ...params, PNO: `${this.page_no}` });
  };
  resetPageCount = () => {
    this.page_no = 1;
    this.globalSearchResult = [];
  };
}
export const GlobalSearchContext = React.createContext(
  new GlobalSearchFilter({ globalSearchApi: globalSearchRepo })
);
export const useGlobalSearchStore = () => React.useContext(GlobalSearchContext);
