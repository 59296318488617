import React from 'react'
import Button from './widget/Button'
import Heading from '../profile/widget/Heading'
import CreateTeam from './view/CreateTeam'
import MyTeams from './view/MyTeams'
import OtherTeams from './view/OtherTeams'
import { useParams } from 'react-router-dom'
const Teams = () => {
    const [show, setShow] = React.useState(false)
    const { userid } = useParams();
    const handleClose = () =>{
        setShow(false)
    } 
    const handleShow = () =>{
        setShow(true)
    }
    return (
        <div className="team-overview-raw section text-center">
            <Heading label='Teams overview' />
            <div className="section-team-overview section mt-3" >
            {!userid && <div className="section-team-overview section mt-3" >
                <Button label='Create new Team' onClick={handleShow}/>
            </div>
            }
            </div>
            <MyTeams />
            <Heading label='Other Teams' />
            <OtherTeams />
            <CreateTeam show={show} onClose={handleClose} TotalCheckinCount={''}/>
        </div>
    )
}

export default Teams