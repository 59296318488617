import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { MegaTournamentResponse } from "../../_common/model/tournament";
import { ITournamentsRepository, tournamentsRepository } from "../../_common/repository/tournaments";

export class MegaTournamentStore {
  
  tournaments?: MegaTournamentResponse[];
  errTournament = "";
  loadingTournament = false;
  
  private tournamentAPI: ITournamentsRepository;

  constructor(args: { tournamentAPI: ITournamentsRepository}) {
    makeAutoObservable(this);
    this.tournamentAPI = args.tournamentAPI;
  }

  loadTournament = async () => {
    runInAction(() => {
      this.loadingTournament = true;
    });
   
    const resp = await this.tournamentAPI.getMegaTournaments();

    if (resp.hasError) {
      runInAction(() => {
        this.errTournament = resp.error;
        this.loadingTournament = false;
      });
      return;
    }

    runInAction(() => {
      this.tournaments = resp.result!;
      this.loadingTournament = false;
    });
  };
}

export const MegaTournamentStoreContext = createContext(new MegaTournamentStore({ tournamentAPI: tournamentsRepository}));
export const useMegaTournamentStore = () => useContext(MegaTournamentStoreContext);
