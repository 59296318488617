import React from 'react'
 interface Props {
    TVID?: string;
    TVTitle?: string;
    TournamentTitle?: string;
    GameTitle?: string;
    TVDate?: string | null;
    GameID?: string;
    TournamentID?: string | null;
    TVVideoID?: string;
    TVlike?: string;
    TVCommentCount?: string;
    islike?: string;
 }
const VideoCard = (props:Props) => {
    const {TVID,TVTitle,TournamentTitle,GameTitle,TVDate,GameID,TournamentID,TVVideoID,TVlike,TVCommentCount,islike}=props;
    return (
        <div className="col-sm-3 col-6 gm-li d-flex">
            <div className="gm-col section d-flex flex-column">
                <div className="img-box flex-shrink-0">
                    <a className="btn-play" href={"https://www.youtube.com/watch?v="+TVVideoID} target="_blank" rel="noreferrer">
                        <img className="img-fluid" src={"https://img.youtube.com/vi/"+TVVideoID+"/hqdefault.jpg"} alt="" />
                    </a>
                </div>
                <div className="about-game-raw d-flex flex-column flex-grow-1 justify-content-around">
                    <div className="about-game-conn">
                        <div className="left-conn">
                            <h5>{TVTitle} </h5>
                            <h6>{GameTitle} </h6>
                        </div>
                    </div>
                    <div className="view-raw section text-center">
                        <a href={"https://www.youtube.com/watch?v="+TVVideoID} target="_blank" rel="noreferrer" className="btn-view">Visit Link</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoCard