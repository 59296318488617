import React from 'react'
import { observer } from 'mobx-react'
import { FieldProps, IDropdowns } from '../../_common/model/field'
import { Field, useField, useFormikContext } from 'formik'
import { FieldError } from '../../_common/widget/FieldError'
import * as Yup from 'yup';
import { useTeamStore } from '../../teams/store/team'

export const GameDropdown = observer((props: FieldProps) => {
    const { name = 'game', disabled, required } = props;

    const { games, game,platformid,getgameselectid } = useTeamStore()

    const [field, meta, helpers] = useField({ name });

    React.useEffect(()=>{
        game('');
    },[])
    const { setFieldValue,values } = useFormikContext<IDropdowns>();
    React.useEffect(() => {
        if (!values.platform_id) {
            return
        }
        (async () => {
            await game(values.platform_id!);
        })();
    }, [values.platform_id])

    React.useEffect(() => {
        helpers.setValue('');
    }, [values.platform_id])

    const validate = async (value: any) => {
        var schema = Yup.string();

        let sch = required
            ? schema
                .required('Please select Game')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }
    const [toogle, setToogle] = React.useState(false);
    return (
        // <div className="select-dropdown">
        //     <Field onClick={(e:any)=> {game(platformid);getgameselectid(e.target.value)}} component="select" name={name} disabled={disabled} validate={validate}>
        //         <option value="">Select Game</option>
        //         {games?.map(option => (
        //             <option key={option.GameID} value={option.GameID}>{option.GameTitle}</option>
        //         ))}
        //     </Field>
        //     <FieldError name={name} />
        // </div>
        <div className="select-dropdown position-relative" onBlur={()=>{setToogle(false)}} tabIndex={6}>
            {/* @ts-ignore */}
        <label onClick={() => setToogle(!toogle)}>{(values[name]===""||values[name] === undefined) ? "Select Game" :  games!.find(item=>item.GameID=== values[name])?.GameTitle }</label>
        {toogle &&
            <div className='position-absolute w-100' style={{zIndex:1}}>
                <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue(name,"");
                        // game(platformid);
                        getgameselectid("")
                        setToogle(false);
                    }}>Select Game</p>
                {games?.map(option => (
                    <p className='cursor-pointer font-D-DinCondensed '  key={option.GameID} onClick={() => {
                        setFieldValue(name,option.GameID);
                        // game(platformid);
                        getgameselectid(option.GameID)
                        setToogle(false);
                    }}>{option.GameTitle}</p>
                ))}
            </div>
        }
         <FieldError name={name} />
        </div>
    )
})