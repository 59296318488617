import React from 'react'
import { FieldProps } from '../../_common/model/field'
import { Field, useFormikContext } from 'formik'
import { FieldError } from '../../_common/widget/FieldError' // wasn't working
import * as Yup from 'yup';
import { ITournamentboardParams } from '../model/leaderboard';

export const YearDropdown = (props: FieldProps) => {
    const { name = "year", disabled, required } = props;

    const validate = async (value: any) => {
        var schema = Yup.string();

        let sch = required
            ? schema
                .required('Please select Year')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }
    const [toogle, setToogle] = React.useState(false);
    const { setFieldValue,values } = useFormikContext<ITournamentboardParams>();
    const data=[
        {
            title:"2018",
            value:"2018"
        },
        {
            title:"2019",
            value:"2019"
        },
        {
            title:"2020",
            value:"2020"
        },
        {
            title:"2021",
            value:"2021"
        },
        {
            title:"2022",
            value:"2022"
        },
        {
            title:"2023",
            value:"2023"
        },
        {
            title:"2024",
            value:"2024"
        }
    ];
    return (
        // <div className="select-dropdown">
        //     <Field component="select" name={name} disabled={disabled} validate={validate}>
        //         <option value="">Select Year</option>
        //             <option value='2018'>2018</option>
        //             <option value='2019'>2019</option>
        //             <option value='2020'>2020</option>
        //             <option value='2021'>2021</option>
        //             <option value='2022'>2022</option>
        //             <option value='2023'>2023</option>
        //     </Field>
        //     <FieldError name={name} />
        // </div>
        <div className="select-dropdown position-relative" onBlur={()=>{setToogle(false)}} tabIndex={6}>
        <label onClick={() => setToogle(!toogle)}>{(values.year===""||values.year === undefined) ? "Select Year" :  data!.find(item=>item.value === values.year)?.title }</label>
        {toogle &&
            <div className='position-absolute w-100' style={{zIndex:1}}>
                <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue("year","");
                       
                        setToogle(false);
                    }}>Select Year</p>
                {data?.map((option,index) => (
                    <p className='cursor-pointer font-D-DinCondensed' key={index} onClick={() => {
                        setFieldValue("year",option.value);
                        setToogle(false);
                    }}>{option.title}</p>
                ))} 
            </div>
        }
        <FieldError name={name} />
        </div>
    )
}