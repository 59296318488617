
import React from 'react';
import { useAuthStore } from "../../auth/store/auth";
import { CarouselSection } from './carousel';
export const Header = () => {
  const { loggedIn } = useAuthStore()

  return (
    <div className="main-banner section" style={loggedIn ? {} : { marginTop: "70px" }}>
      <CarouselSection />
      {/* <img src={Bg} alt="game banner" /> */}
     
    </div>
  );
}