import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import React from 'react'
import { JOIN_TEXT } from '../../core/config';
import { toUnixDateTime, toAlphabets } from '../../core/helper';
import { BattleRoyale } from '../../_common/model/tournament';
import { battleRoyaleRepository, BattleRoyaleRepository } from '../../_common/repository/battleroyale';

export class BattleRoyaleDetailsStore {
    battleroyaleDetailsAPI: BattleRoyaleRepository;

    battleroyale_details_loading = false;
    battleroyale_details_error = ""
    battleroyale_details?: BattleRoyale|null=null;

    join_battleroyale_text = "";
    current_date_time: string | number = "";
    clickable= false;
    lobby_code = '';

    constructor(args: {
        battleroyaleDetailsAPI: BattleRoyaleRepository
    }) {
        makeAutoObservable(this);
        this.battleroyaleDetailsAPI = args.battleroyaleDetailsAPI;
        // this.current_date_time = moment().format("YYYY-MM-DD hh:mm A");
        this.current_date_time = moment().format("YYYY/MM/DD HH:mm");
        this.join_battleroyale_text = JOIN_TEXT.JOIN_NOW;
    };

    loadBattleRoyaleDetails = async (id: string, userId: string) => {
        runInAction(() => {
            this.battleroyale_details_loading = true;
            this.battleroyale_details=null;
        })
        const resp = await this.battleroyaleDetailsAPI.getBattleRoyaleDetails(id, userId);

        if (resp.hasError) {
            runInAction(() => {
                this.battleroyale_details_loading = false;
                this.battleroyale_details_error = resp.error!
            })
            return resp.error;
        }
         runInAction(() => {
            this.battleroyale_details_error="";
            this.battleroyale_details = resp.result!;
            this.lobby_code = this.battleroyale_details.LobbyCode;
            this.setBattleroyaleActionStatus();
            this.battleroyale_details_loading = false;
            return resp.result;
        })
    }
    setBattleroyaleActionStatus = () => {
        // this.current_date_time = toUnixDateTime(this.current_date_time);
        let checkInStartDateTime: string | number = `${this.battleroyale_details?.CheckInstartDate} ${this.battleroyale_details?.CheckinTime}`;
        checkInStartDateTime = toUnixDateTime(checkInStartDateTime);
        let checkInEndDateTime: string | number = `${this.battleroyale_details?.CheckInCloseDate} ${this.battleroyale_details?.CheckoutTime}`;
        checkInEndDateTime = toUnixDateTime(checkInEndDateTime)
        const joinInCount = Number(this.battleroyale_details?.JoinCount) ?? 0;
        const totalTeamsCount = Number(this.battleroyale_details?.RoyalBattleTeamsInGroup) * Number(this.battleroyale_details?.RoyalBattleNumberofGroups);
        const SubmitResultFirstRound = this.battleroyale_details?.SubmitResultFirstRound ?? 0;
        const SubmitResultSecondRound = this.battleroyale_details?.SubmitResultSecondRound ?? 0;
        if (this.current_date_time <= checkInEndDateTime) {
            if (joinInCount < totalTeamsCount) {
                if (toAlphabets(this.battleroyale_details?.isjoin!) !== 'joined') {
                    this.join_battleroyale_text = JOIN_TEXT.JOIN_NOW;
                } else {
                    if (checkInStartDateTime <= this.current_date_time) {
                        if (toAlphabets(this.battleroyale_details?.ischeckin!) !== 'checkedin') {
                            this.join_battleroyale_text = JOIN_TEXT.CHECK_IN;
                        } else {
                            this.join_battleroyale_text = JOIN_TEXT.CHECKED_IN;
                            if (toAlphabets(this.battleroyale_details?.ischeckin!) !== 'checkedin') {
                                if (SubmitResultFirstRound > 0) {
                                    this.join_battleroyale_text = JOIN_TEXT.SUBMIT_RESULT;
                                }

                                if (SubmitResultSecondRound > 0) {
                                    this.join_battleroyale_text = JOIN_TEXT.SUBMIT_RESULT;
                                }
                            }
                        }
                    } else {
                        this.join_battleroyale_text = JOIN_TEXT.JOINED;
                    }
                }
            } else {
                if (this.current_date_time <= checkInStartDateTime) {
                    if (toAlphabets(this.battleroyale_details?.ischeckin!) === 'checkedin') {
                        this.join_battleroyale_text = JOIN_TEXT.CHECKED_IN;
                    } else {
                        if (toAlphabets(this.battleroyale_details?.isjoin!) === 'joined') {
                            this.join_battleroyale_text = JOIN_TEXT.CHECK_IN;
                        } else {
                             //modified condition from joined full to allow checkin even then teams joined cross limit
                            this.join_battleroyale_text = JOIN_TEXT.JOINED_FULL;
                            // this.join_battleroyale_text = JOIN_TEXT.JOIN_NOW;
                        }
                    }
                } else {
                    if (toAlphabets(this.battleroyale_details?.isjoin!) === 'joined') {
                        if (toAlphabets(this.battleroyale_details?.ischeckin!) === 'checkedin') {
                            this.join_battleroyale_text = JOIN_TEXT.CHECKED_IN;
                        } else {
                            if (Number(this?.battleroyale_details?.Checkincount) < totalTeamsCount) {
                                this.join_battleroyale_text = JOIN_TEXT.CHECK_IN;
                                // this.join_battleroyale_text = JOIN_TEXT.JOIN_NOW;
                            } else {
                                // this.join_battleroyale_text = JOIN_TEXT.JOIN_NOW;
                                this.join_battleroyale_text = JOIN_TEXT.CHECK_IN_FULL;
                            }
                        }
                    } else {
                        //modified condition from joined full to allow checkin even then teams joined cross limit
                        // this.join_battleroyale_text = JOIN_TEXT.JOINED_FULL;
                        // if (joinInCount >= totalTeamsCount) {
                        if (Number(this?.battleroyale_details?.Checkincount) < totalTeamsCount) {
                            this.join_battleroyale_text = JOIN_TEXT.JOIN_NOW;
                        } else {
                            // this.join_battleroyale_text = JOIN_TEXT.JOIN_NOW;
                            this.join_battleroyale_text = JOIN_TEXT.JOINED_FULL;
                        }
                    }
                }
            }
        } else {
            if (toAlphabets(this.battleroyale_details?.ischeckin!) === 'checkinclosed') {
                this.join_battleroyale_text = JOIN_TEXT.CHECK_IN_CLOSED;
                // this.lobby_code = '';
            }
            if (toAlphabets(this.battleroyale_details?.ischeckin!) === 'checkedin') {
                if (SubmitResultFirstRound > 0) {
                    this.join_battleroyale_text = JOIN_TEXT.SUBMIT_RESULT;
                }
                if (SubmitResultSecondRound > 0) {
                    this.join_battleroyale_text = JOIN_TEXT.SUBMIT_RESULT;
                }
            }
        }
        // this.join_battleroyale_text = this.battleroyale_details?.tournamentStatus!;
        // this.join_battleroyale_text = "checkin" // just to testing
        if(toAlphabets(this.join_battleroyale_text) === "joinnow" || toAlphabets(this.join_battleroyale_text) === "checkin"){
            this.clickable=true;
        }
        else{
            this.clickable=false;
        }

    }
}
export const BattleRoyaleDetailsContext = React.createContext(new BattleRoyaleDetailsStore({ battleroyaleDetailsAPI: battleRoyaleRepository }));;
export const useBattleRoyaleDetailsStore = () => React.useContext(BattleRoyaleDetailsContext)