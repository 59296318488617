import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { ITournamentPayload } from "../../_common/model/tournament";
import { ITournamentsRepository, tournamentsRepository } from "../../_common/repository/tournaments";

export class CheckinTournamentStore {
  
  checkin_tournament = "";
  checkin_tournament_error = "";
  checkin_tournament_loading = false;
  
  private checkinAPI: ITournamentsRepository;

  constructor(args: { checkinAPI: ITournamentsRepository}) {
    makeAutoObservable(this);
    this.checkinAPI = args.checkinAPI;
  }

  checkinTournament = async (payload: ITournamentPayload) => {
    runInAction(() => {
      this.checkin_tournament_loading = true;
    });
    const resp = await this.checkinAPI.checkinTournament(payload);

    if (resp.hasError) {
      runInAction(() => {
        this.checkin_tournament_error = resp.error!;
        this.checkin_tournament_loading = false;
      });
      return resp.error;
    }
    runInAction(() => {
      this.checkin_tournament = resp.result!;
      this.checkin_tournament_loading = false;
    });
    return resp.result;
  };
}

export const CheckinTournamentStoreContext = createContext(new CheckinTournamentStore({ checkinAPI: tournamentsRepository}));
export const useCheckinTournamentStore = () => useContext(CheckinTournamentStoreContext);
