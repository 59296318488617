import { observer } from 'mobx-react'
import React from 'react'
import { useAuthStore } from '../../auth/store/auth'
import { IGL_IMAGE_URL, WITHDRAWAL_AMOUNT_LIMIT } from '../../core/config'
import { PaymentRequestForm } from '../view/PaymentRequestForm'
import { WalletType } from './WalletType'
import { useProfileStore } from '../../profile/store/profileInfo'

export const Payment = observer(() => {
    // const { user } = useAuthStore();
    const { profile_info } = useProfileStore()
    return (
        <>
            <WalletType imgUrl={`${IGL_IMAGE_URL}/payment.png`} title='Payment' >
                {/* {Number(user?.UserCredit!) < WITHDRAWAL_AMOUNT_LIMIT */}
                {Number(profile_info?.UserCredit!) < WITHDRAWAL_AMOUNT_LIMIT
                    ? <p>Insufficient IGL coins for payout.<br />
                        Your balance must be at least {WITHDRAWAL_AMOUNT_LIMIT} IGL Coins to withdraw the money and you need to maintain a balance of minimum {WITHDRAWAL_AMOUNT_LIMIT} IGL Coins </p>
                    :<PaymentRequestForm />
                }
            </WalletType>
        </>
    )
})
