import moment from "moment"

export const dateTime=(date:string)=>{
    if(!date) return '';
    return moment(date).format('lll')
}
export const date=(date:string)=>{
    if(!date) return '';
    return moment(date,'DD/MM/YYYY').format('ll')
}
export const toAlphabets=(str: string | null)=>{
    return str?.toLowerCase().replace(/[^a-zA-Z]/g, "")
}

export const toUnixDateTime=(dateTime: string | number)=>{
    // return new Date(dateTime).getTime();
    // return moment(dateTime, "YYYY-MM-DD hh:mm aa").valueOf()
    // console.log("helper ",new Date(moment(dateTime, "YYYY-MM-DD hh:mm a").format("YYYY-MM-DD hh:mm a")).getTime())
    return moment(dateTime, "YYYY-MM-DD hh:mm A").format("YYYY/MM/DD HH:mm")
}