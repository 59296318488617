import React from 'react'

type Props = {
    rank?: number;
    team?: string;
    game?: string;
    points?: string;
    date?: string;
}

export const LeaderboardDataRow: React.FC<Props> = (props) => {
    const { rank, team, game, points, date } = props;
    return (
        <>
            <tr>
                <td>{rank}</td>
                <td>{team}</td>
                <td>{game}</td>
                <td>{points || '-'}</td>
            </tr>
        </>
    )
}