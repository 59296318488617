import { makeAutoObservable, runInAction } from 'mobx';
import React from 'react';
import { IUserIDPayload, IVerificationPayload, gTBYGUBTSBP_new } from '../model/tournament';
import { verificationRepository, VerificationRepository } from '../repository/verification';
export class VerificationStore {
    verification_result: string = "";
    verification_loading = false;
    verification_error = "";
 
    JoinNowModalShow=false;
    showVerificationModalValue=false;

    verificationAPI: VerificationRepository
    constructor(args: {
        verificationAPI: VerificationRepository
    }) {
        this.verificationAPI = args.verificationAPI;
        makeAutoObservable(this);
    }

    verify = async (payload: IVerificationPayload) => {
        runInAction(() => {
            this.verification_loading = true;
            this.verification_error = "";
        })
        const resp = await this.verificationAPI.verify(payload);
        if (resp.hasError) {
            runInAction(() => {
                this.verification_loading = false;
                this.verification_error = resp.error!;
            })
            return resp
        }
        runInAction(() => {
            this.verification_loading = false;
            this.verification_error = "";
            this.verification_result = resp.result!;
            this.JoinNowModalShow=true;
            this.showVerificationModalValue=false;
        })
        return resp
    }

    setShowVerificationModal=async(payload:boolean)=>{
        this.showVerificationModalValue=payload;
    }
   
    setJoinNowModal=async(payload:boolean)=>{
        this.JoinNowModalShow=payload;
    }
    getTeamsByUserGameIDByTeamSizeByPlatform_new_loading=false;
    getTeamsByUserGameIDByTeamSizeByPlatform_new_resp?:gTBYGUBTSBP_new;
    getTeamsByUserGameIDByTeamSizeByPlatform_new_error:any;

    getTeamsByUserGameIDByTeamSizeByPlatform_new=async(payload:IUserIDPayload)=>{
        runInAction(()=>{
            this.getTeamsByUserGameIDByTeamSizeByPlatform_new_loading=true;
        })
        const resp = await this.verificationAPI.getTeamsByUserGameIDByTeamSizeByPlatform_new(payload);
        if (resp.hasError) {
            runInAction(() => {
                this.getTeamsByUserGameIDByTeamSizeByPlatform_new_loading = false;
                this.getTeamsByUserGameIDByTeamSizeByPlatform_new_error = resp.error!;
            })
            return;
        }
        runInAction(()=>{
            this.getTeamsByUserGameIDByTeamSizeByPlatform_new_loading=false;
            this.getTeamsByUserGameIDByTeamSizeByPlatform_new_resp=resp.result!;
        })
        return;
    }
}
export const VerificationStoreContext = React.createContext(new VerificationStore({ verificationAPI: verificationRepository }));
export const useVerificationStore = () => React.useContext(VerificationStoreContext);