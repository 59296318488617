import React from 'react'

import PsnImg from "../../assets/images/ic1.png";
import SteamImg from "../../assets/images/ic2.png";
import XboxImg from "../../assets/images/ic3.png";
import PlaystoreImg from "../../assets/images/ic4.png";
import AppstoreImg from "../../assets/images/ic5.png";
import NintendoImg from "../../assets/images/ic6.png";
import EpicgamesImg from "../../assets/images/ic7.png";
import DiscordImg from "../../assets/images/ic8.png";

type Props = {
    platformImg: string;
    platformName: string;
}

const GAMING_PLATFORMS_ROW1 = [
    { path: PsnImg, name: 'PSN' },
    { path: SteamImg, name: 'STEAM' },
    { path: XboxImg, name: 'XBOX' },
    { path: PlaystoreImg, name: 'PLAYSTORE' },
    
]
const GAMING_PLATFORMS_ROW2 = [
    { path: AppstoreImg, name: 'APPSTORE' },
    { path: NintendoImg, name: 'NINTENDO' },
    { path: EpicgamesImg, name: 'EPIC GAMES' },
    { path: DiscordImg, name: 'DISCORD' },
]

const GamingPlatforms = () => {
    return (
        <>
            <div className="gaming-plateform-raw text-center section pt10">
                <div className="title-shadow section">
                    <h4>Gaming Platforms</h4>
                </div>
                <div className="section">
                    <div className="row platform-list-row justify-content-center align-items-center">
                        <div className="platform-row col-sm-12 col-12">
                            <div className="row">
                                {GAMING_PLATFORMS_ROW1.map((item, index) => (
                                    <GamingPlatform key={index} platformImg={item.path} platformName={item.name} />
                                ))}
                            </div>
                        </div>
                        <div className="platform-row col-sm-12 col-12">
                            <div className="row">
                                {GAMING_PLATFORMS_ROW2.map((item, index) => (
                                    <GamingPlatform key={index} platformImg={item.path} platformName={item.name} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const GamingPlatform = (props: Props) => {
    return (
        <>
            <div className="col-sm-3 col-6 d-flex list-col">
                <div className="con-box section mx-auto my-auto">
                    <div className="img-box">
                        <img src={props.platformImg} alt="profile img" className="img-fluid" />
                    </div>
                    <h5>{props.platformName}</h5>
                </div>
            </div>
        </>
    )
}

export default GamingPlatforms