import React from 'react'
import { Field, ErrorMessage } from 'formik';

type Props = {
    name: string,
}

export const Amount = (props: Props) => {
    const { name } = props;
    return (
        <>
            <Field name={name} className="form-control input-b mb-3" placeholder="Enter Payout Amount" />
            <ErrorMessage
                name={name}
                component="span"
                className="text-danger"
            />
        </>
    )
}