import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { MegaTournamentResponse } from "../../_common/model/tournament";
import { IItcBingoRepository,itcBingoRepository } from "../../_common/repository/itc-bingo";

export class ItcBingoTournamentStore {
  
  tournaments?: MegaTournamentResponse[];
  errTournament = "";
  loadingTournament = false;
  
  private tournamentAPI: IItcBingoRepository;

  constructor(args: { tournamentAPI: IItcBingoRepository}) {
    makeAutoObservable(this);
    this.tournamentAPI = args.tournamentAPI;
  }

  loadTournament = async () => {
    runInAction(() => {
      this.loadingTournament = true;
    });
    const resp = await this.tournamentAPI.getBingoTournaments();

    if (resp.hasError) {
      runInAction(() => {
        this.errTournament = resp.error!;
        this.loadingTournament = false;
      });
      return;
    }

    runInAction(() => {
      this.tournaments = resp.result!;
      this.loadingTournament = false;
    });
  };
}

export const ItcBingoTournamentStoreContext = createContext(new ItcBingoTournamentStore({ tournamentAPI: itcBingoRepository}));
export const useItcBingoTournamentStore = () => useContext(ItcBingoTournamentStoreContext);
