import { makeAutoObservable, runInAction } from 'mobx';
import React from 'react';
import { contactRepository, ContactRepository } from "../../_common/repository/contact";

export class ContactStore{
    contactAPI: ContactRepository;

    contact_error = "";
    contact_loading = false;
    contact_result = "";
    constructor(args: {
        contactAPI: ContactRepository
    }) {
        makeAutoObservable(this);
        this.contactAPI = args.contactAPI;
    };
    contactUs = async (payload: any) => {
        runInAction(() => {
          this.contact_loading = true
          this.contact_error = ""
        })
    
        const resp = await this.contactAPI.contactUs(payload)
        if (resp.hasError) {
          runInAction(() => {
            this.contact_error = resp.error!;
            this.contact_loading = false;
          })
          return
        }
        runInAction(() => {
          this.contact_result = resp.result!;
          this.contact_loading = false;
        })
      }
}


export const ContactStoreContext = React.createContext(new ContactStore({ contactAPI: contactRepository }));
export const useContactStore = () => React.useContext(ContactStoreContext);