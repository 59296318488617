import React from 'react'

type Props = {
    lobbyCode?: string;
}

export const LobbyCode = (props: Props) => {
    const { lobbyCode } = props;
    if(!lobbyCode){
        return <></>
    }
    return (
        <>
            {/* <input className="btn-ac ic-lobby text-light" value={`Lobby Code - ${lobbyCode}`}/> */}
            {/* <div className="btn-ac ic-lobby text-light cursor-pointer" onClick={()=> navigator.clipboard.writeText(lobbyCode)}>Lobby Code | <label className='font-bold'>ID-</label> 171115622 Pass- 00</div> */}
            <div className="btn-ac ic-lobby text-light cursor-pointer" onClick={()=> navigator.clipboard.writeText(lobbyCode)}>Lobby Code | {lobbyCode}</div>
        </>
    )
}