import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { authstore, AuthStore } from "../../auth/store/auth";
import {
  Game,
  TeamMember,
  Platform,
  Team,
  MemberList,
  EligibleTeam,
  ITeamPayload,
  IEligibleTeamPayload,
  TeamSizetype,
  GameIdValidation,
} from "../../_common/model/team";
import { teamRepository, TeamRepository } from "../../_common/repository/team";

export class TeamStore {
  auth_store?: AuthStore;

  my_team_loading = false;
  my_team_error = "";
  my_teams?: Team[];

  other_team_loading = false;
  other_team_error = "";
  other_teams?: Team[];

  creating = false;
  create_error = "";
  created = false;

  platform_loading = false;
  platform_error = "";
  platforms?: Platform[];

  game_loading = false;
  game_error = "";
  games?: Game[];

  game_id_loading:boolean=false;
  game_id_error:string="";
  games_id_val?:GameIdValidation;

  team_size_loading = false;
  team_size_error = "";
  team_sizes?: TeamSizetype[];

  team_member_loading = false;
  team_member_error = "";
  team_member_list?: TeamMember[] = [];

  eligible_team_loading = false;
  eligible_team_error = "";
  eligible_teams?: EligibleTeam[];

  success_msg?: string = "";

  platformid = "";
  gameselectid = "";

  constructor(args: { teamAPI: TeamRepository; authstore: AuthStore }) {
    makeAutoObservable(this);
    this.teamAPI = args.teamAPI;
    this.auth_store = args.authstore;
    this.success_msg = "";
  }

  teamAPI: TeamRepository;

  getplatformid = (id:string)=> {
    this.platformid = id;
  }
  getgameselectid = (id:string)=> {
    this.gameselectid = id;
  }

  myTeam = async (userId: string) => {
    runInAction(() => {
      this.my_team_loading = true;
      this.my_team_error = "";
    });
    const resp = await this.teamAPI.getMyTeam(userId);
    if (resp.hasError) {
      runInAction(() => {
        this.my_team_loading = false;
        this.my_team_error = resp.error!;
      });
      return;
    }
    runInAction(() => {
      this.my_team_loading = false;
      this.my_teams = resp.result!;
    });
  };

  otherTeam = async (userId: string) => {
    runInAction(() => {
      this.other_team_loading = true;
      this.other_team_error = "";
    });
    const resp = await this.teamAPI.getOtherTeam(userId);
    if (resp.hasError) {
      runInAction(() => {
        this.other_team_loading = false;
        this.other_team_error = resp.error!;
      });
      return;
    }
    runInAction(() => {
      this.other_team_loading = false;
      this.other_teams = resp.result!;
    });
  };

  platform = async () => {
    runInAction(() => {
      this.platform_loading = true;
      this.platform_error = "";
    });
    const resp = await this.teamAPI.getPlatform();
    if (resp.hasError) {
      runInAction(() => {
        this.platform_loading = false;
        this.platform_error = resp.error!;
      });
      return;
    }
    runInAction(() => {
      this.platform_loading = false;
      this.platforms = resp.result!;
    });
  };

  game = async (param: string) => {
    runInAction(() => {
      this.game_loading = true;
      this.game_error = "";
    });
    const resp = await this.teamAPI.getGame(param);
    if (resp.hasError) {
      runInAction(() => {
        this.game_loading = false;
        this.game_error = resp.error!;
      });
      return;
    }
    runInAction(() => {
      this.game_loading = false;
      this.games = resp.result!;
    });
  };
  

  gameIdValidation = async (param: string) => {
    runInAction(() => {
      this.game_id_loading = true;
      this.game_id_error = "";
    });
    const resp = await this.teamAPI.getIdValidation(param);
    if (resp.hasError) {
      runInAction(() => {
        this.game_id_loading = false;
        this.game_id_error = resp.error!;
      });
      return;
    }
    runInAction(() => {
      this.game_id_loading = false;
      this.games_id_val = resp.result!;
    });
  };

  teamSize = async (param: string) => {
    runInAction(() => {
      this.team_size_loading = true;
      this.team_size_error = "";
    });
    const resp = await this.teamAPI.getTeamSize(param);
    if (resp.hasError) {
      runInAction(() => {
        this.team_size_loading = false;
        this.team_size_error = resp.error!;
      });
      return;
    }
    runInAction(() => {
      this.team_size_loading = false;
      this.team_sizes = resp.result!;
    });
  };

  teamMember = async (user: string, game: string,  tournament_type:string, tournamentId:string) => {
    runInAction(() => {
      this.team_member_loading = true;
      this.team_member_error = "";
    });
    const resp = await this.teamAPI.getTeamMember(user, game, tournament_type, tournamentId );
    if (resp.hasError) {
      runInAction(() => {
        this.team_member_loading = false;
        this.team_member_error = resp.error!;
      });
      return;
    }
    runInAction(() => {
      this.team_member_loading = false;
      this.team_member_list = resp.result!;
    });
    return resp.result!;
  };

  addTeamMember = (
    state: any,
    arr: MemberList[],
    count: number,
    name: string
  ) => {
    for (let i = 1; i < count; i++) {
      const data = {} as MemberList;
      // const anyState = state as any
      data.memberusername = state[`${name}_name_${i}`];
      data.team_member_game_id = state[`${name}_id_${i}`];
      data.memberrole = "4";
      arr.push(data);
    }
  };
  
// 

  newTeam = async (payload: ITeamPayload) => {
    runInAction(() => {
      this.creating = true;
      this.create_error = "";
      this.success_msg = "";
    });

    const resp = await this.teamAPI.createTeam(payload);
    if (resp.hasError) {
      runInAction(() => {
        this.create_error = resp.error!;
        this.creating = false;
      });
      return;
    }
    runInAction(() => {
      this.created = true;
      this.creating = false;
      this.success_msg = resp?.result?.msg;
    });
    return resp.result;
  };
  loadEligibleTeam = async (payload: IEligibleTeamPayload, type?: number) => {
    runInAction(() => {
      this.eligible_team_loading = true;
      this.eligible_team_error = "";
    });
    const resp = await this.teamAPI.getEligibleTeam(payload, type);
    if (resp.hasError) {
      runInAction(() => {
        this.eligible_team_loading = false;
        this.eligible_team_error = resp.error!;
      });
      return;
    }
    runInAction(() => {
      this.eligible_team_loading = false;
      this.eligible_teams = resp.result!;
    });
  };
  hideSuccess = () => {
    this.success_msg = "";
    this.myTeam(this.auth_store?.user?.id!);
  };
  teamname_valid: boolean = false;
  check_teamname_loading: boolean = false;
  check_teamname_error: string = "";
  check_teamname = async (payload: { user_id: string; team_name: string }) => {
    runInAction(() => {
      this.teamname_valid = false;
      this.check_teamname_loading = true;
      this.check_teamname_error = "";
    });
    const resp = await this.teamAPI.check_teamname(payload);
    if (resp.hasError) {
      runInAction(() => {
        this.check_teamname_loading = false;
        this.teamname_valid = false;
        this.check_teamname_error = "Network Error";
      });
      return;
    }
    runInAction(() => {
      if (resp.result?.TeamNameExists === 1) {
        this.teamname_valid = false;
        this.check_teamname_error = "Team Name already exits. Choose a different Team Name";
      } else {
        this.teamname_valid = true;
        this.check_teamname_error = "";
      }
      this.check_teamname_loading = false;
    });
    return resp.result?.TeamNameExists;
  };

}

export const TeamStoreContext = createContext(
  new TeamStore({ teamAPI: teamRepository, authstore: authstore })
);
export const useTeamStore = () => useContext(TeamStoreContext);
