import React from 'react'
import { Link } from 'react-router-dom';
import { ITeamProps } from '../../_common/model/props.interface';
import { Image } from '../../_common/widget/Image';
import team_logo_srcSet from '../../assets/images/logo-3d-compressed.png'
import { observer } from 'mobx-react';
import { useAuthStore } from '../../auth/store/auth';
export const TeamsCard = observer((props: ITeamProps) => {
    const {user} = useAuthStore()
    const { teamid, owner, member, game, gameId, platform, teamName, city, gridCol,TeamImage,  userId } = props;
    return (
        <div className={`${gridCol} col-6 team-li flex-shrink-0`}>
            <div className="team-li-conn h-100">
                {/* <button className="btn-edit edit-member-btn"><img className="img-fuild" src="https://www.iglnetwork.com/assets/images/ic-edit.png" alt="edit team" /></button> */}
                <div className="team-img" >
                <Link to={`/profile/teamdetails/${teamid}`}>
                    <Image src={TeamImage || team_logo_srcSet} alt="..."/>
                </Link>
                </div>
                <h4>
                    <span><Link to={`/profile/teamdetails/${teamid}`}>{teamName}</Link></span>
                    {/* <span>{teamName}</span> */}
                </h4>
                <div className="team-details-col">
                    <table>
                        <tbody>
                            {owner
                                ? <tr>
                                    <td>Owner</td>
                                    <td>-</td>
                                    <td><h5>{user?.id != userId ? <Link to={`/user/${userId}/profile`}>{owner}</Link> : owner}&nbsp;&nbsp;</h5></td>
                                </tr>
                                : <></>
                            }
                            <tr>
                                <td>Members</td>
                                <td>-</td>
                                <td><h5>{member}</h5></td>
                            </tr>
                            <tr>
                                <td>Game</td>
                                <td>-</td>
                                <td><h5>{game}</h5></td>
                            </tr>
                            <tr>
                                <td>Game ID</td>
                                <td>-</td>
                                <td><h5>{gameId}</h5></td>
                            </tr>
                            <tr>
                                <td>Platform</td>
                                <td>-</td>
                                <td><h5>{platform==="PLAYSTATION4"?"PS4":platform}</h5></td>
                            </tr>
                            {city
                                ? <tr>
                                    <td>City</td>
                                    <td>-</td>
                                    <td><h5>{city}</h5></td>
                                </tr>
                                : <></>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
});