import axios from "axios";
import {  INotificationParams, INotificationResp } from "../model/notifications";
import { R } from "../pkg/types";
import { CORE_API_URL } from "../../core/config";

export class NotificationsRepository {

    async getNotifications(params: INotificationParams): Promise<R<INotificationResp>> {
        try {
            const resp = await axios.get(`${CORE_API_URL}/get_notifications_new`,
            // const resp = await axios.get(`${CORE_API_URL}/get_notifications`,
                { 
                    headers:{
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                },
                    params 
                },
                  
                
            );
            const list = resp.data.NotificationList as INotificationResp;
            if(!list){
                return new R({ error: resp?.data?.message });
            }
            return new R({ result: list });
        } catch (e) {
            return new R({ error: e });
        }
    }

}

export const notificationsRepository = new NotificationsRepository();