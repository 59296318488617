
import React from 'react';
import { useAuthStore } from "../../auth/store/auth";
import { IGL_IMAGE_URL } from '../../core/config';

export const GameCarousel = () => {
  const { loggedIn } = useAuthStore()

  return (
    <div className="main-banner section" style={loggedIn ? {} : { marginTop : "70px" }}>
      <img src={`${IGL_IMAGE_URL}/Website_Banners_2.jpg`} alt="game banner" />
      <div className="banner-con section text-center">
						<div className="title-raw" id="games">
							<h1 className='my-3'>Games<span>Games</span></h1>
						</div>
					</div>
    </div>
  );
}