import React, { useEffect } from 'react'
// components
import Banner from './components/banner'
import VotingSection from './components/voting-section'
// modal
import Modal from 'react-bootstrap/Modal';
// mobx
import { observer } from "mobx-react-lite";
import { useTeamVotingStore } from './store/teamvotingstore';
import { useAuthStore } from '../auth/store/auth';
import { useProfileStore } from '../profile/store/profileInfo';
// main function
const TeamVoting = observer(() => {
  const { loadTeamVotingList, getTeams_list, modalState, modalStatechange, modalData, voteTeam, afterVoteModal, closeAfterVoteModal, getTeams_loading, vote_loading } = useTeamVotingStore();
  const { user } = useAuthStore();
  const { loadProfileInfo } = useProfileStore();
  useEffect(() => {
    const data = user?.id !== undefined ? user?.id : "0";
    loadTeamVotingList(data)
    if (user?.id) {
      loadProfileInfo(user.id);
    }
  }, [])
  const handleClick = (team_id?: string, team_vote_id?: string) => {
    const data = user?.id !== undefined ? user?.id : "0";
    voteTeam({
      vote_id: team_vote_id,
      team_id: team_id,
      vote_userid: user?.id
    }, data);
  }
  return (
    <React.Fragment>
      {getTeams_loading ?
        <div className='display-3 d-flex justify-content-center align-items-center text-white' style={{ height: "calc(100vh - 70px)" }}>Loading...</div>
        :
        <div className=" section team-voting">
          <div className="section mt80 game-lists-raw">
            <div className="container-fluid ">
              <div className="row mb-5">
                <div className="title-raw pt-5 mt80">
                  <h1><span>TEAM VOTING</span></h1>
                  {/* <p className="text-light text-capitalize font-Goldman">you can vote only one Team each section</p> */}
                </div>
              </div>
              {getTeams_list?.map((list, index) =>
                <div className='row gm-li-raw' key={index}>
                <div className="col-12 title-raw">
                    <h1>{list?.name}</h1>
                    <p className='mb-4 font-MonsterratRegular text-white fs-5'>{list?.team_vote_date}</p>
                </div>
                  <VotingSection
                    teamlist={list.teamlist}
                    modalState={modalState}
                    setModalState={modalStatechange}
                  />
                </div>
              )}

            </div>
          </div>
        </div>

      }

      {/* {getTeams_loading ?
        <div className='display-3 d-flex justify-content-center align-items-center text-white' style={{ height: "calc(100vh - 70px)" }}>Loading...</div>
        : getTeams_list?.map((list, index) =>
          <React.Fragment key={index}>
            <Banner name={list?.name} />
            <VotingSection
              teamlist={list.teamlist}
              modalState={modalState}
              setModalState={modalStatechange}
            />
          </React.Fragment>
        )} */}
      <Modal show={modalState} animation={true} className="login-modal confirm-vote-modal modal-raw choose-your-team-modalbox modal-border-raw modal ">
        <button type="button" className="btn-close-modal"
          onClick={() => modalStatechange()}
        >×</button>
        <Modal.Body>
          <div className="section gm-li-raw">
            <div className="row" style={{ textAlign: "center" }}>
              <div className="col-sm-12 col-md-12 gm-li">
                <div className="gm-col section">
                  <div className="img-box gh5">
                    <img className="img-fluid" id="team_img" src={modalData?.team_img} style={{ borderRadius: " 10px" }} alt="" />
                  </div>
                  <div className="about-game-raw" style={{ marginTop: "12px" }}>
                    <div className="about-game-conn">
                      <div className="left-conn">
                        <h4 style={{ color: "#fff", textTransform: "uppercase" }} id="team_name">{modalData?.team_name}</h4>
                        <h6 id="team_total_vote">{modalData?.team_vote_count} Vote</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row plateform-players-pupop">
                    <div className="col-sm-6 col-md-6" style={{ marginTop: "20px" }}>
                      <button className="btn cancel btn-send" onClick={() => modalStatechange()}>Cancel</button>
                    </div>
                    <div className="col-sm-6 col-md-6" style={{ marginTop: "20px" }}>
                      {vote_loading
                        ? <button className="btn vote btn-send" disabled>Loading...</button>
                        : <button className="btn vote btn-send"
                          onClick={() => handleClick(modalData?.team_id, modalData?.team_vote_id)}
                        //  onclick="user_voting()"
                        >Vote</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={afterVoteModal} animation={true} id="after_vote" className="login-modal modal-raw modal fade in show">
        <button type="button" className="btn-close-modal"
          onClick={() => closeAfterVoteModal()}
        >×</button>
        <Modal.Body>

          <div className="row">
            <div className="col-md-12">
              <div className="title-raw mb30">
                <h3>CONGRATS!!</h3>
                <p className='text-white text-uppercase'>Your Vote Has Been Submitted</p>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
});

export default TeamVoting