
import ic_signup from "../../assets/images/ic-signup.png";
import ic_complete from "../../assets/images/ic-complete.png";
import ic_win from "../../assets/images/ic-win.png";
import { StepItem } from "../widget/StepItem";

const STEP_DATA = [
    {
        step: 1,
        imgUrl: ic_signup,
        title: 'Sign Up',
        description: 'Sign up using your email ID to participate',
    },
    {
        step: 2,
        imgUrl: ic_complete,
        title: 'Compete',
        description: 'Compete for trophies, cash and bragging rights',
    },
    {
        step: 3,
        imgUrl: ic_win,
        title: 'Win & Earn',
        description: 'Win coins or exchange with cash',
    }
];

export const StepSection = () => {
    return (
        <div className="step-section-raw section">
            <div className="container">
                <ul>
                    {STEP_DATA.map(item => (
                        <StepItem
                            key={item.step}
                            step={item.step}
                            imgUrl={item.imgUrl}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};