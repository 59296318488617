import React from 'react'
import { observer } from 'mobx-react'
import { useTeamStore } from '../store/team'
import { FieldProps } from '../../_common/model/field'
import { Field, useField, useFormikContext } from 'formik'
import { FieldError } from '../../_common/widget/FieldError'
import { TeamState } from '../../_common/model/team'
import * as Yup from 'yup';

export const GameDropdown = observer((props: FieldProps) => {
    const { name = 'game', value, disabled, required } = props;

    const { games, game, game_loading,  } = useTeamStore()
    const { gameIdValidation,game_id_error,game_id_loading,games_id_val} = useTeamStore()

    const [field, meta, helpers] = useField({ name });

    const { values } = useFormikContext<TeamState>();

    React.useEffect(() => {
        if (!values.team_platform) {
            return
        }
        (async () => {
            await game(values.team_platform);
        })();
        helpers.setValue(value)
    }, [values.team_platform])
    
    // React.useEffect(() => {
    //     if (!values.team_game) {
    //         return
    //     }
        
    //     (async () => {
    //         await gameIdValidation(values.team_game);
    //     })();
    //     // helpers.setValue(value)
    //     // gameIdValidation
    
    // }, [values.team_game])

    const validate = async (value: any) => {
        var schema = Yup.string();

        let sch = required
            ? schema.required('Please select Game!')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }

    return (
        <div className="form-grop">
            <label htmlFor={name} className={`${required ? "required-field" : ""}`}>Games</label>
            <p>Choose a game your team is on</p>
            <Field component="select" className="form-control" name={name} disabled={disabled} validate={validate} style={disabled ? {background: "#b1afaf"} : {}}>
                <option value="">Select Game</option>
                {game_loading ? <option disabled>Loading..</option>
                : games?.map(option => (
                    <option key={option.GameID} value={option.GameID}>{option.GameTitle}</option>
                ))}
            </Field>
            <FieldError name={name} />
            {/* <p className="text-danger small text-start">{meta.error}</p> */}
        </div>
    )
})