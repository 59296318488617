import React from 'react'
import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { useGameStore } from '../store/game';
import { ErrorMsg } from '../../_common/widget/ErrorMsg';
import { IModalProps } from '../../_common/model/props.interface';
import { DataResponse } from '../../_common/widget/DataResponse';
import { useParams } from 'react-router-dom';
type Props = IModalProps & { id?: string }
export const GameLikedList = observer((props: Props) => {
    const { show, onClose } = props;
    const { id } = useParams();
    const { loadGameLikeMembers, game_like_error, game_like_loading, game_like_result } = useGameStore();

    React.useEffect(() => {
        loadGameLikeMembers(id!)
    }, [])
    return (
        <>
            <Modal show={show} className="modal-raw modal-border-raw ">
                <button type="button" className="btn-close-modal" onClick={onClose}>&times;</button>
                <Modal.Body>
                    <div className="row text-center">
                        <div className="col-sm-12 col-12">
                            <div className="title-raw downarrow mb30">
                                <h3>Likes<span>Likes</span></h3>
                            </div>
                        </div>
                    </div>
                    <DataResponse data={game_like_result} isLoading={game_like_loading}>
                        <ErrorMsg msg={game_like_error} />
                        {
                            game_like_result?.map((item, idx) => (
                                <div className="row mb-3" key={idx}>
                                    <>
                                        <div className="col-md-3 col-4">
                                            <img src={item.UserProfileImage} className="img-fluid" />
                                        </div>
                                        <div className="col-md-9 col-8">
                                            <h4 className="text-break text-uppercase">{item.username}</h4>
                                        </div>
                                    </>
                                </div>
                            ))
                        }
                    </DataResponse>
                </Modal.Body>
            </Modal>
        </>
    )
});