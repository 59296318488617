import React from 'react'
import { NavLink } from 'react-router-dom';
import { TOURNAMENT_TYPE } from '../../core/config';
import { observer } from 'mobx-react';
import { useAuthStore } from '../../auth/store/auth';

interface Props {
  tab: string;
  tabContent: string;
}

const TabItem = (props: Props) => {
  const { tab, tabContent } = props;
  return (
    <li className="nav-item pb-3 pb-lg-0" >
      <NavLink  className="nav-link" to={`/tournament/${tab}`} role="button">{tabContent}</NavLink>
    </li>
  )
};

export const TournamentTabSection = observer(() => {
  const {user}=useAuthStore();
  return (
    <div className="tabbing-raw section pt-5">
      <div className="tournaments-tab-raw section mobiles">
        <ul id="nav-tabs-wrapper" className="nav nav-tabs nav-tabs-horizontal" role="tablist">
          <TabItem tabContent='Upcoming' tab={TOURNAMENT_TYPE.upcoming}/>
          {user?.id && 
           <TabItem tabContent='My Tournaments' tab={TOURNAMENT_TYPE.my_tournament}/>
          }
        </ul>
      </div>
    </div >
  )
})