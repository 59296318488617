import { observer } from "mobx-react"
import { ErrorMsg } from "../../_common/widget/ErrorMsg";
import { useNewsStore } from "../store/news";

export const MoreNewsButton = observer(() => {
    const { loadMoreNews, more_data_available, news_error } = useNewsStore();
    const handleLoadMore = () => {
        loadMoreNews();
    }
    if (!more_data_available) {
        return <ErrorMsg msg={`Oops! ${news_error}.`} />
    }
    return (
        <button className="mt50 btn-sm btn-main txt-shad-none" onClick={handleLoadMore}>View More</button>
    )
});