import React from 'react'
import { Team } from '../../_common/model/team';
import { DataResponse } from '../../_common/widget/DataResponse';
import { TeamsCard } from './TeamsCard';
import { observer } from 'mobx-react';

type Props = {
    data: Team[],
    isLoading: boolean
}

const TeamSection = observer((props: Props) => {

    const { data, isLoading } = props
    return (
        <DataResponse data={data} isLoading={isLoading}>
            <div className="the-team-raw mt50 section">
                <div className="row justify-content-center align-items-stretch">
                    {
                        data?.map(item => (
                            <TeamsCard
                                key={item.TeamID}
                                userId={item.TeamUserID}
                                teamid={item.TeamID}
                                teamName={item.TeamName}
                                owner={item.username}
                                member={item.MemberCount}
                                game={item.GameTitle}
                                gameId={item.TeamUserGameID}
                                platform={item.PlatformName}
                                TeamImage={item.TeamImage}
                                gridCol='col-sm-3'
                            />
                        ))
                    }
                </div>
            </div>
        </DataResponse>

    )
})

export default TeamSection