import { makeAutoObservable, runInAction } from 'mobx'
import React from 'react'
import { Banner, IMidwayImage} from '../../_common/model/midway'
import { MidwayRepository,midwayRepository} from '../../_common/repository/midway'
export class MidwayStore {
    private midwayAPI: MidwayRepository;
   
    midwayData ?: IMidwayImage[] ;
    midway_loading = false
    midway_error = "";
    
    //
    active_page = 1;
    total_pages = 1;
    limit = 16;
    //

    //
    banners?: Banner[];
    errBanner = "";
    loadingBanner = false;
    //

    constructor(args: {
        midwayAPI: MidwayRepository
    }) {
        makeAutoObservable(this);
        this.midwayAPI = args.midwayAPI;
        // this.loadMidwayData({page_no:this.active_page});
    }

    loadMidwayData=async (params:{page_no:number})=>{
        runInAction(()=>{
            this.midway_loading = true;
        })
        const resp = await this.midwayAPI.loadGallery(params);
        if (resp.hasError) {
            runInAction(() => {
                this.midway_error=resp.error!;
                this.midway_loading = false;
            });
            return;
        }
        runInAction(()=>{
            this.midwayData=resp.result?.midjourneyimageData;
            this.total_pages = resp.result?.totalRows ? Math.ceil(resp.result?.totalRows / this.limit) : 0
            this.midway_loading = false;
            this.midway_error='';
        })
    }
    //pagination  starts
    changePage = (pageNo: number) => {
        this.active_page = pageNo;
        // this.loadMidwayData({page_no: this.active_page })
    }
    //pagination ends

    loadBanner = async (args:{device_type:string}) => {
        runInAction(() => {
          this.loadingBanner = true;
          this.errBanner = "";
        });
        const resp = await this.midwayAPI.getBannners(args);
    
        if (resp.hasError) {
          runInAction(() => {
            this.errBanner = resp.error!;
            this.loadingBanner = false;
          });
          return;
        }
    
        runInAction(() => {
          this.banners = resp.result!;
          this.loadingBanner = false;
        });
      };
}
export const MidwayStoreContext = React.createContext(new MidwayStore({ midwayAPI: midwayRepository }));
export const useMidwayStore = () => React.useContext(MidwayStoreContext);