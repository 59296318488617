
import { ContactMail } from "../../_common/widget/ContactMail"
import { PhoneContact } from "../../_common/widget/PhoneContact"

export const ContactDetails = () =>{
    return (
        <ul>
                                                    <li>
                                                        <div className="location-raw"></div>
                                                        <div className="contact-text-wrap">
                                                            <p><span>HOUSE OF GAMING PVT LTD</span></p>
                                                            <p>
                                                                Hungama Digital Media Entertainment Pvt. Ltd.<br />
                                                                SF-B-07, Art Guild House, Phoenix Market City, Lal Bahadur Shastri Rd, Kurla West, <br />
                                                                Mumbai, Maharashtra 400 070.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <ContactMail />
                                                        <div className="contact-text-wrap">
                                                            <p>support@iglnetwork.com</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <PhoneContact />
                                                        <div className="contact-text-wrap">
                                                            <p>
                                                                +91 9167332984
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
    )
}