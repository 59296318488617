import React from "react";
import Playstore from "../../assets/images/playstore.png";
import Applestore from "../../assets/images/applestore.png";
// import Mobile from "../../assets/images/mobile.png";
import Mobile from "../../assets/images/mobile 2.png";
import Socialmedia from "../widget/Socialmedia";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="footer-wrapper section ">
          <div className="container">
            <div className="row row-horizontal">
              <div className="col-md-12 col-sm-12 col-12 footer-box-wrap">
                <div className="footer-wrap">
                  <div className="right-wrap">
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-6">
                        <h4>INDIAN GAMING LEAGUE</h4>
                        <ul>
                          <li>
                            <Link to="/news">IGL News</Link>
                          </li>
                          <li>
                            <Link to="/leaderboard">Leaderboard</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4 col-sm-6 col-6">
                        <h4>Support</h4>
                        <ul>
                          <li>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </li>
                          <li>
                            <Link to="/service-terms">Terms of Service</Link>
                          </li>
                          <li>
                            <Link to="/legality">Legality</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4 col-sm-6 col-6">
                        <h4>Community</h4>
                        <ul>
                          <li>
                            <Link to="/about">About us</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact us</Link>
                          </li>
                        </ul>

                        <Socialmedia />
                      </div>
                      <div className="col-md-12 col-sm-6 col-6 footer-logo">
                        <ul>
                          <li className="mt-0 mt-md-1">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://play.google.com/store/apps/details?id=com.iglgames"
                            >
                              <img src={Playstore} alt="Playstore"/>
                            </a>
                          </li>
                          <li className="mt-3 mt-md-1">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://apps.apple.com/us/app/igl-indian-gaming-league/id1490988972?ls=1"
                            >
                              <img src={Applestore} alt="Apple Store" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="left-wrap">
                    <img src={Mobile} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12 text-center">
                <p>
                  © 2023 Indian Gaming League. All Rights Reserved. | <Link to="#">Site map</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
