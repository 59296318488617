import React from 'react'
import { IGL_IMAGE_URL } from '../../core/config'

export const Discord = () => {
    return (<>
        {/* <a href="https://discord.com/invite/iglnetwork" target="_blank" rel="noopener">
            <img src={`${IGL_IMAGE_URL}/join_discord.png`} alt="Discord" title="Join our Discord" className="img-fluid"
                style={{ maxWidth: "134px", minWidth: "100px" }} />
        </a> */}
<div className="login-raw"><a className="btn-main btn-sqr-gradient" href="https://discord.com/invite/iglnetwork" target="_blank"><i className="fa-brands fa-discord me-1"></i>Join Our Discord</a></div>
        </>
    )
}
