import api from "../../core/api";
import { Banner, IMidwayResp} from "../model/midway";
import { R } from "../pkg/types";

export class MidwayRepository {
    
    async loadGallery(params:{page_no:number}): Promise<R<IMidwayResp>> {
        try {
            const resp = await api.get(`/get_midJourneyImages`, {params});
            const result = resp.data;
            if (!resp.data.midjourneyimageData) throw resp.data.msg
            return new R({ result: result});
        }
        catch (e: any) {
            if (!e) {
                return new R({ error: "Oops! Something went wrong" })
            }
            return new R({ error: e.response.data.msg })
        }
    }
    
    async getBannners(args:{device_type:string}): Promise<R<Banner[]>> {
        try {
          const resp = await api.get(`/getMidjourneyBanners?${new URLSearchParams(args)}`);
    
          const list = resp.data.midjourneybannerData as Banner[];
          return new R({ result: list });
        } catch (e) {
          return new R({ error: e });
        }
      }
}

export const midwayRepository = new MidwayRepository();