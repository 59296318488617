import React from 'react'
import { observer } from 'mobx-react'
import { FieldProps, IDropdowns } from '../../_common/model/field'
import { Field, useField, useFormikContext } from 'formik'
import * as Yup from 'yup';
import { FieldError } from '../../_common/widget/FieldError'
import { useTeamStore } from '../../teams/store/team'
import { ITournamentboardParams } from '../model/leaderboard';

export const TeamSizeDropdown = observer((props: FieldProps) => {
    const { name='size', disabled, required } = props;

    const { team_sizes, teamSize,gameselectid } = useTeamStore()
    const [field, meta, helpers] = useField({ name });
    const { setFieldValue,values } = useFormikContext<ITournamentboardParams>();

    React.useEffect(() => { 
       
        (async () => {
            await teamSize("");
        })();
    }, [])
    React.useEffect(() => { 
        if(!values.game_id){
            (async () => {
                await teamSize("");
            })();
            return
        }
        (async () => {
            await teamSize(values.game_id!);
        })();
    }, [values.game_id])

    React.useEffect(() => {
        helpers.setValue('')
    }, [values.game_id, values.platform_id])
    
    const validate = async (value: any) => {
        var schema = Yup.string();

        let sch = required
            ? schema
                .required('Please select Size!')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }
    const [toogle, setToogle] = React.useState(false);
    return (
        // <div className="select-dropdown">
        //     <Field onClick={()=> {teamSize(gameselectid)}} component="select" name={name} disabled={disabled} validate={validate}>
        //         <option value="">Select Team Size</option>
        //         {team_sizes?.map((item,index) => (
        //                 <option value={item?.teamSize} key={index}>{item?.name}</option>
        //         ))}
        //     </Field>
        //     <FieldError name={name} />
        // </div>
        <div className="select-dropdown position-relative" onBlur={()=>{setToogle(false)}} tabIndex={6}>
            {/* @ts-ignore */}
        <label onClick={() => setToogle(!toogle)}>{(values[name]===""||values[name] === undefined) ? "Select Team Size" :  team_sizes!.find(item=>item.teamSize.toString() == values[name])?.name }</label>
        {toogle &&
            <div className='position-absolute w-100' style={{zIndex:1}}>
                <p className='cursor-pointer font-D-DinCondensed ' onClick={() => {
                        setFieldValue(name,"");
                        setToogle(false);
                    }}>Select Team Size</p>
                {team_sizes?.map(option => (
                    <p className='cursor-pointer font-D-DinCondensed '  key={option.teamSize} onClick={() => {
                        setFieldValue(name,option.teamSize);
                        teamSize(gameselectid)
                        setToogle(false);
                    }}>{option.name}</p>
                ))}
            </div>
        }
        <FieldError name={name} />
        </div>
    )
})