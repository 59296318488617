import React from 'react'
import { Link } from 'react-router-dom';
import moment from "moment"
import { observer } from 'mobx-react';
import { removeOrdinalSuffix } from '../../core/config';
interface Props {
    gameType?: number;
    imgUrl?: string;
    detailId?: string | number;
    title?: string;
    date?: string;
    coins?: string | number;
    platform?: string;
    players?: string;
    btnLabel?: string;
    gameName?: string;
    time?: string;
    teamsize?:string;
    from_campaign?:string | null
};

export const TournamentCard = observer((props: Props) => {
    const { imgUrl, detailId, title, date, coins, platform, players, btnLabel, gameName, gameType, time,teamsize, from_campaign } = props;
    const detail_endpoint = gameType == 1 ? 'details' : 'brdetails';
    let path =`/tournament/${detail_endpoint}/${detailId}`;
    if(from_campaign){
        path= path+'?from_campaign='+from_campaign  
    }
    return (
        <div className="gm-col section d-flex flex-column flex-grow-1 flex-column">
            <div className="img-box gh5 flex-shrink-0">
            <Link to={path} className='position-relative'><img className="img-fluid" src={imgUrl} alt={gameName} />
            {Number(coins)===0 && <div className='scrims-sticker'>SCRIMS</div>}
            </Link>
            </div>
            <div className="about-game-raw flex-grow-1 d-flex flex-column justify-content-between">
                <div className="about-game-conn">
                    <div className="left-conn" >
                        <h5 className='text-break'><Link to={path}>{title}</Link></h5>
                        <h6>Next Match<br />{detail_endpoint === 'brdetails'? `${moment(date, 'MMM Do, YYYY').format('MMM D, YYYY')}` : removeOrdinalSuffix(date!)}, {detail_endpoint === 'brdetails'? `${moment(time, ["h:mm:ss A"]).format("HH:mm")}` : `${moment(time, ["HH.mm.ss"]).format("HH:mm")}`}
                        {/* {date} { moment(time, ["HH.mm"]).format("hh:mm A")} */}
                        </h6>
                    </div>
                   <div className="right-conn">
                        <h3>{coins ||'0'}<br /><span>IGL Coins</span></h3>
                    </div>
                </div>
                <ul className="plateform-players-col px-0 mx-0 row align-items-center">
                    <li className="col-4 col-lg-4 px-0">
                        <span>platform</span>
                        <h6>{platform==="PLAYSTATION4"?"PS4":platform==="PLAYSTATION5"?"PS5":platform || "NA"}</h6>
                    </li>
                    <li className="col-4 col-lg-4 px-0">
                        <span>players</span>
                        <h6>{players || "NA"}</h6>
                    </li>
                    <li className="col-4 col-lg-4 px-0">
                        <span>Team Size</span>
                        <h6>{teamsize || "NA"}</h6>
                    </li>
                    <li className="col-6 mx-auto mt-3 px-0">
                        <Link to={path} className="btn-join w-100 text-center">
                            {btnLabel}
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
})