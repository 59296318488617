import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useAuthStore } from '../../auth/store/auth';
import { BackButton } from '../../teams/widget/BackButton';
import { useTournamentBracketStore } from '../store/tournamentbrackets';
import { ShareButton } from '../widget/ShareButton';
import Logo from '../../assets/images/logo-3d-compressed.png';
import { useTournamentDetailsStore } from '../store/tournamentdetails';
import { Team } from '../../_common/model/team';
import { TournamentBracketTeam } from '../../_common/model/tournament';
export const TournamentBracketsPage = observer(() => {
    const { loadTournamentBrackets, tournament_brackets, tournament_bracket_error, tournament_bracket_loading } = useTournamentBracketStore();
    const { getTournamentWinners, tournament_winners, tournament_winners_error, tournament_winners_loading } = useTournamentBracketStore();
    const { loadTournamentDetails, tournament_details_error, tournament_details_loading, tournament_details } = useTournamentDetailsStore();
    const { user } = useAuthStore();
    const { state } = useLocation();
    const { id } = useParams();
    React.useEffect(() => {
        if (state === null) {
            loadTournamentBrackets(id!, user?.id!);
            getTournamentWinners(id!, user?.id!);
            loadTournamentDetails(id!, user?.id!)
            return () => { }
        }
        loadTournamentBrackets(state?.id, user?.id!);
        getTournamentWinners(state?.id, user?.id!);
        return () => { }
    }, []);

    function ordinal(n: number) {
        var s = ["th", "st", "nd", "rd"];
        var v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }
    function HorizontalPanningContainer({ children }: any) {
        const outerContainerRef = useRef(null);
        const [isPanning, setIsPanning] = useState(false);
        const [startX, setStartX] = useState(0);
        const [scrollLeft, setScrollLeft] = useState(0);

        const handlePointerDown = (e: any) => {
            setIsPanning(true);
            //   @ts-ignore
            setStartX(e.clientX - outerContainerRef.current.getBoundingClientRect().left);
            //   @ts-ignore
            setScrollLeft(outerContainerRef.current!.scrollLeft);
            //   @ts-ignore
            outerContainerRef.current.classList.add('dragging');
            e.preventDefault();
        };

        const handlePointerUp = () => {
            setIsPanning(false);
            // Remove the 'dragging' class to re-enable text selection
            //   @ts-ignore
            outerContainerRef.current.classList.remove('dragging');
        };

        const handlePointerMove = (e: any) => {
            if (!isPanning) return;
            //   @ts-ignore
            const x = e.clientX - outerContainerRef.current.getBoundingClientRect().left;
            const walk = (x - startX) * 2;
            //   @ts-ignore
            outerContainerRef.current.scrollLeft = scrollLeft - walk;
        };
        return (
            <>
                <div className="">
                    <div className="tournament__logo-container tournament__logo-container--right">
                        <strong className="tournament__logo tournament__logo--gold"></strong>
                    </div>
                    <div
                        className="overflow-x-hidden whitespace-nowrap relative tournament scrollbarHide"
                        ref={outerContainerRef}
                        //   @ts-ignore
                        style={{ cursor: isPanning ? 'grabbing' : 'grab', touchAction: 'pan-x' }}
                        onPointerDown={handlePointerDown}
                        onPointerUp={handlePointerUp}
                        onPointerMove={handlePointerMove}
                        onTouchStart={handlePointerDown}
                        onTouchEnd={handlePointerUp}
                        onTouchMove={handlePointerMove}
                    >
                        {/* <div className="tournament__logo-container tournament__logo-container--right">
                        <strong className="tournament__logo tournament__logo--gold"></strong>
                    </div> */}
                        <div className="inline-block">

                            {children}

                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <div className="main-wrapper tournament-details-col section">
                <div className="inner-banner section">
                    <div className="container-fluid">
                        {state !== null && <BackButton />}
                        <div className="inner-banner-con">
                            <h1>{state !== null ? state?.heading : tournament_details?.TournamentTitle!}</h1>
                            <p>Teams Joined :{state !== null ? state?.teams : `${tournament_details?.TotalJoininCount}/${tournament_details?.TournamentTeams}`}  | Players In Each Team: {state !== null ? state?.size : tournament_details?.TournamentTeamMembers}</p>
                            <div className="section text-center">
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <ShareButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="common-page-raw tournament-brackets-raw section ">
                <div className="container-fluid">
                    <div className="line-arrow">
                        {/* <div className="title-raw">
                            <h3>Tournament Brackets<span>Tournament Brackets</span></h3>
                        </div> */}

                        {tournament_bracket_error !== "" ?
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12">
                                    <div className="banner-con section text-center d-flex justify-content-center align-items-center fs-5 text-white font-MonsterratRegular lh-base">{tournament_bracket_error}
                                    </div>
                                </div>
                            </div>
                            : null}
                        {(tournament_bracket_loading === true || tournament_winners_loading) ?
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12">
                                    <div className="banner-con section text-center d-flex justify-content-center align-items-center" style={{ minHeight: "350px" }}><div className="title-raw"><h1>Loading...</h1>
                                    </div>

                                    </div>
                                </div>
                            </div>
                            : <>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <HorizontalPanningContainer >
                                            <div className='tournament__grid'>
                                                {tournament_brackets?.L?.map((bracket, bracket_index) => {
                                                    const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round;
                                                    return round === "Playoff" ? null :
                                                        <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                            <div className="rounds">
                                                                <div className="timeline section">
                                                                    <div className="events">
                                                                        <ul>
                                                                            <li>
                                                                                <p style={{ fontSize: '15px' }}><Link to="#">{round}</Link></p><p>
                                                                                </p><h5><strong>Round {bracket.Round}</strong><br /> {bracket.Time}</h5>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {Array.isArray(bracket?.Groups) ?
                                                                bracket?.Groups?.map((group, group_index) => (
                                                                    <div className="tournament__match" key={group_index}>
                                                                        {group?.teams?.map((team, team_index) => (
                                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                                {/* <i className="ic-user"></i> */}
                                                                                <img src={team.TeamImage} alt="..." />
                                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{round}</i></Link>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                )) : typeof (bracket?.Groups) == 'object' ?
                                                                    Object.keys(bracket?.Groups).map((groupKey) => {
                                                                        // @ts-ignore
                                                                        let group = bracket?.Groups[groupKey];
                                                                        return (
                                                                            <div className="tournament__match" key={groupKey}>
                                                                                {group?.teams?.map((team: TournamentBracketTeam, team_index: number) => (
                                                                                    <Link
                                                                                        key={team_index}
                                                                                        className="tournament__match__team brradiusf cursor-default"
                                                                                        to="#"
                                                                                        style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}
                                                                                    >
                                                                                        <img src={team.TeamImage} alt="..." />
                                                                                        {team?.TeamName}
                                                                                        <span className='mx-3'>{team?.TeamName}</span>
                                                                                        <i className="ic-sts r2c">{round}</i>
                                                                                    </Link>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    })


                                                                    :
                                                                    <>
                                                                        {[...Array(bracket?.Groups).keys()].map((item, index) =>
                                                                            <div className="tournament__match" key={index}>

                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                            </div>
                                                                        )}
                                                                    </>
                                                            }
                                                        </div>
                                                })}
                                                {/* {tournament_brackets?.R && <div className='tournament__round' style={{width:"120px"}}></div>} */}
                                                {tournament_brackets?.Final ?
                                                    <div className='tournament__round tournament__round--first-round '>

                                                        <div className="rounds">
                                                            <div className="timeline section">
                                                                <div className="events">
                                                                    <ul>
                                                                        <li>
                                                                            <p style={{ fontSize: '15px' }}><Link to="#">{tournament_brackets?.Final?.Round}</Link></p><p>
                                                                            </p><h5><strong>Round {tournament_brackets?.Final?.Round}</strong><br /> {tournament_brackets?.Final?.Time}</h5>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {Array.isArray(tournament_brackets?.Final?.Groups) ?
                                                            tournament_brackets?.Final?.Groups?.map((group, group_index) => (
                                                                <div className={`tournament__match ${tournament_brackets?.R ? ' justify-content-center final-round-with-right' : "final-round-only-left"} `} key={group_index}>
                                                                    {tournament_brackets?.R ? <div>{group?.teams?.map((team, team_index) => (
                                                                        <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                            {/* <i className="ic-user"></i> */}
                                                                            <img src={team.TeamImage} alt="..." />
                                                                            <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{tournament_brackets?.Final?.Round}</i></Link>
                                                                    ))
                                                                    }</div> : <>{group?.teams?.map((team, team_index) => (
                                                                        <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                            {/* <i className="ic-user"></i> */}
                                                                            <img src={team.TeamImage} alt="..." />
                                                                            <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{tournament_brackets?.Final?.Round}</i></Link>
                                                                    ))
                                                                    }</>}
                                                                </div>
                                                            )) : typeof (tournament_brackets?.Final?.Groups) == 'number' ?
                                                                <>
                                                                    {[...Array(tournament_brackets?.Final?.Groups).keys()].map((item, index) =>
                                                                        <div className={`tournament__match ${tournament_brackets?.R ? ' justify-content-center final-round-with-right' : "final-round-only-left"} `} key={index}>
                                                                            {tournament_brackets?.R ? <div>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                            </div> : <>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link></>}


                                                                        </div>
                                                                    )}
                                                                </>
                                                                :
                                                                <>
                                                                    {[...Array(tournament_brackets?.Final).keys()].map((item, index) =>
                                                                        <div className="tournament__match final-round-only-left" key={index}>

                                                                            <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                <img src={Logo} alt="..." />
                                                                                <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                            <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                <img src={Logo} alt="..." />
                                                                                <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                        </div>
                                                                    )}
                                                                </>
                                                        }
                                                    </div>
                                                    : null}

                                                {tournament_brackets?.R?.map((bracket, bracket_index) => {
                                                    const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round as string;
                                                    return round === "Playoff" ? null :
                                                        <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                            <div className="rounds">
                                                                <div className="timeline section">
                                                                    <div className="events">
                                                                        <ul>
                                                                            <li>
                                                                                <p style={{ fontSize: '15px' }}><Link to="#">{round}</Link></p><p>
                                                                                </p><h5><strong>Round {bracket.Round}</strong><br /> {bracket.Time}</h5>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {Array.isArray(bracket?.Groups) ?
                                                                bracket?.Groups?.map((group, group_index) => (
                                                                    <div className="tournament__match right-bracket" key={group_index}>
                                                                        {group?.teams?.map((team, team_index) => (
                                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                                {/* <i className="ic-user"></i> */}
                                                                                <img src={team.TeamImage} alt="..." />
                                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{round}</i></Link>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                )) : typeof (bracket?.Groups) == 'object' ?

                                                                    Object.keys(bracket?.Groups).map((groupKey) => {
                                                                        // @ts-ignore
                                                                        const group = bracket?.Groups[groupKey];

                                                                        return (
                                                                            <div className="tournament__match right-bracket" key={groupKey}>
                                                                                {group?.teams?.map((team: TournamentBracketTeam, team_index: number) => (
                                                                                    <Link
                                                                                        key={team_index}
                                                                                        className="tournament__match__team brradiusf cursor-default"
                                                                                        to="#"
                                                                                        style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}
                                                                                    >
                                                                                        <img src={team.TeamImage} alt="..." />
                                                                                        <span className='mx-3'>{team.TeamName}</span>
                                                                                        <i className="ic-sts r2c">{round}</i>
                                                                                    </Link>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    })


                                                                    :
                                                                    <>
                                                                        {[...Array(bracket?.Groups).keys()].map((item, index) =>
                                                                            <div className="tournament__match right-bracket" key={index}>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                            </div>
                                                                        )}
                                                                    </>
                                                            }
                                                        </div>
                                                }).reverse()}

                                            </div>
                                            {/* L1 and R1 */}
                                           { tournament_brackets?.L1 &&  <div className='tournament__grid'>
                                                {tournament_brackets?.L1?.map((bracket, bracket_index) => {
                                                    const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round;
                                                    return round === "Playoff" ? null :
                                                        <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                            <div className="rounds">
                                                                <div className="timeline section">
                                                                    <div className="events">
                                                                        <ul>
                                                                            <li>
                                                                                <p style={{ fontSize: '15px' }}><Link to="#">{round}</Link></p><p>
                                                                                </p><h5><strong>Round {bracket.Round}</strong><br /> {bracket.Time}</h5>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {Array.isArray(bracket?.Groups) ?
                                                                bracket?.Groups?.map((group, group_index) => (
                                                                    <div className="tournament__match" key={group_index}>
                                                                        {group?.teams?.map((team, team_index) => (
                                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                                {/* <i className="ic-user"></i> */}
                                                                                <img src={team.TeamImage} alt="..." />
                                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{round}</i></Link>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                )) : typeof (bracket?.Groups) == 'object' ?
                                                                    Object.keys(bracket?.Groups).map((groupKey) => {
                                                                        // @ts-ignore
                                                                        let group = bracket?.Groups[groupKey];
                                                                        return (
                                                                            <div className="tournament__match" key={groupKey}>
                                                                                {group?.teams?.map((team: TournamentBracketTeam, team_index: number) => (
                                                                                    <Link
                                                                                        key={team_index}
                                                                                        className="tournament__match__team brradiusf cursor-default"
                                                                                        to="#"
                                                                                        style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}
                                                                                    >
                                                                                        <img src={team.TeamImage} alt="..." />
                                                                                        {team?.TeamName}
                                                                                        <span className='mx-3'>{team?.TeamName}</span>
                                                                                        <i className="ic-sts r2c">{round}</i>
                                                                                    </Link>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    })


                                                                    :
                                                                    <>
                                                                        {[...Array(bracket?.Groups).keys()].map((item, index) =>
                                                                            <div className="tournament__match" key={index}>

                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                            </div>
                                                                        )}
                                                                    </>
                                                            }
                                                        </div>
                                                })}

                                                {tournament_brackets?.R1?.map((bracket, bracket_index) => {
                                                    const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round as string;
                                                    return round === "Playoff" ? null :
                                                        <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                            <div className="rounds">
                                                                <div className="timeline section">
                                                                    <div className="events">
                                                                        <ul>
                                                                            <li>
                                                                                <p style={{ fontSize: '15px' }}><Link to="#">{round}</Link></p><p>
                                                                                </p><h5><strong>Round {bracket.Round}</strong><br /> {bracket.Time}</h5>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {Array.isArray(bracket?.Groups) ?
                                                                bracket?.Groups?.map((group, group_index) => (
                                                                    <div className="tournament__match right-bracket" key={group_index}>
                                                                        {group?.teams?.map((team, team_index) => (
                                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                                {/* <i className="ic-user"></i> */}
                                                                                <img src={team.TeamImage} alt="..." />
                                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{round}</i></Link>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                )) : typeof (bracket?.Groups) == 'object' ?

                                                                    Object.keys(bracket?.Groups).map((groupKey) => {
                                                                        // @ts-ignore
                                                                        const group = bracket?.Groups[groupKey];

                                                                        return (
                                                                            <div className="tournament__match right-bracket" key={groupKey}>
                                                                                {group?.teams?.map((team: TournamentBracketTeam, team_index: number) => (
                                                                                    <Link
                                                                                        key={team_index}
                                                                                        className="tournament__match__team brradiusf cursor-default"
                                                                                        to="#"
                                                                                        style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}
                                                                                    >
                                                                                        <img src={team.TeamImage} alt="..." />
                                                                                        <span className='mx-3'>{team.TeamName}</span>
                                                                                        <i className="ic-sts r2c">{round}</i>
                                                                                    </Link>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    })


                                                                    :
                                                                    <>
                                                                        {[...Array(bracket?.Groups).keys()].map((item, index) =>
                                                                            <div className="tournament__match right-bracket" key={index}>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                                    <img src={Logo} alt="..." />
                                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                                            </div>
                                                                        )}
                                                                    </>
                                                            }
                                                        </div>
                                                }).reverse()}

                                            </div>}
                                        </HorizontalPanningContainer>
                                    </div>
                                </div>
                                {/* playoff display */}

                                {tournament_brackets?.Playoff && tournament_brackets?.Playoff ?
                                    <div className='tournament__grid'>
                                        <div className='tournament__round tournament__round--first-round'>

                                            <div className="rounds">
                                                <div className="timeline section">
                                                    <div className="events">
                                                        <ul>
                                                            <li>
                                                                <p style={{ fontSize: '15px' }}><Link to="#">{tournament_brackets?.Final?.Round}</Link></p><p>
                                                                </p><h5><strong>PlayOff Round {tournament_brackets?.Final?.Round}</strong><br /> {tournament_brackets?.Final?.Time}</h5>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            {Array.isArray(tournament_brackets?.Final?.Groups) ?
                                                tournament_brackets?.Final?.Groups?.map((group, group_index) => (
                                                    <div className="tournament__match " key={group_index}>
                                                        {group?.teams?.map((team, team_index) => (
                                                            <Link key={team_index} className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === team.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                                {/* <i className="ic-user"></i> */}
                                                                <img src={team.TeamImage} alt="..." />
                                                                <span className='mx-3'>{team.TeamName}</span><i className="ic-sts r2c">{tournament_brackets?.Final?.Round}</i></Link>
                                                        ))
                                                        }
                                                    </div>
                                                )) : typeof (tournament_brackets?.Final?.Groups) == 'number' ?
                                                    <>
                                                        {[...Array(tournament_brackets?.Final?.Groups).keys()].map((item, index) =>
                                                            <div className="tournament__match" key={index}>

                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                    <img src={Logo} alt="..." />
                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                    <img src={Logo} alt="..." />
                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                            </div>
                                                        )}
                                                    </>
                                                    :
                                                    <>
                                                        {[...Array(tournament_brackets?.Final).keys()].map((item, index) =>
                                                            <div className="tournament__match" key={index}>

                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                    <img src={Logo} alt="..." />
                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>
                                                                <Link className="tournament__match__team brradiusf cursor-default" to="#" >

                                                                    <img src={Logo} alt="..." />
                                                                    <span className='mx-3'></span><i className="ic-sts r2c"></i></Link>

                                                            </div>
                                                        )}
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    : null}
                                {/* </React.Fragment>)} */}
                                {/* } */}

                                {/* playoff ends here */}

                                {tournament_winners && <div className='tournament__grid'>
                                    {/* winners */}

                                    {tournament_winners?.map((bracket, bracket_index) => {
                                        // const round = typeof bracket.Round == 'number' ? `R ${bracket.Round}` : bracket.Round;
                                        return (
                                            <div className="tournament__round tournament__round--first-round" key={bracket_index}>
                                                <div className="rounds">
                                                    <div className="timeline section">
                                                        <div className="events">
                                                            <ul>
                                                                <li>
                                                                    <p style={{ fontSize: '15px' }}><Link to="#">{bracket.TournamentWinnerPosition === '1' ? 'Winner' : 'RUP'}</Link></p><p>
                                                                    </p>
                                                                    <h5>{bracket.TournamentWinnerPosition === '1' ? "" : ordinal(Number(bracket.TournamentWinnerPosition) - 1)}<strong></strong><br /> {bracket.TournamentWinnerPosition === '1' ? 'Winner' : 'Runner Up'}</h5>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tournament__match tournament-winner" >
                                                    <Link className="tournament__match__team brradiusf cursor-default" to="#" style={user?.id === bracket.TeamOwnerID ? { border: "2px solid yellow" } : {}}>
                                                        <img src={bracket.TeamImage} alt="..." />
                                                        <span className='mx-3'>{bracket.TeamName}</span></Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )?.reverse()}
                                </div>}


                            </>}
                    </div>
                </div>
            </div>
        </>
    )
});