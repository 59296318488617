import React from "react";
import { makeAutoObservable, runInAction } from "mobx";
import { ILeaderboardlist, ILeaderboardParams } from "../../_common/model/leaderboard";
import { leaderBoardRepository, LeaderBoardRepository } from "../../_common/repository/leaderboard";

export class LeaderboardStore {
    leaderboard_loading = false;
    leaderboard_error = "";
    leaderboard_list: ILeaderboardlist[] = [];
    active_page = 1;
    total_pages = 1;
    limit = 20;

    leaderboardAPI: LeaderBoardRepository
    constructor(args: {
        leaderboardAPI: LeaderBoardRepository
    }) {
        makeAutoObservable(this);
        this.leaderboardAPI = args.leaderboardAPI;
    }

    loadLeaderboard = async (params: ILeaderboardParams) => {
        runInAction(() => {
            this.leaderboard_loading = true;
            this.leaderboard_error = '';
        })
        const resp = await this.leaderboardAPI.getLeaderboardList(params);
        if (resp.hasError) {
            runInAction(() => {
                this.leaderboard_loading = false;
                this.leaderboard_error = resp.error!;
            })
        }
        runInAction(() => {
            this.leaderboard_loading = false;
            this.leaderboard_list = resp.result!;
            // resp.count = '100'; // temp used
            this.total_pages = resp.count ? Math.ceil(Number(resp.count) / this.limit) : 0
        })
    }

    changePage = (pageNo: number) => {
        this.active_page = pageNo;
    }
}

const LeaderboardStoreContext = React.createContext(new LeaderboardStore({ leaderboardAPI: leaderBoardRepository }));
export const useLeaderboardStore = () => React.useContext(LeaderboardStoreContext)