import React from 'react'
import { useAuthStore } from '../../auth/store/auth'
import { useNavigate } from "react-router-dom";

export const ComingSoonPage = () => {
  const { loggedIn } = useAuthStore();
  
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <>
      <div className="main-banner section" style={{ marginTop: loggedIn ? "140px" : "90px" }}>
        <div className="container">
          <div className="ComingSoonSection">
            <h2 className='ComingSoon'> Coming Soon </h2>
            <p className='InprogressText'>We are working on something that will make your experience better. Stay tuned!</p>
            <div className='font-Goldman text-center text-white h3 cursor-pointer' onClick={goBack}><span>&#10229;</span>Go Back</div>
            {/* <div className='GoBack'><span>&#10229;</span> <span className="text-decoration-underline" onClick={goBack}>Go Back</span></div> */}
          </div>
        </div>
      </div>
    </>
  )
}
