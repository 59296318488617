import { observer } from 'mobx-react';
import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { IModalProps } from '../../_common/model/props.interface';
import { AddGameModal } from '../view/AddGameModal';

export const AddToGameProfile = observer((props: IModalProps) => {
    const {id}=useParams()
    const [show, setShow]=React.useState(false);
    const showModal = ()=> setShow(true)
    
    const closeModal = ()=> setShow(false)
    return (
        <>
            <li className="profile-col" onClick={showModal}>
                <div className="hexagon-col">
                    <Link to='#'>
                        <h4>+<span>Add</span>
                        </h4>
                    </Link>
                </div>
                <h5>To Profile</h5>
            </li>
            {
                show && <AddGameModal id={id} show={show} onClose={closeModal}/>
            }
        </>
    )
});