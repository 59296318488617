import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { ITournamentParams, TournamentResponse } from "../../_common/model/tournament";
import { tournamentsRepository, ITournamentsRepository } from "../../_common/repository/tournaments";

export class TournamentsStore {
  tournaments?: TournamentResponse[];
  errTournament = "";
  loadingTournament = false;

  tournament_filters?: ITournamentParams;

  private api: ITournamentsRepository;

  constructor(args: { api: ITournamentsRepository }) {
    makeAutoObservable(this);
    this.api = args.api;
  }

  loadTournament = async () => {
    runInAction(() => {
      this.loadingTournament = true;
      this.errTournament = "";
    });
    const resp = await this.api.getUpcomingTournaments();

    if (resp.hasError) {
      runInAction(() => {
       this.errTournament = resp.error!;
        this.loadingTournament = false;
      });
      return;
    }

    runInAction(() => {
      this.tournaments = resp.result!;
      this.loadingTournament = false;
    });
  };

  setTournamentFilters = (params: ITournamentParams) =>{
    this.tournament_filters = params;
    return this.tournament_filters;
  }
}

export const TournamentsStoreContext = createContext(new TournamentsStore({ api: tournamentsRepository }));
export const useTournamentsStore = () => useContext(TournamentsStoreContext);
