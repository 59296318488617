import React from 'react'
import { IGlobalSearchRes } from '../../_common/model/globalsearchfilter'
import { observer } from 'mobx-react';
import { Image } from '../../_common/widget/Image';
import { Link } from 'react-router-dom';

const Card = observer((props:IGlobalSearchRes) => {
    const {username,UserProfileImage, NewsImage,RoyalBattleCoverImage,TournamentCoverImage,NewsTitle,RoyalBattleTitle,TeamImage,TeamName,TournamentTitle, link="", NewsID,TeamID,TournamentID,RoyalBattleID,id, GameImagePath, GameTitle, GameID, VideoEmbedLink, TVTitle}=props;
    return (
        <div className="col-sm-3 col-6 gm-li">
            <div className="gm-col section">
                <div className="img-box">
                    {/* <img className="img-fluid" src={UserProfileImage || NewsImage || RoyalBattleCoverImage || TournamentCoverImage || TeamImage } alt="" /> */}
                    {/* @ts-ignore */}
                    <Image src={UserProfileImage || NewsImage || RoyalBattleCoverImage || TournamentCoverImage || TeamImage || GameImagePath || TVTitle }/>
                </div>
                <div className="about-game-raw">
                    <div className="about-game-conn">
                        <div className="left-conn">
                            <a href={`${link}/${NewsID || TeamID || TournamentID || RoyalBattleID || id || GameID || VideoEmbedLink}`}  target="_blank" rel="noopener noreferrer">{username || NewsTitle || RoyalBattleTitle || TeamName || TournamentTitle || GameTitle || TVTitle}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Card