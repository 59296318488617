import React from 'react'
import { GameDetailsBanner } from '../widget/GameDetailsBanner'
import { GameDetailsSection } from '../widget/GameDetailsSection'
import { useParams } from 'react-router-dom'
import { useAuthStore } from '../../auth/store/auth'
import { useGameStore } from '../store/game'
import { observer } from 'mobx-react'
import { useProfileStore } from '../../profile/store/profileInfo'

export const GameDetailsPage = observer(() => {
  const { user } = useAuthStore();
  const { game_details, loadGameDetails } = useGameStore();
  const { id } = useParams();
  const { loadProfileInfo } = useProfileStore();
  React.useEffect(() => {
    if (user?.id) {
      loadProfileInfo(user?.id);
    }
    loadGameDetails({
      user_id: user?.id,
      game_id: id
    })
  }, [])
  return (
    <>
      <GameDetailsBanner data={game_details!} />
      <GameDetailsSection data={game_details!} />
    </>
  )
});