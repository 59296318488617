import api from "../../core/api";
import { IVerificationPayload, IUserIDPayload,  gTBYGUBTSBP_new} from "../model/tournament";
import { R } from "../pkg/types";

export class VerificationRepository {
    async verify(payload: IVerificationPayload) : Promise<R<string>> {
        try {
            const resp = await api.post(`/checktournamentcode`
                , payload
                , {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });
            const result = resp.data as string;
            if (!result){
                return new R({error: resp.data.msg})
            }
            return new R({ result });
        } catch (e:any) {
            if(!e){
                return new R({ error: "Something went wrong!" })
            }
            else{
                return new R({ error: e.response.data.msg })
            }
        }
    }
    async getTeamsByUserGameIDByTeamSizeByPlatform_new(payload: IUserIDPayload) : Promise<R<gTBYGUBTSBP_new>> {
        try {
            const resp = await api.post(`/getTeamsByUserGameIDByTeamSizeByPlatform_new`
                , payload
                , {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }

                });
            const result = resp.data as gTBYGUBTSBP_new;
            if (!result){
                return new R({error: resp.data.msg})
            }
            return new R({ result });
        } catch (e:any) {
            if(!e){
                return new R({ error: "Something went wrong!" })
            }
            else{
                return new R({ error: e.response.data.msg })
            }
        }
    }
}
export const verificationRepository = new VerificationRepository();