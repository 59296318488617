import React from "react";
import { FieldProps } from "../../_common/model/field";
import * as Yup from "yup";
import { Field } from "formik";
import { FieldError } from "../../_common/widget/FieldError";
import { useTeamStore } from "../store/team";

export const MemberGameId = (props: FieldProps) => {
  const { game_id_error, game_id_loading, games_id_val } = useTeamStore();
  const { name = "id", required } = props;

  const validate = async (value: any) => {
    var schema = Yup.string();
    // let sch = required
    //     ? schema.trim()
    //     .required('This field is mandatory')
    //     .min(1,'This field is mandatory')
    //     .max(50,"Game Id cannot exceed 50 characters")
    //     // .matches(/^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/, 'Please enter a valid value')
    //     .matches(/^(?=.*[a-zA-Z0-9])[^<>[\]{}()*&%$#@!^~| ]+$/, 'Please enter a valid value')
    //     // ? schema
    //     //     .required('This field is Required!').trim().max(50, "Game ID should not exceed more than 50 chars")
    //     //     .matches(/^[A-Za-z0-9_@./#&+-]*$/, 'White space & special characters are not allowed!')  //Note:  \w to replace A-Za-z0-9_
    //     : schema;
    let sch = required
      ? (!game_id_error && games_id_val)
        ? schema
            .trim()
            .required("Game Id is mandatory")
            .min(Number(games_id_val?.minLength), `Game Id must be ${Number(games_id_val?.minLength)} characters`)
            .max(Number(games_id_val?.maxLength), `Game Id cannot exceed ${Number(games_id_val?.maxLength)} characters`)
            .matches(
                // new RegExp(games_id_val?.regExp),
                new RegExp(games_id_val?.regExp),
              games_id_val?.remarks
            )
        : schema
            .trim()
            .required("Game Id is mandatory")
            .min(1, "Game Id is mandatory")
            .max(50, "Game Id cannot exceed 50 characters")
            // .matches(/^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/, 'Please enter a valid value')
            .matches(
              /^(?=.*[a-zA-Z0-9])[^<>[\]{}()*&%$#@!^~| ]+$/,
              "Please enter valid Game Id value"
            )
          // ? schema
          //     .required('This field is Required!').trim().max(50, "Game ID should not exceed more than 50 chars")
          //     .matches(/^[A-Za-z0-9_@./#&+-]*$/, 'White space & special characters are not allowed!')  //Note:  \w to replace A-Za-z0-9_
      : schema;
    let err = await sch.validate(value).catch((err) => err);
    if (err?.errors?.length) {
      return err.errors.join(" ");
    }
  };
  return (
    <>
      <div className="form-grop">
        <Field
          name={name}
          className="form-control"
          placeholder={"Member Game ID"}
          validate={validate}
        />
        <FieldError name={name} />
      </div>
    </>
  );
};
