import React from 'react'
import { TournamentProps } from '../../_common/model/tournament';
import { observer } from 'mobx-react';

export const GameInfo = observer((props: TournamentProps) => {
    const { platform, prize, teams, size, IGLTournamentCoins,TotalCheckinCount } = props;
    
    return (
        <ul>
            <li>
                Platform
                <strong>{platform}</strong>
            </li>
            <li>
                Prize Pool
                {/* <strong>{IGLTournamentCoins || prize || '0'} IGL coins</strong> */}
                <strong> {IGLTournamentCoins !== undefined ? 
                `${IGLTournamentCoins} IGL Coins` : prize!==undefined? `${prize} IGL Coins` : '-' }
                
                </strong>
            </li>
            <li>
            Teams Joined
                <strong>{teams}</strong>
            </li>
            <li>
                Teams Checked In
                <strong>{TotalCheckinCount}</strong>
            </li>
            <li>
                Team Size
                <strong>{size}</strong>
            </li>
            
        </ul>
    )
})