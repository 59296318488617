import React, { useEffect } from 'react'
import { Header } from './components/header'
import Trendingvideosection from './components/trendingvideosection'
import TopGameList from "./components/topgamelist";
import { useAuthStore } from '../auth/store/auth';
import { useProfileStore } from '../profile/store/profileInfo';
const IgLTV = () => {
  const {user}=useAuthStore();
  const {loadProfileInfo}=useProfileStore();
  useEffect(()=>{
    if(user?.id){
      loadProfileInfo(user.id);
    }
  },[])
  return (
    <div>
        <Header/>
        <div className="igl-tv-raw common-page-raw section">
      <div className="container">

       <TopGameList/>
        <Trendingvideosection/>
        
      </div>
    </div>
    </div>
  )
}

export default IgLTV