import React from 'react'
import { IGL_IMAGE_URL } from '../../core/config'

export const ThankyouPage = () => {
    return (
        <>
            <section className="banner">
                <img src={`${IGL_IMAGE_URL}/banner1.jpg`} className="img-responsive full" />
                <div className="banner-caption thankyou">
                    <h2 className="head-d text-uppercase text-center">Thank You!</h2>
                    <p className="space-d"></p>
                    <p className="text-center mb-3" style={{ color: "#fff" }}><strong>For registering with Indian Gaming League. <br />
                        You have signed up successfully with IGL.<br />
                        Your account is now active!<br />
                    </strong></p>
                    <p className="space-d"></p>
                    <p className="space-d"></p>
                    {/* <p className="button loginout text-center">
                        <a className="btn btn-a text-uppercase" href="javascript:void(0)" data-toggle="modal" data-dismiss="modal" data-target="#login-modal">
                            <span className="btn-main bg-trans">Click here to Login</span>
                        </a>
                    </p> */}
                </div>
            </section>
        </>
    )
}