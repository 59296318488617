import { observer } from 'mobx-react'
import React from 'react'
import { useAuthStore } from '../../auth/store/auth';
import { dateTime } from '../../core/helper';
import { DataResponse } from '../../_common/widget/DataResponse';
import { useLedgerStore } from '../store/ledger';
import { LedgerDataRow } from '../widget/LedgerDataRow';
import Pagination from 'react-bootstrap/Pagination'

type Props = {}
export const LedgerListing: React.FC<Props> = observer((props) => {
    const { user } = useAuthStore()
    const { loadLedger, ledger_list, ledger_loading, active_page, changePage, limit, total_pages } = useLedgerStore();
    React.useEffect(() => {
        loadLedger(user?.id!, active_page)
    }, [active_page])
    const handlePageChange = (pageNo: number) => {
        changePage(pageNo)
    }
    const handlePreviousPage = () => {
        changePage(active_page-1)
    }
    const handleNextPage = () => {
        changePage(active_page+1)
    }

    return (
        <>
            <div className="container" style={{ marginTop: "150px" }}>
                <DataResponse isLoading={ledger_loading} data={ledger_list}>
                    <div className="table-responsive forums-table-03">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sno.</th>
                                    <th>Description</th>
                                    <th>Coins</th>
                                    <th>Type</th>
                                    <th style={{minWidth: "95px"}}>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ledger_list.map((item, idx) => (
                                    <LedgerDataRow
                                        sNo={(limit * (active_page -1)) + (idx + 1)}
                                        description={item.LadgerText}
                                        coins={item.coins!}
                                        type={item.type}
                                        date={dateTime(item.LadgerCreatedAt)}
                                        key={idx}
                                    />
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {total_pages > 1 &&
                        <Pagination className='my-4'>
                            <Pagination.Item onClick={handlePreviousPage} disabled={active_page === 1}>&laquo;</Pagination.Item>
                            {Array.from(Array(total_pages).keys()).map((_, index) => {
                                return (
                                    <Pagination.Item
                                        onClick={() => handlePageChange(index + 1)}
                                        key={index + 1}
                                        active={index + 1 === active_page}
                                    >
                                        {index + 1}
                                    </Pagination.Item>
                                );
                            })}
                            <Pagination.Item onClick={handleNextPage} disabled={active_page === total_pages}>&raquo;</Pagination.Item>
                        </Pagination>
                    }
                </DataResponse>
            </div>
        </>
    )
});