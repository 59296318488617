import { observer } from 'mobx-react';
import React from 'react'
import { useAuthStore } from './auth/store/auth'

export const PrivateRoute = observer(({ children }: { children: JSX.Element }) => {
  const { loggedIn, showLogin } = useAuthStore();
  React.useEffect(()=>{
    if (!loggedIn) {
      showLogin();
      return
    }
  },[loggedIn]);
  return children
});