import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import Search from './tabs/search';
import MainTab from './tabs/main-tab';

const TabSection = observer(() => {
    const location = useLocation();
    const TabList = [
        {
            title: "People",
            isActive: false
        },
        {
            title: "News",
            isActive: false
        },
        {
            title: "Games",
            isActive: false
        },
        {
            title: "Teams",
            isActive: false
        },
        {
            title: "IGL Tv",
            isActive: false
        },
        {
            title: "Brackets",
            isActive: false
        },
        {
            title: "Battleroyale",
            isActive: true
        },
        // {
        //     title: "Events",
        //     isActive: false
        // },
        // {
        //     title: "Mentions",
        //     isActive: false
        // },
    ];
    let element=<></>;
    switch (location.pathname) {
        case '/globals/people':
            element = <><MainTab PTYPE='Users' link='/user'/></>
            break;
        case '/globals/news':
            element = <><MainTab PTYPE='News' link='/news/details'/></>
            break;
        case '/globals/games':
            element = <><MainTab PTYPE='Games' link='/games/details'/></>
            break;
        case '/globals/teams':
            element = <><MainTab PTYPE='Teams' link='/profile/teamdetails' /></>
            break;
        case '/globals/igl-tv':
            element = <><MainTab PTYPE='TV' link='https://www.youtube.com/watch?v=' /></>
            break;
        case '/globals/brackets':
            element = <><MainTab PTYPE='TR' link='/tournament/details' /></>
            break;
        case '/globals/battleroyale':
            element = <><MainTab PTYPE='BR' link='/tournament/brdetails' /></>
            break;
        // case '/globals/mentions':
        //     element = <><Mentions /></>
        //     break;
        default:
            element = <Search />
    }
    return (
        <>
            <div className="common-page-raw challenges-raw game-lists-raw search-wrapper section" >
                <div className="container">
                    <div className="section gm-li-raw line-arrow pb10 clear-both">
                        <div className="row justify-content-center align-items-center">
                            <div className="competition-tab-raw section">
                                <ul className="nav nav-tabs" role="tablist">
                                    {TabList.map((item, index) =>
                                        <li className={`nav-item`} key={index}>
                                            <NavLink to={`/globals/${item.title.toLowerCase().replace(' ','-')}`} className={`nav-link`}>{item.title}</NavLink>
                                        </li>)}
                                </ul>
                                {/* <!-- Tab panes --> */}
                                <div className="tab-content">
                                    {element}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export default TabSection