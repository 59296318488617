import { observer } from 'mobx-react';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import ReactDOM from 'react-dom';
import { BinoChipModal } from '../../itc-bingo/components/BinoChip';
import CreateTeam from '../../teams/view/CreateTeam';
import Button from '../../teams/widget/Button';
import { useJoinTournamenStore } from '../../tournament/store/jointournament';
import { TournamentProps } from '../model/tournament';
import { EligibleTeams } from './EligibleTeams';
import { ErrorMsg } from './ErrorMsg';

type ModalProps = {
    show: boolean;
    onClose: React.MouseEventHandler<HTMLButtonElement> | any
}
type Props = ModalProps & TournamentProps & { actionType: string }
export const JoinNowModal = observer((props: Props) => {

    return ReactDOM.createPortal(
        <JoinNowForm {...props} />
        , document.getElementsByTagName('body')[0]
    )
})
const JoinNowForm = observer((props: Props) => {
    const { show, onClose } = props;
    const [showModal, setModal] = React.useState(false);
    const [error, setError] = React.useState('');
    const [accepted, setAccepted] = React.useState(false);
    const { setjoin_tournament_errornew,join_tournament_errornew} = useJoinTournamenStore();
    const handleShowModal = () => {
        if(!accepted){
            setError('Please accept that you have read the rules!')
        }
        else{
            setError('')
            setModal(true)
        }
    }
    const handleTeamClick= ()=>{
        if(!accepted){
            setError('Please accept that you have read the rules!')
        }
        else{
            setError('')
        }
    }
    const closeModal = () => {
        setModal(false)
        onClose()
    }
    const handleChange = (e: any) => {
        if (!e.target.checked) {
            setError('Please accept that you have read the rules!')
        }
        else {
            setError('')
        }
        setAccepted(e.target.checked)
    }
    if (!show) {
        return <></>;
    }
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const campaign = new URLSearchParams(window.location.search).get('from_campaign');
    return (
        <>
            <Modal show={show} className={`modal-raw choose-your-team-modalbox modal-border-raw modal fade in show ${(queryParams && campaign ==="itc") ? "itc-tournament-details-col":""}`} style={showModal?{visibility:"hidden"}:{}} 
            aria-labelledby="contained-modal-title-vcenter"
            centered>
                <button type="button" className="btn-close-modal" data-dismiss="modal" onClick={onClose}>×</button>
                <Modal.Body>
                {((queryParams && campaign ==="itc")||(window.location.pathname==="/bingo-nachos")) && <BinoChipModal/>}
               
                    <div className="upper-section section float-none">
                        <div className="row text-center">
                            <div className="col-sm-12 col-12">
                                <div className="title-raw downarrow mb30">
                                    <h3>Choose your Team<span>Choose your Team</span></h3>
                                    <p className="uppercase">Free tournament.</p>
                                </div>
                                <div className="mb30 section float-none clear" style={{ cursor: accepted ? 'pointer' : 'auto'}}>
                                    <Button label='Create A Team' onClick={()=> {handleShowModal();setjoin_tournament_errornew("")}}/>
                                </div>
                                {join_tournament_errornew && <p className='text-danger small text-center mt-2'>{join_tournament_errornew}</p>}
                                <ErrorMsg msg={error}/>
                                <p>Check the rules before check-in</p>
                                <label className="check-label"><input className="checkbox-circle" name='accepted' checked={accepted} type="checkbox" onChange={handleChange} /> I have read the rules for this game</label>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section section">
                        <div className="team-col">
                            <div className="title-divider section">
                                <h4>Available Teams</h4>
                            </div>
                            <EligibleTeams {...props} onClick={handleTeamClick} accepted={accepted}/>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {showModal && <CreateTeam {...props} show={showModal} onClose={closeModal}/>}
        </>
    )
});