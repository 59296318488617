import axios from "axios";
import { CORE_API_URL, OLD_API_URL } from "./config";

const api = axios.create({
	baseURL: `${CORE_API_URL}`,
	headers: {},
});
export const oldApi = axios.create({
	baseURL: `${OLD_API_URL}`,
	headers: {},
});
export default api