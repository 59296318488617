import React from 'react'

interface Props {
    step: number,
    imgUrl: string,
    title: string,
    description: string,
};

export const StepItem = (props: Props) => {
    const { step, imgUrl, title, description } = props;
    return (
        <li>
            <div className="step-col">
                <span>{step}</span>
                <img className="img-fluid" src={imgUrl} alt={`${imgUrl} pic`} />
                <div className="step-conn">
                    <span className="h6">{title}</span>
                    <p>{description}</p>
                </div>
            </div>
        </li>
    )
}