import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuthStore } from '../../auth/store/auth';
import { TeamsCard } from '../../teams/widget/TeamsCard'
import { TeamsPage } from '../../teams/widget/TeamsPage'
import { DataResponse } from '../../_common/widget/DataResponse';
import { useTournamentJoinedTeamStore } from '../store/joinedteams';
import { useTournamentDetailsStore } from '../store/tournamentdetails';
import { TournamentTeam } from '../../_common/model/team';

export const TournamentJoinedTeams = observer(() => {
  const { user } = useAuthStore();
  const { loadJoinedTeams, joined_teams, joined_teams_loading, showMore,resetPageCount ,loadMoreTeams} = useTournamentJoinedTeamStore();
  const { loadTournamentDetails, tournament_details, tournament_details_loading } = useTournamentDetailsStore();
  const { id } = useParams();
  React.useEffect(() => {
    resetPageCount();
    loadTournamentDetails(id!, user?.id!)
    loadJoinedTeams(id!, user?.id!)
  }, [])
  const [fitlerTeam, setFilterTeam] = React.useState<TournamentTeam[]>([]);
  const [searchString, setSearchString] = React.useState('');
  const handleFilter = (event: React.SyntheticEvent<HTMLInputElement>) => {
  setFilterTeam(joined_teams!.filter(item =>{
      if (item.TeamName.toLowerCase().trim().includes(event.currentTarget.value.trim().toLowerCase())) {
        return item;
    } else if (item.username.toLowerCase().trim().includes(event.currentTarget.value.trim().toLowerCase())) {
        return item;
    }
    }));
    setSearchString(event.currentTarget.value);
  }
  const handleViewMore = () => {
    loadMoreTeams(id!, user?.id!);
    setSearchString('');
    setFilterTeam([]);
}
  return (
    <TeamsPage
      isLoading={tournament_details_loading}
      bgImg={tournament_details?.TournamentCoverImage}
      heading={tournament_details?.TournamentTitle}
      teams={`${tournament_details?.TotalJoininCount}/${tournament_details?.TournamentTeams}`}
      coins={tournament_details?.IGLTournamentCoins+''}
    >
      <DataResponse data={joined_teams} isLoading={joined_teams_loading}>
      {joined_teams?.length !==0 ? 
      <div className='col-12'>
          <div className="row">
            <div className="col-10 col-md-6 col-lg-3 mx-auto ms-md-auto me-md-4">
              <div className="search-game-raw">
                <input type="text" className="form-control cursor-default" defaultValue='' onChange={(e) => handleFilter(e)} placeholder="Search" />
              </div>
            </div>
          </div>
        </div> : null }
        

        {(searchString!=="" && fitlerTeam.length > 0) ? 
        fitlerTeam?.map((item, index) => (
            <TeamsCard
              key={index}
              teamid={item.TeamID}
              owner={item.username}
              member={tournament_details?.TournamentTeamMembers}
              game={tournament_details?.TournamentGame}
              gameId={item.UserGameID}
              platform={tournament_details?.TournamentPlatform}
              teamName={item.TeamName}
              city={item.UserCity}
              TeamImage={item.TeamImage}
              gridCol='col-sm-4'
              userId={item.TeamUserID}   
            />))
          : (searchString!=="" && fitlerTeam.length===0 )? 
          <div className="banner-con section text-center d-flex justify-content-center align-items-center" style={{ minHeight: "350px" }}><div className="title-raw"><h1>No Data Found</h1></div></div>:
           joined_teams?.map((item,index) => (
            <TeamsCard
              key={index}
              teamid={item.TeamID}
              owner={item.username}
              member={tournament_details?.TournamentTeamMembers}
              game={tournament_details?.TournamentGame}
              gameId={item.UserGameID}
              platform={tournament_details?.TournamentPlatform}
              teamName={item.TeamName}
              city={item.UserCity}
              TeamImage={item.TeamImage}
              gridCol='col-sm-4'
              userId={item.TeamUserID}   
            />
          ))}
      </DataResponse>
      {showMore &&
        <div className="col-sm-12 col-12 text-center">
          <button className="mt50 btn-sm btn-main loadMore txt-shad-none" onClick={handleViewMore}>View More</button>
        </div> }
    </TeamsPage>
  )
});
