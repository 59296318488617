import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


export const REGISTER_VALIDATION_SCHEMA = Yup.object({
    firstname: Yup.string()
        .required("Please enter your First Name!")
        .matches(/^[A-Za-z ]*$/, 'Please use alphabets only!')
        .min(2, "Please enter atleast 2 characters!")
        .max(20, "First Name must not exceed 20 characters!"),
    lastname: Yup.string()
        .required("Please enter your Last Name!")
        .matches(/^[A-Za-z ]*$/, 'Please use alphabets only!')
        .min(2, "Please enter atleast 2 characters!")
        .max(20, "Last Name must not exceed 20 characters!"),
    username: Yup.string()
        .required('Please enter Username!')
        .matches(/^[A-Za-z0-9]*$/, 'No special characters (@, _, #, etc.) or spaces allowed')
        .min(5, 'Please enter atleast 5 characters!')
        .max(20, 'Username must not exceed 20 characters!'),
    email: Yup.string().required('Please enter your Email').email('Please enter valid Email!'),
    password: Yup.string()
        .required("Please enter your Password!")
        .matches(/^[A-Za-z0-9@#]+$/, "Only A-Z,0-9,#,@ are allowed!")
        .min(6, 'Please enter atleast 6 characters!')
        .max(40, 'Password must not exceed 40 characters!'),
    confirmpassword: Yup.string()
        .required("Please enter your Confirm Password!")
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match!'),
    mobile: Yup.string()
        .required("Please enter your Phone Number!")
        .matches(phoneRegExp, 'Please enter valid Mobile Number!')
        .min(10, "Please enter 10 digits Mobile Number!")
        .max(10, "Please do not enter more than 10 digits Number!"),
    city: Yup.string()
        .required("Please enter your city!")
        .min(2, "Please enter Proper City Name")
        .max(50, "City Name must not exceed 50 characters!"),
    termcheckage: Yup.bool().oneOf([true], 'Please agree to proceed!'),
    termcheck: Yup.bool().oneOf([true], 'Please agree to proceed!'),
    // newscheck: Yup.string().required("Required!"),
})