import React from 'react'
import { makeAutoObservable } from "mobx";
import { IPopAlertProps } from '../model/props.interface';

export class AlertStore {
    isAlertOpen: IPopAlertProps["show"];
    alertMsg: IPopAlertProps["msg"];
    alertType: IPopAlertProps["type"];
    alertHideTime: IPopAlertProps["hideTime"];
    constructor() {
        makeAutoObservable(this);
        this.isAlertOpen = false;
        this.alertMsg = "";
        this.alertType = "success";
        this.alertHideTime = 5000;
    }
    showAlert=(state: IPopAlertProps)=> {
        this.isAlertOpen = true;
        this.alertType = state.type;
        this.alertMsg = state.msg;
    }
    closeAlert=()=> {
        this.isAlertOpen = false;
    }
}
export const AlertStoreContext = React.createContext(new AlertStore());
export const useAlertStore = () => React.useContext(AlertStoreContext);