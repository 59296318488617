import React from 'react'
import { observer } from 'mobx-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { removeOrdinalSuffix } from '../../core/config';
import ScrimSticker from '../../assets/images/itc-bingo/scrims-sticker.png'
import { Image } from '../../_common/widget/Image';
import BingoIPIMAGEGreen from '../../assets/images/itc-bingo/New Gameon Logo Green.png'
import BingoIPIMAGEPurple from '../../assets/images/itc-bingo/New Gameon Logo Purple.png'
interface Props {
    gameType?: number;
    imgUrl?: string;
    detailId?: string | number;
    title?: string;
    date?: string;
    coins?: string | number;
    platform?: string;
    players?: string;
    btnLabel?: string;
    gameName?: string;
    time?: string;
    teamsize?:string;
    from_campaign?:string;
    gameId?:string;
};
const MegaTournamentCard = observer((props:Props) => {
    const { imgUrl, detailId, title, date, coins, platform, players, btnLabel, gameName, gameType, time,teamsize, from_campaign, gameId } = props;
    const detail_endpoint = gameType == 1 ? 'details' : 'brdetails';
    let path =`/tournament/${detail_endpoint}/${detailId}`;
    if(from_campaign){
        path= path+'?from_campaign='+from_campaign  
    }
    return (
        <div className="itc-TournamentCard ">
            <div className='bg-black d-flex flex-column flex-grow-1 flex-column h-100'>
                <div className="flex-shrink-0">
                    <Link className="position-relative w-100 d-block" to={path}>
                        {/* <img src={imgUrl} alt={gameName} /> */}
                        <Image src={imgUrl!} onErrorStyle={{objectFit:"contain"}} onErrorImg={(gameId ==='69'|| gameId ==='76')?BingoIPIMAGEGreen : (gameId ==='72'|| gameId ==='40')?BingoIPIMAGEPurple :""} alt={gameName??""} />
                        {Number(coins)===0 && <div className='scrims-sticker-new'><img src={ScrimSticker} alt="..." /></div>}
                    </Link>
                </div>
                <div className=" flex-grow-1 d-flex flex-column justify-content-between px-3 py-4">
                    <Link to={path} className='font-Urbanist text-white ellipsis-text'>{title}</Link>

                    <div className='px-2'>
                    <div className='date-time row '>
                        <div className='col-5 text-center'>{detail_endpoint === 'brdetails'? `${moment(date, 'MMM Do, YYYY').format('MMM D, YYYY')}` : removeOrdinalSuffix(date!)}</div>
                        <div className="col-1 d-flex justify-content-center"> <span className='breaker ' /></div>
                        <div className='col-5 text-center'>{detail_endpoint === 'brdetails'? `${moment(time, ["h:mm:ss A"]).format("HH:mm")}` : `${moment(time, ["HH.mm.ss"]).format("HH:mm")}`}</div>
                    </div>
                    </div>

                    <ul className="px-0 mx-0 row align-items-center">
                        <li className="col-4 col-lg-4 px-0 text-start">
                            <span className='font-Urbanist text-white body-text'>Platform</span>
                            <p className='font-Urbanist text-white fw-bold body-text'>{platform==="PLAYSTATION4"?"PS4":platform==="PLAYSTATION5"?"PS5":platform || "NA"}</p>
                        </li>
                        <li className="col-4 col-lg-4 px-0 text-center">
                            <span className='font-Urbanist text-white body-text'>Team Size</span>
                            <p className='font-Urbanist text-white fw-bold body-text'>{teamsize || "NA"}</p>
                        </li>
                        <li className="col-4 col-lg-4 px-0 text-end">
                            <span className='font-Urbanist text-white body-text'>Players</span>
                            <p className='font-Urbanist text-white fw-bold body-text'>{players || "NA"}</p>
                        </li>

                    </ul>
                    <div className="d-flex ">
                        <div className="mt-3 w-50 me-3 btn-join-black">
                            <span className='fw-bold'>{coins || "0"} IGL</span> COINS
                        </div>
                        <div className=" mt-3 w-50 d-flex flex-grow-1">
                            <Link to={path} className="btn-join-color w-100 text-center d-flex justify-content-center align-items-center
                             text-center text-capitalize">{btnLabel}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default MegaTournamentCard