import { makeAutoObservable, runInAction } from 'mobx';
import React from 'react'
import { Tournament } from '../../_common/model/tournament';
import { ITournamentsRepository, tournamentsRepository } from '../../_common/repository/tournaments';
import { toAlphabets, toUnixDateTime } from '../../core/helper';
import moment from 'moment';
import { JOIN_TEXT } from '../../core/config';

export class TournamentDetailsStore {
    tournamentDetailsAPI: ITournamentsRepository;

    tournament_details_loading = false;
    tournament_details_error = ""
    tournament_details?: Tournament

    current_date_time: string | number = "";
    join_tournament_text = "";
    clickable = false;
    constructor(args: {
        tournamentDetailsAPI: ITournamentsRepository

    }) {
        this.tournamentDetailsAPI = args.tournamentDetailsAPI;
        makeAutoObservable(this);
        this.current_date_time = moment().format("YYYY/MM/DD HH:mm");
        this.join_tournament_text = JOIN_TEXT.JOIN_NOW;
    };

    loadTournamentDetails = async (id: string, userId: string) => {
        runInAction(() => {
            this.tournament_details_loading = true;
            this.tournament_details_error = "";
        })
        const resp = await this.tournamentDetailsAPI.getTournamentDetails(id, userId);
        if (resp.hasError) {
            runInAction(() => {
                this.tournament_details_loading = false;
                this.tournament_details_error = resp.error!
            })
            return
        }
        runInAction(() => {
            this.tournament_details = resp.result!;
            this.joinTournaments();
            this.tournament_details_loading = false;
        })
        return resp;
    }
    joinTournaments = () => {
        // this.current_date_time = toUnixDateTime(this.current_date_time);
        let checkInStartDateTime: string | number = `${this.tournament_details?.TournamentCheckInstartDate} ${this.tournament_details?.TournamentCheckInTime}`;
        checkInStartDateTime = toUnixDateTime(checkInStartDateTime);
        let checkInEndDateTime: string | number = `${this.tournament_details?.TournamentCheckInCloseDate} ${this.tournament_details?.TournamentCheckOutTime}`;
        checkInEndDateTime = toUnixDateTime(checkInEndDateTime)

        const joinedTeamsCount = this.tournament_details?.TotalCheckinCount ?? 0;
        const teamCanJoin = Number(this.tournament_details?.TournamentTeams) ?? 0;
        if (this.tournament_details?.isexpire == 1) {
            this.join_tournament_text = JOIN_TEXT.EXPIRED;
        } else {
            if (toAlphabets(this.tournament_details?.isjoin!) === "joinnow") {
                if (this.current_date_time <= checkInEndDateTime) {
                    this.join_tournament_text = JOIN_TEXT.JOIN_NOW;
                    if(this.tournament_details?.TournamentTeams == this.tournament_details?.TotalCheckinCount){
                        this.join_tournament_text = JOIN_TEXT.JOINED_FULL;
                    }
                } else {
                    this.join_tournament_text = JOIN_TEXT.CHECK_IN_CLOSED;
                }
            } else if (toAlphabets(this.tournament_details?.isjoin!) === "joined") {
                if (toAlphabets(this.tournament_details?.ischeckin!) === "checkedin") {
                    if (this.tournament_details?.showsubmit == 0) {
                        this.join_tournament_text = JOIN_TEXT.CHECKED_IN;
                    } else if (this.tournament_details?.showsubmit == 1) {
                        this.join_tournament_text = JOIN_TEXT.SUBMIT_RESULT;
                    }
                } else if (toAlphabets(this.tournament_details?.ischeckin!) === "checkin") {
                    if ((checkInStartDateTime <= this.current_date_time) && (this.current_date_time <= checkInEndDateTime)) {
                        if (joinedTeamsCount >= teamCanJoin) {
                            this.join_tournament_text = JOIN_TEXT.CHECK_IN_FULL;
                        } else {
                            this.join_tournament_text = JOIN_TEXT.CHECK_IN;
                        }
                    } else {
                        if (toAlphabets(this.tournament_details?.isjoin!) === "joined") {
                            this.join_tournament_text = JOIN_TEXT.JOINED;
                        } else {
                            this.join_tournament_text = JOIN_TEXT.CHECK_IN_CLOSED;
                        }
                    }
                } else {
                    this.join_tournament_text = JOIN_TEXT.JOINED;
                }
            }
        }
        // this.join_tournament_text = this.tournament_details?.tournamentStatus!;
        // this.join_tournament_text = "join now"; // just to test
        if (toAlphabets(this.join_tournament_text) === "joinnow" || toAlphabets(this.join_tournament_text) === "checkin") {
            this.clickable = true;
        }
        else {
            this.clickable = false;
        }
    }
}
export const TournamentDetailsContext = React.createContext(new TournamentDetailsStore({ tournamentDetailsAPI: tournamentsRepository }));;
export const useTournamentDetailsStore = () => React.useContext(TournamentDetailsContext)