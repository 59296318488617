import React from 'react'
import {Link} from "react-router-dom"
import { IGL_IMAGE_URL } from '../../core/config'
const TopGameList = () => {
  return (
    <div className="most-popular-raw section mt80">
    {/* <!--<h3 className="title-divider">Most Popular</h3>--> */}
    <ul>
      <li>
        <Link to="/comingsoon">
          <img
            src={`${IGL_IMAGE_URL}/game-teamfight.jpg`}
            alt=""
          />
        </Link>
        <h5>Teamfight</h5>
      </li>
      <li>
        <Link to="/comingsoon">
          <img
            src={`${IGL_IMAGE_URL}/game-freefire.jpg`}
            alt=""
          />
        </Link>
        <h5>Freefire</h5>
      </li>
      <li>
        <Link to="/comingsoon">
          <img
             src={`${IGL_IMAGE_URL}/game-cod.jpg`}
            alt=""
          />
        </Link>
        <h5>Call of duty</h5>
      </li>
      <li>
        <Link to="/comingsoon">
          <img
            src={`${IGL_IMAGE_URL}/game-ludo.jpg`}
            alt=""
          />
        </Link>
        <h5>Ludo</h5>
      </li>
      <li>
        <Link to="/comingsoon">
          <img
           src={`${IGL_IMAGE_URL}/game-mario-master.jpg`}
            alt=""
          />
        </Link>
        <h5>Mario</h5>
      </li>
    </ul>
  </div>
  )
}

export default TopGameList