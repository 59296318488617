import React from "react"
import { ContactDetails } from "./ContactDetails";
import { ContactForm } from "./ContactForm";

export const ContactPage = () => {

    return (
        <>
            <div className="main-wrapper contact-banner section">
                <div className="container-fluid">
                    <div className="banner-con section">
                        <div className="main-wrapper section">
                            <div className="container">
                                <h2 className="head-f text-uppercase text-center">TELL US WHAT’S ON YOUR MIND</h2>
                                <p className="space-e"></p>
                                <div className="contact-us-form pb10">
                                    <div className="container">
                                        <ContactForm />
                                    </div>
                                </div>
                                <div className="common-page-raw section ptb10">
                                    <div className="container">
                                        <div className="contact-gaming-raw section line-arrow">
                                            <div className="contact-line-raw section">
                                                <ContactDetails/>
                                            </div>
                                            {/* <div className="contact-sub">
                                                <h4>
                                                    Subscribe to our newsletter
                                                </h4>
                                                <Form>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <textarea className="form-control" placeholder="Enter your email" id="SubscribeEmail" name="SubscribeEmail"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mgtp">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <div className="text-center">
                                                                    <button
                                                                        className="btn btn-send"
                                                                        name="SubscribeNews"
                                                                        id="SubscribeNews"
                                                                        style={{ marginTop: 15 }}
                                                                    >Subscribe</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}