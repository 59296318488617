import { makeAutoObservable, observable, runInAction } from "mobx"
import { createContext, useContext } from "react"
import { authstore, AuthStore } from "../../auth/store/auth"
import { UserProfileInfo } from "../../_common/model/user"
import { userRepository, UserRepository } from "../../_common/repository/user"

export class ProfileStore {
  auth_store?: AuthStore

  profile_loading = false
  profile_error = ''
  profile_info?: UserProfileInfo;

  other_profile_loading = false
  other_profile_error = ''
  other_profile_info?: UserProfileInfo

  constructor(args: {
    profileAPI: UserRepository,
    authstore:AuthStore,
  }) {
    makeAutoObservable(this)
    this.profileAPI = args.profileAPI
    this.auth_store = args.authstore;
  }

  profileAPI: UserRepository

  loadProfileInfo = async (userId: string) => {
    runInAction(() => {
      this.profile_loading = true
      this.profile_error = ""
    })
    const resp = await this.profileAPI.getProfileInfo(userId)
    if (resp.hasError) {
      runInAction(() => {
        this.profile_loading = false
        this.profile_error = resp.error!
      })
      return
    }    
    runInAction(() => {
      this.profile_loading = false
      this.profile_info =resp.result!
    })
  }
  resetNotiCount=()=>{
    this.profile_info = {...this.profile_info!, unread_notifications:"0"};
  }
  loadOtherProfileInfo = async (userId: string) => {
    runInAction(() => {
      this.other_profile_loading = true
      this.other_profile_error = ""
    })
    const resp = await this.profileAPI.getProfileInfo(userId)
    if (resp.hasError) {
      runInAction(() => {
        this.other_profile_loading = false
        this.other_profile_error = resp.error!
      })
      return
    }    
    runInAction(() => {
      this.other_profile_loading = false
      this.other_profile_info =resp.result!
    })
  }
}

export const ProfileStoreContext = createContext(new ProfileStore({ profileAPI: userRepository , authstore:authstore}));
export const useProfileStore = () => useContext(ProfileStoreContext);