import { Field, useField } from 'formik'
import React from 'react'
import { useAuthStore } from '../../auth/store/auth';
import { FieldProps } from '../../_common/model/field'

export const TeamMember = (props: FieldProps) => {
    const { name = 'member', value, disabled } = props;
    const { user } = useAuthStore();
    return (
        <>
            <div className="form-grop">
                <Field name={name} value={user?.username} className="form-control" placeholder="Team Member" disabled={disabled} />
            </div>
        </>
    )
}
