import React from 'react'
import Img1 from "../../assets/images/itc-bingo/follow-7.png"
import Img2 from "../../assets/images/itc-bingo/follow-6.png"
// import Img3 from "../../assets/images/itc-bingo/follow-3.png"
const BingoPackets = () => {
    return (
        <div className='followus-carousel bg-black'>
            <div className="col-8 col-md-6 col-xl-4 mx-auto d-flex justify-content-evenly">
            {/* <div>
                <img
                    className="d-block"
                    src={Img3}
                    alt="Third slide"
                />
            </div> */}
          
            <a href="https://bingo.addng.plus/nachos/hog23/index_en.html?utm_source=IGL&utm_medium=microsite&utm_campaign_type=capture&utm_campaign=IPG_Snacks_Bingo!_Nachos_HOG%20FREEFIREMAX%20X%20BGMI%20TOURNAMENT_Nov_2023&utm_campaign_id=&utm_audience=igl_microsite%20visitors&utm_audience_id=&utm_content=microsite_cta&utm_content_id=" target={"_blank"} rel="noopener noreferrer">
            <div className='img-container purple'>
                <img
                    className="d-block"
                    src={Img2}
                    alt="Second slide"
                />
            </div>
            </a>
            <a href="https://bingo.addng.plus/nachos/hog23/index_en.html?utm_source=IGL&utm_medium=microsite&utm_campaign_type=capture&utm_campaign=IPG_Snacks_Bingo!_Nachos_HOG%20FREEFIREMAX%20X%20BGMI%20TOURNAMENT_Nov_2023&utm_campaign_id=&utm_audience=igl_microsite%20visitors&utm_audience_id=&utm_content=microsite_cta&utm_content_id=" target={"_blank"}  rel="noopener noreferrer">
            <div className='img-container green'>
                <img
                    className="d-block"
                    src={Img1}
                    alt="First slide"
                />
            </div>
            </a>
            </div>
        </div>
    )
}

export default BingoPackets