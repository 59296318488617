import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
//import { Banner } from "../model/banner";
import {
  ITournamentParams,
  MyTournamentResponse,
} from "../../_common/model/tournament";
import {
  tournamentsRepository,
  ITournamentsRepository,
} from "../../_common/repository/mytournaments";

export class MyTournamentsStore {
  mytournaments?: Array<MyTournamentResponse> = [];
  errTournament = "";
  loadingTournament = false;
  more_data_available: boolean = false;
  page_no = 0;
  private api: ITournamentsRepository;

  constructor(args: { api: ITournamentsRepository }) {
    makeAutoObservable(this);
    // this.mytournaments = [];
    this.api = args.api;
  }
  loadTournament = async (params: ITournamentParams) => {
    runInAction(() => {
      this.loadingTournament = true;
      this.errTournament = "";
      this.more_data_available = false;
    });
    const resp = await this.api.getMyTournaments(params);
    if (resp.hasError) {
      runInAction(() => {
        this.errTournament = resp.error!;
        this.loadingTournament = false;
        this.more_data_available = false;
        this.page_no = 0;
      });
      return;
    }
    runInAction(() => {
      // this.mytournaments = resp.result!;
      if (resp.result!.length >= 10) {
        this.more_data_available = true;
      } else {
        this.more_data_available = false;
      }
      //
      if (this.mytournaments && this.mytournaments.length >= 1) {
        this.mytournaments = [...this.mytournaments!, ...resp.result!];
      } else {
        this.mytournaments = resp.result!;
      }
      //
      this.loadingTournament = false;
    });
  };

  loadMoreTournament = (params?: ITournamentParams) => {
    this.page_no++;
    this.loadTournament({ ...params, PNO: `${this.page_no}` });
  };
  resetPageCount = () => {
    this.page_no = 0;
    this.mytournaments = [];
  };
}

export const MyTournamentsStoreContext = createContext(
  new MyTournamentsStore({ api: tournamentsRepository })
);
export const useMyTournamentsStore = () =>
  useContext(MyTournamentsStoreContext);
