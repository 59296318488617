import React from 'react';
// import banner1 from "../../assets/images/logo.png";
import banner1 from "../../assets/images/logo-3d-compressed.png";
import Carousel from "react-bootstrap/Carousel";
import { observer } from "mobx-react";
import { useHomeStore } from "../../home/store/home";
import { useAuthStore } from "../../auth/store/auth";
import HeaderBG from "../../assets/images/itc-bingo/header.png";
import HeaderBG2 from "../../assets/images/itc-bingo/pubg.png";
import HeaderBG3 from "../../assets/images/itc-bingo/new state.png";
import HeaderBG4 from "../../assets/images/itc-bingo/call of duty.png";
import BingoLogo from "../../assets/images/itc-bingo/Powered bingo logo.png";
import '../../itc-bingo/page.css';
import { Link } from 'react-router-dom';
export const CarouselSection = observer(() => {
  const { loadBanner, loadingBanner, banners } = useHomeStore()!;
  const { loggedIn } = useAuthStore()
  const { user } = useAuthStore();
  React.useEffect(() => {
    if(window.innerWidth<768){
      loadBanner({device_type:"2"});
      return;
    }
    loadBanner({device_type:"1"});  
  }, [])
  return (
    <div className="main-banner section" style={loggedIn ? {} : { marginTop: "70px" }}>
      {loadingBanner ? <div className='title-raw d-flex justify-content-center align-items-center mx-auto' style={{ height: '450px' }}>
        <h1 className=''>Loading...</h1>
      </div> :
        <Carousel fade id="IglBanner"  interval={null}>
         <Carousel.Item className="w-100 d-block relative">
  <div className="row campaign-banner" style={{background:`url(${HeaderBG2})`}}>
          <div className="col-6 content d-flex justify-content-center align-items-center flex-column col-sm-6  " >
          <div className='w-100 text-center'>
            <img src={BingoLogo} className='bingoLogo' alt="..." />
          </div>
          <p className='heading float-none text-center text-white text-uppercase'>BINGO! Nachos Game ON CUP Games</p>
          <p className='font-Urbanist text-white carousel-body float-none text-center '>This tournament brings together top players and uprising players in these games for competitive gameplay and exciting matchups with revolutionized snacking partner</p>
         <Link to="/bingo-nachos"> <button className='bg-itc rounded fw-bold font-Urbanist float-none'>{user?.loggedin ? "More Details" : "REGISTER NOW"}</button></Link>
          </div>
          <div className="col-6 d-flex justify-content-center align-items-center px-0 mx-0">
            <div className="games-banner">
              <div className="bg-black">
               <div className='card-container'>
                <img src={HeaderBG2} alt="" />
                <p><Link to="/tournament/brdetails/42416?from_campaign=itc" style={{color:"#52C417"}}>BGMI</Link></p>
               </div>
               <div className='card-container'>
                <img src={HeaderBG} alt="" />
                <p > <Link to="/tournament/brdetails/42417?from_campaign=itc" style={{color:"#A377D6"}}>FREE FIRE INDIA </Link></p>
               </div>
               <div className='card-container'>
                <img src={HeaderBG4} alt="" />
                <p > <Link to="/tournament/brdetails/42419?from_campaign=itc"  style={{color:"#A377D6"}}>CALL OF DUTY <br/> MOBILE</Link></p>
               </div>
               <div className='card-container'>
                <img src={HeaderBG3} alt="" />
                <p><Link to="/tournament/brdetails/42418?from_campaign=itc" style={{color:"#52C417"}}>NEW STATE MOBILE</Link> </p>
               </div>
              </div>
            </div>
          </div>
  </div>
</Carousel.Item> 
          {banners && Array.isArray(banners) ? (
            banners?.map((banner, idx) => {
              return (
                <Carousel.Item
                  key={idx}
                // interval={1000}
                // style={{ width: "100%", display: "flex", justifyContent: "center" }}
                // className="w-100 d-flex justify-content-center"
                >
                  <img className="d-block w-100  h-100" src={`${banner.image_url}`} alt={banner.image} />
                </Carousel.Item>
              );
            })
          ) : (
            <Carousel.Item
              // interval={1000}
              style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <img
                className="d-block w-100"
                src={banner1}
                alt="First slide"
                style={{ objectFit: "none", height: "150px" }}
              />
            </Carousel.Item>
          )}
        </Carousel>}
    </div>
  );
});