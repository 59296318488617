import React from 'react'

type Props = {
    label: string,
    type?: 'submit' | 'reset' | 'button' | undefined,
    onClick?: React.MouseEventHandler<HTMLElement>
}
const Button = (props: Props) => {
    const {label, type, onClick}= props;
    return (
        <button
            type={type || "button"}
            className="btn-main btn-sqr-gradient"
            onClick={onClick}
        >
            {label}
        </button>
    )
}

export default Button