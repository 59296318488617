import { observer } from 'mobx-react';
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuthStore } from '../../auth/store/auth';
import { toAlphabets } from '../../core/helper';
import { TournamentProps } from '../../_common/model/tournament';
import { JoinNowModal } from '../../_common/widget/JoinNowModal';
// import { PrivateTournamentJoinNowModal } from '../../_common/widget/PrivateTournamentJoinNowModal';
import { Verification } from '../../_common/widget/Verification';
import { useCheckinTournamentStore } from '../store/checkintournament';
import { useTournamentDetailsStore } from '../store/tournamentdetails';
// import { useVerificationStore } from '../../_common/store/verification';
import { useSuccessStore } from '../../_common/store/success';
import { useAlertStore } from '../../_common/store/alert';
import { useJoinTournamenStore } from '../store/jointournament';
import { useVerificationStore } from '../../_common/store/verification';
import { CoinDeduct } from '../../_common/widget/CoinDeduct';
import { useProfileStore } from '../../profile/store/profileInfo';
import { ErrorModal } from '../../_common/widget/ErrorModal'

export const JoinTournamentButton = observer((props: TournamentProps) => {
    const { isPrivate } = props;
    const { setShowVerificationModal, setJoinNowModal, showVerificationModalValue, JoinNowModalShow } = useVerificationStore();
    const { join_tournament_text, clickable, loadTournamentDetails, tournament_details_loading } = useTournamentDetailsStore();
    const { user } = useAuthStore();
    const { loadProfileInfo, profile_info } = useProfileStore();
    const { id } = useParams();
    // const [show, setShow] = React.useState(false);
    const { setjoin_tournament_errornew } = useJoinTournamenStore();
    const { checkinTournament, checkin_tournament_loading } = useCheckinTournamentStore();
    const { showSuccess } = useSuccessStore();
    const { showAlert } = useAlertStore();
    const [showModalnew, setModalnew] = React.useState(false);
    const [errorMessage,setErrorMessage]=React.useState("");
    // const [showVerificationModal, setShowVerificationModal] = React.useState(false);
    const showModal = async () => {
        if (toAlphabets(join_tournament_text) === "joinnow" && isPrivate === "1") {
            // if(isPrivate === "0") { 
            await setShowVerificationModal(true);
        }
        else {
            if (toAlphabets(join_tournament_text) === "checkin") {

                if (props?.entryFee == "0") {
                    await checkinTournament({
                        tournament_id: id!,
                        user_id: user?.id!,
                    }).then((data) => {
                        showSuccess(data)
                        loadTournamentDetails(id!, user?.id!)
                        if(data.status==="1"){
                            showSuccess(data?.msg);
                        }
                        else{
                            setShowErrorModal(true)
                            setErrorMessage(data?.msg);
                        }
                    });
                }
                else {
                    setModalnew(true)
                }

                // console.log(res)
                return;
            }
            setJoinNowModal(true);
        }
    }
    const handleCloseVerification = () => setShowVerificationModal(false);
    const closeModal = () => setJoinNowModal(false);

    const [accepted, setAccepted] = React.useState(false);


    const closeModalnew = () => {
        setModalnew(false)
    }
    React.useEffect(() => {
        if (accepted) {
            setModalnew(false)
        }
    }, [accepted])

    const handleChange = async () => {
        // if(accepted){
        await checkinTournament({
            tournament_id: id!,
            user_id: user?.id!,
        }).then((data) => {
            loadTournamentDetails(id!, user?.id!)
            if(data.status==="1"){
                showSuccess(data);
            }
            else{
                setShowErrorModal(true)
                setErrorMessage(data?.msg);
            }
            setModalnew(false)
            loadProfileInfo(user?.id!)
        });
        // }
        // else{
        setAccepted(true)
        // }
    }
    const [showErrorModal, setShowErrorModal] = React.useState(false);

    const closeErrorModal = () => {
        setShowErrorModal(false)
    }

    // const noAmount = () => {
    //     showSuccess("No Sufficient IGL Coins in your Account.")
    // }

    return (
        <>
            {(parseInt(props?.entryFee!) <= parseInt(profile_info?.UserCredit)) ?
                <span onClick={() => { setjoin_tournament_errornew("") }}>
                    <span className="btn-ac join-btn" role={(clickable === true && checkin_tournament_loading === false) ? "button" : ""} onClick={(clickable && checkin_tournament_loading === false) ? showModal : () => { (clickable) && setModalnew(true) }}><i></i> &nbsp;{(tournament_details_loading || checkin_tournament_loading) ? 'loading...' : join_tournament_text}</span>
                    {JoinNowModalShow && <JoinNowModal show={JoinNowModalShow} onClose={closeModal} actionType={join_tournament_text} {...props} />}
                    <CoinDeduct onAcceptButtonClick={handleChange} {...props} show={showModalnew} onClose={closeModalnew} accepted={accepted} />
                    {/* <PrivateTournamentJoinNowModal show={JoinNowModalShow} onClose={closeModal} actionType={join_tournament_text} {...props} /> */}
                    <Verification {...props} show={showVerificationModalValue} onClose={handleCloseVerification} />
                </span>
                :
                <span onClick={() => { (clickable === true && checkin_tournament_loading === false) && setShowErrorModal(true) }}>
                    <span className="btn-ac join-btn" role={(clickable === true && checkin_tournament_loading === false) ? "button" : ""}><i></i> &nbsp;{(tournament_details_loading || checkin_tournament_loading) ? 'loading...' : join_tournament_text}</span>
                </span>
            }
            <ErrorModal {...props} show={showErrorModal} onClose={closeErrorModal}>
                <div className="title-raw">
                    <h3>Alert!</h3>
                </div>
                {errorMessage}
            </ErrorModal>
        </>

    )
});