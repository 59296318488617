import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuthStore } from '../../auth/store/auth';
import { TeamsCard } from '../../teams/widget/TeamsCard'
import { TeamsPage } from '../../teams/widget/TeamsPage'
import { DataResponse } from '../../_common/widget/DataResponse';
import { useBattleRoyaleJoinedTeamStore } from '../store/joinedteams';
import { useBattleRoyaleDetailsStore } from '../store/battleroyaledetails';
import { useProfileStore } from '../../profile/store/profileInfo';
import { BattleRoyaleTeam } from '../../_common/model/team';

export const BattleRoyaleJoinedTeams = observer(() => {
  const { user } = useAuthStore();
  const { loadJoinedTeams, joined_teams, joined_teams_loading, showMore, loadMoreGames, resetPageCount } = useBattleRoyaleJoinedTeamStore();
  const { loadBattleRoyaleDetails, battleroyale_details, battleroyale_details_loading } = useBattleRoyaleDetailsStore();
  const { loadProfileInfo } = useProfileStore();
  const { id } = useParams();
  React.useEffect(() => {
    resetPageCount();
    loadBattleRoyaleDetails(id!, user?.id!)
    loadJoinedTeams(id!, user?.id!);
    loadProfileInfo(user?.id!);
  }, [])
  const [fitlerTeam, setFilterTeam] = React.useState<BattleRoyaleTeam[]>([]);
  const [searchString, setSearchString] = React.useState('');
  const handleFilter = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setFilterTeam(joined_teams!.filter(item => {
      if (item.TeamName.toLowerCase().trim().includes(event.currentTarget.value.trim().toLowerCase())) {
        return item;
      } else if (item.username.toLowerCase().trim().includes(event.currentTarget.value.trim().toLowerCase())) {
        return item;
      }
    }));
    setSearchString(event.currentTarget.value);
  }
  const handleViewMore = () => {
    loadMoreGames(id!, user?.id!);
    setSearchString('');
    setFilterTeam([]);
  }
  return (
    <TeamsPage
      isLoading={battleroyale_details_loading}
      bgImg={battleroyale_details?.RoyalBattleCoverImage}
      heading={battleroyale_details?.RoyalBattleTitle}
      teams={`${battleroyale_details?.JoinCount} / ${Number(battleroyale_details?.RoyalBattleNumberofGroups) * Number(battleroyale_details?.RoyalBattleTeamsInGroup)}`}
      coins={battleroyale_details?.IGLRoyaleBattleCoins}
    >
      <DataResponse data={joined_teams} isLoading={joined_teams_loading}>
        {joined_teams?.length !== 0 ? <div className='col-12'>
          <div className="row">
            <div className="col-10 col-md-6 col-lg-3 mx-auto ms-md-auto me-md-4">
              <div className="search-game-raw">
                <input type="text" className="form-control cursor-default" defaultValue='' onChange={(e) => handleFilter(e)} placeholder="Search" />
              </div>
            </div>
          </div>
        </div> : null}

       
          {fitlerTeam.length > 0 ? fitlerTeam?.map((item, index) => (
            <TeamsCard
              userId={item.UserID}
              teamid={item.BRTeamTeamID}
              owner={item.username}
              member={battleroyale_details?.RoyalBattleTeamSize}
              game={battleroyale_details?.GameTitle}
              gameId={item.TeamUserGameID}
              platform={battleroyale_details?.PlatformName}
              teamName={item.TeamName}
              city={item.UserCity}
              gridCol='col-sm-4'
              TeamImage={item.TeamImage}
              key={index}
            />
          )) :
          (searchString!=="" && fitlerTeam.length===0 )?<div className="banner-con section text-center d-flex justify-content-center align-items-center" style={{ minHeight: "350px" }}><div className="title-raw"><h1>No Data Found</h1></div></div> : 
          joined_teams?.map((item, index) => (
            <TeamsCard
              userId={item.UserID}
              teamid={item.BRTeamTeamID}
              owner={item.username}
              member={battleroyale_details?.RoyalBattleTeamSize}
              game={battleroyale_details?.GameTitle}
              gameId={item.TeamUserGameID}
              platform={battleroyale_details?.PlatformName}
              teamName={item.TeamName}
              city={item.UserCity}
              gridCol='col-sm-4'
              TeamImage={item.TeamImage}
              key={index}
            />
          ))}
      </DataResponse>
      { showMore &&
        <div className="col-sm-12 col-12 text-center">
          <button className="mt50 btn-sm btn-main loadMore txt-shad-none" onClick={handleViewMore}>View More</button>
        </div> }
    </TeamsPage>
  )
});
