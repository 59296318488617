import { ErrorMessage, Field } from 'formik';
import React from 'react'

type Props = {
    name: string,
}

export const BankDetailCheckBox = (props: Props) => {
    const { name } = props;
    return (
        <div className="form-group d-flex align-items-center">
            <Field type="checkbox" name={name} id={name}/>
            <label className='text-white small text-start ps-1' htmlFor={name}>Save bank details for later use</label>
            <ErrorMessage name={name} className="text-danger" component="span" />
        </div>
    )
}