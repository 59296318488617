import React, { useEffect, useState, Suspense } from 'react';
import { MegaTournaments } from '../../tournament/view/MegaTournaments';
import TrendingVideoSection from './TrendingVideoSection';
import { observer } from 'mobx-react';
// import AdSlot from '../../_common/view/AdsComponent/AD_Index';

export const OngoingEventSection = observer(() => {
    const AdSlot = React.lazy(()=> import('../../_common/view/AdsComponent/AD_Index'));
    const [isMobile, setIsMobile] = useState<boolean>(false);
    useEffect(() => {
        // Check the window width and set isMobile accordingly
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as per your requirement
        };

        handleResize(); // Call once on initial load

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="ongoing-events-raw section">
            <div className="container">
                <div className="game-lists-raw ongoing-competition-raw section">
                    <div className="title-raw">
                        <h1>
                            Ongoing Competition
                            <span>Competition</span>
                        </h1>
                    </div>
                    <MegaTournaments />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* {!isMobile && <AdSlot adUnitPath="" adSize={[728, 90]} />}
                            {isMobile && <AdSlot adUnitPath="" adSize={[300, 250]} />} */}
                            {!isMobile && <Suspense fallback={<></>}>
                                <AdSlot adUnitPath="/317733190/IGL_Web_Home_728x90_Ad2" adSize={[728, 90]} />
                            </Suspense>}
                            {isMobile && <Suspense fallback={<></>}>
                                <AdSlot adUnitPath="/317733190/IGL_Web_Home_300x250_Ad2" adSize={[300, 250]} />
                            </Suspense>}
                        </div>
                    </div>
                </div>
                <TrendingVideoSection />
            </div>
        </div>
    );
})