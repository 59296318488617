import React from 'react';
import ReactDOM from 'react-dom';

interface Props {
    children?: React.ReactNode;
    type?: string;
};

export const GameRules: React.FC<Props> = (props) => {
    const { children, type } = props;

    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const from_campaign = new URLSearchParams(window.location.search).get('from_campaign');
    return (
        <>
            {type === "text"
                ? <h6 className="pointer" onClick={handleShow}>Rules <span></span></h6>
                : <a href="#" data-toggle="modal" data-target="#rules-modal" className="btn-ac ic-rules" onClick={handleShow}><span>Rules</span></a>
            }
            {show && ReactDOM.createPortal(
                <div className={`rules-modal  modal-raw modal fade in show d-block ${queryParams && from_campaign==="itc"?" itc-tournament-details-col":"" }`} aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <button type="button" className="btn-close-modal" data-dismiss="modal" onClick={handleClose}>×</button>
                            <div className="modal-body">
                                <div className="rules-col section relative">
                                    <div className={`title-raw ${queryParams && from_campaign==="itc"?"title-raw-itc":"" }`}>
                                        <h2>Rules<span>Rules</span></h2>
                                    </div>
                                    <div className='text-white'>
                                    {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                , document.getElementsByTagName('body')[0]
            )
            }
        </>
    );
}