import { Field } from 'formik'
import React from 'react'
import * as Yup from 'yup';
import { FieldProps } from '../../_common/model/field';
import { FieldError } from '../../_common/widget/FieldError';

export const TeamName = (props: FieldProps) => {
    const { name = "team_name", required } = props

    const validate = async (value: any) => {
        var schema = Yup.string();
        let sch = required
            ? schema
                .required('Please enter Team Name!')
                .matches(/^[A-Za-z0-9]*$/, 'No special characters (@, _, #, etc.) or spaces allowed')
                .max(20, 'Team Name must not exceed 20 characters!')
            : schema;
        let err = await sch.validate(value).catch(err => err)
        if (err?.errors?.length) {
            return err.errors.join(" ")
        }
    }

    return (
        <div className="form-grop">
            <label htmlFor={name} className={`${required ? "required-field" : ""}`}>Team name</label>
            <p>Your team will be known as this may be changed later</p>
            <Field
                name={name}
                className="form-control"
                validate={validate}
            />
            <FieldError name={name} />
        </div>
    )
}
