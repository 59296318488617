import React,{Suspense} from "react";

import { HomeStore, HomeStoreContext } from "./store/home";
import { homeRepository } from "../_common/repository/home";
import { OngoingEventSection } from "./view/OngoingEventSection";
import { StepSection } from "./view/StepSection";
import { CarouselSection } from "../_common/widget/CarouselSection";
import { Discord } from "../_common/widget/Discord";
import FindUsOn from "./view/FindUsOn";
// import AdSlot from "";

export const HomePage = () => {
  const store = new HomeStore({ homeAPI: homeRepository });
  return (
    <HomeStoreContext.Provider value={store}>
      <Home />
    </HomeStoreContext.Provider>
  );
};

const Home = () => {
  const AdSlot = React.lazy(()=> import('../_common/view/AdsComponent/AD_Index'));
 //ads work starts
 const [isMobile, setIsMobile] = React.useState<boolean>(false);
 React.useEffect(() => {
     // Check the window width and set isMobile accordingly
     const handleResize = () => {
         setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as per your requirement
     };

     handleResize(); // Call once on initial load

     window.addEventListener("resize", handleResize);
     return () => {
         window.removeEventListener("resize", handleResize);
     };
 }, []);
 //   ads work ends
  return (
    <div>
      <CarouselSection />
      <ButtonSection />
      <StepSection />

      {!isMobile && <Suspense fallback={<></>}>
      <AdSlot adUnitPath="/317733190/IGL_Web_Home_728x90_Ad1" adSize={[728, 90]} />
      </Suspense>}
      {isMobile && <Suspense fallback={<></>}>
      <AdSlot adUnitPath="/317733190/IGL_Web_Home_300x250_Ad1" adSize={[300, 250]} />
      </Suspense>}
      {/* {!isMobile && <AdSlot adUnitPath="/317733190/IGL_Web_Home_728x90_Ad1" adSize={[728, 90]} />}
      {isMobile && <AdSlot adUnitPath="" adSize={[300, 250]} />} */}
      <OngoingEventSection />     
      <FindUsOn/>
    </div>
  );
};

const ButtonSection = () => {
  return (
    <>
      <div className="container text-center d-md-none">
        <div className="row pt-4">
          <Discord />
        </div>
      </div>
    </>
  )
}