import React from 'react'
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import CircularProgress from '@mui/material/CircularProgress';
import { FieldError } from '../_common/widget/FieldError';
import { useAuthStore } from '../auth/store/auth';
import {redirect, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
const VALIDATION_SCHEMA = Yup.object({
    password: Yup.string()
        .required("Please enter your Password!")
        .matches(/^[A-Za-z0-9@#]+$/, "Only A-Z,0-9,#,@ are allowed!")
        .min(6, 'Please enter atleast 6 characters!')
        .max(40, 'Password must not exceed 40 characters!'),
        confirm_password: Yup.string()
        .required("Please enter your Confirm Password!")
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match!'),
});

const ResetPassword = observer(() => {
    const {resetPasswordResp,resetPasswordLoading,resetPasswordError,resetPassword, showLogin}=useAuthStore();
    const {id}=useParams();
    React.useEffect(()=>{
        if(!id){
            redirect('/');
        }
    },[id])
    const handleSubmit = async (values: any) => {
        resetPassword({user_id:id!,newpassword:values.password}).then(()=>{
            if(resetPasswordResp.msg!==""){
                setTimeout(()=>{
                    showLogin();
                },1000)
            }
        })
    }
    return (
        <div className='container-fluid min-vh-100 justify-content-center align-items-center d-flex flex-column'>
            <div className='row w-100'>
                <div className='col-10 mx-auto col-md-6 col-lg-5'>
                    <div className="title-raw mt-5 pt-2">
                        <h1>RESET PASSWORD</h1>
                    </div>
                </div>
            </div>
            <div className='row w-100'>
                <div className='col-10 mx-auto col-md-6 col-lg-5'>
                    <Formik
                        initialValues={{
                            password: "",
                            confirm_password: ""
                        }}
                        validationSchema={
                            VALIDATION_SCHEMA
                        }
                        onSubmit={(values, { resetForm }) => {
                            handleSubmit(values);
                            // resetForm();
                        }}
                    >
                        <Form>
                            <div className="contact-form">
                                <div className="row">
                                    <div className='col-12 justify-content-center d-flex mt-4'>
                                        <div className="floating-label-group section">
                                            <Field
                                                type="password"
                                                className="form-control"
                                                name="password"
                                            />
                                            <label className="floating-label">Password</label>
                                            <FieldError name={"password"} />
                                        </div>
                                    </div>
                                    <div className='col-12 justify-content-center d-flex mt-3'>
                                        <div className="floating-label-group section">
                                            <Field
                                                type="password"
                                                className="form-control"
                                                name="confirm_password"
                                            />
                                            <label className="floating-label">Confirm Password</label>
                                            <FieldError name={"confirm_password"} />
                                        </div>
                                    </div>
                                    {resetPasswordError && <p className='text-danger small text-start'>{resetPasswordError}</p>}
                                    {resetPasswordResp && <p className='text-success small text-start mb-4'>{resetPasswordResp.msg}</p>}
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 ">
                                        <button className={`btn-main btn-sqr-gradient px-4 text-white  d-block mx-auto mt-0`} type='submit'>
                                            
                                             {resetPasswordLoading  ?  <CircularProgress size={24} sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '60%',
                                            marginTop: '-12px',
                                        }}/>: 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
})

export default ResetPassword