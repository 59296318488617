import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { ITournamentParams, Tournament } from "../../_common/model/tournament";
import { tournamentsRepository, ITournamentsRepository } from "../../_common/repository/tournaments";

export class UpcomingTournamentStore {
  upcoming_tournaments?: Tournament[];
  upcoming_tournaments_error = "";
  upcoming_tournaments_loading = false;

  // 
  page_no = 1;
  more_data_available: boolean = false;
  // 

  private api: ITournamentsRepository;

  constructor(args: { api: ITournamentsRepository }) {
    makeAutoObservable(this);
    this.api = args.api;
  };

  loadUpcomingTournaments = async (params: ITournamentParams) => {
    runInAction(() => {
      this.upcoming_tournaments_loading = true;
      this.upcoming_tournaments_error = "";
      this.more_data_available = false;
    });
    const resp = await this.api.getUpcomingTournaments(params);

    if (resp.hasError) {
      runInAction(() => {
        this.upcoming_tournaments_error = resp.error!;
        this.upcoming_tournaments_loading = false;
        this.upcoming_tournaments=[];
        this.more_data_available = false;
      });
      return;
    }

    runInAction(() => {
      // this.upcoming_tournaments = resp.result!;
   
      if (resp.result!.length >= 10) {
        this.more_data_available = true;
      } else {
        this.more_data_available = false;
      }

      //
      if (this.upcoming_tournaments && this.upcoming_tournaments.length >= 1) {
        this.upcoming_tournaments = [...this.upcoming_tournaments!, ...resp.result!];
      } else {
        this.upcoming_tournaments = resp.result!;
      }
      //
      this.upcoming_tournaments_loading = false;
    });
  };

  resetPageCount = () => {
    this.page_no = 1;
    this.upcoming_tournaments = [];
  };

  loadMoreTournament = (params?: ITournamentParams) => {
    this.page_no++;
    this.loadUpcomingTournaments({ ...params, PNO: `${this.page_no}` });
  };

}

export const UpcomingTournamentStoreContext = createContext(new UpcomingTournamentStore({ api: tournamentsRepository }));
export const useUpcomingTournamentStore = () => useContext(UpcomingTournamentStoreContext);
