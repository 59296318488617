import { fontSize } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react'

import { Modal } from 'react-bootstrap'
import { useProfileStore } from '../../profile/store/profileInfo';
import { IModalProps } from '../model/props.interface';

type Props = IModalProps & { children?: React.ReactNode }

export const CoinDeduct = observer((props: any) => {
    const { profile_info } = useProfileStore()
    const { show, onClose,onAcceptButtonClick, children } = props;
    // console.log(props)
    if(!show){
        return <></>
    }
    return (
        <Modal show={show} animation={true} id="after_vote" className="login-modal modal-raw modal fade in show" onHide={onClose}>
            <button type="button" className="btn-close-modal"
                onClick={onClose}
            >×</button>
            <Modal.Body className='px-5'>

                <div className="row">
                    <div className="col-md-12">
                        <div className="title-raw">
                        <h3 className='mb-5'>Confirmation<span>Confirmation</span></h3>
                            {/* <p className='text-white text-uppercase'>Confirmation</p> */}
                            <div className='row'>
                                <div className='col-6'>
                                    <p className='text-white text-uppercase text-end'>Joining Fees</p>
                                </div>
                                <div className='col-6'>
                                    <p className='text-white text-uppercase text-start'>{props?.entryFee} IGL Coins</p>
                                </div>
                            </div>
                            {/* <p style={{fontSize:"14px", float:"none"}} className='text-white mt-3'>IGL coin will be deducted from your wallet</p> */}
                            {/* <p style={{fontSize:"14px", float:"none"}} className='text-white mt-3'>Current IGL Coins in Account {profile_info?.UserCredit} IGL Coins <br/>
                            Balance after deduction will be {profile_info?.UserCredit - props?.entryFee} IGL Coins </p>
                            <div style={{borderTop:"1px solid #3385cc"}} className=''></div> */}

                            <div className='row'>
                                <div className='col-6'>
                                    <p className='text-white text-uppercase text-end'>Current Balance</p>
                                </div>
                                <div className='col-6'>
                                    <p className='text-white text-uppercase text-start'>{profile_info?.UserCredit} IGL Coins</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <p className='text-white text-uppercase text-end'>Final Balance</p>
                                </div>
                                <div className='col-6'>
                                    <p className='text-white text-uppercase text-start'>{profile_info?.UserCredit - props?.entryFee} IGL Coins</p>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6  d-flex'>
                                <button onClick={onClose} className="btn-main btn-two-line ms-auto" type="submit" style={{ position: 'relative', cursor:'pointer',background:"none" }}>
                                    Cancel
                                </button>
                                </div>
                                <div className='col-6 d-flex'>
                                <button onClick={()=>onAcceptButtonClick(true)} className="btn-main btn-two-line me-auto" type="submit" style={{ position: 'relative', cursor:'pointer' }}>
                                    {/* {creating || check_teamname_loading ? 'Loading...' : 'Create Team'}
                                    {(creating || check_teamname_loading) && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )} */}
                                    Accept
                                </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
})
