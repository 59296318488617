import React from 'react'
import { useAuthStore } from '../../auth/store/auth'

export const LeaderboardBanner = () => {
    const { loggedIn } = useAuthStore()
    return (
        <>
            <div className="main-banner leaderboard-banner-raw section" style={loggedIn ? {} : { marginTop: "70px" }}>
                <div className="inner-banner section" style={{ backgroundImage: 'url(https://www.iglnetwork.com/assets/images/inner-banner.jpg)', height: '250px' }}>
                    <div className="container-fluid">
                        <div className="inner-banner-con">
                            <div className="title-raw">
                                <h1>Leaderboard<span>Leaderboard</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
