import React, { useEffect } from 'react'
import Button from '../widget/Button'
import { GameDropdown } from '../widget/GameDropdown'
import { PlatformDropdown } from '../widget/PlatformDropdown'
import { TeamImage } from '../widget/TeamImage'
import { TeamName } from '../widget/TeamName'
import { TeamSizeDropdown } from '../widget/TeamSizeDropdown'
import { Formik, Form, ErrorMessage } from 'formik'
import { ITeamPayload, MemberList, TeamState } from '../../_common/model/team'
import { TeamMembersHeading } from '../widget/TeamMembersHeading'
import { TeamMember } from '../widget/TeamMember'
import { MemberGameId } from '../widget/MemberGameId'
import { TournamentProps } from '../../_common/model/tournament'
import { observer } from 'mobx-react'
import { TeamMemberAutocomplete } from '../widget/TeamMemberAutocomplete'
import { useTeamStore } from '../store/team'
import { useAuthStore } from '../../auth/store/auth'
import { IModalProps } from '../../_common/model/props.interface'
import { useJoinBatttleRoyaleStore } from '../../battleroyale/store/joinbattleroyale'
import { useJoinTournamenStore } from '../../tournament/store/jointournament'
import { GAME_TYPE } from '../../core/config'
import { useParams } from 'react-router-dom'
import { useBattleRoyaleDetailsStore } from '../../battleroyale/store/battleroyaledetails'
import { useTournamentDetailsStore } from '../../tournament/store/tournamentdetails'
import CircularProgress from '@mui/material/CircularProgress';
import { useSuccessStore } from '../../_common/store/success'
import { ErrorMsg } from '../../_common/widget/ErrorMsg'
import { clearObserving } from 'mobx/dist/internal'
import { CoinDeduct } from '../../_common/widget/CoinDeduct'
import { ErrorModal } from '../../_common/widget/ErrorModal'

const TEAM_MEMBER = 'team_member';
// const SUBSTITUTE = 'sibstitute';

const initialState: TeamState = {
    team_name: "",
    team_platform: "",
    team_game: "",
    team_size: "",
    team_image: [],
    team_members: [],
    in_game_id: "",
    team_member_name_1: "",
    team_member_id_1: "",
    team_member_name_2: "",
    team_member_id_2: "",
    team_member_name_3: "",
    team_member_id_3: "",
    team_member_name_4: "",
    team_member_id_4: "",
    team_member_name_5: "",
    team_member_id_5: "",
    team_member_name_6: "",
    team_member_id_6: "",
    team_member_name_7: "",
    team_member_id_7: "",
    team_member_name_8: "",
    team_member_id_8: "",
};
type Props = IModalProps & TournamentProps;

export const CreateTeamForm = observer((props: Props) => {
    const { addTeamMember, newTeam,create_error,success_msg, myTeam, creating, check_teamname, check_teamname_loading } = useTeamStore();
    const { user } = useAuthStore();
    const { id } = useParams();
    const { joinBattleRoyale, join_battleroyale_loading } = useJoinBatttleRoyaleStore();
    const { joinTournament, join_tournament_errornew, join_tournament_loading } = useJoinTournamenStore();
    const { loadBattleRoyaleDetails } = useBattleRoyaleDetailsStore();
    const { loadTournamentDetails } = useTournamentDetailsStore();
    const { showSuccess } = useSuccessStore();
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const handleSubmit = async (values: any, setErrors: any, setFieldValue: any) => {
        setShowErrorModal(false);
        let team_members: MemberList[] = []
        let err_count = 0;
        if (
            values.team_image?.type === "image/apng" ||
            values.team_image?.type === "image/gif" ||
            values.team_image?.type === "image/ico" ||
            values.team_image?.type === "image/cur" ||
            values.team_image?.type === "image/jpg" ||
            values.team_image?.type === "image/jpeg" ||
            values.team_image?.type === "image/jfif" ||
            values.team_image?.type === "image/pjpeg" ||
            values.team_image?.type === "image/pjp" ||
            values.team_image?.type === "image/png" ||
            values.team_image?.type === "image/*"
        ) {
            // console.log('setting files image')
            setErrors({ "team_image": "" })
        }
        else {
            if (values.team_image?.length > 0 || values.team_image?.length === undefined) {
                setFieldValue({ "team_image": [] })
                setErrors({ "team_image": "File not supported" })
                err_count = err_count + 1;

            }
        }
        // values.team_name
        await check_teamname({ user_id: user?.id!, team_name: values.team_name }).then(data => {
            if (data === 1) {
                err_count = err_count + 1;
                setErrors({ "team_name": "Team Name already exits. Choose a different Team Name" })
                return;
            }
        });
        if (err_count > 0)
            return;
        else {
            addTeamMember(values, team_members, values.team_size, TEAM_MEMBER)
            var duplicateIndices: any = [];
            var duplicateIndicesiddd: any = [];
            const errors: any = {};
            team_members.forEach((member, index) => {
                for (let i = index + 1; i < team_members.length; i++) {
                    const nextMember = team_members[i];
                    if (member.memberusername === nextMember.memberusername) {
                        duplicateIndices.push(index);
                        duplicateIndices.push(i);
                    }
                    if (member.team_member_game_id === nextMember.team_member_game_id) {
                        duplicateIndicesiddd.push(index);
                        duplicateIndicesiddd.push(index + 1);
                    }
                    if(values.in_game_id === nextMember.team_member_game_id){
                        errors["in_game_id"] = "Duplicate Game Id found";
                        duplicateIndicesiddd.push(i);
                    }
                }
            });
            if (duplicateIndices.length > 0 || duplicateIndicesiddd.length > 0) {
                
                duplicateIndices.forEach((index: any) => {
                    const fieldName = `team_member_name_${index + 1}`;
                    errors[fieldName] = "Duplicate Team Member found";
                });
                duplicateIndicesiddd.forEach((index: any) => {
                    const fieldName = `team_member_id_${index + 1}`;
                    errors[fieldName] = "Duplicate Game Id found";
                });
                setErrors(errors);
            }
            if (duplicateIndices.length === 0 && duplicateIndicesiddd.length === 0) {
                let newteamPayload:ITeamPayload={
                    memberlist: JSON.stringify(team_members),
                    TeamGameUserID: values.in_game_id,
                    TeamWebsite: 'NA',
                    TeamGameID: values.team_game,
                    user_id: user?.id!,
                    TeamPlatformID: values.team_platform,
                    TeamName: values.team_name,
                    TeamImage: values.team_image
                }
                if(props.gameType!==undefined){
                    newteamPayload={
                        ...newteamPayload,
                        tournamentId:id,
                        tournament_type: props.gameType === GAME_TYPE.tournament? '1': props.gameType === GAME_TYPE.battleroyale?'2':''
                    }
                }
                let resp = await newTeam(newteamPayload);
                if(resp?.status==="0"){
                    // showSuccess(resp?.msg)
                    setShowErrorModal(true);
                    return 
                }
                let msg = resp?.msg as any;
                if(resp?.status==="0"){
                    setShowErrorModal(true);
                }
                if (id) {
                    if (props.gameType === GAME_TYPE.tournament) {
                        let respnew = await joinTournament({
                            user_id: user?.id!,
                            team_id: resp?.data.Team_id!,
                            tournament_id: id!
                        }) as string;

                        let msgnew = respnew as any;
                        // console.log(msgnew)
                        if (msgnew) {
                            props.onClose()
                        }
                        await loadTournamentDetails(id!, user?.id!);
                    }
                    else if (props.gameType === GAME_TYPE.battleroyale) {
                        msg = await joinBattleRoyale({
                            user_id: user?.id!,
                            team_id: resp?.data.Team_id!,
                            battleroyale_id: id!
                        }) as string;
                        props.onClose()
                        await loadBattleRoyaleDetails(id!, user?.id!);
                    }
                } else {
                    props.onClose()
                    myTeam(user?.id!)
                }
                showSuccess(msg)
            }
        }
    }
    return (
        <div>
            <Formik
                initialValues={initialState}
                onSubmit={(values, { setErrors, setFieldValue, resetForm }) => {
                    handleSubmit(values, setErrors, setFieldValue)
                }}

            >
                {(formik) => {
                    const member_size = Number(formik.values?.team_size) - 1 || 0; // since 1 field for in game id
                    return (
                        <Form>
                            <div className="row text-center">
                                <div className="col-sm-6">
                                    <TeamName name='team_name' required />
                                </div>
                                <div className="col-sm-6">
                                    <PlatformDropdown name='team_platform' value={props.platformId} disabled={props.platformId! ? true : false} required />
                                </div>
                                <div className="col-sm-6">
                                    <GameDropdown name='team_game' value={props.gameId} disabled={props.size! ? true : false} required />
                                </div>
                                <div className="col-sm-6">
                                    <TeamSizeDropdown name='team_size' value={props.size} disabled={props.gameId! ? true : false} required />
                                </div>
                                <div className="col-sm-6">
                                    {/* <TeamImage name='team_image' formik={formik}/> */}
                                    <div className="form-grop">
                                        <label htmlFor={'team_image'}>Team Image</label>
                                        <input type="file" id='team_image' name={'team_image'} placeholder='' accept="image/*" className="form-control" onChange={(e: any) => {
                                            formik.setFieldValue('team_image', e.currentTarget.files[0])
                                        }
                                        } />
                                        {formik?.errors?.team_image && <p className="text-danger small text-start">{formik?.errors?.team_image}</p>}
                                    </div>
                                </div>
                                <div className="col-12 form-grop">
                                    <div className="section section-title">
                                        <div className="text-left section">
                                            <label htmlFor="">Team members</label>
                                            <p>Please enter your team member information</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">

                                    {(member_size < 0)
                                        ? <></>
                                        : member_size === 0 && Number(formik.values?.team_size) === 1 ?
                                            <><TeamMembersHeading required />
                                                <div className="row">
                                                    <div className="col-6">
                                                        {/* <TeamMemberAutocomplete name={`${TEAM_MEMBER}_name_${idx + 1}`} required /> */}
                                                        <TeamMember name='user' disabled />
                                                    </div>
                                                    <div className="col-6">
                                                        <MemberGameId name='in_game_id' required />
                                                    </div>
                                                </div></>
                                            : <>
                                                <TeamMembersHeading required />
                                                <div className="row">
                                                    <div className="col-6">
                                                        {/* <TeamMemberAutocomplete name={`${TEAM_MEMBER}_name_${idx + 1}`} required /> */}
                                                        <TeamMember name='user' disabled />
                                                    </div>
                                                    <div className="col-6">
                                                        <MemberGameId name='in_game_id' required />
                                                    </div>
                                                </div>
                                                {[...new Array(member_size)].map((item, idx) => (
                                                    <div className="row" key={idx}>
                                                        <div className="col-6">
                                                            <TeamMemberAutocomplete name={`${TEAM_MEMBER}_name_${idx + 1}`} required />
                                                        </div>
                                                        <div className="col-6">
                                                            <MemberGameId name={`${TEAM_MEMBER}_id_${idx + 1}`} required />
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                    }
                                    {/* <TeamMembers count={totalMember - 1} name={TEAM_MEMBER} onChange={handleChange} /> */}
                                </div>
                                {/* {
                                    !(props.platformId! || props.size! || props.gameId!)
                                        ? <div className="col-12">
                                            <div className="section section-title align-items-center justify-content-between">
                                                <div>
                                                    <label htmlFor="">Substitutes</label>
                                                </div>
                                                {
                                                    addedMember <= 1
                                                        ? <div onClick={handleMember}>
                                                            <a className="addmore-btn">+ Add Member</a>
                                                        </div>
                                                        : <></>
                                                }
                                            </div>
                                            <TeamMembersHeading />
                                            <TeamMembers count={addedMember} name={SUBSTITUTE} onChange={handleChange} />
                                        </div>
                                        : <></>
                                } */}
                            </div>
                            <div className="section text-center" >
                                <button className="btn-main btn-two-line" type={(creating || check_teamname_loading || join_battleroyale_loading || join_tournament_loading) ? 'button' : "submit"} style={{ position: 'relative', cursor: creating ? 'auto' : 'pointer' }}>
                                    {(creating || check_teamname_loading || join_battleroyale_loading || join_tournament_loading) ? 'Loading...' : 'Create Team'}
                                    {(creating || check_teamname_loading || join_battleroyale_loading || join_tournament_loading) && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </button>
                                {join_tournament_errornew && <p className='text-danger small text-center mt-2'>{join_tournament_errornew}</p>}
                                {create_error && <p className='text-danger small text-center mt-2'>{join_tournament_errornew}</p>}

                            </div>
                        </Form>
                    )
                }
                }
            </Formik>

            <ErrorModal show={showErrorModal} onClose={()=>setShowErrorModal(false)}>
                    <div className="title-raw">
                        <h3>Alert!</h3>
                    </div>
                    {success_msg}
            </ErrorModal>

        </div>
    )
})