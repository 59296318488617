import  api from "../../core/api";
import {oldApi } from "../../core/api";
import { Banner } from "../model/banner";
import { TournamentResponse } from "../model/tournament";
import { R } from "../pkg/types";

export interface IHomeRepository {
  getBannners(args:{device_type:string}): Promise<R<Banner[]>>;
  getTournaments(): Promise<R<TournamentResponse[]>>;
}

class HomeRepository implements IHomeRepository {
  async getBannners(args:{device_type:string}): Promise<R<Banner[]>> {
    try {
      // const resp = await oldApi.get(`/Home_api/banners?${new URLSearchParams(args)}`, {
      const resp = await api.get(`/get_banners?${new URLSearchParams(args)}`, {
        // headers: {
        //   "x-api-key": "satyam@123",
        // },
      });

      const list = resp.data.data as Banner[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }
  async getTournaments(): Promise<R<TournamentResponse[]>> {
    try {
      const resp = await oldApi(`/Home_api/tournaments_list`, {
        method: "GET",
        headers: {
          "x-api-key": "satyam@123",
        },
      });

      const list = resp.data.data as TournamentResponse[];
      return new R({ result: list });
    } catch (e) {
      return new R({ error: e });
    }
  }
}

export const homeRepository: IHomeRepository = new HomeRepository();
