import React from "react";
import { makeAutoObservable, runInAction } from "mobx";
import { LedgerResponse } from "../../_common/model/wallet";
import { walletRepository, WalletRepository } from "../../_common/repository/wallet";

export class LedgerStore {
    ledger_loading=false;
    ledger_error = "";
    ledger_list:LedgerResponse[]=[];
    active_page = 1;
    total_pages = 1;
    limit = 20;
    
    ledgerAPI: WalletRepository
    constructor(args:{
        ledgerAPI: WalletRepository
    }){
        makeAutoObservable(this);
        this.ledgerAPI=args.ledgerAPI;
    }

    loadLedger=async (userId: string, pageNo: number)=>{
        runInAction(()=>{
            this.ledger_loading=true;
        })
        const resp = await this.ledgerAPI.getLedger(userId, pageNo);
        if(resp.hasError){
            runInAction(()=>{
                this.ledger_loading=false;
                this.ledger_error=resp.error!;
            })
        }
        runInAction(()=>{
            this.ledger_loading=false;
            this.ledger_list=resp.result!;
            this.total_pages = Math.ceil(Number(resp.count)/this.limit)
        })
    }
    
    changePage = (pageNo: number) => {
        this.active_page = pageNo;
    }
}

const LedgerStoreContext = React.createContext(new LedgerStore({ledgerAPI: walletRepository}));
export const useLedgerStore = ()=>React.useContext(LedgerStoreContext)