import React from 'react'
import { BackButton } from './BackButton';

interface Props {
    isLoading?: boolean;
    bgImg?: string;
    heading?: string;
    teams?: string;
    coins?: string;
    children?: React.ReactNode;
}

export const TeamsPage: React.FC<Props> = (props) => {
    const { bgImg, heading, teams, coins, children, isLoading } = props;
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const from_campaign = new URLSearchParams(window.location.search).get('from_campaign');
    return (
        <>
            <div className={queryParams && from_campaign ==="itc"?"main-wrapper tournament-details-col section itc-tournament-details-col":"main-wrapper tournament-details-col section"} style={queryParams && from_campaign ==="itc" ? {background:`url(${require('../../assets/images/itc-main-banner.jpg')})`, backgroundSize:'cover'}:{}} >
                <div className="inner-banner section"
                    style={{ backgroundImage: `linear-gradient(44deg, rgba(0, 0, 0, 0.6), rgba(5, 6, 8, 0.6)), url('${bgImg}')` }}>
                    <div className="container-fluid">
                        <BackButton />
                        <div className={queryParams && from_campaign ==="itc"?"inner-banner-con title-raw title-raw-itc":"inner-banner-con"}>
                            {isLoading 
                                ? <h4 className='my-5'>Data Loading...</h4>
                                : <>
                                    <h1>{heading}</h1>
                                    <p className={queryParams && from_campaign ==="itc"?"font-MonsterratRegular":""}>Teams Joined :  {teams} | Prize Pool: {`${coins} IGL Coins`} </p>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`common-page-raw tournament-team-col profile-details-raw section section ${queryParams && from_campaign ==="itc"?"itc-tournament-details-col":""}`} style={queryParams && from_campaign ==="itc" ? {background:'#111'}:{}}>
                <div className="container">
                    <div className="team-overview-raw section text-center pt50 pb10">
                        <div className="section-team-overview section line-arrow">
                            <div className="the-team-raw  section">
                                <div className="container">

                                <div className="row justify-content-center align-items-center">
                                    {children}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}