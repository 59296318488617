import { observer } from 'mobx-react';
import React from 'react'
import Socialmedia from '../../_common/widget/Socialmedia';
import Name from "../widget/Name";
import Email from "../widget/Email";
import Password from "../widget/Password";
import City from "../widget/City";
import Number from "../widget/Number";
import Checkbox from '../widget/Checkbox';
import TermsCondition from "../widget/TermsCondition";
import { useAuthStore } from '../store/auth';
import Button from '../widget/Button';
import { Form, Formik } from 'formik';
import { REGISTER_VALIDATION_SCHEMA } from '../validation';
import Username from '../widget/Username';
import { ErrorMsg } from '../../_common/widget/ErrorMsg';

const initialState = {
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    mobile: "",
    city: "",
    termcheckage: false,
    termcheck: false,
    newscheck: false,
}

export const RegisterForm = observer(() => {

    const { register, registering, registerError, showLogin } = useAuthStore()

    let handleSubmit = async (values: any) => {
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.has('from_campaign') || window.location.pathname==="/bingo-nachos"){
            register({
                firstname: values.firstname,
                lastname: values.lastname,
                username: values.username,
                email: values.email,
                password: values.password,
                mobile: values.mobile,
                termcheckage: values.termcheckage,
                termcheck: values.termcheck,
                newscheck: values.newscheck,
                from_campaign: window.location.pathname==="/bingo-nachos"?"itc":queryParams.get('from_campaign')!
            })
            return;
        }
        register({
            firstname: values.firstname,
            lastname: values.lastname,
            username: values.username,
            email: values.email,
            password: values.password,
            mobile: values.mobile,
            termcheckage: values.termcheckage,
            termcheck: values.termcheck,
            newscheck: values.newscheck
        })
    }
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="title-raw">
                        <h3>Create an Account</h3>
                    </div>
                </div>
            </div>
            <ErrorMsg msg={registerError} />
            <Formik
                initialValues={initialState}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values)
                }}
                validationSchema={REGISTER_VALIDATION_SCHEMA}
            >
                <Form
                    autoComplete='off'
                > 
                    <div className="row">
                    <input autoComplete="false" name="hidden" type="text" style={{display:"none"}}/>
                        <Name
                            name="firstname"
                            label="First Name"
                            isFloating
                        />
                        <Name
                            name="lastname"
                            label="Last Name"
                            isFloating
                        />
                        <Username
                            name="username"
                            label="User Name"
                            isFloating
                        />
                        <Email
                            name="email"
                            label="Email"
                            isFloating
                        />
                        <Password
                            name="password"
                            label="Password"
                        />
                        <Password
                            name="confirmpassword"
                            label="Confirm Password"
                        />
                        <Number
                            name="mobile"
                            label="Mobile Number"
                            isFloating
                        />
                        <City
                            name="city"
                            label="City"
                            isFloating
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="terms-raw">
                                <Checkbox
                                    name="termcheckage"
                                    label="I confirm that I am at least 18 years of age!"
                                />
                                <TermsCondition
                                    name="termcheck"
                                />
                                <Checkbox
                                    name="newscheck"
                                    label="I would like to receive newsletters from iglnetwork.com."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <Button type="submit" label={registering ? 'Registering..' : 'Register now!'} />
                            <label className="already-member-col">Already a Member? <a onClick={showLogin}>Login</a></label>
                            <Socialmedia />
                        </div>
                    </div>
                </Form>
            </Formik>
        </>
    )
});