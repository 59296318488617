import { makeAutoObservable, runInAction } from 'mobx'
import React from 'react'
import { TeamvotinglistResp, Teamvotinglist } from '../../_common/model/teamvoting'
import { teamVotingRepository , TeamVoting as TeamVotingClass } from '../../_common/repository/teamvoting'
export class TeamVoting {
    getTeams: TeamVotingClass
    getTeamsError=""
    getTeams_loading = true
    getTeams_list: TeamvotinglistResp[] = []
    selectTeams:TeamVotingClass
    modalState=false;
    modalData:Teamvotinglist={}
    afterVoteModal=false;
    vote_loading: boolean = false;
    voting_error: string = ""
    constructor(args: {
        getTeams: TeamVotingClass,
        selectTeams:TeamVotingClass,
    }) {
        makeAutoObservable(this);
        this.getTeams = args.getTeams;
        this.selectTeams= args.selectTeams;
    }

    loadTeamVotingList = async (args:string) => {
        
        runInAction(() => {
            this.getTeams_loading = true;
            this.getTeamsError = "";
        })
        const resp = await this.getTeams.getTeams(args);
        if (resp.hasError) {
            runInAction(() => {
                this.getTeams_loading = false;
                this.getTeamsError = resp.error!;
            })
            return
        }
        runInAction(() => {
            this.getTeams_loading = false;   
            this.getTeams_list = resp.result!;
        })
    }
    
    modalStatechange = () => {
        runInAction(() => {
            this.modalState = !this.modalState;
        })
        return
    }
    modalDataChange=async (args:Teamvotinglist)=>{
        runInAction(() => {
            this.modalData=args
        })
        return
    }
    voteTeam=async (args:object,userId:string)=>{
        runInAction(() => {
            this.vote_loading = true;
            this.voting_error = "";
        });
        
        const resp= await this.selectTeams.SelectTeam(args);
          if (resp.hasError) {
            runInAction(() => {
              this.voting_error = resp.error!;
              this.vote_loading = false;
            });
            return;
          }
          runInAction(() => {
            this.vote_loading = false;
            this.voting_error = resp.error!;
          });
          this.modalStatechange();
          this.openAfterVoteModal();
          this.loadTeamVotingList(userId);
    }
    
    closeAfterVoteModal = () => {
        runInAction(() => {
            this.afterVoteModal = false;
        })
    }
    
    openAfterVoteModal = () => {
        runInAction(() => {
            this.afterVoteModal = true;
        })
    }
    
}
export const TeamVoitingStoreContext = React.createContext(new TeamVoting({ getTeams: teamVotingRepository,selectTeams: teamVotingRepository}));
export const useTeamVotingStore = () => React.useContext(TeamVoitingStoreContext);