import React from 'react'
import { GAME_TYPE } from '../../core/config';
import { GameRules } from '../../_common/widget/GameRules';
import { LobbyCode } from '../../battleroyale/widget/LobbyCode';
import { ShareButton } from './ShareButton';
import { BracketButton } from './BracketButton';
import { CheckedInTeamsButton } from './CheckedInTeamsButton';
import { JoinedTeamsButton } from './JoinedTeamsButton';
import { JoinTournamentButton } from './JoinTournamentButton';
import { JoinBattleRoyaleButton } from '../../battleroyale/widget/JoinBattleRoyaleButton';
import { TournamentProps } from '../../_common/model/tournament';
import { useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react';

export const GameAction = observer((props: TournamentProps) => {
    const { gameType, rules="", lobbyCode, scoringText="", howToPlay="" } = props;
    const detail_endpoint = gameType === GAME_TYPE.tournament ? 'tournament' : 'battleroyale';
    const {id} = useParams();
    return (
        <>
            <div className="section text-center">
                <div className="row">
                    <div className="col-sm-12 col-12">
                        {gameType === GAME_TYPE.tournament
                            ? <JoinTournamentButton {...props} />
                            : <JoinBattleRoyaleButton {...props} />
                        }
                    </div>
                    <div className="col-sm-12 col-12 top-btns">
                        {/* <button className="btn-ac like d-none"><span>Follow</span></button> */}
                        <ShareButton />
                        {/* {rules===""?null:<GameRules>
                            <h4>Rules:</h4>
                            <div dangerouslySetInnerHTML={{ __html: rules! }}></div>
                            <h4>Scoring:</h4>
                            <div dangerouslySetInnerHTML={{ __html: scoringText! }}></div>
                            <h4>HOW TO PLAY?:</h4>
                            <div dangerouslySetInnerHTML={{ __html: howToPlay! }}></div>
                        </GameRules>} */}
                        {(rules!=="" || scoringText!=="" || howToPlay!=="")?<GameRules>
                            {rules!==""?
                            <>
                            <h4>Rules:</h4>
                            <div dangerouslySetInnerHTML={{ __html: rules! }}></div>
                            </>:null}
                            {scoringText!==""?<><h4>Scoring:</h4>
                            <div dangerouslySetInnerHTML={{ __html: scoringText! }}></div></>:null}
                            {howToPlay!==""?<><h4>HOW TO PLAY?:</h4>
                            <div dangerouslySetInnerHTML={{ __html: howToPlay! }}></div></>:null}
                            
                            
                        </GameRules>:null}
                    </div>
                    <div className="col-sm-12 col-12 btm-btns">
                        <JoinedTeamsButton detailEndpoint={detail_endpoint} {...props} />
                        {gameType === GAME_TYPE.battleroyale
                            ? <LobbyCode lobbyCode={lobbyCode} />
                            : <BracketButton {...props} id={id} />
                        }
                        <CheckedInTeamsButton detailEndpoint={detail_endpoint} />
                    </div>
                </div>
            </div>
        </>
    )
})