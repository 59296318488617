import api from "../../core/api";
import { ITeamDetail } from "../model/teamdetails";
import { R } from "../pkg/types";
export class TeamDetailsRepo {
    async getTeamDetails(team_id:number|string): Promise<R<ITeamDetail>> {
        try {
            const resp = await api.get(`/teamdetails?team_id=${team_id}`);
            const list = resp.data.Userlist as ITeamDetail[];
            if (!list) throw resp.data.msg
            const returnlist= list[0]
            // console.log(returnlist)
            return new R({result: returnlist});
        }
        catch (e: any) {
            return new R({error: e.response.data.msg});
        }
    } 
    // async getNewsDetails(id: string, userId: string): Promise<R<INewsDetails>> {
    //     try {
    //         const resp = await api.get(`/newsdetails?user_id=${userId}&news_id=${id}`);
    //         const details = resp.data.NewsDetails as INewsDetails;
    //         if (!details) throw resp.data.msg
    //         return new R({result: details});
    //     }
    //     catch (e: any) {
    //         return new R({error: e.response.data.msg});
    //     }
    // }
    // async setNewsLike(payload: INewsLikeRequest): Promise<R<string>> {
    //     try {
    //         const resp = await api.post(`/newslike`
    //             , payload
    //             , {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                 }

    //             });
    //         const result = resp.data;
    //         if (!result) throw resp.data.msg
    //         return new R({ result: result});
    //     }
    //     catch (e: any) {
    //         return new R({ error: e.response.data.msg })
    //     }
    // }
    
    // async setNewsPost(payload: INewsLikeRequest): Promise<R<string>> {
    //     try {
    //         const resp = await api.post(`/comment_news`
    //             , payload
    //             , {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                 }

    //             });
    //         const result = resp.data;
    //         if (!result) throw resp.data.msg
    //         return new R({ result: result});
    //     }
    //     catch (e: any) {
    //         return new R({ error: e.response.data.msg })
    //     }
    // }
    // async getNewsComments(params:INewsRequest): Promise<R<INewsComment[]>> {
    //     try {
    //         const resp = await api.get(`/get_newscomments?user_id=${params.user_id}&news_id=${params.news_id}`);
    //         const list = resp.data.NewsComment as INewsComment[];
    //         if (!list) throw resp.data.msg
    //         return new R({result: list});
    //     }
    //     catch (e: any) {
    //         return new R({error: e.response.data.msg});
    //     }
    // } 
}

export const teamDetailsRepo = new TeamDetailsRepo();