import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTeamDetailStore } from './store/teamDetails';
import { observer } from 'mobx-react';
import { Image } from '../_common/widget/Image';

const TeamDetails = observer(() => {
    const { id } = useParams();
    const { loadProfileInfo, teamDetailsData } = useTeamDetailStore();
    // console.log(id)
    useEffect(() => {
        if (id) {
            loadProfileInfo(id);
        }
    }, []);
    // useEffect(() => {
    //     console.log(teamDetailsData);
    // }, [teamDetailsData]);

    return (
        <div className='main-wrapper profile-raw section'>
            <div className="container">
                <div className="tab-pane" id="team" role="tabpanel">
                    <div className="team-overview-raw section text-center pt10">
                        <h2>Teams overview</h2>
                        <div className="section-team-detail section mt80 d-block">
                            <div className="won-lost-raw pb10 pdlr15 section">
                                <div className="row">
                                    <ProgressCircle won={teamDetailsData?.TournamentWinCount!} lost={teamDetailsData?.TournamentLossCount!} type="Tournaments" />
                                    <ProgressCircle won={teamDetailsData?.ChallengeWinCount!} lost={teamDetailsData?.ChallengeLossCount!} type="Challenges" />
                                    <ProgressCircle won={teamDetailsData?.BRWinCount!} lost={teamDetailsData?.BRLossCount!} type="Battle Royale" />
                                </div>
                            </div>
                            <div className="the-team-raw mt50 pdlr15 section">
                                <div className="row justify-content-center mb-3 mb-md-5">
                                    <div className="col-sm-6 col-12 team-li">
                                        <div className="team-li-conn">
                                            <div className="team-img edit-member-btn mb-3">
                                                <Image src={teamDetailsData?.TeamImage!} alt={teamDetailsData?.TeamName!} />
                                            </div>
                                            <h4 className="edit-member-btn pb-2">{teamDetailsData?.TeamName}</h4>
                                            <div className="team-details-col">
                                                <table>
                                                    <tbody><tr>
                                                        <td>Owner</td>
                                                        <td>-</td>
                                                        <td>
                                                        <h5 className='font-D-DinCondensedBold'><Link to={`/user/${teamDetailsData?.id}/profile`}>{teamDetailsData?.username}</Link></h5>
                                                        </td>
                                                    </tr>
                                                        <tr>
                                                            <td>Members</td>
                                                            <td>-</td>
                                                            <td>
                                                                <h5 className='font-D-DinCondensedBold'>{teamDetailsData?.MemberCount}</h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Game</td>
                                                            <td>-</td>
                                                            <td>
                                                                <h5 className='font-D-DinCondensedBold'><Link to={`/games/details/${teamDetailsData?.TeamGameID}`}>{teamDetailsData?.GameTitle}</Link></h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Game ID</td>
                                                            <td>-</td>
                                                            <td>
                                                            <h5 className='font-D-DinCondensedBold'>
                                                                    {/* <!-- <a href="games/details/"></a> --> */}

                                                                   {teamDetailsData?.TeamUserGameID}


                                                                </h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Platform</td>
                                                            <td>-</td>
                                                            <td>
                                                                <h5 className='font-D-DinCondensedBold'>{teamDetailsData?.PlatformName}</h5>
                                                            </td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 team-li py-4 py-sm-0">
                                        <div className="team-li-conn">
                                            <h4 className='mb-3'>TEAM MEMBERS</h4>
                                            {teamDetailsData?.memberlist?.map((memberitem, indexmember) =>
                                                <div className="team-details-col mb-3" key={indexmember}>
                                                    <div className="img-box">
                                                        <Image src={memberitem?.UserProfileImage!} alt={memberitem.username!} />
                                                        {/* <img src={memberitem?.UserProfileImage} className="img-fluid" alt={memberitem.username} /> */}
                                                    </div>
                                                    <div className="member-con">
                                                    <h5><Link to={`/user/${memberitem?.id}/profile`}>{memberitem.username}</Link></h5>
                                                        <h6>{memberitem?.Teammemgameid !== null || memberitem?.Teammemgameid !== undefined || memberitem?.Teammemgameid !== "" ? memberitem?.Teammemgameid : memberitem?.TeamUserGameID}</h6>
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TeamDetails

type ProgressProps = {
    won: string;
    lost: string;
    type: string;
}
const ProgressCircle = (props: ProgressProps) => {
    const { won, lost, type } = props;
    const percent = lost !== "0" ? (Number(won) / Number(lost)) * 100 : 0;
    return (
        <div className="col-sm-4 col-6">
            <div className="progress progress-circle-raw" data-percentage={Math.round(percent)}>
                <span className="progress-left">
                    <span className="progress-bar"></span>
                </span>
                <span className="progress-right">
                    <span className="progress-bar"></span>
                </span>
                <div className="progress-value">
                    <div className="won-col">
                        {won}<br />
                        <span>Won</span>
                    </div>
                    <div className="lost-col">
                        {lost}<br />
                        <span>Lost</span>
                    </div>
                </div>
            </div>
            <h5 className="bottom-shadow">{type}</h5>
        </div>
    )
}