import { Field } from 'formik';
import React from 'react'
import { FieldProps } from '../../_common/model/field'
import { FieldError } from '../../_common/widget/FieldError';

export const Comment = (props: FieldProps) => {
    const { name = "comment", label } = props;

    return (
        <div className="col-md-12">
            <div className="form-group">
                <Field
                    {...props}
                    component="textarea"
                    className="form-control" placeholder={label}
                    maxLength={500}
                />
                <FieldError name={name} />
            </div>
        </div>
    )
}