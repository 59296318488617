import React from 'react'
import { IGameDetails } from '../../_common/model/game'
// import { GameRules } from '../../_common/widget/GameRules'
import { AddToGameProfile } from './AddToGameProfile'
import { GameLike } from './GameLike'
type Props = {
    data: IGameDetails
}
export const GameDetailsBanner = (props: Props) => {
    return (
        <>
            <div className="main-wrapper section gamedetail">
                <div className="inner-banner section" style={{backgroundImage:`linear-gradient(44deg, rgba(0, 0, 0, 0.6), rgba(5, 6, 8, 0.6)), url('${props?.data?.GameBannerImage}')` , height:"575", width:"1349"}}>
                    <div className="container-fluid">
                        <div className="inner-banner-con">
                            <div className="title-raw">
                                <h1>{props.data?.GameTitle}</h1>
                                {/* <GameRules>
                                    <div dangerouslySetInnerHTML={{ __html:  props.data.GameDescription}}></div>
                                </GameRules> */}
                            </div>
                            <ul className="game-rules-col d-none">
                                <GameLike count={props.data?.Glikecount}/>
                                <AddToGameProfile/>                               
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}