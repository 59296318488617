import React, { useEffect } from 'react'
import VideoCard from './VideoCard'
import { observer } from 'mobx-react'
import { useTrendingStore } from '../../igl-tv/store/trendingstore'
import { Link } from 'react-router-dom'

const TrendingVideoSection = observer(() => {
	const {newlatestvideo,listLatestVideo} =  useTrendingStore()!
	useEffect(()=> {
		newlatestvideo()
	},[])
  return (
    <div className="game-lists-raw trending-videos-raw section">
			<div className="title-raw">
				<h2>Trending videos<span>Trending videos</span></h2>
			</div>
			<div className="section gm-li-raw text-center">
				<div className="row">
					
							{listLatestVideo?.map((data,index) => (
								<VideoCard 
								TVID={data.TVID}
								TVTitle={data.TVTitle}
								TournamentTitle={data.TournamentTitle}
								TVVideoID={data.TVVideoID}
								GameTitle={data.GameTitle}
								TVDate={data.TVDate}
								GameID={data.GameID}
								TournamentID={data.TournamentID}
								TVlike={data.TVlike}
								islike={data.islike}
								key={index}
								/>
								))}
				
				</div>
						<Link to="/tv" className="mt50 btn-sm btn-main txt-shad-none">View all</Link>
			</div>
		</div>
  )
})

export default TrendingVideoSection