import { observer } from 'mobx-react';
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuthStore } from '../../auth/store/auth';
import { DETAILS_TYPE, GAME_TYPE } from '../../core/config';
import { useBattleRoyaleDetailsStore } from '../store/battleroyaledetails';
import { DetailsSection } from '../../tournament/view/DetailsSection';
import { date } from '../../core/helper';
import { SocialConnect } from '../../_common/widget/SocialConnect';

export const BattleRoyaleDetails = observer(() => {
    const { loadBattleRoyaleDetails,battleroyale_details_loading, battleroyale_details, lobby_code,battleroyale_details_error } = useBattleRoyaleDetailsStore();
    const { user } = useAuthStore();
    const { id } = useParams();
    React.useEffect(() => {
       
        window.scrollTo(0, 0);
    }, [battleroyale_details]);
    React.useEffect(() => {
        if(!user?.id){
            return;
        }
        loadBattleRoyaleDetails(id!, user?.id!);
        // console.log(Number(battleroyale_details?.RoyalBattleNumberofGroups ) * Number(battleroyale_details?.RoyalBattleTeamsInGroup))
    }, [id]);
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const from_campaign = new URLSearchParams(window.location.search).get('from_campaign');
    return (
        <>
        {battleroyale_details_loading?
        <div className={`row ${queryParams && from_campaign ==="itc" ? "itc-tournament-details-col":""}`}>
            <div className="col-12 d-flex justify-content-center align-items-center" style={{height:'80vh'}}>
                <div className={`title-raw ${queryParams && from_campaign ==="itc" ? "title-raw-itc":""}`}>
                    <h1>Loading...</h1>
                </div>
            </div>
        </div>:
        battleroyale_details_error!==""?
        <div className={`row ${queryParams && from_campaign ==="itc" ? "itc-tournament-details-col":""}`}>
        <div className="col-12 d-flex justify-content-center align-items-center" style={{height:'80vh'}}>
            <div className="title-raw">
                <h1>{battleroyale_details_error}</h1>
            </div>
        </div>
    </div>:
            <DetailsSection
                bgImg={battleroyale_details?.RoyalBattleCoverImage}
                heading={battleroyale_details?.RoyalBattleTitle!}
                detailType={DETAILS_TYPE.battleroyale}
                platform={battleroyale_details?.PlatformName}
                platformId={battleroyale_details?.RoyalBattlePlatformID}
                prize={battleroyale_details?.IGLRoyaleBattleCoins}
                teams={`${battleroyale_details?.JoinCount}/ ${Number(battleroyale_details?.RoyalBattleNumberofGroups ) * Number(battleroyale_details?.RoyalBattleTeamsInGroup)}`}
                size={battleroyale_details?.RoyalBattleTeamSize}
                TotalCheckinCount={`${battleroyale_details?.Checkincount}/${Number(battleroyale_details?.RoyalBattleNumberofGroups ) * Number(battleroyale_details?.RoyalBattleTeamsInGroup)}`}
                date={date(battleroyale_details?.BattleRoyaleDate!)}
                startTime={battleroyale_details?.BattleRoyaleTime}
                endTime={battleroyale_details?.BattleRoyaleEndTime}
                checkInTime={battleroyale_details?.CheckinTime}
                checkInCloseTime={battleroyale_details?.CheckoutTime}
                // 
                startdate={battleroyale_details?.RoyalBattleStartDate}
                enddate={battleroyale_details?.BattleRoyaleEndDate}
                checkInDate={battleroyale_details?.CheckInstartDate}
                checkInCloseDate={battleroyale_details?.CheckInCloseDate}
                // 
                // level={battleroyale_details?.RoyalBattleLevel}
                level={battleroyale_details?.RoyalbattlelevelName}
                levelId={battleroyale_details?.RoyalBattleLevelID}
                game={battleroyale_details?.GameTitle}
                expirationDate={date(battleroyale_details?.BattleRoyaleEndDate!)}
                expirationTime={battleroyale_details?.BattleRoyaleEndTime}
                timeZone={battleroyale_details?.RoyalBattleTimeZone}
                entryFee={battleroyale_details?.RoyaleBattleEntryFees}
                gameType= {GAME_TYPE.battleroyale}
                gameId = {battleroyale_details?.RoyalBattleGameID}
                rules={battleroyale_details?.RoyalBattleRulesText}
                scoringText={battleroyale_details?.RoyalBattleScoringText}
                howToPlay={battleroyale_details?.RoyalBattleHowToPlay}
                lobbyCode={lobby_code}
                isPrivate={battleroyale_details?.tournaments_type}
                prize1={battleroyale_details?.RoyaleBattlePointPrize1}
                prize2={battleroyale_details?.RoyaleBattlePointPrize2}
                prize3={battleroyale_details?.RoyaleBattlePointPrize3}
                prize4={battleroyale_details?.RoyaleBattlePointPrize4}
                prize5={battleroyale_details?.RoyaleBattlePointPrize5}
                winnerPoints={battleroyale_details?.RoyaleBattlePoint1}
                runnerup1stPoint={battleroyale_details?.RoyaleBattlePoint2}
                runnerup2ndPoints={battleroyale_details?.RoyaleBattlePoint3}
                runnerup3ndPoints={battleroyale_details?.RoyaleBattlePoint4}
                runnerup4ndPoints={battleroyale_details?.RoyaleBattlePoint5}
                roundTime={battleroyale_details?.RoyaleBattleTimeinRounds}
                from_campaign={battleroyale_details?.from_campaign}
            />}
             <SocialConnect />
        </>
    )
});