import React, { useEffect, useState, Suspense} from "react";
import { observer } from "mobx-react"
import { MoreNewsButton } from "../widget/MoreNewsButton"
import { NewsListing } from "../widget/NewsListing"
import { useNewsStore } from "../store/news";
import { useAuthStore } from "../../auth/store/auth";
import { useProfileStore } from "../../profile/store/profileInfo";
// import AdSlot from "../../_common/view/AdsComponent/AD_Index";

export const NewsPage = observer(() => {
    const AdSlot = React.lazy(()=> import('../../_common/view/AdsComponent/AD_Index'));
    const { news_list } = useNewsStore();
    const { user } = useAuthStore();
    const { loadProfileInfo } = useProfileStore();
    React.useEffect(() => {
      if (user?.id) {
        loadProfileInfo(user?.id);
    }
    }, []);

// ads code below
    const [isMobile, setIsMobile] = useState<boolean>(false);
    useEffect(() => {
    // Check the window width and set isMobile accordingly
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as per your requirement
    };

    handleResize(); // Call once on initial load

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
    return (
        <>
            <div className="main-wrapper section">
                <div className="section mt80">
                    <div className="container">
                        <div className="row">
                            <div className="title-raw">
                                <h1>Latest news<span>Latest news</span></h1>
                                <p className="text-light ">Get Updated With The Latest Gaming News</p>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">  
                            {!isMobile && <Suspense fallback={<></>}>
                                <AdSlot adUnitPath="/317733190/IGL_Web_News_728x90_Ad1" adSize={[728, 90]} />
                            </Suspense>}
                            {isMobile && <Suspense fallback={<></>}>
                                <AdSlot adUnitPath="/317733190/IGL_Web_News_300x250_Ad1" adSize={[300, 250]} />
                            </Suspense>}
                            </div>
                        </div>
                            <NewsListing />
                    </div>
                </div>
            </div>
            {news_list?.length>=10?
            <div className="common-page-raw section">
                <div className="container-fluid">
                    <div className="section line-arrow pb10">
                        <div className="row">
                            <div className="col-12 text-center">
                                <MoreNewsButton />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :null}
        </>
    )
})