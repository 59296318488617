import { observer } from 'mobx-react';
import React from 'react'
import { Link, useParams } from 'react-router-dom'

type Props = {
    detailEndpoint: string;
}

export const JoinedTeamsButton = observer((props: Props) => {
    const { detailEndpoint } = props;
    const { id } = useParams();
    const queryParams = new URLSearchParams(window.location.search).has('from_campaign');
    const from_campaign = new URLSearchParams(window.location.search).get('from_campaign');
    const detail_endpoint = detailEndpoint === "battleroyale" ? "brjoinedteams" : "joinedteams";
    const path = `/tournament/${detail_endpoint}/${id}${queryParams && from_campaign ==="itc"?"?from_campaign=itc":""}`;

    return (
        <>
            <Link to={path} className="btn-ac ic-team"><span>Teams joined</span></Link>
        </>
    )
})