import { Field } from 'formik';
import React from 'react'
import { FieldProps } from '../../_common/model/field';
import { FieldError } from '../../_common/widget/FieldError';

const Name = (props: FieldProps) => {
    const { label, isFloating = false } = props;
    return (
        <div className="col-md-6">
            {
                isFloating
                    ? <div className="floating-label-group section">
                        <TextField {...props} />
                        <label className="floating-label">{label}</label>
                    </div>
                    : <div className="form-group">
                        <TextField {...props} placeholder={label} />
                    </div>
            }
        </div>
    )
}
type TextFieldProps = FieldProps & { placeholder?: string }
const TextField = (props: TextFieldProps) => {
    const { name = "name" } = props;

    return (
        <>
            <Field
                {...props}
                className="form-control"
            />
            <FieldError name={name} />
        </>
    )
}

export default Name